import { ArrowForwardRounded, ErrorOutlineRounded, InfoOutlined, WarningAmberRounded } from '@mui/icons-material';
import { Box, Card, Stack, Tooltip, darken, lighten, useTheme } from '@mui/material';
import { MouseEvent } from 'react';
import { Banner } from '../../utils';

export type SideBarAlertSeverity = 'error' | 'warning' | 'info';

export interface SideBarAlertProps {
  severity: SideBarAlertSeverity;
  message: string;
  /// Only shown when isCompact is true. Uses the message if none is provided
  tooltip?: string;
  isCompact: boolean;
  action?: (e: MouseEvent<HTMLDivElement>) => void;
}

export function SideBarAlert({ severity, message, tooltip, isCompact, action }: SideBarAlertProps) {
  const theme = useTheme();

  if (isCompact) {
    const getAlertBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const getAlertColor = theme.palette.mode === 'light' ? darken : lighten;

    return (
      <Tooltip title={tooltip ?? message} placement="right" arrow>
        <Card
          elevation={0}
          sx={{
            backgroundColor: getAlertBackgroundColor(theme.palette[severity].light, 0.9),
            p: 2,
            color: getAlertColor(theme.palette[severity].light, 0.6)
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {getIconForSeverity(severity, theme.palette[severity].main)}
          </Box>
        </Card>
      </Tooltip>
    );
  } else {
    return (
      <Banner
        sx={{ p: 1, '& .MuiAlert-icon': { py: 0 }, '& .MuiAlert-message': { py: 0 } }}
        severity={severity}
        icon={getIconForSeverity(severity, undefined)}
        onClick={action != null ? (e) => action?.(e) : undefined}
      >
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 13,
            fontWeight: 600
          }}
        >
          {message}

          {action != null && <ArrowForwardRounded sx={{ fontSize: 15 }} />}
        </Stack>
      </Banner>
    );
  }
}
function getIconForSeverity(severity: SideBarAlertSeverity, color: string | undefined) {
  switch (severity) {
    case 'warning':
      return <WarningAmberRounded fontSize="small" sx={{ color }} />;
    case 'error':
      return <ErrorOutlineRounded fontSize="small" sx={{ color }} />;
    case 'info':
      return <InfoOutlined fontSize="small" sx={{ color }} />;
  }
}
