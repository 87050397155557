import { useServices } from '@/hooks';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface ErrorIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;

  /**
   * The error message. Optional.
   */
  message?: string;
  /**
   * The error icon color. Optional. Default is `error`.
   */
  color?: 'primary' | 'secondary' | 'error' | 'textSecondary';
  size?: 'tiny' | 'small' | 'normal';
  orientation?: 'horizontal' | 'vertical';
  /**
   * If true, a default message will be displayed if message is undefined. Default to `true`
   */
  useDefaultErrorMessage?: boolean;

  action?: {
    title: string;
    onClick: () => void;
  };
}

export const ErrorIndicator = observer(
  ({
    className,
    style,
    message,
    color = 'textSecondary',
    useDefaultErrorMessage = true,
    sx = [],
    size = 'normal',
    orientation = 'vertical',
    action
  }: ErrorIndicatorProps) => {
    const theme = useTheme();
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const isNormal = size === 'normal';
    const isTiny = size === 'tiny';

    const iconSize = isNormal ? 30 : isTiny ? 16 : 24;
    const messageVariant = isNormal ? 'subtitle1' : isTiny ? 'caption' : 'subtitle2';

    return (
      <Stack
        sx={sx}
        className={className}
        style={style}
        spacing={isTiny ? 1 : 2}
        alignItems={orientation === 'vertical' ? 'center' : 'flex-start'}
      >
        <Stack
          direction={orientation === 'vertical' ? 'column' : 'row'}
          spacing={1}
          sx={[
            {
              alignItems: 'center',
              justifyContent: 'center'
            }
          ]}
        >
          <ErrorIcon
            // textSecondary is not a valid color for the icon.
            // So setting it to primary here and overriding it in the sx below
            color={color === 'textSecondary' ? 'primary' : color}
            sx={{ fontSize: iconSize, color: color === 'textSecondary' ? theme.palette.text.secondary : undefined }}
          />
          {(message != null || useDefaultErrorMessage) && (
            <Typography
              variant={messageVariant}
              color={color === 'textSecondary' ? 'textSecondary' : 'textPrimary'}
              sx={{
                textAlign: 'center',
                fontWeight: 500
              }}
            >
              {message ?? strings.errorDefaultMessage}
            </Typography>
          )}
        </Stack>

        {action != null && (
          <Button onClick={() => action?.onClick()} variant={!isTiny ? 'contained-grey' : 'text'} size="small">
            {action.title}
          </Button>
        )}
      </Stack>
    );
  }
);
