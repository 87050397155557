import { useActivePlannerId, useBackButtonListener, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { TimeOfDay, dateToTimeOfDay, timeOfDayIsBetweenOthers, timeOfDayToDate } from '@/models';
import { AllPlannerItemsPasteboardContentKind } from '@/services';
import { UserDashboardPeriodInfoPopoverViewItem } from '@/viewmodels';
import { timestampFromDate } from '@bufbuild/protobuf/wkt';
import { AddRounded, ContentPasteRounded } from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverOrigin,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { format, isToday, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../../lists';
import { Banner, FormPopoverActions, FormPopoverHeader, UpdatablePresenter } from '../../../../utils';
import { CreateWorkOptionsMenu } from '../../../CreateWorkOptionsMenu';
import { resolvedPeriodBackgroundColor } from '../UserDashboardCalendarViewUtils';
import { UserDashboardPeriodInfoPopoverNoteItem } from './UserDashboardPeriodInfoPopoverNoteItem';
import { UserDashboardPeriodInfoPopoverWorkItem } from './UserDashboardPeriodInfoPopoverWorkItem';

export interface UserDashboardPeriodInfoPopoverProps {
  isOpen: boolean;
  anchorEl: null | Element | (() => Element) | undefined;
  date: Date;
  startTime: TimeOfDay;
  endTime: TimeOfDay;
  periodLabel: string;
  courseSectionId: string | undefined;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const UserDashboardPeriodInfoPopover = observer(
  ({
    isOpen,
    anchorEl,
    date,
    startTime,
    endTime,
    periodLabel,
    courseSectionId,
    onClose,
    anchorOrigin,
    transformOrigin
  }: UserDashboardPeriodInfoPopoverProps) => {
    const { dateService, pasteboard, navigation, settings } = useServices();
    const plannerId = useActivePlannerId();
    const theme = useTheme();

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createUserDashboardPeriodInfoPopoverViewModel(
          plannerId,
          date,
          startTime,
          endTime,
          periodLabel,
          courseSectionId
        ),
      [plannerId, date, startTime, endTime, courseSectionId]
    );

    const strings = LocalizedStrings.calendar.periodInfo;
    const location = useLocation();
    const navigate = useNavigateAsync();
    const pasteboardContent = pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind);

    const [createWorkMenuAnchor, setCreateWorkMenuAnchor] = useState<HTMLDivElement | undefined>();

    function addWork(e: MouseEvent<HTMLDivElement>) {
      setCreateWorkMenuAnchor(e.currentTarget);
    }

    function showCreateWork() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToWorkEdit(navigate, location, undefined, {
        dueTime: timestampFromDate(set(date, { ...startTime })),
        isDueAllDay: false,
        courseSectionId
      });
    }

    function showCreateNote() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToNoteEdit(navigate, location, undefined, {
        time: timestampFromDate(set(date, { ...startTime })),
        isAllDay: false,
        courseSectionId
      });
    }

    function showCreatePublishedWork() {
      setCreateWorkMenuAnchor(undefined);
      navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
        dueDate: set(date, { ...startTime }),
        isDueAllDay: false,
        courseSectionId:
          viewModel.occurrence.courseSection?.schoolsCourseSection != null
            ? viewModel.occurrence.courseSection?.courseSection!.id
            : undefined
      });
    }

    function onPaste() {
      setCreateWorkMenuAnchor(undefined);
      viewModel.occurrence.onPaste();
    }

    useBackButtonListener((e) => {
      if (isOpen) {
        e.stopPropagation();
        onClose();
      }
    });

    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        slotProps={{ paper: { sx: { width: 550 } } }}
        onClose={() => onClose()}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Stack>
          <FormPopoverHeader title={format(date, 'PPP')} />

          <UpdatablePresenter
            viewModel={viewModel}
            renderData={() => {
              const { occurrence, items } = viewModel;
              const { courseSection, roomName, ordinal, isSkipped, startTime, endTime } = occurrence;
              const color = courseSection?.courseSection?.color ?? theme.palette.action.disabled;

              const courseTitle = courseSection?.courseSection?.title ?? strings.noClass();
              const section = occurrence.courseSection?.courseSection?.section;

              const formattedStartTime = format(timeOfDayToDate(startTime), 'p');
              const formattedEndTime = format(timeOfDayToDate(endTime), 'p');
              const formattedTimes = strings.times(formattedStartTime, formattedEndTime);
              const showPeriodLabel = settings.calendarShowPeriodLabels && occurrence.periodLabel.length > 0;

              const bgColor = resolvedPeriodBackgroundColor(courseSection?.courseSection?.color, undefined, theme);

              const isCurrent =
                isToday(date) && timeOfDayIsBetweenOthers(dateToTimeOfDay(dateService.now), startTime, endTime);

              return (
                <Stack spacing={2} sx={{ width: '100%', py: 2 }}>
                  {isSkipped && (
                    <Box sx={{ px: 2 }}>
                      <Banner severity="info">{strings.skippedMessage()}</Banner>
                    </Box>
                  )}

                  <Stack spacing={2} sx={{ px: 2 }}>
                    <Stack spacing={1}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: isCurrent ? theme.palette.primary.main : theme.palette.text.secondary,
                          fontWeight: '600',
                          px: 1
                        }}
                      >
                        {formattedTimes}
                      </Typography>

                      <Box sx={{ backgroundColor: bgColor, borderRadius: 1, overflow: 'hidden' }}>
                        <Stack direction="row" spacing={1}>
                          <ColorIndicator color={color} width={8} isRounded={false} />

                          <Stack sx={{ flex: 1, p: 1 }}>
                            <Stack
                              direction="row"
                              spacing={2}
                              sx={{
                                alignItems: 'baseline'
                              }}
                            >
                              <Typography variant="body1" sx={{ flex: 1, fontWeight: '600' }}>
                                {courseTitle}
                              </Typography>

                              {section != null && <Typography variant="subtitle2">{section}</Typography>}
                            </Stack>

                            {(ordinal > 0 || roomName.length > 0) && (
                              <Stack spacing={0.5}>
                                {roomName.length > 0 && <Typography variant="subtitle2">{roomName}</Typography>}

                                {ordinal > 0 && (
                                  <Typography variant="subtitle2">{strings.occurrenceCount(ordinal)}</Typography>
                                )}
                              </Stack>
                            )}

                            {showPeriodLabel && (
                              <Typography variant="h6" sx={{ fontWeight: '600', textAlign: 'right' }}>
                                {occurrence.periodLabel}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack>
                    <List sx={{ px: 0 }} disablePadding>
                      {items.map(renderItem)}

                      {viewModel.canCreateWork && (
                        <Box sx={{ pt: items.length > 0 ? 1 : 0 }}>
                          <Stack direction="column">
                            {pasteboardContent != null && (
                              <ListItemButton onClick={onPaste} dense>
                                <ListItemIcon>
                                  <ContentPasteRounded />
                                </ListItemIcon>
                                <ListItemText primary={strings.pasteItemButtonTitle()} />
                              </ListItemButton>
                            )}

                            <ListItemButton onClick={addWork} dense>
                              <ListItemIcon>
                                <AddRounded />
                              </ListItemIcon>
                              <ListItemText primary={strings.createWorkButtonTitle()} />
                            </ListItemButton>
                          </Stack>

                          <CreateWorkOptionsMenu
                            open={createWorkMenuAnchor != null}
                            anchorEl={createWorkMenuAnchor}
                            onClose={() => setCreateWorkMenuAnchor(undefined)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            canPublishWork={viewModel.canPublishWork}
                            onCreatePersonalWorkOptionSelect={showCreateWork}
                            onCreateNoteOptionSelect={showCreateNote}
                            onPublishWorkOptionSelect={showCreatePublishedWork}
                            onPaste={onPaste}
                          />
                        </Box>
                      )}
                    </List>
                  </Stack>
                </Stack>
              );
            }}
          />

          <FormPopoverActions
            onSubmit={() => {
              onClose();
              return Promise.resolve();
            }}
            canSubmit={true}
            customSubmitTitle={strings.closeButtonTitle()}
          />
        </Stack>
      </Popover>
    );
  }
);

function renderItem(item: UserDashboardPeriodInfoPopoverViewItem) {
  switch (item.case) {
    case 'note':
      return <UserDashboardPeriodInfoPopoverNoteItem key={item.value.id} note={item.value} />;

    case 'publishedWork':
    case 'work':
      return <UserDashboardPeriodInfoPopoverWorkItem key={item.value.id} work={item} />;
  }
}
