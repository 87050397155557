import { CourseSectionInfo, dateToPBDate, plannerCourseSectionDetailsToInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { ItemDistributionPatternKind } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/item_distribution_pattern_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { captureException } from '@sentry/react';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { localizedCompareWithProperties } from '../../utils';
import { UpdatableViewModelState } from '../shared';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import {
  BaseItemDuplicationDialogViewModel,
  ItemDuplicationItemAndDateInfo
} from './BaseItemDuplicationDialogViewModel';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerItemKind } from './PlannerItemKind';

export interface PlannerDistributeItemViewModel extends UpdatableDialogViewModel {
  readonly item: PlannerCopyItemInfo;
  readonly courseSections: CourseSectionInfo[];
  readonly selectedCourseSectionIds: string[];
  readonly availableTimeOptions: ItemDistributionPatternKind[];
  timeOption: ItemDistributionPatternKind | undefined;
  toggleSelectionOfCourse(id: string): void;
}

export class AppPlannerDistributeItemViewModel
  extends BaseItemDuplicationDialogViewModel
  implements PlannerDistributeItemViewModel
{
  @observable private _isSaving = false;
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _courseSections: CourseSectionInfo[] = [];
  @observable private _originalCourseSectionId = '';
  @observable private _originalItem: PlannerCopyItemInfo | undefined;
  @observable private _availableTimeOptions: ItemDistributionPatternKind[] = [];
  @observable private _plannerDistributeTimeOption: ItemDistributionPatternKind | undefined;
  private _selectedCourseSectionIds = observable.set<string>();

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    plannerId: string,
    itemKind: PlannerItemKind,
    onDismiss: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    plannerStore = ServiceContainer.services.plannerStore,
    workStore = ServiceContainer.services.workStore
  ) {
    super(plannerId, itemKind, onDismiss, localization, plannerStore, workStore);
    makeObservable(this);

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.distributeItem.saveButtonTitle(),
      'contained',
      () => this.save()
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = !this._isSaving && this.hasChanges;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get item(): PlannerCopyItemInfo {
    return this._originalItem!;
  }

  @computed
  get courseSections() {
    return this._courseSections.filter((cs) => cs.id !== this._originalCourseSectionId);
  }

  @computed
  get selectedCourseSectionIds(): string[] {
    return Array.from(this._selectedCourseSectionIds);
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get timeOption(): ItemDistributionPatternKind | undefined {
    return this._plannerDistributeTimeOption;
  }

  set timeOption(value: ItemDistributionPatternKind | undefined) {
    this._plannerDistributeTimeOption = value;
  }

  @computed
  get availableTimeOptions(): ItemDistributionPatternKind[] {
    return this._availableTimeOptions;
  }

  @computed
  get hasChanges(): boolean {
    return this.selectedCourseSectionIds.length > 0 && this._plannerDistributeTimeOption != null;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSaving;
  }

  @computed
  get hasData(): boolean {
    return this._state === 'fulfilled' && this._originalItem != null && this._availableTimeOptions.length > 0;
  }

  @action
  toggleSelectionOfCourse(id: string) {
    if (this._selectedCourseSectionIds.has(id)) {
      this._selectedCourseSectionIds.delete(id);
    } else {
      this._selectedCourseSectionIds.add(id);
    }
  }

  async reloadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this.loadWork(this._itemKind.id);
          break;

        case 'note':
          await this.loadNote(this._itemKind.id);
          break;

        case 'publishedWork':
          await this.loadPublishedWork(this._itemKind.id, this._itemKind.schoolId);
          break;
      }
    } catch (e) {
      captureException(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  private async loadWork(id: string) {
    const info = await this.loadDataForWork(id);
    await this.setDataForInfo(info);
  }

  private async loadNote(id: string) {
    const info = await this.loadDataForNote(id);
    await this.setDataForInfo(info);
  }

  private async loadPublishedWork(id: string, schoolId: string) {
    const info = await this.loadDataForPublishedWork(id, schoolId);

    const taughtClasses = this.courseSectionsLoadable.values.filter(
      (cs) => cs.schoolsCourseSection != null && cs.role === CourseSectionRole.TEACHER
    );
    await this.setDataForInfo(info, taughtClasses);
  }

  private async setDataForInfo(info: ItemDuplicationItemAndDateInfo, courseSections?: CourseSectionDetails[]) {
    const { item, date, isAllDay } = info;
    const timeOptions = await this.makeTimeOptions(item.courseId, date, isAllDay);

    runInAction(() => {
      this._state = 'fulfilled';
      this._originalItem = item;
      this._originalCourseSectionId = item.courseId ?? '';
      this._courseSections = this.makeCourseSectionInfos(courseSections ?? this.courseSectionsLoadable.values);
      this._availableTimeOptions = timeOptions;
    });
  }

  private makeCourseSectionInfos(courseSections: CourseSectionDetails[]): CourseSectionInfo[] {
    return courseSections
      .map(plannerCourseSectionDetailsToInfo)
      .sort((c1, c2) =>
        localizedCompareWithProperties([{ value1: c1.title, value2: c2.title }], this._localization.currentLocale)
      );
  }

  private async makeTimeOptions(
    courseId: string | undefined,
    date: Date | undefined,
    isAllDay: boolean
  ): Promise<ItemDistributionPatternKind[]> {
    const options: ItemDistributionPatternKind[] = [
      ItemDistributionPatternKind.SAME_TIME,
      ItemDistributionPatternKind.SAME_DAY
    ];
    const canDistributeToOccurrence = await this.canDistributeToOccurrence(courseId, date, isAllDay);

    if (canDistributeToOccurrence) {
      options.push(ItemDistributionPatternKind.SAME_OCCURRENCE);
    }

    return options;
  }

  private async canDistributeToOccurrence(
    courseId: string | undefined,
    date: Date | undefined,
    isAllDay: boolean
  ): Promise<boolean> {
    if (courseId == null || courseId.length === 0 || date == null || isAllDay) {
      return false;
    }

    try {
      const day = dateToPBDate(date);
      const calendarStore = this._plannerStore.getCalendarStore(this._plannerId);
      // Making sure the day is loaded.
      await calendarStore.fetchDays(day, day, false);
      const occurrencesForDate = calendarStore.getCourseSectionsOccurrencesForDate(date);
      // Can distribute to same occurrence if original item occurs during a period of its course section.
      return occurrencesForDate.some((o) => o.courseSectionId === courseId);
    } catch (e) {
      captureException(e);
      // If an error occurs while loading periods, we simply hide to 'same-occurrence' option.
      return false;
    }
  }

  private async save() {
    if (this._plannerDistributeTimeOption == null) {
      return;
    }

    runInAction(() => (this._isSaving = true));

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this.distributeWork(this._plannerDistributeTimeOption);
          break;

        case 'note':
          await this.distributeNote(this._plannerDistributeTimeOption);
          break;

        case 'publishedWork': {
          await this.distributePublishedWork(this._plannerDistributeTimeOption);
          break;
        }
      }

      runInAction(() => {
        this._state = 'fulfilled';
        this._isSaving = false;
      });
      await this.dismiss();
    } catch (e) {
      captureException(e);
      runInAction(() => (this._error = LocalizedStrings.planner.distributeItem.saveErrorMessage()));
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  private distributeWork(pattern: ItemDistributionPatternKind) {
    return this._workStore.distributeWork(
      this._itemKind.id,
      this.selectedCourseSectionIds,
      pattern,
      true // Always link to original work.
    );
  }

  private distributeNote(pattern: ItemDistributionPatternKind) {
    return this._workStore.distributeNote(
      this._itemKind.id,
      this.selectedCourseSectionIds,
      pattern,
      true // Always link to original note.
    );
  }

  private async distributePublishedWork(pattern: ItemDistributionPatternKind) {
    const courseIds = chain(this.selectedCourseSectionIds)
      .map((id) => this.courseSectionsLoadable.data.get(id)?.schoolsCourseSection?.id)
      .compact()
      .value();

    await this._workStore.distributePublishedWork(
      this._itemKind.id,
      courseIds,
      pattern,
      true // Always link to original work.
    );
  }
}
