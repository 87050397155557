import { createPreferenceValue, curriculumCoursesForUser, plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import {
  CurriculumCoursesLoadable,
  CurriculumDataStore,
  CurriculumEnrolledCourseIdsInPlannerLoadable,
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  UserDataStore,
  UserPreferenceLoadable,
  mergeLoadableStates
} from '@/stores';
import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { computed, makeObservable, observable, override, runInAction, when } from 'mobx';
import LocalizedStrings from 'strings';
import { localizedCompareWithProperties } from '../../utils';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface PlannerNewCurriculumCoursesDialogViewModel extends UpdatableDialogViewModel {
  readonly description: string;
  readonly isOpen: boolean;
  readonly selectedCourseIds: string[];
  readonly newCourses: Course[];
  toggleCourseSelection(id: string): void;
}

export class AppPlannerNewCurriculumCoursesDialogViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PlannerNewCurriculumCoursesDialogViewModel
{
  @observable private _selectedCourseIds = observable.array<string>();

  private readonly _ignoreActionConfig: DialogActionButtonConfiguration;
  private readonly _enrollActionConfig: SaveDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string,
    private readonly _curriculumStore: CurriculumDataStore = ServiceContainer.services.curriculumStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(localization, () => Promise.resolve());
    makeObservable(this);
    void this.reloadData();

    this._ignoreActionConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'left',
      'close',
      'start',
      () => LocalizedStrings.planner.newCurriculumCourses.ignoreButtonTitle(),
      'contained-grey',
      () => this.ignoreCourses(),
      undefined,
      undefined
    );

    this._enrollActionConfig = new SaveDialogActionButtonConfiguration(
      'main',
      this._localization,
      () => this.enrollInCourses(),
      () => LocalizedStrings.planner.newCurriculumCourses.enrollButtonTitle(),
      'add'
    );

    when(
      () => this.hasData,
      () => runInAction(() => this._selectedCourseIds.replace(this.newCourses.map((cs) => cs.id)))
    );
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  private get plannerCourseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get curriculumCoursesLoadable(): CurriculumCoursesLoadable {
    return this._curriculumStore.getCourses();
  }

  @computed
  private get enrolledCurriculumCoursesLoadable(): CurriculumEnrolledCourseIdsInPlannerLoadable {
    return this._curriculumStore.getEnrolledCourseIdsInPlanner(this._plannerId);
  }

  @computed
  private get seenCurriculumCoursesLoadable(): UserPreferenceLoadable {
    return this._userStore.getPreference('seen-curriculum-course-ids');
  }

  @computed
  private get seenCourseIds(): string[] {
    if (this.seenCurriculumCoursesLoadable.data.value == null) {
      return [];
    }

    const value = this.seenCurriculumCoursesLoadable.data.value.value;
    if (value.case === 'stringValue') {
      return value.value.split('|');
    }

    return [];
  }

  @computed
  private get hasTaughtClasses(): boolean {
    return this.plannerCourseSectionsLoadable.values.some((cs) => cs.role === CourseSectionRole.TEACHER);
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._enrollActionConfig.isEnabled = this.selectedCourseIds.length > 0;
    return [this._ignoreActionConfig, this._enrollActionConfig];
  }

  @computed
  private get isReadOnly(): boolean {
    return !plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get isOpen(): boolean {
    return this.hasData && !this.isReadOnly && this.newCourses.length > 0;
  }

  @computed
  get selectedCourseIds(): string[] {
    return this._selectedCourseIds;
  }

  @computed
  get description(): string {
    const strings = LocalizedStrings.planner.newCurriculumCourses;

    if (this.hasTaughtClasses) {
      return strings.descriptionTeacher();
    }

    switch (this._userStore.user.persona) {
      case UserPersona.TEACHER:
        return strings.descriptionTeacher();

      case UserPersona.PARENT:
        return strings.descriptionParent();

      case UserPersona.STUDENT:
      case UserPersona.OTHER:
      case UserPersona.UNSPECIFIED:
        return strings.descriptionOther();
    }
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([
      this.enrolledCurriculumCoursesLoadable.state,
      this.seenCurriculumCoursesLoadable.state,
      this.curriculumCoursesLoadable.state,
      this.plannerCourseSectionsLoadable.state
    ]);
  }

  readonly hasChanges = false;

  @computed
  get isSubmitting(): boolean {
    return this.hasChanges;
  }

  @computed
  get hasData(): boolean {
    return (
      this.curriculumCoursesLoadable.hasData &&
      this.seenCurriculumCoursesLoadable.hasData &&
      this.enrolledCurriculumCoursesLoadable.hasData &&
      this.plannerCourseSectionsLoadable.hasData
    );
  }

  @computed
  get newCourses(): Course[] {
    const seenCourseIds = this.seenCourseIds;
    const allCourses = this.curriculumCoursesLoadable.values;
    const availableCourses = curriculumCoursesForUser(allCourses, this._userStore.user.persona, this.hasTaughtClasses);

    return availableCourses
      .filter((cs) => !seenCourseIds.includes(cs.id))
      .sort((c1, c2) =>
        localizedCompareWithProperties(
          [
            { value1: c1.title, value2: c2.title },
            { value1: c1.description, value2: c2.description }
          ],
          this._localization.currentLocale
        )
      );
  }

  toggleCourseSelection(id: string) {
    const hasRemoved = this._selectedCourseIds.remove(id);
    if (!hasRemoved) {
      this._selectedCourseIds.push(id);
    }
  }

  async reloadData(): Promise<void> {
    await Promise.all([
      this.enrolledCurriculumCoursesLoadable.fetch(true),
      this.curriculumCoursesLoadable.fetch(true),
      this.seenCurriculumCoursesLoadable.fetch(true)
    ]);
  }

  private async enrollInCourses() {
    const courseIds = Array.from(this.enrolledCurriculumCoursesLoadable.data);
    courseIds.push(...this.selectedCourseIds);

    await Promise.all([
      this._curriculumStore.setEnrolledCourseIdsInPlanner(this._plannerId, courseIds),
      this.updateUserPreference()
    ]);

    // Updating planner data
    await this.plannerCourseSectionsLoadable.fetch(true);
    await Promise.all([
      this._plannerStore.getPlannerContentsInPlanner(this._plannerId).fetch(true),
      this._plannerStore.getInboxWorkCountInPlanner(this._plannerId).fetch(true)
    ]);
  }

  private async ignoreCourses() {
    await this.updateUserPreference();
  }

  private async updateUserPreference() {
    const newSeenCourseIds = this.curriculumCoursesLoadable.values.map((cs) => cs.id).join('|');
    const newSeenCoursePreferenceValue = createPreferenceValue({
      value: { case: 'stringValue', value: newSeenCourseIds }
    });

    await this._userStore.setPreference('seen-curriculum-course-ids', newSeenCoursePreferenceValue);
  }
}
