import { useNavigateAsync, useServices } from '@/hooks';
import { convertRichTextToPlainText } from '@/models';
import { PlannerItemsItem, PlannerItemsViewModel } from '@/viewmodels';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { AddRounded } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { Observer, observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';
import { List } from '../../lists';
import { CreateWorkOptionsMenu } from '../../shared';
import { PlannerItemsEmptyIndicator } from './PlannerItemsEmptyIndicator';
import { PlannerItemsHeader } from './PlannerItemsHeader';
import { PlannerItemsNoResultsIndicator } from './PlannerItemsNoResultsIndicator';
import { PlannerItemsNoSearchResultsIndicator } from './PlannerItemsNoSearchResultsIndicator';
import { PlannerItemsNoteView } from './PlannerItemsNoteView';
import { PlannerItemsPublishedWorkView } from './PlannerItemsPublishedWorkView';
import { PlannerItemsWorkView } from './PlannerItemsWorkView';

export interface PlannerItemsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerItemsViewModel;
}

export const PlannerItems = observer(({ sx = [], className, viewModel }: PlannerItemsProps) => {
  const { navigation, pasteboard } = useServices();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = LocalizedStrings.planner.items;
  const location = useLocation();
  const navigate = useNavigateAsync();
  const [createItemMenuAnchor, setCreateItemMenuAnchor] = useState<HTMLButtonElement | undefined>();

  function onCreateWorkButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setCreateItemMenuAnchor(e.currentTarget);
  }

  function showCreatePublishedWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function showCreateWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToWorkEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function showCreateNote() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToNoteEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function onPaste() {
    setCreateItemMenuAnchor(undefined);
    pasteboard.setPasteContext({ courseSectionId: viewModel.courseSection?.courseSection?.id });
  }

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PlannerItemsHeader sx={{ p: 1.5, pb: 0.5 }} viewModel={viewModel} />
      <Observer>
        {() => {
          const dateFiltersTitle = titleForDateFilters(viewModel.minimumDate, viewModel.maximumDate);

          return dateFiltersTitle != null ? (
            <Typography
              variant="subtitle2"
              color="primary"
              sx={{
                fontWeight: '600',
                px: 1.5
              }}
            >
              {dateFiltersTitle}
            </Typography>
          ) : null;
        }}
      </Observer>
      <Observer>
        {() => (
          <List
            sx={{ width: '100%', height: '100%' }}
            bottomPadding={56}
            keyForItem={(section, row) => keyForItem(section, row, viewModel)}
            sections={viewModel.sections}
            renderItem={(section, row) => renderItem(section, row, viewModel)}
            rowHeightForItem={(section, row) => heightForItemAtRow(section, row, viewModel, isExtraSmallScreen)}
          />
        )}
      </Observer>

      {!isExtraSmallScreen && viewModel.canCreateItem && (
        <Button
          variant="contained"
          onClick={onCreateWorkButtonPressed}
          startIcon={<AddRounded />}
          sx={{ borderRadius: 50, position: 'absolute', bottom: 16, right: 16 }}
        >
          {strings.createWorkButtonTitle()}
        </Button>
      )}

      <CreateWorkOptionsMenu
        open={createItemMenuAnchor != null}
        anchorEl={createItemMenuAnchor}
        onClose={() => setCreateItemMenuAnchor(undefined)}
        canPublishWork={viewModel.canPublishWork}
        onCreatePersonalWorkOptionSelect={showCreateWork}
        onCreateNoteOptionSelect={showCreateNote}
        onPublishWorkOptionSelect={showCreatePublishedWork}
        onPaste={onPaste}
      />
    </Box>
  );
});

function keyForItem(section: number, row: number, viewModel: PlannerItemsViewModel): string {
  const { upcomingItems, pastItems, searchText, maximumDate, minimumDate } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    if (searchText.length > 0) {
      return 'no-search-results';
    } else if (minimumDate != null || maximumDate != null) {
      return 'no-results';
    } else {
      return 'empty';
    }
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];
  return item.value.id;
}

function renderItem(section: number, row: number, viewModel: PlannerItemsViewModel) {
  const { upcomingItems, pastItems, searchText, maximumDate, minimumDate } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    if (searchText.length > 0) {
      return <PlannerItemsNoSearchResultsIndicator searchText={searchText} />;
    } else if (minimumDate != null || maximumDate != null) {
      return <PlannerItemsNoResultsIndicator />;
    } else {
      return <PlannerItemsEmptyIndicator />;
    }
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];
  switch (item.kind) {
    case 'work':
      return (
        <PlannerItemsWorkView
          key={item.value.id}
          work={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );

    case 'note':
      return (
        <PlannerItemsNoteView
          key={item.value.id}
          note={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );

    case 'publishedWork':
      return (
        <PlannerItemsPublishedWorkView
          key={item.value.id}
          work={item.value}
          displayCourseColor={viewModel.displayCourseColor}
          isReadOnly={!viewModel.canCreateItem}
        />
      );
  }
}

function titleForDateFilters(minimumDate: Date | undefined, maximumDate: Date | undefined): string | undefined {
  const strings = LocalizedStrings.planner.items;
  if (minimumDate != null && maximumDate != null) {
    return strings.minimumAndMaximumFilterLabel(format(minimumDate, 'PPP'), format(maximumDate, 'PPP'));
  } else if (minimumDate != null) {
    return strings.minimumDateOnlyFilterLabel(format(minimumDate, 'PPP'));
  } else if (maximumDate != null) {
    return strings.maximumDateOnlyFilterLabel(format(maximumDate, 'PPP'));
  }

  return undefined;
}

function heightForItemAtRow(section: number, row: number, viewModel: PlannerItemsViewModel, isSmallScreen: boolean) {
  const { upcomingItems, pastItems } = viewModel;

  if (upcomingItems.length === 0 && pastItems.length === 0 && section === 0 && row === 0) {
    return ItemHeights.emptyIndicator;
  }

  const item = section === 0 ? upcomingItems[row] : pastItems[row];

  if (item.kind === 'note') {
    if (isSmallScreen && !item.value.hasDate) {
      return ItemHeights.noteSmall;
    }

    return ItemHeights.note;
  }

  const hasDescription = getDescriptionForItem(item).trim().length > 0;
  const isScheduled = getItemIsScheduled(item);

  if (isSmallScreen) {
    if (hasDescription && item.value.hasDate) {
      return ItemHeights.threeLines;
    } else if (item.value.hasDate || hasDescription) {
      return ItemHeights.twoLine;
    } else {
      return ItemHeights.singleLine;
    }
  }

  if (hasDescription && isScheduled) {
    return ItemHeights.threeLines;
  }

  return hasDescription || isScheduled ? ItemHeights.twoLine : ItemHeights.singleLine;
}

function getDescriptionForItem(item: PlannerItemsItem) {
  switch (item.kind) {
    case 'publishedWork':
      return convertRichTextToPlainText(item.value.publishedWork.description);
    case 'work':
      return convertRichTextToPlainText(item.value.work.description);
    case 'note':
      return convertRichTextToPlainText(item.value.note.text);
  }
}

function getItemIsScheduled(item: PlannerItemsItem) {
  switch (item.kind) {
    case 'publishedWork': {
      const { publishedWork } = item.value;
      return publishedWork.status === PublishedWorkStatus.SCHEDULED && publishedWork.scheduledPublishTime != null;
    }

    case 'work':
    case 'note':
      return false;
  }
}

const ItemHeights = {
  emptyIndicator: 48,
  threeLines: 92,
  twoLine: 64,
  singleLine: 52,
  note: 64,
  noteSmall: 52
};
