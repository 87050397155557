import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { EditableProperty } from './EditableProperty';

export abstract class EditableModel<T> {
  protected _fields: IObservableArray<EditableProperty<unknown>> = observable.array<EditableProperty<unknown>>([]);
  @observable private _markedAsDeleted = false;

  protected constructor(
    private readonly _isNew = false,
    readonly initialModel: T
  ) {
    makeObservable(this);
  }

  @computed
  get hasChanges() {
    // To-be-created models that have no fields set yet are not considered as changed.
    return this.shouldBeDeleted || this._fields.some((field) => field.isChanged);
  }

  get shouldBeCreated() {
    return this._isNew;
  }

  @computed
  get shouldBeDeleted(): boolean {
    return this.markedAsDeleted || this._fields.every((field) => field.value == null);
  }

  @computed
  get markedAsDeleted(): boolean {
    return this._markedAsDeleted;
  }

  @action
  markAsDeleted() {
    this._markedAsDeleted = true;
  }

  @action
  resetChanges(): void {
    this._markedAsDeleted = false;
    this._fields.forEach((field) => field.reset());
  }

  abstract get updatedModel(): T;

  abstract get hasChangesToSharedProperties(): boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected setFields(fields: EditableProperty<any>[]) {
    if (this._fields.length > 0) {
      throw new Error("Invalid operation. Fields should be set once in the derived class' constructor.");
    }

    this._fields = observable.array<EditableProperty<unknown>>(fields);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getHasChangesToSharedProperties(fields: EditableProperty<any>[]): boolean {
    return fields.some((field) => field.isChanged);
  }
}
