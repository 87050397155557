import {
  Day,
  DayOfWeek,
  ItemRepeatPatternKind,
  NoteDuplicationDetailsResponse,
  NoteRepeatResponse,
  PublishedWorkDuplicationDetailsResponse,
  PublishedWorkRepeatResponse,
  timestampDateOptional,
  WorkDuplicationDetailsResponse,
  WorkRepeatResponse
} from '@/models';
import { ApplicationSettingsStorage, LocalizationService, UserService } from '@/services';
import { CreatePublishedWorkRequest, UpdatePublishedWorkRequest, WorkTransportService } from '@/transports';
import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { ItemDistributionPatternKind } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/item_distribution_pattern_kind_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { TimeSlotGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/time_slot_group_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { getOrCreateInObservableMap } from '../../utils';
import { StoreInvalidator, WorkDataStore } from '../contracts';
import {
  AppCourseSectionNotesLoadable,
  AppCourseSectionWorksLoadable,
  AppNoteLoadable,
  AppNotesLoadable,
  AppPublishedWorkLoadable,
  AppSchoolCourseSectionPublishedWorksLoadable,
  AppWorkIconsLoadable,
  AppWorkLoadable,
  AppWorksLoadable,
  CourseSectionNotesLoadable,
  CourseSectionWorksLoadable,
  NoteLoadable,
  NotesLoadable,
  PublishedWorkLoadable,
  SchoolCourseSectionPublishedWorksLoadable,
  WorkIconsLoadable,
  WorkLoadable,
  WorksLoadable
} from '../loadables';

export class AppWorkDataStore implements WorkDataStore {
  @observable private readonly _workIconLoadable: WorkIconsLoadable;
  private readonly _workLoadables = observable.map<string, WorkLoadable>();
  private readonly _worksLoadables = observable.map<string, WorksLoadable>();
  private readonly _courseSectionWorksLoadables = observable.map<string, CourseSectionWorksLoadable>();
  private readonly _notesLoadables = observable.map<string, NotesLoadable>();
  private readonly _courseSectionNotesLoadables = observable.map<string, CourseSectionNotesLoadable>();
  private readonly _noteLoadables = observable.map<string, NoteLoadable>();
  private readonly _publishedWorkLoadables = observable.map<string, PublishedWorkLoadable>();
  private readonly _publishedWorksByCourseSectionId = observable.map<
    string,
    SchoolCourseSectionPublishedWorksLoadable
  >();

  constructor(
    private readonly _workTransport: WorkTransportService,
    user: UserService,
    private readonly _storeInvalidator: StoreInvalidator,
    settingsStorage: ApplicationSettingsStorage,
    localization: LocalizationService
  ) {
    makeObservable(this);
    this._workIconLoadable = new AppWorkIconsLoadable(_workTransport);

    reaction(
      () => ({ user: user.currentUser, isDemoMode: settingsStorage.isDemoMode }),
      (value, oldValue) => {
        if (value.user?.userId !== oldValue.user?.userId || value.isDemoMode !== oldValue.isDemoMode) {
          this.invalidateAll();
        }

        if (value.user != null) {
          void this._workIconLoadable.fetch(true);
        }
      }
    );

    reaction(
      () => localization.currentLocale,
      () => {
        if (user.currentUser != null) {
          void this._workIconLoadable.fetch(true);
        }
      }
    );

    reaction(
      () => _storeInvalidator.plannersRevision,
      () => this.invalidateAll()
    );
  }

  getWorkLoadable(workId: string): WorkLoadable {
    return getOrCreateInObservableMap(
      this._workLoadables,
      workId,
      () => new AppWorkLoadable(workId, this._workTransport)
    );
  }

  getWorksLoadable(plannerId: string): WorksLoadable {
    return getOrCreateInObservableMap(
      this._worksLoadables,
      plannerId,
      () => new AppWorksLoadable(plannerId, this._workTransport)
    );
  }

  getCourseSectionWorksLoadable(
    plannerId: string,
    courseSectionId: string,
    externalSourceName: string | undefined
  ): CourseSectionWorksLoadable {
    const key = `${plannerId}~~${courseSectionId}~~${externalSourceName ?? 'n/a'}`;
    return getOrCreateInObservableMap(
      this._courseSectionWorksLoadables,
      key,
      () => new AppCourseSectionWorksLoadable(plannerId, courseSectionId, externalSourceName, this._workTransport)
    );
  }

  getNotesLoadable(plannerId: string): NotesLoadable {
    return getOrCreateInObservableMap(
      this._notesLoadables,
      plannerId,
      () => new AppNotesLoadable(plannerId, this._workTransport)
    );
  }

  getCourseSectionNotesLoadable(plannerId: string, courseSectionId: string): CourseSectionNotesLoadable {
    const key = `${plannerId}~~${courseSectionId}`;
    return getOrCreateInObservableMap(
      this._courseSectionNotesLoadables,
      key,
      () => new AppCourseSectionNotesLoadable(plannerId, courseSectionId, this._workTransport)
    );
  }

  getNoteLoadable(noteId: string): NoteLoadable {
    return getOrCreateInObservableMap(
      this._noteLoadables,
      noteId,
      () => new AppNoteLoadable(noteId, this._workTransport)
    );
  }

  getPublishedWorksInCourseSection(
    courseSectionId: string,
    schoolId: string
  ): SchoolCourseSectionPublishedWorksLoadable {
    const key = `${schoolId}-${courseSectionId}`;
    return getOrCreateInObservableMap(
      this._publishedWorksByCourseSectionId,
      key,
      () => new AppSchoolCourseSectionPublishedWorksLoadable(courseSectionId, schoolId, this._workTransport)
    );
  }

  getPublishedWorkLoadable(publishedWorkId: string, schoolId: string): PublishedWorkLoadable {
    const id = `${publishedWorkId}-${schoolId}`;
    return getOrCreateInObservableMap(
      this._publishedWorkLoadables,
      id,
      () => new AppPublishedWorkLoadable(publishedWorkId, schoolId, this._workTransport)
    );
  }

  @computed
  get workIcons(): WorkIconsLoadable {
    return this._workIconLoadable;
  }

  async setWorkStatus(workId: string, status: WorkStatus, syncToken: bigint) {
    const work = await this._workTransport.setWorkStatus(workId, status, syncToken);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(work);
  }

  async createWork(work: Work): Promise<Work> {
    const result = await this._workTransport.createWork(work);
    this.updateWorkLoadablesForUpdatedWork(result);
    return result;
  }

  async updateWork(work: Work, shouldClearDuplicationIds: boolean): Promise<Work> {
    const updatedWork = await this._workTransport.updateWork(work, shouldClearDuplicationIds);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(updatedWork);
    return updatedWork;
  }

  async updateWorkDuplicates(work: Work): Promise<Work[]> {
    const updatedWorks = await this._workTransport.updateWorkDuplicates(work);
    updatedWorks.forEach((w) => this.updateWorkLoadablesForUpdatedWork(w));
    return updatedWorks;
  }

  async cancelPlannedWork(plannedWorkId: string, workId: string, syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.cancelPlannedWork(plannedWorkId, workId, syncToken);
    // Updating work loadable if one exists.
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async setWorkSteps(workId: string, steps: WorkStep[], syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.setWorkSteps(workId, steps, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async unlinkWorkFromCopies(work: Work): Promise<Work> {
    const updatedWork = await this._workTransport.updateWork(work, true);
    this.updateWorkLoadablesForUpdatedWork(updatedWork);
    return updatedWork;
  }

  async unlinkNoteFromCopies(note: Note): Promise<Note> {
    const updatedNote = await this._workTransport.updateNote(note, true);
    this.updateNoteLoadablesForUpdatedNote(updatedNote);
    return updatedNote;
  }

  async unlinkPublishedWorkFromCopies(publishedWork: PublishedWork): Promise<PublishedWork> {
    const updatedPublishedWork = await this._workTransport.updatePublishedWork(
      publishedWork.id,
      publishedWork.schoolId,
      {
        title: publishedWork.title,
        iconId: publishedWork.iconId,
        description: publishedWork.description,
        importanceLevel: publishedWork.importance,
        dueTime: timestampDateOptional(publishedWork.dueTime),
        isDueAllDay: publishedWork.isDueAllDay,
        maxGrade: publishedWork.maxGrade,
        recipientIds: publishedWork.recipientIds,
        attachments: publishedWork.attachments,
        status:
          publishedWork.scheduledPublishTime != null ? PublishedWorkStatus.SCHEDULED : PublishedWorkStatus.PUBLISHED,
        scheduledPublishedTime: timestampDateOptional(publishedWork.scheduledPublishTime)
      },
      publishedWork.syncToken,
      true
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(updatedPublishedWork);
    return updatedPublishedWork;
  }

  async getAvailableTimeSlots(
    plannerId: string,
    workId: string,
    currentTimezone: string,
    durationMinutes: number,
    incrementMinutes: number,
    extraDays: number,
    plannedWorkId?: string
  ): Promise<TimeSlotGroup[]> {
    return await this._workTransport.getAvailableTimeSlots(
      plannerId,
      workId,
      currentTimezone,
      durationMinutes,
      incrementMinutes,
      extraDays,
      plannedWorkId
    );
  }

  async planWork(workId: string, timeSlot: TimeSlot, stepIds: string[], syncToken: bigint): Promise<Work> {
    const work = await this._workTransport.createPlannedWork(workId, timeSlot, stepIds, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async updatePlannedWork(
    plannedWorkId: string,
    workId: string,
    timeSlot: TimeSlot,
    stepIds: string[],
    syncToken: bigint
  ): Promise<Work> {
    const work = await this._workTransport.updatePlannedWork(plannedWorkId, workId, timeSlot, stepIds, syncToken);
    this.updateWorkLoadablesForUpdatedWork(work);
    return work;
  }

  async createNote(note: Note): Promise<Note> {
    const result = await this._workTransport.createNote(note);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async updateNote(note: Note, shouldClearDuplicationIds: boolean): Promise<Note> {
    const result = await this._workTransport.updateNote(note, shouldClearDuplicationIds);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async updateNoteDuplicates(note: Note): Promise<Note[]> {
    const updatedNotes = await this._workTransport.updateNoteDuplicates(note);
    updatedNotes.forEach((n) => this.updateNoteLoadablesForUpdatedNote(n));
    return updatedNotes;
  }

  async setNoteStatus(noteId: string, isArchived: boolean, syncToken: bigint): Promise<Note> {
    const result = await this._workTransport.setNoteStatus(noteId, isArchived, syncToken);
    this.updateNoteLoadablesForUpdatedNote(result);
    return result;
  }

  async createPublishedWork(
    courseSectionId: string,
    schoolId: string,
    plannerId: string,
    request: CreatePublishedWorkRequest
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.createPublishedWork(courseSectionId, schoolId, plannerId, request);
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async updatePublishedWork(
    publishedWorkId: string,
    schoolId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint,
    shouldClearDuplicationIds: boolean
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.updatePublishedWork(
      publishedWorkId,
      schoolId,
      request,
      syncToken,
      shouldClearDuplicationIds
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async updatePublishedWorkDuplicates(
    publishedWorkId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint
  ): Promise<PublishedWork[]> {
    const updatedPublishedWorks = await this._workTransport.updatePublishedWorkDuplicates(
      publishedWorkId,
      request,
      syncToken
    );
    updatedPublishedWorks.forEach((pw) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(pw));
    return updatedPublishedWorks;
  }

  async setPublishedWorkStatus(
    publishedWorkId: string,
    schoolId: string,
    status: PublishedWorkStatus,
    scheduledPublishedTime: Date | undefined,
    syncToken: bigint
  ): Promise<PublishedWork> {
    const publishedWork = await this._workTransport.setPublishedWorkStatus(
      publishedWorkId,
      schoolId,
      status,
      scheduledPublishedTime,
      syncToken
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork);
    return publishedWork;
  }

  async createCopyOfWork(
    plannerId: string,
    workId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<Work> {
    const { item, original } = await this._workTransport.createCopyOfWork(
      plannerId,
      workId,
      dueTime,
      isDueAllDay,
      courseSectionId,
      linkCopy
    );
    this.updateWorkLoadablesForUpdatedWork(item);
    this.updateWorkLoadablesForUpdatedWork(original);
    return item;
  }

  async distributeWork(
    workId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<Work[]> {
    const copies = await this._workTransport.distributeWork(workId, courseSectionIds, patternKind, areCopiesLinked);
    copies.forEach((c) => this.updateWorkLoadablesForUpdatedWork(c));
    return copies;
  }

  /**
   * Creates copy of a work through time until a specific date using a specific pattern.
   * @param workId The of the original work.
   * @param areCopiesLinked Indicates if the copies are linked to the original.
   * @param until The date or occurrence count until which to repeat the work.
   * @param pattern The pattern to use when deciding where to create copies.
   * @param shouldPlaceInCourseOccurrences Indicates if the copies should be placed in course occurrences.
   * @param firstDayOfWeek Optional. The first day of the week. Defaults to Monday.
   * @param modifiedDuplicationId Optional. The id of an existing duplication id of the same kind, currently assigned to
   * the target work. When provided, works in the future can be affected by the new properties
   * of this repeat operation. It is never guaranteed that the same duplication id will be used.
   */
  async repeatWork(
    workId: string,
    areCopiesLinked: boolean,
    until: { case: 'untilDate'; value: Day } | { case: 'untilCount'; value: number },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<WorkRepeatResponse> {
    const response = await this._workTransport.repeatWork(
      workId,
      areCopiesLinked,
      until,
      pattern,
      shouldPlaceInCourseOccurrences,
      firstDayOfWeek,
      modifiedDuplicationId
    );
    this.updateWorkLoadablesForUpdatedWork(response.original);
    response.created.forEach((w) => this.updateWorkLoadablesForUpdatedWork(w));
    response.updated.forEach((w) => this.updateWorkLoadablesForUpdatedWork(w));
    response.removed.forEach((id) => this._workLoadables.delete(id));
    return response;
  }

  async createCopyOfNote(
    plannerId: string,
    noteId: string,
    time: Date | undefined,
    isAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<Note> {
    const { item, original } = await this._workTransport.createCopyOfNote(
      plannerId,
      noteId,
      time,
      isAllDay,
      courseSectionId,
      linkCopy
    );
    this.updateNoteLoadablesForUpdatedNote(item);
    this.updateNoteLoadablesForUpdatedNote(original);
    return item;
  }

  async distributeNote(
    noteId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<Note[]> {
    const copies = await this._workTransport.distributeNote(noteId, courseSectionIds, patternKind, areCopiesLinked);
    copies.forEach((c) => this.updateNoteLoadablesForUpdatedNote(c));
    return copies;
  }

  async repeatNote(
    noteId: string,
    areCopiesLinked: boolean,
    until: { case: 'untilDate'; value: Day } | { case: 'untilCount'; value: number },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<NoteRepeatResponse> {
    const response = await this._workTransport.repeatNote(
      noteId,
      areCopiesLinked,
      until,
      pattern,
      shouldPlaceInCourseOccurrences,
      firstDayOfWeek,
      modifiedDuplicationId
    );
    this.updateNoteLoadablesForUpdatedNote(response.original);
    response.created.forEach((n) => this.updateNoteLoadablesForUpdatedNote(n));
    response.updated.forEach((n) => this.updateNoteLoadablesForUpdatedNote(n));
    response.removed.forEach((id) => this._noteLoadables.delete(id));
    return response;
  }

  async createCopyOfPublishedWork(
    publishedWorkId: string,
    targetSchoolId: string,
    targetPlannerId: string,
    targetCourseSectionId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    isCopyLinked: boolean
  ): Promise<PublishedWork> {
    const { item, original } = await this._workTransport.createCopyOfPublishedWork(
      publishedWorkId,
      targetSchoolId,
      targetPlannerId,
      targetCourseSectionId,
      dueTime,
      isDueAllDay,
      isCopyLinked
    );

    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(item);
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(original);

    return item;
  }

  async distributePublishedWork(
    publishedWorkId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<PublishedWork[]> {
    const works = await this._workTransport.distributePublishedWork(
      publishedWorkId,
      courseSectionIds,
      patternKind,
      areCopiesLinked
    );

    works.forEach((w) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(w));
    return works;
  }

  async repeatPublishedWork(
    publishedWorkId: string,
    areCopiesLinked: boolean,
    until:
      | { case: 'untilDate'; value: Day }
      | {
          case: 'untilCount';
          value: number;
        },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<PublishedWorkRepeatResponse> {
    const response = await this._workTransport.repeatPublishedWork(
      publishedWorkId,
      areCopiesLinked,
      until,
      pattern,
      shouldPlaceInCourseOccurrences,
      firstDayOfWeek,
      modifiedDuplicationId
    );
    this.updatePublishedWorkLoadablesForUpdatedPublishedWork(response.original);
    response.created.forEach((pw) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(pw));
    response.updated.forEach((pw) => this.updatePublishedWorkLoadablesForUpdatedPublishedWork(pw));
    response.removed.forEach((id) => this._publishedWorkLoadables.delete(id));
    return response;
  }

  getWorkDuplicationDetails(
    duplicationIds: string[],
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<WorkDuplicationDetailsResponse> {
    return this._workTransport.getWorkDuplicationDetails(duplicationIds, plannerId, shouldIncludeRelatedDuplications);
  }

  getNoteDuplicationDetails(
    duplicationIds: string[],
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<NoteDuplicationDetailsResponse> {
    return this._workTransport.getNoteDuplicationDetails(duplicationIds, plannerId, shouldIncludeRelatedDuplications);
  }

  getPublishedWorkDuplicationDetails(
    duplicationIds: string[],
    schoolId: string,
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<PublishedWorkDuplicationDetailsResponse> {
    return this._workTransport.getPublishedWorkDuplicationDetails(
      duplicationIds,
      schoolId,
      plannerId,
      shouldIncludeRelatedDuplications
    );
  }

  @action
  invalidateAllPublishedWorks() {
    this._publishedWorksByCourseSectionId.clear();
  }

  @action
  private invalidateAll() {
    void this._workIconLoadable.invalidate(false);
    this._workLoadables.clear();
    this._courseSectionWorksLoadables.clear();
    this._notesLoadables.clear();
    this._courseSectionNotesLoadables.clear();
    this._publishedWorkLoadables.clear();
    this.invalidateAllPublishedWorks();
  }

  private updateWorkLoadablesForUpdatedWork(work: Work) {
    this._storeInvalidator.invalidateCalendar();
    this._workLoadables.get(work.id)?.updateWithValue(work);
    void this._worksLoadables.get(work.plannerId)?.fetch(true);

    if (work.courseSectionId.length > 0) {
      const key = `${work.plannerId}~~${work.courseSectionId}~~n/a`;
      void this._courseSectionWorksLoadables.get(key)?.fetch(true);
    }
  }

  private updateNoteLoadablesForUpdatedNote(note: Note) {
    this._storeInvalidator.invalidateCalendar();
    this._noteLoadables.get(note.id)?.updateWithValue(note);
    void this._notesLoadables.get(note.plannerId)?.fetch(true);

    if (note.courseSectionId.length > 0) {
      const key = `${note.plannerId}~~${note.courseSectionId}`;
      void this._courseSectionNotesLoadables.get(key)?.fetch(true);
    }
  }

  private updatePublishedWorkLoadablesForUpdatedPublishedWork(publishedWork: PublishedWork) {
    this._storeInvalidator.invalidateCalendar();
    const key = `${publishedWork.id}-${publishedWork.schoolId}`;
    this._publishedWorkLoadables.get(key)?.updateWithValue(publishedWork);
    const keyForCourse = `${publishedWork.schoolId}-${publishedWork.courseSectionId}`;
    void this._publishedWorksByCourseSectionId.get(keyForCourse)?.fetch(true);
  }
}
