import { useBackButtonListener, useServices } from '@/hooks';
import { InfoRounded } from '@mui/icons-material';
import { Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef, useState } from 'react';
import { Subheader } from './Subheader';

export interface LegendItem {
  readonly title: string;
  readonly subtitle?: string;
  readonly icon: (size: number) => ReactNode;
  readonly iconSize?: number;
  readonly hidden?: boolean;
}

export interface LegendSection {
  readonly title?: string;
  readonly items: LegendItem[];
}

export interface LegendButtonProps {
  sx?: SxProps;
  className?: string;
  buttonTitle?: string;
  popoverTitle?: string;
  isIconOnly?: boolean;
  sections: LegendSection[];
}

export const LegendButton = observer(
  ({ sx = [], className, buttonTitle, popoverTitle, sections, isIconOnly }: LegendButtonProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showPopover, setShowPopover] = useState(false);

    useBackButtonListener((e) => {
      if (showPopover) {
        e.stopPropagation();
        setShowPopover(false);
      }
    });

    return (
      <>
        {isIconOnly ? (
          <IconButton ref={buttonRef} sx={sx} className={className} onClick={() => setShowPopover(true)} size="small">
            <InfoRounded fontSize="small" />
          </IconButton>
        ) : (
          <Button
            ref={buttonRef}
            endIcon={<InfoRounded />}
            sx={sx}
            className={className}
            onClick={() => setShowPopover(true)}
          >
            {buttonTitle ?? strings.legendButtonDefaultTitle}
          </Button>
        )}
        <Popover
          open={showPopover}
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => setShowPopover(false)}
        >
          <Stack spacing={1} sx={{ width: 320 }}>
            <Stack>
              <Typography
                variant="body1"
                sx={{
                  p: 1.5,
                  fontWeight: '600'
                }}
              >
                {popoverTitle ?? strings.legendButtonDefaultTitle}
              </Typography>
              <Divider />
            </Stack>

            {sections.map((section, index) => (
              <Stack key={`section-${index}`} spacing={0} sx={{ px: 1.5, pb: 1.5 }}>
                {section.title && <Subheader disableGutters>{section.title}</Subheader>}

                <Stack key={`section-${index}`} spacing={1}>
                  {section.items.map((item, itemIndex) =>
                    item.hidden !== true ? (
                      <Stack key={`item-${itemIndex}-${index}`} spacing={1}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            alignItems: 'center'
                          }}
                        >
                          {item.icon(item.iconSize ?? 24)}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              flex: 1
                            }}
                          >
                            {item.title}
                          </Typography>
                        </Stack>

                        {item.subtitle != null && (
                          <Typography
                            variant="body2"
                            sx={{
                              flex: 1,
                              color: (theme) => theme.palette.text.secondary
                            }}
                          >
                            {item.subtitle}
                          </Typography>
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Popover>
      </>
    );
  }
);
