import { PlannerDeleteDuplicatedItemSectionItemInfo } from '@/viewmodels';
import { CheckRounded } from '@mui/icons-material';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerDeleteDuplicatedItemOccurrenceListItemProps {
  sx?: SxProps;
  className?: string;
  item: PlannerDeleteDuplicatedItemSectionItemInfo;
  getIsSelected: (id: string) => boolean;
  onSelect: (id: string) => void;
}

export const PlannerDeleteDuplicatedItemOccurrenceListItem = observer(
  ({ sx = [], item, getIsSelected, className, onSelect }: PlannerDeleteDuplicatedItemOccurrenceListItemProps) => {
    const strings = LocalizedStrings.planner.archiveDuplicatedItem;

    return (
      <ListItemButton sx={sx} className={clsx(className, 'rounded')} dense onClick={() => onSelect(item.id)}>
        <ListItemText
          primary={item.dueDate != null ? format(item.dueDate, 'PP') : strings.noDueDateLabel()}
          secondary={
            item.dueDate != null ? (item.isAllDay ? strings.noTimeLabel() : format(item.dueDate, 'p')) : undefined
          }
        />

        {getIsSelected(item.id) && <CheckRounded color="primary" />}
      </ListItemButton>
    );
  }
);
