import i18n from 'translate';

export const PlannerWorkloadPublishedWorkDetailsStrings = {
  createdOnLabel: () => i18n.t('planner.workload.publishedWorkDetails.createdOnLabel'),
  currentUserIconTooltip: () => i18n.t('planner.workload.publishedWorkDetails.currentUserIconTooltip'),
  descriptionLabel: () => i18n.t('planner.workload.publishedWorkDetails.descriptionLabel'),
  dueDateLabel: () => i18n.t('planner.workload.publishedWorkDetails.dueDateLabel'),
  dueDateNone: () => i18n.t('planner.workload.publishedWorkDetails.dueDateNone'),
  duePeriodLabel: () => i18n.t('planner.workload.publishedWorkDetails.duePeriodLabel'),
  grade: (count: number) => i18n.t('planner.workload.publishedWorkDetails.grade', { count }),
  gradeLabel: () => i18n.t('planner.workload.publishedWorkDetails.gradeLabel'),
  noGradeLabel: () => i18n.t('planner.workload.publishedWorkDetails.noGradeLabel'),
  noSectionLabel: () => i18n.t('planner.workload.publishedWorkDetails.noSectionLabel'),
  noTeachersLabel: () => i18n.t('planner.workload.publishedWorkDetails.noTeachersLabel'),
  ownedWorkBannerLabel: () => i18n.t('planner.workload.publishedWorkDetails.ownedWorkBannerLabel'),
  publishedImmediatelyLabel: () => i18n.t('planner.workload.publishedWorkDetails.publishedImmediatelyLabel'),
  publishedOnLabel: () => i18n.t('planner.workload.publishedWorkDetails.publishedOnLabel'),
  teachersLabel: () => i18n.t('planner.workload.publishedWorkDetails.teachersLabel'),
  title: () => i18n.t('planner.workload.publishedWorkDetails.title'),
  titleLabel: () => i18n.t('planner.workload.publishedWorkDetails.titleLabel'),
  unknownClassLabel: () => i18n.t('planner.workload.publishedWorkDetails.unknownClassLabel')
};

export const PlannerWorkloadStrings = {
  createPublishedWorkButtonTitle: () => i18n.t('planner.workload.createPublishedWorkButtonTitle'),
  displayedClassesFilterLabel: () => i18n.t('planner.workload.displayedClassesFilterLabel'),
  emptySubtitle: () => i18n.t('planner.workload.emptySubtitle'),
  emptyTitle: () => i18n.t('planner.workload.emptyTitle'),
  goBackToAllClasses: () => i18n.t('planner.workload.goBackToAllClasses'),
  goToTodayButtonTitle: () => i18n.t('planner.workload.goToTodayButtonTitle'),
  importantItemCount: (count: number) => i18n.t('planner.workload.importantItemCount', { count }),
  itemCount: (count: number) => i18n.t('planner.workload.itemCount', { count }),
  itemsTab: () => i18n.t('planner.workload.itemsTab'),
  loadDataErrorTooltip: () => i18n.t('planner.workload.loadDataErrorTooltip'),
  loadNextWeekDataTooltip: () => i18n.t('planner.workload.loadNextWeekDataTooltip'),
  loadPreviousWeekDataTooltip: () => i18n.t('planner.workload.loadPreviousWeekDataTooltip'),
  loadingDataTooltip: () => i18n.t('planner.workload.loadingDataTooltip'),
  manageClassesButtonTitle: () => i18n.t('planner.workload.manageClassesButtonTitle'),
  noMajorItemsIndicatorTitle: () => i18n.t('planner.workload.noMajorItemsIndicatorTitle'),
  noOccurrencesFound: (startDate: string, endDate: string) =>
    i18n.t('planner.workload.noOccurrencesFound', { startDate, endDate }),
  noSectionNumber: () => i18n.t('planner.workload.noSectionNumber'),
  noTaughtSectionsButtonTitle: () => i18n.t('planner.workload.noTaughtSectionsButtonTitle'),
  noTaughtSectionsMessage: () => i18n.t('planner.workload.noTaughtSectionsMessage'),
  onlyShowDatesWithOccurrence: () => i18n.t('planner.workload.onlyShowDatesWithOccurrence'),
  openStudentPlannerTooltip: () => i18n.t('planner.workload.openStudentPlannerTooltip'),
  optionsPopoverTitle: () => i18n.t('planner.workload.optionsPopoverTitle'),
  pasteItemButtonTitle: () => i18n.t('planner.workload.pasteItemButtonTitle'),
  publishedWorkDetails: PlannerWorkloadPublishedWorkDetailsStrings,
  publishedWorkListItemDuePeriod: () => i18n.t('planner.workload.publishedWorkListItemDuePeriod'),
  publishedWorkListItemDueTime: () => i18n.t('planner.workload.publishedWorkListItemDueTime'),
  publishedWorkListItemNoDuePeriod: () => i18n.t('planner.workload.publishedWorkListItemNoDuePeriod'),
  publishedWorkListItemPublishDate: () => i18n.t('planner.workload.publishedWorkListItemPublishDate'),
  publishedWorkListItemPublishTime: () => i18n.t('planner.workload.publishedWorkListItemPublishTime'),
  publishedWorkListItemTaughtSectionTooltip: () => i18n.t('planner.workload.publishedWorkListItemTaughtSectionTooltip'),
  publishedWorkListItemUnknownClass: () => i18n.t('planner.workload.publishedWorkListItemUnknownClass'),
  showWeekendsOption: () => i18n.t('planner.workload.showWeekendsOption'),
  studentAboveThreshold: () => i18n.t('planner.workload.studentAboveThreshold'),
  studentAtThreshold: () => i18n.t('planner.workload.studentAtThreshold'),
  studentAtThresholdMessage: (count: number) => i18n.t('planner.workload.studentAtThresholdMessage', { count }),
  studentNoData: () => i18n.t('planner.workload.studentNoData'),
  studentOverThresholdMessage: (count: number) => i18n.t('planner.workload.studentOverThresholdMessage', { count }),
  studentUnderThreshold: () => i18n.t('planner.workload.studentUnderThreshold'),
  studentUnderThresholdMessage: (count: number) => i18n.t('planner.workload.studentUnderThresholdMessage', { count }),
  studentWorkListHeader: () => i18n.t('planner.workload.studentWorkListHeader'),
  studentsTab: () => i18n.t('planner.workload.studentsTab'),
  subtitle: () => i18n.t('planner.workload.subtitle'),
  title: () => i18n.t('planner.workload.title'),
  unknownSectionButtonTitle: () => i18n.t('planner.workload.unknownSectionButtonTitle'),
  unknownSectionMessage: () => i18n.t('planner.workload.unknownSectionMessage'),
  workloadGroupStudentListClose: () => i18n.t('planner.workload.workloadGroupStudentListClose'),
  workloadGroupStudentListTitle: () => i18n.t('planner.workload.workloadGroupStudentListTitle'),
  workloadGroupStudentsCount: (count: number) => i18n.t('planner.workload.workloadGroupStudentsCount', { count }),
  workloadGroupViewStudentsTooltip: () => i18n.t('planner.workload.workloadGroupViewStudentsTooltip')
};
