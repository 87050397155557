import { useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Banner, Dialog } from '../../../utils';
import { ScheduleCycleCreateCopy } from './ScheduleCycleCreateCopy';

export interface ScheduleCycleCreateCopyDialogProps {
  isOpen: boolean;
  scheduleCycle: ScheduleCycle;
  // Force explicit declaration.
  plannerId: string | undefined;
  schoolId: string | undefined;
  onCancel?: () => void;
}

export const ScheduleCycleCreateCopyDialog = observer(
  ({ isOpen, scheduleCycle, plannerId, schoolId, onCancel }: ScheduleCycleCreateCopyDialogProps) => {
    if (plannerId == null && schoolId == null) {
      throw new Error('Must provide at least one of them');
    }

    const { route } = useServices();
    const navigate = useNavigateAsync();

    function onDismiss(scheduleCycleId: string | undefined) {
      if (scheduleCycleId != null) {
        if (plannerId != null) {
          // School or not, we must navigate to the planner's schedule details.
          const toPlanner = route.resolvePlannerScheduleConfigLocation(scheduleCycleId, undefined, plannerId);
          void navigate(toPlanner, { replace: true });
        } else {
          const toAdmin = route.resolveAdminSchoolScheduleLocation(schoolId!, scheduleCycleId);
          void navigate(toAdmin, { replace: true });
        }
      } else if (onCancel != null) {
        onCancel();
      } else {
        void navigate(-1);
      }
    }

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCreateCopyDialogViewModel(
          scheduleCycle,
          schoolId ?? plannerId!,
          schoolId != null ? 'school' : 'planner',
          onDismiss
        ),
      [scheduleCycle, schoolId, plannerId]
    );

    return (
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={LocalizedStrings.scheduleCycle.create.copyTitle()}
        width="md"
        renderData={() => (
          <Stack spacing={2}>
            <ScheduleCycleCreateCopy viewModel={viewModel} />

            {viewModel.createError != null && (
              <Banner severity="error">{LocalizedStrings.scheduleCycle.create.errorMessage()}</Banner>
            )}
          </Stack>
        )}
      />
    );
  }
);
