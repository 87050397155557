import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { isSxArray } from '@/utils';
import { Box, Grid2, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../utils';
import { PlannerWorkload } from './PlannerWorkload';
import { PlannerWorkloadEmptyView } from './PlannerWorkloadEmptyView';
import {
  PlannerWorkloadForceReloadContext,
  PlannerWorkloadForceReloadContextValue
} from './PlannerWorkloadForceReloadContext';
import { PlannerWorkloadHeader } from './PlannerWorkloadHeader';
import { PlannerWorkloadNoTaughtSections } from './PlannerWorkloadNoTaughtSections';

export interface PlannerWorkloadPageProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerWorkloadPage = observer(({ sx = [], className }: PlannerWorkloadPageProps) => {
  const { featureFlag, route } = useServices();

  const plannerId = useActivePlannerId();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const viewModel = useViewModel((viewModels) => viewModels.createPlannerWorkloadPageViewModel(plannerId), [plannerId]);

  if (!featureFlag.isEnabled('workload-manager')) {
    return <Navigate to={route.resolvePlannerLocation(plannerId)} replace />;
  }

  return (
    <Box
      className={className}
      sx={[
        {
          width: '100%',
          height: '100%'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex'
        }}
      >
        <Grid2
          container
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Grid2
            size={{ xs: 12, xl: 10 }}
            offset={{ xl: 1 }}
            sx={{
              height: '100%'
            }}
          >
            <BackgroundImageScreenPaper
              sx={{
                maxHeight: !isExtraSmallScreen ? '100%' : undefined,
                height: isExtraSmallScreen ? '100%' : undefined,
                width: '100%',
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <PlannerWorkloadForceReloadContext value={new PlannerWorkloadForceReloadContextValue()}>
                <Stack spacing={0} sx={{ width: '100%', maxHeight: '100%', overflow: 'hidden' }}>
                  <PlannerWorkloadHeader viewModel={viewModel} sx={{ p: 1.5, pb: 0.5 }} />

                  <UpdatablePresenter
                    sx={{ flex: 1, overflow: 'hidden' }}
                    viewModel={viewModel}
                    renderData={() => {
                      if (!viewModel.hasTaughtClasses) {
                        return <PlannerWorkloadNoTaughtSections sx={{ p: 2 }} />;
                      } else if (viewModel.allClasses.length > 0) {
                        return <PlannerWorkload viewModel={viewModel} sx={{ width: '100%', maxHeight: '100%' }} />;
                      } else {
                        return <PlannerWorkloadEmptyView sx={{ p: 2 }} />;
                      }
                    }}
                  />
                </Stack>
              </PlannerWorkloadForceReloadContext>
            </BackgroundImageScreenPaper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
});
