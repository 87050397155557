import { createUnsplash, Photo, SearchResults, Unsplash } from '@studyoco/unsplash-ts';
import { reaction } from 'mobx';
import { EnvironmentService, LocalizationService, UnsplashTransportService } from '../contracts';

export class AppUnsplashTransportService implements UnsplashTransportService {
  private readonly _unsplash: Unsplash;

  constructor(
    private readonly _localization: LocalizationService,
    environment: EnvironmentService
  ) {
    this._unsplash = createUnsplash(environment.unsplashAccessKey, this._localization.currentLocale);

    reaction(
      () => this._localization.currentLocale,
      () => this._unsplash.setLocale(this._localization.currentLocale)
    );
  }

  async search(query: string, page: number): Promise<SearchResults> {
    return await this._unsplash.searchPhotos({ query, page });
  }

  async download(photo: Photo) {
    await this._unsplash.trackPhotoDownload(photo.id);
  }
}
