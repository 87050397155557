import { useServices } from '@/hooks';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { Box, Stack, TableCell, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';

export interface PlannerWorkloadCourseSectionsTableDayHeaderProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  getPlannerDay: (date: Date) => PlannerDay | undefined;
}

export const PlannerWorkloadCourseSectionsTableDayHeader = observer(
  ({ sx = [], className, date, getPlannerDay }: PlannerWorkloadCourseSectionsTableDayHeaderProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const isToday = isSameDay(date, dateService.now);
    const plannerDay = getPlannerDay(date);

    return (
      <TableCell sx={sx} className={className}>
        <Stack sx={{ alignItems: 'center' }}>
          <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '500' }}>
            {format(date, 'EEEE')}
          </Typography>

          <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isToday && (
              <Box
                sx={{
                  backgroundColor: theme.palette.error.main,
                  width: `calc(100% + ${theme.spacing(1)})`,
                  height: '100%',
                  position: 'absolute',
                  zIndex: 1,
                  borderRadius: 2
                }}
              />
            )}
            <Typography
              variant="subtitle1"
              sx={{
                zIndex: 2,
                fontWeight: '600',
                color: isToday ? theme.palette.error.contrastText : undefined,
                minWidth: 18
              }}
            >
              {format(date, 'LLL d')}
            </Typography>

            {plannerDay?.cycleDayName != null && (
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ position: 'absolute', top: '-10%', left: `calc(100% + ${theme.spacing(1)})` }}
              >
                {plannerDay.cycleDayName}
              </Typography>
            )}
          </Box>
        </Stack>
      </TableCell>
    );
  }
);
