import { useServices } from '@/hooks';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { alpha, IconButton, List, ListItem, ListItemText, Stack, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  addDays,
  differenceInCalendarDays,
  endOfWeek,
  format,
  isSameDay,
  isWeekend,
  isWithinInterval,
  startOfWeek,
  subDays
} from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';
import { PlannerWorkloadDateListItem } from './PlannerWorkloadDateListItem';
import { useRegisterPlannerWorkloadForceReload } from './PlannerWorkloadForceReloadContext';

export interface PlannerWorkloadDateListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
  courseSection: CourseSectionDetails;
  date: Date;
  setDate: (date: Date | undefined) => void;
  showOnlyOccurrences: boolean;
}

export const PlannerWorkloadDateList = observer(
  ({
    sx = [],
    className,
    viewModel,
    courseSection,
    date,
    setDate,
    showOnlyOccurrences
  }: PlannerWorkloadDateListProps) => {
    const { dateService, settings } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.planner.workload;

    const initialDates = useMemo(() => {
      const today = dateService.now;
      const range = computeDateRange(date, today);
      return times(7).map((i) => addDays(range.start, i));
    }, []);

    useRegisterPlannerWorkloadForceReload(() => {
      const today = dateService.now;
      const range = computeDateRange(date, today);
      const dates = times(7).map((i) => addDays(range.start, i));
      void viewModel.loadWorkloadForDates(dates, courseSection.courseSection!.id, true);
    });

    useEffect(() => {
      void viewModel.loadWorkloadForDates(initialDates, courseSection.courseSection!.id, true);
    }, [initialDates]);

    const [allDates, setAllDates] = useState(initialDates);
    const displayedDates = useMemo(() => {
      const dates = settings.plannerWorkloadShowWeekends ? allDates : allDates.filter((d) => !isWeekend(d));
      return showOnlyOccurrences
        ? dates.filter((d) => viewModel.getFirstOccurrenceForCourseSectionOnDay(d, courseSection.courseSection!.id))
        : dates;
    }, [allDates, settings.plannerWorkloadShowWeekends, showOnlyOccurrences]);

    function loadMoreBefore() {
      const baseDate = allDates[0];

      const newDates: Date[] = [];
      for (let i = 7; i > 0; i--) {
        newDates.push(subDays(baseDate, i));
      }
      setAllDates([...newDates, ...allDates]);
      void viewModel.loadWorkloadForDates(newDates, courseSection.courseSection?.id, true);
    }

    function loadMoreAfter() {
      const baseDate = allDates[allDates.length - 1];
      const newDates: Date[] = [];

      for (let i = 1; i < 8; i++) {
        newDates.push(addDays(baseDate, i));
      }
      setAllDates([...allDates, ...newDates]);
      void viewModel.loadWorkloadForDates(newDates, courseSection.courseSection?.id, true);
    }

    const showNoOccurrencesMessage = showOnlyOccurrences && displayedDates.length === 0;

    return (
      <List sx={[{ flex: 1, overflow: 'auto' }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
        <Stack sx={{ position: 'relative' }}>
          {displayedDates.map((d, i) => (
            <PlannerWorkloadDateListItem
              key={d.toString()}
              date={d}
              onSelect={(date) => setDate(date)}
              isSelected={isSameDay(d, date)}
              courseSection={courseSection}
              viewModel={viewModel}
              hasOccurrence={
                viewModel.getFirstOccurrenceForCourseSectionOnDay(d, courseSection.courseSection!.id) != null
              }
              showDivider={i < displayedDates.length - 1}
            />
          ))}

          <Tooltip title={strings.loadPreviousWeekDataTooltip()}>
            <IconButton
              size="small"
              onClick={loadMoreBefore}
              sx={[
                {
                  left: theme.spacing(1)
                },
                !showNoOccurrencesMessage
                  ? {
                      position: 'absolute',
                      top: theme.spacing(1),
                      backgroundColor: alpha(theme.palette.background.default, 0.6),
                      backdropFilter: 'blur(12px)'
                    }
                  : { alignSelf: 'flex-start' }
              ]}
            >
              <ArrowUpwardRounded sx={{ color: theme.palette.text.secondary }} fontSize="small" />
            </IconButton>
          </Tooltip>

          {showNoOccurrencesMessage && (
            <ListItem dense>
              <ListItemText
                primary={strings.noOccurrencesFound(
                  format(allDates[0], 'PPP'),
                  format(allDates[allDates.length - 1], 'PPP')
                )}
                slotProps={{ primary: { color: 'textSecondary' } }}
              />
            </ListItem>
          )}

          <Tooltip title={strings.loadNextWeekDataTooltip()}>
            <IconButton
              size="small"
              onClick={loadMoreAfter}
              sx={[
                {
                  left: theme.spacing(1)
                },
                !showNoOccurrencesMessage
                  ? {
                      position: 'absolute',
                      bottom: theme.spacing(1),
                      backgroundColor: alpha(theme.palette.background.default, 0.6),
                      backdropFilter: 'blur(12px)'
                    }
                  : { alignSelf: 'flex-start' }
              ]}
            >
              <ArrowDownwardRounded sx={{ color: theme.palette.text.secondary }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </List>
    );
  }
);

interface DateRange {
  start: Date;
  end: Date;
}

function computeDateRange(inputDate: Date, today: Date): DateRange {
  const isToday = isSameDay(inputDate, today);
  const startOfInputWeek = startOfWeek(inputDate); // Sunday
  const endOfInputWeek = endOfWeek(inputDate); // Saturday

  if (differenceInCalendarDays(inputDate, today) < 0) {
    const sixDaysAfter = addDays(inputDate, 6);
    if (differenceInCalendarDays(sixDaysAfter, today) > 0 || isToday) {
      return { start: inputDate, end: addDays(inputDate, 6) };
    }
    return { start: startOfInputWeek, end: endOfInputWeek };
  }

  if (isToday) {
    return { start: today, end: addDays(today, 6) };
  }

  // If date is in the future
  const threeDaysBefore = addDays(inputDate, -3);
  const threeDaysAfter = addDays(inputDate, 3);
  if (isWithinInterval(today, { start: threeDaysBefore, end: inputDate })) {
    return { start: today, end: addDays(today, 6) };
  }
  return { start: threeDaysBefore, end: threeDaysAfter };
}
