import { useServices } from '@/hooks';
import { WorkloadGroupsInfo } from '@/models';
import { isSxArray } from '@/utils';
import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { ContentPasteRounded, SendRounded, WbSunnyRounded } from '@mui/icons-material';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDayStateIndicator } from '../../shared/calendar/day/UserDashboardCalendarDayStateIndicator';
import { PlannerWorkloadItemGroupsListSection } from './PlannerWorkloadItemGroupsListSection';

export interface PlannerWorkloadItemGroupsListProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  getGroups: (date: Date) => WorkloadGroupsInfo | undefined;
  onCreatePublishedWork?: () => void;
  onPaste?: () => void;
}

export const PlannerWorkloadItemGroupsList = observer(
  ({ sx = [], className, date, getGroups, onPaste, onCreatePublishedWork }: PlannerWorkloadItemGroupsListProps) => {
    const { pasteboard } = useServices();
    const groups = getGroups(date);
    const aboveThresholdGroups = groups?.aboveThresholdGroups ?? [];
    const atThresholdGroups = groups?.atThresholdGroups ?? [];
    const underThresholdGroups = groups?.underloadedGroups ?? [];
    const strings = LocalizedStrings.planner.workload;

    const pasteboardContent = pasteboard.currentContentForKinds(['published-work']);
    const hasSomeGroups =
      aboveThresholdGroups.length > 0 || atThresholdGroups.length > 0 || underThresholdGroups.length > 0;

    return (
      <Box
        sx={[{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }, ...(isSxArray(sx) ? sx : [sx])]}
        className={className}
      >
        <Stack spacing={2} sx={{ overflow: 'auto', flex: 1 }}>
          {aboveThresholdGroups.length > 0 && (
            <PlannerWorkloadItemGroupsListSection
              status={WorkloadStatus.ABOVE_THRESHOLD}
              groups={aboveThresholdGroups}
            />
          )}

          {atThresholdGroups.length > 0 && (
            <PlannerWorkloadItemGroupsListSection status={WorkloadStatus.AT_THRESHOLD} groups={atThresholdGroups} />
          )}

          {underThresholdGroups.length > 0 && (
            <PlannerWorkloadItemGroupsListSection
              status={WorkloadStatus.UNDER_THRESHOLD}
              groups={underThresholdGroups}
            />
          )}

          {groups != null && !hasSomeGroups && (
            <Box>
              <UserDashboardCalendarDayStateIndicator
                renderIcon={(size, color) => <WbSunnyRounded sx={{ width: size, height: size, color }} />}
                title={strings.noMajorItemsIndicatorTitle()}
              />
            </Box>
          )}
        </Stack>

        {(onPaste != null || onCreatePublishedWork != null) && (
          <Stack direction="row" sx={{ pt: 2, justifyContent: 'flex-end' }} spacing={1}>
            {onPaste != null && pasteboardContent != null && (
              <Button
                variant="contained-grey"
                startIcon={<ContentPasteRounded />}
                sx={{ px: 2 }}
                className="pill-shaped"
                onClick={onPaste}
                size="small"
              >
                {strings.pasteItemButtonTitle()}
              </Button>
            )}
            {onCreatePublishedWork != null && (
              <Button
                variant="contained"
                className="pill-shaped"
                startIcon={<SendRounded />}
                onClick={onCreatePublishedWork}
                size="small"
              >
                {strings.createPublishedWorkButtonTitle()}
              </Button>
            )}
          </Stack>
        )}
      </Box>
    );
  }
);
