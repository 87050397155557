import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarAnnotationView } from '../../shared';

export interface PlannerWorkloadCourseSectionStudentsListProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  getPlannerDay: (date: Date) => PlannerDay | undefined;
}

export const PlannerWorkloadCourseSectionDetailsPlannerDayHeader = observer(
  ({ sx = [], className, date, getPlannerDay }: PlannerWorkloadCourseSectionStudentsListProps) => {
    const theme = useTheme();
    const plannerDay = getPlannerDay(date);

    return (
      <Stack direction="row" sx={sx} className={className} spacing={3} flexWrap="nowrap" alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Typography sx={{ fontWeight: '600', position: 'relative' }} variant="body2">
            {format(date, 'PPP')}
          </Typography>

          {plannerDay != null && (
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                position: 'absolute',
                left: `calc(100% + ${theme.spacing(1)})`,
                top: `calc(${theme.spacing(1)} * -1)`
              }}
            >
              {plannerDay.cycleDayName}
            </Typography>
          )}
        </Box>
        <Stack
          direction="row"
          spacing={1}
          sx={{ flex: 1, flexWrap: 'nowrap', overflow: 'hidden', justifyContent: 'flex-start' }}
        >
          {plannerDay?.annotations.map((a, index) => (
            <UserDashboardCalendarAnnotationView
              key={index}
              annotation={{ ...a, id: index.toString() }}
              sx={{ flexShrink: 0 }}
            />
          ))}
        </Stack>
      </Stack>
    );
  }
);
