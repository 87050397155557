import { makeObservable, override } from 'mobx';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from './DialogViewModel';
import { MultipleActionsAlertDialogAction } from './MultipleActionsAlertDialogAction';

export interface MultipleActionsAlertDialogViewModel extends StaticDialogViewModel {
  readonly multipleActions: MultipleActionsAlertDialogAction[];
}

export class AppMultipleActionsAlertDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements MultipleActionsAlertDialogViewModel
{
  constructor(
    onCancel: () => Promise<void>,
    readonly multipleActions: MultipleActionsAlertDialogAction[]
  ) {
    super(() => onCancel());

    makeObservable(this);
  }

  @override
  get actions() {
    return [];
  }
}
