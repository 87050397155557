import { getOptionalDashConcatenation } from '@/models';
import { PublishedWorkDetailsViewModel } from '@/viewmodels';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { Box, Grid2 } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { WorkAttachmentList } from '../../lists';
import { Banner, Subheader } from '../../utils';
import { PublishedWorkDetailsTitleCard } from './PublishedWorkDetailsTitleCard';
import { PublishedWorkScheduledTimeBanner } from './PublishedWorkScheduledTimeBanner';

export interface PublishedWorkDetailsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PublishedWorkDetailsViewModel;
}

export const PublishedWorkDetails = observer(({ sx = [], className, viewModel }: PublishedWorkDetailsProps) => {
  const strings = LocalizedStrings.publishedWork.details;
  return (
    <Box sx={sx} className={className}>
      {viewModel.publishedWork.scheduledPublishTime != null && (
        <PublishedWorkScheduledTimeBanner
          sx={{ mb: 1 }}
          scheduledTime={timestampDate(viewModel.publishedWork.scheduledPublishTime)}
        />
      )}

      {viewModel.duplicationCount > 1 && (
        <Banner severity="none">{strings.numberOfCopies(viewModel.duplicationCount)}</Banner>
      )}

      <Box
        sx={{
          width: '100%'
        }}
      >
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            {/* A PublishedWork will always have a CourseSection. */}
            <Subheader>{getOptionalDashConcatenation(viewModel.courseTitle, viewModel.section)}</Subheader>
            <PublishedWorkDetailsTitleCard
              publishedWork={viewModel.publishedWork}
              icon={viewModel.workIcon}
              courseColor={viewModel.courseColor}
            />
          </Grid2>
        </Grid2>
      </Box>
      {viewModel.attachments.length > 0 && (
        <Box
          sx={{
            mt: 2
          }}
        >
          <WorkAttachmentList attachments={viewModel.attachments} />
        </Box>
      )}
    </Box>
  );
});
