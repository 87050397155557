import { createCycleDayItemRepetition, ItemRepeatPatternCycleDayKind, ScheduleCycleInfo } from '@/models';
import { getScheduleCycleKind, titleForCycleDay } from '@/viewmodels';
import { Box, Chip, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';

export interface PlannerRepeatItemCycleDayPatternOptionsProps {
  sx?: SxProps;
  className?: string;
  scheduleCycle: ScheduleCycleInfo;
  pattern: ItemRepeatPatternCycleDayKind;
  onChange: (pattern: ItemRepeatPatternCycleDayKind) => void;
}

export const PlannerRepeatItemCycleDayPatternOptions = observer(
  ({ sx = [], className, scheduleCycle, onChange, pattern }: PlannerRepeatItemCycleDayPatternOptionsProps) => {
    function toggleCycleDay(cycleDay: number) {
      const newSelection = [...pattern.value.cycleDays];

      const existingCycleDayIndex = newSelection.indexOf(cycleDay) ?? -1;

      if (existingCycleDayIndex >= 0) {
        newSelection.splice(existingCycleDayIndex, 1);
      } else {
        newSelection.push(cycleDay);
      }

      onChange({ value: createCycleDayItemRepetition({ cycleDays: newSelection }), case: 'cycleDay' });
    }

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              rowGap: 1,
              columnGap: 1,
              overflow: 'hidden',
              flexWrap: 'wrap'
            }}
          >
            {times(scheduleCycle.scheduleCycle.cycleDayCount).map((cycleDay) => {
              const isSelected = pattern.value.cycleDays.find((v) => v === cycleDay + 1);

              return (
                <Chip
                  key={cycleDay}
                  onClick={() => toggleCycleDay(cycleDay + 1)}
                  label={titleForCycleDay(
                    cycleDay + 1,
                    getScheduleCycleKind(scheduleCycle.scheduleCycle),
                    'long',
                    true,
                    scheduleCycle.scheduleCycle.cycleDayNames
                  )}
                  color={isSelected ? 'primary' : 'default'}
                  variant={isSelected ? 'filled' : 'outlined'}
                />
              );
            })}
          </Box>
        </Stack>
      </Stack>
    );
  }
);
