import { AccessRightSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_right_pb';
import { AccountInfoSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_info_pb';
import { AccountSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { AttachmentSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/attachment_pb';
import { CourseSectionDetailsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_details_pb';
import { CourseSectionSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { DailyWorkloadSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/daily_workload_pb';
import { ExternalSourceSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/external_source_pb';
import { ManagedSchoolInformationSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/managed_school_information_pb';
import { PublishedWorkDuplicationDetailsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_duplication_details_pb';
import { PublishedWorkSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { RichTextSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/rich_text_pb';
import { SchoolInformationSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_information_pb';
import { SchoolSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_pb';
import { SnapshotDetailsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/snapshot_details_pb';
import { StudentSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { StudentStatsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_stats_pb';
import { TeacherSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_pb';
import { TeacherPreferencesSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_preferences_pb';
import { WorkloadGroupSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_group_pb';
import { WorkloadSettingsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_settings_pb';
import { WorkloadSummarySchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_summary_pb';
import { WorkloadThresholdsSchema } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_thresholds_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createSchoolAccessRight(init?: MessageInitShape<typeof AccessRightSchema>) {
  return create(AccessRightSchema, init);
}

export function createSchoolAccount(init?: MessageInitShape<typeof AccountSchema>) {
  return create(AccountSchema, init);
}

export function createSchoolAccountInfo(init?: MessageInitShape<typeof AccountInfoSchema>) {
  return create(AccountInfoSchema, init);
}

export function createSchoolAttachment(init?: MessageInitShape<typeof AttachmentSchema>) {
  return create(AttachmentSchema, init);
}

export function createSchoolCourseSection(init?: MessageInitShape<typeof CourseSectionSchema>) {
  return create(CourseSectionSchema, init);
}

export function createSchoolCourseSectionDetails(init?: MessageInitShape<typeof CourseSectionDetailsSchema>) {
  return create(CourseSectionDetailsSchema, init);
}

export function createDailyWorkload(init?: MessageInitShape<typeof DailyWorkloadSchema>) {
  return create(DailyWorkloadSchema, init);
}

export function createSchoolExternalSource(init?: MessageInitShape<typeof ExternalSourceSchema>) {
  return create(ExternalSourceSchema, init);
}

export function createManagedSchoolInformation(init?: MessageInitShape<typeof ManagedSchoolInformationSchema>) {
  return create(ManagedSchoolInformationSchema, init);
}

export function createPublishedWork(init?: MessageInitShape<typeof PublishedWorkSchema>) {
  return create(PublishedWorkSchema, init);
}

export function createPublishedWorkDuplicationDetails(
  init?: MessageInitShape<typeof PublishedWorkDuplicationDetailsSchema>
) {
  return create(PublishedWorkDuplicationDetailsSchema, init);
}

export function createSchoolRichText(init?: MessageInitShape<typeof RichTextSchema>) {
  return create(RichTextSchema, init);
}

export function createSchool(init?: MessageInitShape<typeof SchoolSchema>) {
  return create(SchoolSchema, init);
}

export function createSchoolInformation(init?: MessageInitShape<typeof SchoolInformationSchema>) {
  return create(SchoolInformationSchema, init);
}

export function createSchoolSnapshotDetails(init?: MessageInitShape<typeof SnapshotDetailsSchema>) {
  return create(SnapshotDetailsSchema, init);
}

export function createStudent(init?: MessageInitShape<typeof StudentSchema>) {
  return create(StudentSchema, init);
}

export function createStudentStats(init?: MessageInitShape<typeof StudentStatsSchema>) {
  return create(StudentStatsSchema, init);
}

export function createTeacher(init?: MessageInitShape<typeof TeacherSchema>) {
  return create(TeacherSchema, init);
}

export function createTeacherPreferences(init?: MessageInitShape<typeof TeacherPreferencesSchema>) {
  return create(TeacherPreferencesSchema, init);
}

export function createWorkloadGroup(init?: MessageInitShape<typeof WorkloadGroupSchema>) {
  return create(WorkloadGroupSchema, init);
}

export function createWorkloadSettings(init?: MessageInitShape<typeof WorkloadSettingsSchema>) {
  return create(WorkloadSettingsSchema, init);
}

export function createWorkloadSummary(init?: MessageInitShape<typeof WorkloadSummarySchema>) {
  return create(WorkloadSummarySchema, init);
}

export function createWorkloadThresholds(init?: MessageInitShape<typeof WorkloadThresholdsSchema>) {
  return create(WorkloadThresholdsSchema, init);
}
