import { Banner } from '@/components';
import { AccessAlarmRounded } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PublishedWorkScheduledTimeBannerProps {
  sx?: SxProps;
  className?: string;
  scheduledTime: Date;
}

export const PublishedWorkScheduledTimeBanner = observer(
  ({ sx = [], className, scheduledTime }: PublishedWorkScheduledTimeBannerProps) => {
    const strings = LocalizedStrings.publishedWork.details;

    return (
      <Banner
        sx={sx}
        className={className}
        severity="info"
        icon={<AccessAlarmRounded fontSize="small" color="inherit" />}
      >
        {strings.scheduleTimeBannerLabel(format(scheduledTime, 'PPPp'))}
      </Banner>
    );
  }
);
