import {
  comparePlannerCourseSectionDetails,
  dateToPBDate,
  dateToString,
  StudentWorkloadDetailsInfo,
  StudentWorkloadStatus,
  timestampDateOptional,
  WorkloadGroupDetails,
  WorkloadGroupsInfo,
  WorkloadPublishedWorkInfo,
  WorkloadSummaryInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { Loadable, PlannerCalendarStore, PlannerDetailedCourseSectionsLoadable } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { WorkloadGroup } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_group_pb';
import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { Timestamp, timestampDate } from '@bufbuild/protobuf/wkt';
import { isWithinInterval } from 'date-fns';
import { chain } from 'lodash';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { localizedCompareWithProperties } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

interface Period {
  start: Timestamp;
  end: Timestamp;
  label: string;
}

interface CourseOccurrenceInfo {
  startTime: Date;
  endTime: Date;
  occurrence: CourseSectionOccurrence;
}

export interface PlannerWorkloadPageViewModel extends UpdatableViewModel {
  readonly hasTaughtClasses: boolean;
  readonly allClasses: CourseSectionDetails[];
  readonly visibleClasses: CourseSectionDetails[];
  readonly isFetchingData: boolean;
  readonly hasFetchError: boolean;

  loadWorkloadForDates(dates: Date[], courseSectionId: string | undefined, force: boolean): Promise<void>;
  getPlannerDay(date: Date): PlannerDay | undefined;
  getFirstOccurrenceForCourseSectionOnDay(date: Date, sectionId: string): CourseOccurrenceInfo | undefined;
  getCourseSectionWorkloadInformationForDate(courseId: string, date: Date): WorkloadSummaryInfo | undefined;
  getCourseSectionWorkloadGroupsForDate(courseSectionId: string, date: Date): WorkloadGroupsInfo | undefined;
  getWorkloadStatusForStudents(courseSectionId: string, date: Date): StudentWorkloadStatus[];
  getWorkloadDetailsForStudent(
    studentId: string,
    courseSectionId: string,
    date: Date
  ): StudentWorkloadDetailsInfo | undefined;
  getPublishedWorkInfo(id: string, courseSectionId: string, date: Date): WorkloadPublishedWorkInfo | undefined;
}

export class AppPlannerWorkloadPageViewModel extends BaseUpdatableViewModel implements PlannerWorkloadPageViewModel {
  @observable private _courseSectionsFilter: string[] | undefined = undefined;
  private readonly _calendarStore: PlannerCalendarStore;

  constructor(
    private readonly _plannerId: string,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _localization = ServiceContainer.services.localization,
    private readonly _settingsStorage = ServiceContainer.services.settingsStorage,
    private readonly _workloadStore = ServiceContainer.services.workloadStore,
    private readonly _workStore = ServiceContainer.services.workStore
  ) {
    super();
    this._calendarStore = this._plannerStore.getCalendarStore(this._plannerId);
    makeObservable(this);

    const fetchDisplayedCourseSections = async () => {
      const filter = await this._settingsStorage.plannerWorkloadDisplayedCourseSectionsForUserDashboard(
        this._plannerId
      );
      runInAction(() => (this._courseSectionsFilter = filter));
    };

    void fetchDisplayedCourseSections();

    reaction(
      () => this._settingsStorage.plannerWorkloadDisplayedCourseSectionsForUserDashboard(this._plannerId),
      (idsPromise) => {
        const updateFilters = async () => {
          const ids = await idsPromise;
          runInAction(() => (this._courseSectionsFilter = ids));
        };

        void updateFilters();
      }
    );
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable, this._workStore.workIcons];
  }

  @computed
  get hasTaughtClasses(): boolean {
    return this.courseSectionsLoadable.values.some((cs) => cs.role === CourseSectionRole.TEACHER);
  }

  @computed
  get allClasses(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.visibleCourseSections
      .sort((a, b) => comparePlannerCourseSectionDetails(a, b, this._localization.currentLocale))
      .filter((cs) => cs.schoolsCourseSection != null);
  }

  @computed
  get visibleClasses(): CourseSectionDetails[] {
    if (this._courseSectionsFilter == null) {
      return this.allClasses;
    }

    return this.allClasses.filter((c) => this._courseSectionsFilter?.includes(c.courseSection!.id));
  }

  @computed
  get isFetchingData(): boolean {
    return this._workloadStore.isFetching || this._calendarStore.isFetching;
  }

  @computed
  get hasFetchError(): boolean {
    return this._workloadStore.error != null || this._calendarStore.error != null;
  }

  getPlannerDay(date: Date): PlannerDay | undefined {
    return this._calendarStore.days.get(dateToString(date));
  }

  getCourseSectionWorkloadInformationForDate(courseId: string, date: Date): WorkloadSummaryInfo | undefined {
    return this._workloadStore.getCourseSectionWorkloadInformationForDate(courseId, dateToPBDate(date));
  }

  async loadWorkloadForDates(dates: Date[], courseSectionId: string | undefined, force: boolean) {
    const sortedDates = dates.sort((a, b) => a.getTime() - b.getTime());
    const startDate = dateToPBDate(sortedDates[0]);
    const endDate = dateToPBDate(sortedDates[sortedDates.length - 1]);

    let courseSectionIds: string[];
    if (courseSectionId != null) {
      const course = this.courseSectionsLoadable.data.get(courseSectionId);
      if (course?.schoolsCourseSection == null) {
        throw new Error(`No course found for the given courseSectionId ${courseSectionId}`);
      }

      courseSectionIds = [course.schoolsCourseSection.id];
    } else {
      courseSectionIds = this.visibleClasses.map((c) => c.schoolsCourseSection!.id);
    }

    const ids = chain(courseSectionIds)
      .map((id) => {
        const cs = this.allClasses.find((c) => c.schoolsCourseSection?.id === id);
        return cs != null ? { courseId: id, schoolId: cs.schoolsCourseSection!.schoolId } : undefined;
      })
      .compact()
      .value();

    const days = dates.map((d) => dateToPBDate(d));
    await Promise.all([
      this._calendarStore.fetchDays(startDate, endDate, force),
      this._workloadStore.fetchCourseSectionsWorkloadInformation(ids, days, force)
    ]);
  }

  getFirstOccurrenceForCourseSectionOnDay(date: Date, sectionId: string): CourseOccurrenceInfo | undefined {
    const plannerDay = this.getPlannerDay(date);

    return chain(plannerDay?.items)
      .map((o) =>
        o.item.case === 'courseSectionOccurrence' && o.item.value.courseSectionId === sectionId
          ? {
              startTime: timestampDate(o.contextualStartTime!),
              endTime: timestampDate(o.contextualEndTime!),
              occurrence: o.item.value
            }
          : undefined
      )
      .compact()
      .first()
      .value();
  }

  getCourseSectionWorkloadGroupsForDate(courseSectionId: string, date: Date): WorkloadGroupsInfo | undefined {
    const dailyWorkload = this._workloadStore.getCourseSectionWorkloadDetailsForDate(
      courseSectionId,
      dateToPBDate(date)
    );

    if (dailyWorkload == null) {
      return undefined;
    }

    const aboveThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.ABOVE_THRESHOLD) ?? [];
    const atThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.AT_THRESHOLD) ?? [];
    const underThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.UNDER_THRESHOLD) ?? [];

    return {
      aboveThresholdGroups: aboveThresholdGroups.map((g) => this.convertGroupToInfo(g, date)),
      atThresholdGroups: atThresholdGroups.map((g) => this.convertGroupToInfo(g, date)),
      underloadedGroups: underThresholdGroups.map((g) => this.convertGroupToInfo(g, date))
    };
  }

  getWorkloadStatusForStudents(courseSectionId: string, date: Date): StudentWorkloadStatus[] {
    return this._workloadStore.getWorkloadStatusForStudents(courseSectionId, dateToPBDate(date)).sort((a, b) =>
      localizedCompareWithProperties(
        [
          { value1: a.student.fullName, value2: b.student.fullName },
          { value1: a.student.emailAddress, value2: b.student.emailAddress },
          { value1: a.student.plannerId, value2: b.student.plannerId }
        ],
        this._localization.currentLocale
      )
    );
  }

  getWorkloadDetailsForStudent(
    studentId: string,
    courseSectionId: string,
    date: Date
  ): StudentWorkloadDetailsInfo | undefined {
    const details = this._workloadStore.getWorkloadDetailsForStudent(studentId, courseSectionId, dateToPBDate(date));

    if (details == null) {
      return undefined;
    }

    const periods = this.getPeriodsForDate(date);
    const works = details.works.map((pw) => this.mapPublishedWorkToInfo(pw, periods));
    return {
      ...details,
      works
    };
  }

  getPublishedWorkInfo(id: string, courseSectionId: string, date: Date): WorkloadPublishedWorkInfo | undefined {
    const dailyWorkload = this._workloadStore.getCourseSectionWorkloadDetailsForDate(
      courseSectionId,
      dateToPBDate(date)
    );

    if (dailyWorkload == null) {
      return undefined;
    }

    for (const group of dailyWorkload.groups) {
      for (const work of group.importantPublishedWorks) {
        if (work.id === id) {
          const periods = this.getPeriodsForDate(date);
          return this.mapPublishedWorkToInfo(work, periods);
        }
      }
    }

    return undefined;
  }

  private convertGroupToInfo(group: WorkloadGroup, date: Date): WorkloadGroupDetails {
    const periods = this.getPeriodsForDate(date);

    const works = group.importantPublishedWorks.map((pw) => this.mapPublishedWorkToInfo(pw, periods));
    return { works, students: group.students, status: group.status };
  }

  private mapPublishedWorkToInfo(work: PublishedWork, periods: Period[]): WorkloadPublishedWorkInfo {
    const courseSection = this._workloadStore.getCourseSection(work.courseSectionId);
    const plannerCourseSection = this.allClasses.find((cs) => cs.schoolsCourseSection?.id === work.courseSectionId);
    const periodTag = this.getPeriodLabelForDate(timestampDateOptional(work.dueTime), work.isDueAllDay, periods);
    return {
      publishedWork: work,
      courseSection,
      periodTag,
      plannerCourseSection,
      isTaughtSection: plannerCourseSection?.role === CourseSectionRole.TEACHER
    };
  }

  private getPeriodsForDate(date: Date): Period[] {
    const items = this._calendarStore.days.get(dateToString(date))?.items;
    return chain(items)
      .map((i) =>
        i.item.case === 'courseSectionOccurrence' && !i.isContextualAllDay
          ? { start: i.contextualStartTime!, end: i.contextualEndTime!, label: i.item.value.periodLabel }
          : undefined
      )
      .compact()
      .value();
  }

  private getPeriodLabelForDate(date: Date | undefined, isAllDay: boolean, periods: Period[]): string | undefined {
    if (date == null || isAllDay) {
      return undefined;
    }

    return periods.find(({ start, end }) =>
      isWithinInterval(date, { start: timestampDate(start), end: timestampDate(end) })
    )?.label;
  }
}
