import { useServices } from '@/hooks';
import { OpenInNewRounded } from '@mui/icons-material';
import { IconButton, List, ListItem, ListItemText, Link as MuiLink, Popover, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Link } from 'react-router';
import LocalizedStrings from 'strings';
import { WorkloadGroupDetails } from '../../../models';
import { FormPopoverActions, FormPopoverHeader } from '../../utils';

export interface PlannerWorkloadItemsGroupStudentListPopoverProps {
  sx?: SxProps;
  className?: string;
  group: WorkloadGroupDetails;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const PlannerWorkloadItemsGroupStudentListPopover = observer(
  ({ sx = [], className, group, open, anchorEl, onClose }: PlannerWorkloadItemsGroupStudentListPopoverProps) => {
    const { localization, route } = useServices();
    const strings = LocalizedStrings.planner.workload;

    const sortedStudents = useMemo(
      () =>
        [...group.students].sort((a, b) =>
          a.fullName.localeCompare(b.fullName, localization.currentLocale, { sensitivity: 'base' })
        ),
      [group.students]
    );

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        sx={sx}
        className={className}
        onClose={onClose}
        slotProps={{ paper: { sx: { width: 320 } } }}
      >
        <FormPopoverHeader title={strings.workloadGroupStudentListTitle()} />

        <List dense>
          {sortedStudents.map((student) => (
            <ListItem
              key={student.accountId}
              secondaryAction={
                <Tooltip title={strings.openStudentPlannerTooltip()}>
                  <IconButton edge="end" component={Link} to={route.resolvePlannerLocation(student.plannerId)}>
                    <OpenInNewRounded fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemText
                primary={student.fullName}
                secondary={student.emailAddress}
                slotProps={{
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  secondary: {
                    variant: 'caption',
                    component: MuiLink,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...({ href: `mailto:${student.emailAddress}`, underline: 'hover' } as any)
                  }
                }}
              />
            </ListItem>
          ))}
        </List>

        <FormPopoverActions
          onSubmit={() => {
            onClose();
            return Promise.resolve();
          }}
          canSubmit
          customSubmitTitle={strings.workloadGroupStudentListClose()}
        />
      </Popover>
    );
  }
);
