import { Card, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode } from 'react';
import LocalizedStrings from 'strings';
import {
  RequiresTodaySchoolsSubscriptionButton,
  RequiresTodaySchoolsSubscriptionIconButton,
  SearchBar
} from '../../utils';

export interface AdminContentCardAction {
  label: { case: 'text'; value: string; icon?: ReactNode } | { case: 'icon'; value: ReactNode };
  onPress: (e: MouseEvent<HTMLButtonElement>) => void;
  requiresActiveSubscription?: boolean;
  isHidden?: boolean;
}

export interface AdminContentCardProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  searchValue?: string;
  onSearch?: (value: string) => void;
  children: ReactNode;
  actions?: AdminContentCardAction[];
}

export const AdminContentCard = observer(
  ({ sx = [], className, onSearch, searchValue, actions = [], children, title, subtitle }: AdminContentCardProps) => {
    const hasSearch = searchValue != null && onSearch != null;
    const strings = LocalizedStrings.admin;

    const visibleActions = actions.filter((a) => a.isHidden !== true);

    return (
      <Card
        sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        className={className}
        elevation={1}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{
            pt: 1,
            pl: 2,
            pr: hasSearch ? 1 : 2,
            alignItems: 'flex-start'
          }}
        >
          <Stack sx={{ flex: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: '600' }}>
              {title}
            </Typography>
            {subtitle != null && <Typography variant="caption">{subtitle}</Typography>}
          </Stack>

          {(hasSearch || visibleActions.length > 0) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              {renderActions(visibleActions)}

              {hasSearch && (
                <SearchBar
                  placeholder={strings.defaultSearchPlaceholder()}
                  sx={{ flex: { xs: 1, sm: undefined }, minWidth: 300 }}
                  onSearch={(value) => onSearch(value ?? '')}
                />
              )}
            </Stack>
          )}
        </Stack>
        <Observer>{() => <>{children}</>}</Observer>
      </Card>
    );
  }
);

function renderActions(actions: AdminContentCardAction[]) {
  return actions.map((action, index) => {
    switch (action.label.case) {
      case 'icon':
        return renderIconAction(`${index}`, action);
      case 'text':
        return renderTextAction(`${index}`, action, action.label.icon);
    }
  });
}

function renderIconAction(key: string, action: AdminContentCardAction) {
  return (
    <RequiresTodaySchoolsSubscriptionIconButton
      key={key}
      size="small"
      onClick={(e: MouseEvent<HTMLButtonElement>) => action.onPress(e)}
      requiresSubscription={action.requiresActiveSubscription ?? true}
    >
      {action.label.value}
    </RequiresTodaySchoolsSubscriptionIconButton>
  );
}

function renderTextAction(key: string, action: AdminContentCardAction, icon: ReactNode | undefined) {
  return (
    <RequiresTodaySchoolsSubscriptionButton
      key={key}
      size="small"
      onClick={(e: MouseEvent<HTMLButtonElement>) => action.onPress(e)}
      requiresSubscription={action.requiresActiveSubscription ?? true}
      startIcon={icon}
    >
      {action.label.value}
    </RequiresTodaySchoolsSubscriptionButton>
  );
}
