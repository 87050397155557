import {
  CalendarFilters,
  CalendarWeekDensity,
  Day,
  TimeOfDay,
  WorkIcons,
  dayToDate,
  timestampToTimeOfDay
} from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, DateService } from '@/services';
import { WorkDataStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Timestamp, timestampDate } from '@bufbuild/protobuf/wkt';
import { isToday as dateIsToday, differenceInMinutes } from 'date-fns';
import { max } from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UserDashboardCalendarItemPosition } from '../shared';
import {
  UserDashboardCalendarWeekAllDayItemInfo,
  UserDashboardCalendarWeekPriority
} from './UserDashboardCalendarWeekItems';

export interface UserDashboardCalendarWeekDayViewModel {
  readonly date: Date;
  readonly isToday: boolean;
  readonly cycleDayName: string;
  readonly allDayItems: UserDashboardCalendarWeekAllDayItemInfo[];
  readonly priorities: UserDashboardCalendarWeekPriority[];
}

export abstract class AppUserDashboardCalendarWeekDayViewModel implements UserDashboardCalendarWeekDayViewModel {
  @observable protected _now: Date;

  protected constructor(
    protected readonly _day: Day,
    private readonly _pointsPerHour: () => number,
    private readonly _displayedCourseSectionIds: () => string[] | undefined,
    protected readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);

    this._now = dateService.now;
    setInterval(() => runInAction(() => (this._now = dateService.now)), 60 * 1_000);
  }

  protected abstract get courseSections(): CourseSectionDetails[];

  @computed
  protected get courseSectionsById(): Map<string, CourseSectionDetails> {
    return new Map(this.courseSections.map((cs) => [cs.courseSection!.id, cs]));
  }

  @computed
  protected get workIcons(): WorkIcons {
    return this._workStore.workIcons.data;
  }

  @computed
  protected get displayedCourseSectionIds(): string[] | undefined {
    return this._displayedCourseSectionIds();
  }

  @computed
  protected get filters(): CalendarFilters {
    return this._settings.calendarWeekFilters;
  }

  @computed
  protected get density(): CalendarWeekDensity {
    return this._settings.calendarWeekDensity;
  }

  @computed
  private get pointsPerHour(): number {
    return this._pointsPerHour();
  }

  @computed
  get date(): Date {
    return dayToDate(this._day);
  }

  @computed
  get isToday(): boolean {
    return dateIsToday(this.date);
  }

  abstract get cycleDayName(): string;

  abstract get allDayItems(): UserDashboardCalendarWeekAllDayItemInfo[];

  abstract get priorities(): UserDashboardCalendarWeekPriority[];

  protected getPositionForItem(
    startTime: Timestamp | undefined,
    endTime: Timestamp | undefined,
    minimumHeight = 20
  ): UserDashboardCalendarItemPosition {
    if (startTime == null) {
      return { top: 0, height: 0 };
    }

    const topOffset = this.getTopPositionForTimeOfDay(timestampToTimeOfDay(startTime));
    let height: number;

    if (endTime != null) {
      const numberOfMinutes = differenceInMinutes(timestampDate(endTime), timestampDate(startTime));
      // Removing 1 so that periods that end at the same time another one begins have a slight offset between.
      height = max([(numberOfMinutes * this.pointsPerHour) / 60 - 1, minimumHeight])!;
    } else {
      height = minimumHeight;
    }

    return { top: topOffset, height };
  }

  private getTopPositionForTimeOfDay(timeOfDay: TimeOfDay): number {
    return timeOfDay.hours * this.pointsPerHour + (timeOfDay.minutes * this.pointsPerHour) / 60;
  }
}
