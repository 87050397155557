import { createCourseSectionDetails, Day, SuggestedPeriod, TimeOfDay } from '@/models';
import { ApplicationSettingsService, ApplicationSettingsStorage, LocalizationService, UserService } from '@/services';
import { PlannerTransportService, ScheduleCycleTransportService } from '@/transports';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { PlannerRelationshipDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_details_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { Calendar } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { action, computed, makeObservable, observable, ObservableSet, reaction } from 'mobx';
import { getOrCreateInObservableMap } from '../../utils';
import { PlannerCalendarStore, PlannerDataStore, StoreInvalidator } from '../contracts';
import {
  AppInboxWorkCountLoadable,
  AppPlannerContentsLoadable,
  AppPlannerDemoDetailedCourseSectionsLoadable,
  AppPlannerDetailedCourseSectionsLoadable,
  AppPlannerParticipationCodeLoadable,
  InboxWorkCountLoadable,
  PlannerContentsLoadable,
  PlannerDemoDetailedCourseSectionsLoadable,
  PlannerDetailedCourseSectionsLoadable,
  PlannerParticipationCodeLoadable
} from '../loadables';
import { AppPlannerCalendarStore } from './AppPlannerCalendarStore';

export class AppPlannerDataStore implements PlannerDataStore {
  private readonly _plannerContentsByPlannerId = observable.map<string, PlannerContentsLoadable>();
  private readonly _inboxWorkCountByPlannerId = observable.map<string, InboxWorkCountLoadable>();
  private readonly _courseSectionsByIdByPlannerId = observable.map<string, PlannerDetailedCourseSectionsLoadable>();
  private readonly _demoCourseSectionsByIdByPlannerId = observable.map<
    string,
    PlannerDemoDetailedCourseSectionsLoadable
  >();
  private readonly _calendarStoreByPlannerId = observable.map<string, PlannerCalendarStore>();
  private readonly _participationCode: PlannerParticipationCodeLoadable;
  private readonly _displayedPlannerIdHistory = new ObservableSet<string>();

  @computed
  get displayedPlannerIdHistory(): Set<string> {
    return new Set(this._displayedPlannerIdHistory);
  }

  constructor(
    private readonly _plannerTransport: PlannerTransportService,
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService,
    private readonly _localization: LocalizationService,
    private readonly _storeInvalidator: StoreInvalidator,
    private readonly _settings: ApplicationSettingsService,
    settingsStorage: ApplicationSettingsStorage,
    user: UserService
  ) {
    makeObservable(this);
    this._participationCode = new AppPlannerParticipationCodeLoadable(_plannerTransport);

    reaction(
      () => ({ user: user.currentUser, isDemoMode: settingsStorage.isDemoMode }),
      (value, oldValue) => {
        if (value.user?.userId !== oldValue.user?.userId || value.isDemoMode !== oldValue.isDemoMode) {
          this.invalidateAll();
        }

        if (
          value.user != null &&
          value.user.userId === oldValue.user?.userId &&
          value.user.cultureName !== oldValue.user?.cultureName
        ) {
          // If same user, but with a different cultureName, we fetch PlannerContents.
          this._plannerContentsByPlannerId.forEach((loadable) => void loadable.fetch(true));
        }
      }
    );

    reaction(
      () => _storeInvalidator.plannersRevision,
      () => this.invalidateAll()
    );
  }

  @action
  addPlannerIdToHistory(id: string) {
    this._displayedPlannerIdHistory.add(id);
  }

  getCourseSectionsInPlanner(plannerId: string): PlannerDetailedCourseSectionsLoadable {
    return getOrCreateInObservableMap(
      this._courseSectionsByIdByPlannerId,
      plannerId,
      () => new AppPlannerDetailedCourseSectionsLoadable(plannerId, this._plannerTransport)
    );
  }

  getDemoCourseSectionsInPlanner(plannerId: string): PlannerDemoDetailedCourseSectionsLoadable {
    return getOrCreateInObservableMap(
      this._demoCourseSectionsByIdByPlannerId,
      plannerId,
      () => new AppPlannerDemoDetailedCourseSectionsLoadable(plannerId, this._plannerTransport)
    );
  }

  getCanPublishWorkInPlanner(plannerId: string): boolean {
    const courseSectionsLoadable = this.getCourseSectionsInPlanner(plannerId);

    if (!courseSectionsLoadable.hasData) {
      return false;
    }

    return courseSectionsLoadable.values.some((course) => course.role === CourseSectionRole.TEACHER);
  }

  getPlannerContentsInPlanner(plannerId: string): PlannerContentsLoadable {
    return getOrCreateInObservableMap(
      this._plannerContentsByPlannerId,
      plannerId,
      () => new AppPlannerContentsLoadable(plannerId, this._plannerTransport, this._localization)
    );
  }

  getInboxWorkCountInPlanner(plannerId: string): InboxWorkCountLoadable {
    return getOrCreateInObservableMap(
      this._inboxWorkCountByPlannerId,
      plannerId,
      () => new AppInboxWorkCountLoadable(plannerId, this._plannerTransport)
    );
  }

  getCalendarStore(plannerId: string): PlannerCalendarStore {
    return getOrCreateInObservableMap(
      this._calendarStoreByPlannerId,
      plannerId,
      () =>
        new AppPlannerCalendarStore(
          plannerId,
          this._localization,
          this._settings,
          this._plannerTransport,
          this._storeInvalidator
        )
    );
  }

  async createCourseSection(
    plannerId: string,
    title: string,
    section: string,
    backgroundColor: string
  ): Promise<CourseSection> {
    const courseSection = await this._plannerTransport.createCourseSectionInPlanner(
      plannerId,
      title,
      section,
      backgroundColor
    );
    this.createOrUpdateCourseSectionList(plannerId, courseSection);
    return courseSection;
  }

  async updateCourseSection(
    courseSectionId: string,
    plannerId: string,
    title: string,
    section: string,
    backgroundColor: string,
    isVisible: boolean,
    syncToken: bigint
  ): Promise<CourseSection> {
    const courseSection = await this._plannerTransport.updateCourseSectionInPlanner(
      courseSectionId,
      title,
      section,
      backgroundColor,
      isVisible,
      syncToken
    );

    this.createOrUpdateCourseSectionList(plannerId, courseSection);
    void this._plannerContentsByPlannerId.get(plannerId)?.fetch(true);
    return courseSection;
  }

  async deleteCourseSection(
    id: string,
    syncToken: bigint,
    plannerId: string,
    shouldAlsoCancelWorks: boolean
  ): Promise<void> {
    await this._plannerTransport.deleteCourseSectionInPlanner(id, syncToken, shouldAlsoCancelWorks);
    this._courseSectionsByIdByPlannerId.get(plannerId)?.remove(id);
    void this._plannerContentsByPlannerId.get(plannerId)?.fetch(true);
  }

  async changeCourseSectionVisibility(
    courseSectionId: string,
    syncToken: bigint,
    plannerId: string,
    isVisible: boolean
  ): Promise<CourseSection> {
    const courseSection = await this._plannerTransport.changeCourseSectionVisibilityInPlanner(
      courseSectionId,
      syncToken,
      isVisible
    );

    this.createOrUpdateCourseSectionList(plannerId, courseSection);
    void this._plannerContentsByPlannerId.get(plannerId)?.fetch(true);
    return courseSection;
  }

  async getPlannerRelationshipDetails(plannerId: string): Promise<PlannerRelationshipDetails[]> {
    return await this._plannerTransport.getPlannerRelationshipDetails(plannerId);
  }

  async getSharingInvitationCode(
    plannerId: string,
    accessKind: AccessKind,
    plannerRelationshipKind: PlannerRelationshipKind
  ): Promise<string> {
    return await this._plannerTransport.getSharingInvitationCode(plannerId, accessKind, plannerRelationshipKind);
  }

  async ensureCustomWorksCompleted(plannerId: string, effect: CustomActionEffect): Promise<void> {
    await this._plannerTransport.ensureCustomWorksCompleted(plannerId, effect, '', '');
    void this.getInboxWorkCountInPlanner(plannerId).fetch(true);
  }

  getTeacherParticipationCode(): PlannerParticipationCodeLoadable {
    return this._participationCode;
  }

  async requestPlannerAccess(plannerIds: string[], accessKind: AccessKind, relationshipKind: PlannerRelationshipKind) {
    return await this._plannerTransport.requestPlannerAccess(plannerIds, accessKind, relationshipKind);
  }

  async getSuggestedPeriods(
    periodScheduleId: string,
    time: TimeOfDay,
    scheduleCycle: ScheduleCycle
  ): Promise<SuggestedPeriod> {
    return await this._scheduleCycleTransport.getSuggestedPeriods(periodScheduleId, time, scheduleCycle);
  }

  async getVolatileCalendar(minimumDay: Day, maximumDay: Day, scheduleCycles: ScheduleCycle[]): Promise<Calendar> {
    return await this._scheduleCycleTransport.getVolatileCalendar(minimumDay, maximumDay, scheduleCycles);
  }

  async fetchPlannerContents(plannerId: string): Promise<void> {
    await Promise.all([
      this.getPlannerContentsInPlanner(plannerId).fetch(true),
      this.getInboxWorkCountInPlanner(plannerId).fetch(true)
    ]);
  }

  private createOrUpdateCourseSectionList(plannerId: string, courseSection: CourseSection) {
    const courseSections = this._courseSectionsByIdByPlannerId.get(plannerId);

    if (courseSections == null) {
      return;
    }

    const courseId = courseSection.id;
    const existingCourseSection = courseSections.data.get(courseId);

    const newCourseSection = createCourseSectionDetails({
      ...(existingCourseSection ?? { role: CourseSectionRole.STUDENT }),
      courseSection
    });
    courseSections.addOrReplace(courseId, newCourseSection);
  }

  @action
  private invalidateAll() {
    this._plannerContentsByPlannerId.clear();
    this._inboxWorkCountByPlannerId.clear();
    this._courseSectionsByIdByPlannerId.clear();
  }
}
