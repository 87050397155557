import { Day, dayToString, UserDashboard, UserDashboardInfo } from '@/models';
import { ScheduleCycleTransportService } from '@/transports';
import { mergeObservableMaps } from '@/utils';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { captureException } from '@sentry/react';
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { CalendarDataStore, StoreInvalidator, UserDataStore } from '../contracts';

export class AppCalendarDataStore implements CalendarDataStore {
  private _lastFetchedDays: { start: Day; end: Day } | undefined;

  private _days = observable.map<string, CalendarDay>();
  @observable protected _moreRecentDraftScheduleCycleIds: string[] = [];
  @observable private _error: string | undefined;
  @observable private _fetchingCount = 0;

  @computed
  private get dashboard(): UserDashboard {
    switch (this._dashboardInfo.kind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboardInfo.id);
        if (planner == null) {
          break;
        }
        return { id: planner.id, planner, kind: 'planner', title: planner.title };
      }
      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboardInfo.id);
        if (school == null) {
          break;
        }
        return { id: school.school!.id, school, kind: 'school', title: school.school!.name };
      }
    }
    throw new Error(`Unsupported dashboard kind: ${this._dashboardInfo.kind}`);
  }

  @computed
  private get scheduleCycleIds(): string[] {
    switch (this.dashboard.kind) {
      case 'planner': {
        return this.dashboard.planner.scheduleCycleIds;
      }
      case 'school': {
        return this.dashboard.school.school!.scheduleCycleIds;
      }
    }
  }

  @computed
  get isFetching(): boolean {
    return this._fetchingCount > 0;
  }

  @computed
  get days(): Map<string, CalendarDay> {
    return new Map(this._days);
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get moreRecentDraftScheduleCycleIds(): string[] {
    return this._moreRecentDraftScheduleCycleIds;
  }

  constructor(
    private readonly _dashboardInfo: UserDashboardInfo,
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService,
    private readonly _userStore: UserDataStore,
    storeInvalidator: StoreInvalidator
  ) {
    makeObservable(this);
    reaction(
      () => storeInvalidator.calendarRevision,
      () => this.invalidate()
    );
  }

  async fetchDays(startDay: Day, endDay: Day, force = false): Promise<void> {
    if (
      !force &&
      this._lastFetchedDays != null &&
      isEqual(this._lastFetchedDays.start, startDay) &&
      isEqual(this._lastFetchedDays.end, endDay)
    ) {
      return;
    }

    // Prevents infinite load.
    this._lastFetchedDays = { start: startDay, end: endDay };

    runInAction(() => {
      this._fetchingCount++;
      this._error = undefined;
    });

    const newValues = observable.map<string, CalendarDay>();

    try {
      const days = await this.loadData(startDay, endDay);
      days.forEach((day) => {
        const key = this.keyForDayContent(day);
        if (key.length === 0) {
          return;
        }

        newValues.set(key, day);
      });

      runInAction(() => {
        mergeObservableMaps(this._days, newValues);
      });
    } catch (e) {
      captureException(e);
      runInAction(() => {
        this._error = (e as Error).message;
      });
    } finally {
      runInAction(() => this._fetchingCount--);
    }
  }

  @action
  invalidate() {
    this._lastFetchedDays = undefined;
  }

  protected async loadData(startDay: Day, endDay: Day): Promise<CalendarDay[]> {
    const calendar = await this._scheduleCycleTransport.getCalendar(this.scheduleCycleIds, startDay, endDay, [], true);
    runInAction(() => (this._moreRecentDraftScheduleCycleIds = calendar.moreRecentDraftScheduleCycleIds));
    return calendar.calendarDays;
  }

  protected keyForDayContent(dayContent: CalendarDay): string {
    return dayToString(dayContent.day!);
  }
}
