import { useActivePlannerId, useNavigateAsync, useViewModel } from '@/hooks';
import { PlannerItemKind } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import LocalizedStrings from 'strings';
import { Banner, Dialog } from '../../../utils';
import { PlannerCopyOriginalItemCard } from '../PlannerCopyOriginalItemCard';
import { PlannerDeleteDuplicatedItemCourseSectionHeader } from './PlannerDeleteDuplicatedItemCourseSectionHeader';
import { PlannerDeleteDuplicatedItemOccurrenceListItem } from './PlannerDeleteDuplicatedItemOccurrenceListItem';

export interface PlannerDeleteDuplicatedItemDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  itemKind: PlannerItemKind;
  duplicationIds: string[];
}

export const PlannerDeleteDuplicatedItemDialog = observer(
  ({ sx, duplicationIds, itemKind, isOpen, className }: PlannerDeleteDuplicatedItemDialogProps) => {
    const plannerId = useActivePlannerId();
    const navigate = useNavigateAsync();
    const strings = LocalizedStrings.planner.archiveDuplicatedItem;

    function dismiss() {
      return navigate(-1);
    }

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createPlannerDeleteDuplicatedItemViewModel(plannerId, itemKind, duplicationIds, dismiss),
      [plannerId, itemKind, close]
    );

    return (
      <Dialog
        title={strings.title()}
        isOpen={isOpen}
        sx={sx}
        className={className}
        width="sm"
        viewModel={viewModel}
        disableOverflow
        isContentDisplayFlex
        renderData={() => (
          <Stack spacing={2} sx={{ width: '100%', overflow: 'hidden', flex: 1 }}>
            <PlannerCopyOriginalItemCard item={viewModel.item} />

            <Stack spacing={1} sx={{ flex: 1, overflow: 'hidden' }}>
              <Typography variant="caption" sx={{ px: 1, fontWeight: '600' }} color="textSecondary">
                {strings.subtitle()}
              </Typography>

              <Stack sx={{ flex: 1, overflow: 'auto' }} spacing={1}>
                {viewModel.sections.map((section) => (
                  <Stack key={section.courseSection?.courseSection?.id ?? 'no-section'}>
                    <PlannerDeleteDuplicatedItemCourseSectionHeader courseSection={section.courseSection} />

                    <Stack>
                      {section.items.map((item) => (
                        <PlannerDeleteDuplicatedItemOccurrenceListItem
                          key={item.id}
                          item={item}
                          getIsSelected={(id) => viewModel.selectedItemIds.includes(id)}
                          onSelect={(id) => viewModel.toggleSelection(id)}
                        />
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              {viewModel.selectedItemIds.length > 0 && (
                <Banner severity="info" icon={<Fragment />}>
                  <Typography variant="subtitle2">
                    {strings.selectedOccurrencesLabel(viewModel.selectedItemIds.length)}
                  </Typography>
                </Banner>
              )}
            </Stack>
          </Stack>
        )}
      />
    );
  }
);
