import { CalendarFilters, Day, dateToPBDate, dayToString } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, ApplicationSettingsStorage } from '@/services';
import { Loadable, PlannerCalendarStore, WorkDataStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../../UpdatableViewModel';
import { UserDashboardCalendarWeekDayViewModel } from './UserDashboardCalendarWeekDayViewModel';

export interface UserDashboardCalendarWeekViewModel extends UpdatableViewModel {
  readonly courseSections: CourseSectionDetails[];
  readonly pointsPerHour: number;
  readonly scheduleCycleWithDraftId: string | undefined;
  readonly hasCalendarSyncError: boolean;

  getViewModelForDay(day: Day): UserDashboardCalendarWeekDayViewModel;
  fetchDays(date: Date, force: boolean): Promise<void>;
}

export abstract class AppUserDashboardCalendarWeekViewModel
  extends BaseUpdatableViewModel
  implements UserDashboardCalendarWeekViewModel
{
  protected _viewModelByDay = observable.map<string, UserDashboardCalendarWeekDayViewModel>();
  @observable private _courseSectionsFilter: string[] | undefined = undefined;

  private readonly _pointsPerHourByDensity = {
    small: 45,
    regular: 70,
    large: 100
  };

  protected constructor(
    protected readonly _dashboardId: string,
    protected readonly _calendarStore: PlannerCalendarStore,
    protected readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    protected readonly _settingsStorage: ApplicationSettingsStorage = ServiceContainer.services.settingsStorage
  ) {
    super();
    makeObservable(this);

    const fetchDisplayedCourseSections = async () => {
      const filter = await this._settingsStorage.calendarWeekDisplayedCourseSectionsForUserDashboard(this._dashboardId);
      runInAction(() => (this._courseSectionsFilter = filter));
    };

    void fetchDisplayedCourseSections();

    reaction(
      () => this._settingsStorage.calendarWeekDisplayedCourseSectionsForUserDashboard(this._dashboardId),
      (idsPromise) => {
        const updateFilters = async () => {
          const ids = await idsPromise;
          runInAction(() => (this._courseSectionsFilter = ids));
        };

        void updateFilters();
      }
    );
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [this._workStore.workIcons];
  }

  @computed
  protected get workIconsById(): Map<string, WorkIcon> {
    return this._workStore.workIcons.data.iconsById;
  }

  @computed
  protected get filters(): CalendarFilters {
    return this._settings.calendarWeekFilters;
  }

  @computed
  protected get courseSectionsFilter(): string[] | undefined {
    return this._courseSectionsFilter;
  }

  @computed
  get pointsPerHour(): number {
    return this._pointsPerHourByDensity[this._settings.calendarWeekDensity];
  }

  @computed
  get scheduleCycleWithDraftId(): string | undefined {
    return this._calendarStore.moreRecentDraftScheduleCycleIds[0];
  }

  @computed
  get hasCalendarSyncError(): boolean {
    return this._calendarStore.error != null;
  }

  abstract get courseSections(): CourseSectionDetails[];

  getViewModelForDay(day: Day): UserDashboardCalendarWeekDayViewModel {
    const key = dayToString(day);
    const existing = this._viewModelByDay.get(key);

    if (existing != null) {
      return existing;
    }

    const viewModel = this.makeViewModelForDay(day, key);
    this._viewModelByDay.set(key, viewModel);
    return viewModel;
  }

  async fetchDays(date: Date, force: boolean): Promise<void> {
    const startDate = startOfWeek(subWeeks(date, 1));
    const endDate = endOfWeek(addWeeks(date, 1));
    await this._calendarStore.fetchDays(dateToPBDate(startDate), dateToPBDate(endDate), force);
  }

  protected abstract makeViewModelForDay(day: Day, dayString: string): UserDashboardCalendarWeekDayViewModel;
}
