import { useServices } from '@/hooks';
import EmptyListIcon from '@mui/icons-material/SchoolRounded';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator } from '../../../utils';

export interface PlannerDoneListEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
  size?: 'small' | 'regular';
}
export const PlannerDoneListEmptyIndicator = observer(({ sx, size, className }: PlannerDoneListEmptyIndicatorProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <EmptyListIndicator
      sx={sx}
      className={className}
      size={size}
      title={strings.doneEmptyListTitle}
      subtitle={strings.upcomingEmptyListSubtitle}
      renderIcon={(className) => <EmptyListIcon className={className} />}
    />
  );
});
