import i18n from 'translate';

export const PlannerItemsFiltersStrings = {
  displayedKindsLabel: () => i18n.t('planner.items.filters.displayedKindsLabel'),
  displayedKindsNoteOptionLabel: () => i18n.t('planner.items.filters.displayedKindsNoteOptionLabel'),
  displayedKindsPublishedWorkOptionLabel: () => i18n.t('planner.items.filters.displayedKindsPublishedWorkOptionLabel'),
  displayedKindsWorkOptionLabel: () => i18n.t('planner.items.filters.displayedKindsWorkOptionLabel'),
  maximumDate: () => i18n.t('planner.items.filters.maximumDate'),
  minimumDate: () => i18n.t('planner.items.filters.minimumDate'),
  otherSectionTitle: () => i18n.t('planner.items.filters.otherSectionTitle'),
  showNoDateItems: () => i18n.t('planner.items.filters.showNoDateItems'),
  showPastItems: () => i18n.t('planner.items.filters.showPastItems'),
  subtitle: () => i18n.t('planner.items.filters.subtitle'),
  title: () => i18n.t('planner.items.filters.title')
};

export const PlannerItemsStrings = {
  allItemsTitle: () => i18n.t('planner.items.allItemsTitle'),
  createMenuNoteLabel: () => i18n.t('planner.items.createMenuNoteLabel'),
  createMenuPublishedWorkLabel: () => i18n.t('planner.items.createMenuPublishedWorkLabel'),
  createMenuWorkLabel: () => i18n.t('planner.items.createMenuWorkLabel'),
  createWorkButtonTitle: () => i18n.t('planner.items.createWorkButtonTitle'),
  dueDate: (date: string) => i18n.t('planner.items.dueDate', { date }),
  emptyLabel: () => i18n.t('planner.items.emptyLabel'),
  emptyNoResultsLabel: () => i18n.t('planner.items.emptyNoResultsLabel'),
  emptyNoSearchResultsLabel: (searchText: string) => i18n.t('planner.items.emptyNoSearchResultsLabel', { searchText }),
  externalSourceName: (sourceName: string) => i18n.t('planner.items.externalSourceName', { sourceName }),
  filters: PlannerItemsFiltersStrings,
  maximumDateOnlyFilterLabel: (date: string) => i18n.t('planner.items.maximumDateOnlyFilterLabel', { date }),
  minimumAndMaximumFilterLabel: (minDate: string, maxDate: string) =>
    i18n.t('planner.items.minimumAndMaximumFilterLabel', { minDate, maxDate }),
  minimumDateOnlyFilterLabel: (date: string) => i18n.t('planner.items.minimumDateOnlyFilterLabel', { date }),
  pastWorksSectionTitle: () => i18n.t('planner.items.pastWorksSectionTitle'),
  publishedDate: (date: string) => i18n.t('planner.items.publishedDate', { date }),
  studentVisibilityDateLabel: (date: string) => i18n.t('planner.items.studentVisibilityDateLabel', { date }),
  title: () => i18n.t('planner.items.title')
};
