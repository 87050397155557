import { useActivePlannerId, useNavigateAsync, usePrevious, useServices, useViewModel } from '@/hooks';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { LightMuiThemeAppBarColor } from '../../resources/themes';
import { isError } from '../../utils';
import { UserDashboardLayout } from '../layout';
import { InitializingScreen, ServerErrorScreen } from '../shared';
import { PlannerParticipationRequestsDialog } from './management';

interface LocationState {
  mainScreenTeacherShowParticipationRequests?: boolean;
  userDashboardPlannerOptionsShowParticipationRequests?: boolean;
  mainAppBarShowParticipationRequests?: boolean;
}

export interface MainScreenPlannerProps {
  className?: string;
}

export const MainScreenPlanner = observer(({ className }: MainScreenPlannerProps) => {
  const { analytics, settingsStorage, userStore } = useServices();
  const theme = useTheme();
  const location = useLocation();
  const state: LocationState = (location.state as LocationState) || {};
  const navigate = useNavigateAsync();

  const plannerId = useActivePlannerId();
  const previousPlannerId = usePrevious(plannerId);
  const viewModel = useViewModel((viewModels) => viewModels.createMainScreenPlannerViewModel(plannerId), [plannerId]);

  useEffect(() => {
    void analytics.logOnboardingCompleted(userStore.schools.map((s) => s.school!.id));
  }, []);

  useEffect(() => {
    if (previousPlannerId !== plannerId) {
      if (
        viewModel.shouldShowPendingParticipationRequests &&
        state.mainScreenTeacherShowParticipationRequests !== true &&
        state.userDashboardPlannerOptionsShowParticipationRequests !== true &&
        state.mainAppBarShowParticipationRequests !== true
      ) {
        viewModel.setHasShownPendingParticipationRequests();
        const newState: LocationState = { ...state, mainScreenTeacherShowParticipationRequests: true };
        void navigate(location, { state: newState });
      }
    }
  }, [plannerId]);

  if (viewModel.state == 'pending') {
    return <InitializingScreen />;
  }

  if (isError(viewModel.state)) {
    return <ServerErrorScreen error={viewModel.state} />;
  }

  return (
    <>
      <meta
        name="theme-color"
        media="(prefers-color-scheme: dark)"
        content={settingsStorage.backgroundImage?.color ?? theme.palette.background.default}
        data-rh="true"
      />
      <meta
        name="theme-color"
        content={settingsStorage.backgroundImage?.color ?? LightMuiThemeAppBarColor}
        data-rh="true"
      />

      <UserDashboardLayout className={className}>
        <Outlet />
      </UserDashboardLayout>

      {state.mainScreenTeacherShowParticipationRequests === true && (
        <PlannerParticipationRequestsDialog isOpen={true} plannerId={plannerId} />
      )}
    </>
  );
});
