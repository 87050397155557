import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { addMonths, isBefore } from 'date-fns';
import { ObservableMap, action, computed, makeObservable, observable } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import { Day, UserDashboardKind, compareTerms, createTerm, dayToDate } from '../../../models';
import { ServiceContainer } from '../../../providers';
import { DateService, LocalizationService } from '../../../services';
import { ScheduleCycleDetailsEditViewModel } from './ScheduleCycleDetailsEditViewModel';
import { ScheduleCycleKind } from './ScheduleCycleUtils';

export interface ScheduleCycleCreateViewModel extends ScheduleCycleDetailsEditViewModel {
  readonly isShowingDates: boolean;
  readonly isShowingScheduleKind: boolean;
  readonly isShowingTerms: boolean;
  readonly createError: string | undefined;
  showDates(): void;
  showScheduleKind(): void;
  showTerms(): void;
}

export class AppScheduleCycleCreateViewModel implements ScheduleCycleCreateViewModel {
  @observable private _name: string | undefined;
  @observable private _startDate: Date;
  @observable private _endDate: Date;
  @observable private _scheduleKind: ScheduleCycleKind;
  @observable private _daysPerCycle: number;
  @observable private _isPerDayPeriodSchedules = false;
  @observable private _firstCycleDay = 1;
  @observable private _terms: ObservableMap<string, Term>;
  @observable private _isShowingDates: boolean;
  @observable private _isShowingScheduleKind: boolean;
  @observable private _isShowingTerms: boolean;
  @observable protected _createError: string | undefined;

  constructor(
    protected readonly _dashboardId: string,
    protected readonly _dashboardKind: UserDashboardKind,
    protected readonly _scheduleCycle: ScheduleCycle | undefined,
    protected readonly _dateService: DateService = ServiceContainer.services.dateService,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    if (_scheduleCycle != null) {
      this._name = _scheduleCycle.name;
      this._startDate = dayToDate(_scheduleCycle.startDay!);
      this._endDate = dayToDate(_scheduleCycle.endDay!);
      this._daysPerCycle = _scheduleCycle.cycleDayCount;
      this._terms = _scheduleCycle.terms.reduce((value, term) => {
        value.set(term.id, term);
        return value;
      }, observable.map<string, Term>());

      if (_scheduleCycle.isDayOfWeekAligned) {
        this._scheduleKind = _scheduleCycle.cycleDayCount === 7 ? 'week' : 'multiple-week';
      } else {
        this._scheduleKind = 'cycle-day';
      }

      this._isShowingDates = true;
      this._isShowingScheduleKind = true;
      this._isShowingTerms = true;
    } else {
      this._startDate = _dateService.now;
      this._endDate = addMonths(_dateService.now, 9);
      this._scheduleKind = 'week';
      this._daysPerCycle = 7;
      this._terms = observable.map();
      this._isShowingDates = false;
      this._isShowingScheduleKind = false;
      this._isShowingTerms = false;
    }
  }

  isNew = true;

  @computed
  get isPerDayPeriodSchedules() {
    return this._isPerDayPeriodSchedules;
  }

  set isPerDayPeriodSchedules(value: boolean) {
    this._isPerDayPeriodSchedules = value;
  }

  @computed
  get isShowingDates(): boolean {
    return this._isShowingDates;
  }

  @computed
  get isShowingScheduleKind(): boolean {
    return this._isShowingScheduleKind;
  }

  @computed
  get isShowingTerms(): boolean {
    return this._isShowingTerms;
  }

  @computed
  get name(): string | undefined {
    return this._name;
  }

  set name(value: string | undefined) {
    this._name = value;
  }

  @computed
  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  @computed
  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    if (isBefore(this.startDate, value)) {
      this._endDate = value;
    }
  }

  @computed
  get scheduleKind(): ScheduleCycleKind {
    return this._scheduleKind;
  }

  set scheduleKind(value: ScheduleCycleKind) {
    this._scheduleKind = value;

    if (value === 'week') {
      this.daysPerCycle = 7;
    } else if (value === 'multiple-week') {
      this.daysPerCycle = 14;
    }
  }

  @computed
  get daysPerCycle(): number {
    return this._daysPerCycle;
  }

  set daysPerCycle(value: number) {
    this._daysPerCycle = value;
  }

  @computed
  get firstCycleDay(): number {
    return this._firstCycleDay;
  }

  set firstCycleDay(value: number) {
    this._firstCycleDay = value;
  }

  @computed
  get canSave(): boolean {
    return (this.name ?? '') !== '' && this.isShowingDates && this.isShowingScheduleKind && this.isShowingTerms;
  }

  @computed
  get createError(): string | undefined {
    return this._createError;
  }

  @computed
  get terms(): Term[] {
    return Array.from(this._terms.values()).sort((a, b) => compareTerms(a, b, this._localization.currentLocale));
  }

  @computed
  get cycleDayNames() {
    return [];
  }

  readonly canEditFirstCycleDay = true;
  readonly canEditCycleNames = false;

  @action
  showDates() {
    this._isShowingDates = true;
  }

  @action
  showScheduleKind() {
    this._isShowingScheduleKind = true;
  }

  @action
  showTerms() {
    this._isShowingTerms = true;
  }

  @action
  addOrUpdateTerm(termId: string | undefined, name: string, startDay: Day, endDay: Day) {
    const term = createTerm({ id: termId ?? uuidV4(), name, startDay, endDay });
    this._terms.set(term.id, term);
  }

  @action
  deleteTerm(id: string) {
    this._terms.delete(id);
  }
}
