import { CopyDuplicationDetailsSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/copy_duplication_details_pb';
import { CycleDayItemRepetitionSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/cycle_day_item_repetition_pb';
import { DistributionDuplicationDetailsSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/distribution_duplication_details_pb';
import { OccurrenceItemRepetitionSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/occurrence_item_repetition_pb';
import { RepetitionDuplicationDetailsSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/repetition_duplication_details_pb';
import { WeekItemRepetitionSchema } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/week_item_repetition_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createCopyDuplicationDetails(init?: MessageInitShape<typeof CopyDuplicationDetailsSchema>) {
  return create(CopyDuplicationDetailsSchema, init);
}

export function createCycleDayItemRepetition(init?: MessageInitShape<typeof CycleDayItemRepetitionSchema>) {
  return create(CycleDayItemRepetitionSchema, init);
}

export function createDistributionDuplicationDetails(
  init?: MessageInitShape<typeof DistributionDuplicationDetailsSchema>
) {
  return create(DistributionDuplicationDetailsSchema, init);
}
export function createOccurrenceItemRepetition(init?: MessageInitShape<typeof OccurrenceItemRepetitionSchema>) {
  return create(OccurrenceItemRepetitionSchema, init);
}

export function createRepetitionDuplicationDetails(init?: MessageInitShape<typeof RepetitionDuplicationDetailsSchema>) {
  return create(RepetitionDuplicationDetailsSchema, init);
}

export function createWeekItemRepetition(init?: MessageInitShape<typeof WeekItemRepetitionSchema>) {
  return create(WeekItemRepetitionSchema, init);
}
