import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarAnnotationView } from '../../shared';

export interface AdminWorkloadListDateHeaderProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  getCalendarDay: (date: Date) => CalendarDay | undefined;
}

export const AdminWorkloadListDateHeader = observer(
  ({ sx = [], date, getCalendarDay, className }: AdminWorkloadListDateHeaderProps) => {
    const calendarDay = getCalendarDay(date);

    return (
      <Stack sx={sx} spacing={1} className={className}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1" fontWeight="600">
            {format(date, 'PPPP')}
          </Typography>

          {calendarDay != null && !calendarDay.isDayOfWeekCycleDayName && (
            <Typography variant="subtitle2" color="textSecondary">
              {calendarDay.cycleDayName}
            </Typography>
          )}
        </Stack>

        {calendarDay != null && calendarDay.annotations.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            sx={{ flex: 1, flexWrap: 'nowrap', overflow: 'hidden', justifyContent: 'flex-start' }}
          >
            {calendarDay?.annotations.map((a, index) => (
              <UserDashboardCalendarAnnotationView
                key={index}
                annotation={{ ...a, id: index.toString() }}
                sx={{ flexShrink: 0 }}
              />
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);
