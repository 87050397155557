import { useServices, useViewModel } from '@/hooks';
import { generateWorkIconUrl, WorkIconInfo, WorkloadPublishedWorkInfo } from '@/models';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { css } from '@emotion/css';
import {
  CalendarMonthRounded,
  CreateRounded,
  GradeRounded,
  PersonRounded,
  SchoolRounded,
  SendRounded
} from '@mui/icons-material';
import { Box, Grid2, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import LocalizedStrings from 'strings';
import { ColorIndicator, WorkIcon } from '../../lists';
import { Banner, Dialog, RichTextViewer } from '../../utils';

export interface PlannerWorkloadPublishedWorkDetailsDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  item: WorkloadPublishedWorkInfo;
}

export const PlannerWorkloadPublishedWorkDetailsDialog = ({
  sx = [],
  className,
  isOpen,
  onClose,
  item
}: PlannerWorkloadPublishedWorkDetailsDialogProps) => {
  const { userStore, workStore } = useServices();
  const strings = LocalizedStrings.planner.workload.publishedWorkDetails;
  const userId = userStore.user.userId;

  const close = () => {
    onClose();
    return Promise.resolve();
  };

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerWorkloadItemDetailsDialogViewModel(item, close),
    [item]
  );

  return (
    <Dialog
      title={strings.title()}
      sx={sx}
      className={className}
      isOpen={isOpen}
      viewModel={viewModel}
      width="md"
      renderData={() => {
        const { periodTag, publishedWork, courseSection, plannerCourseSection } = viewModel.item;

        const icon = workStore.workIcons.data.iconsById.get(publishedWork.iconId)!;
        const iconInfo: WorkIconInfo = {
          id: icon.iconId,
          title: icon.iconName,
          lightUrl: generateWorkIconUrl(icon, 'light', undefined, undefined, 'major'),
          darkUrl: generateWorkIconUrl(icon, 'dark', undefined, undefined, 'major'),
          externalBadgeUrl: undefined
        };

        const teachers = courseSection?.details?.teachers ?? [];

        return (
          <Grid2 container sx={{ width: '100%' }} columnSpacing={2} rowSpacing={3}>
            {publishedWork.creatorUserId === userId && (
              <Banner severity="info">{strings.ownedWorkBannerLabel()}</Banner>
            )}

            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Stack spacing={2}>
                <Info
                  icon={<WorkIcon icon={iconInfo} size={30} displayCourseSectionColor={false} />}
                  caption={strings.titleLabel()}
                  values={[
                    {
                      text: publishedWork.title
                    }
                  ]}
                />

                <Info
                  icon={<CreateRounded />}
                  caption={strings.createdOnLabel()}
                  values={[
                    {
                      text: format(timestampDate(publishedWork.createdTime!), 'PPPp')
                    }
                  ]}
                />

                <Info
                  icon={<SendRounded />}
                  caption={strings.publishedOnLabel()}
                  values={[
                    {
                      text:
                        publishedWork.scheduledPublishTime != null
                          ? format(timestampDate(publishedWork.createdTime!), 'PPPp')
                          : strings.publishedImmediatelyLabel()
                    }
                  ]}
                />

                <Info
                  icon={<CalendarMonthRounded />}
                  caption={strings.dueDateLabel()}
                  values={[
                    {
                      text:
                        publishedWork.dueTime != null
                          ? format(timestampDate(publishedWork.createdTime!), 'PPPp')
                          : strings.dueDateNone(),
                      subtitle: periodTag != null ? `${strings.duePeriodLabel()} ${periodTag}` : undefined
                    }
                  ]}
                />
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12, sm: 6 }}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Icon icon={<SchoolRounded />} />

                  <Stack direction="row" spacing={1}>
                    <Box sx={{ py: 0.5, display: 'flex' }}>
                      <ColorIndicator color={plannerCourseSection?.courseSection?.color ?? courseSection?.color} />
                    </Box>
                    <Stack>
                      <Typography sx={{ fontWeight: '500' }}>
                        {courseSection?.title ?? strings.unknownClassLabel()}
                      </Typography>

                      <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '600' }}>
                        {courseSection?.section ?? strings.noSectionLabel()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Info
                  icon={<PersonRounded />}
                  caption={strings.teachersLabel()}
                  values={
                    teachers.length > 0
                      ? teachers.map((t) => ({
                          text: t.fullName,
                          subtitle: t.emailAddress,
                          subtitleLink: `mailto: ${t.emailAddress}`,
                          icon:
                            t.userId === userId ? (
                              <Tooltip title={strings.currentUserIconTooltip()}>
                                <SchoolRounded fontSize="small" color="primary" />
                              </Tooltip>
                            ) : undefined
                        }))
                      : [{ text: strings.noTeachersLabel() }]
                  }
                />

                {publishedWork.maxGrade > 0 && (
                  <Info
                    icon={<GradeRounded />}
                    caption={strings.gradeLabel()}
                    values={[
                      {
                        text:
                          publishedWork.maxGrade > 0 ? strings.grade(publishedWork.maxGrade) : strings.noGradeLabel()
                      }
                    ]}
                  />
                )}
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
              <Stack spacing={1}>
                <Caption text={strings.descriptionLabel()} />

                <RichTextViewer
                  id={'workload-item-details'}
                  label={strings.descriptionLabel()}
                  content={publishedWork.description}
                  textSize="small"
                />
              </Stack>
            </Grid2>
          </Grid2>
        );
      }}
    />
  );
};

interface IconProps {
  sx?: SxProps;
  className?: string;
  icon: ReactNode;
}

function Icon({ sx = [], className, icon }: IconProps) {
  const theme = useTheme();

  const iconClassName = css({
    width: 30,
    height: `calc(30px + ${theme.spacing(1)})`,
    pt: 1,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

  return (
    <Box sx={sx} className={clsx(className, iconClassName)}>
      {icon}
    </Box>
  );
}

interface CaptionProps {
  sx?: SxProps;
  className?: string;
  text: string;
}

function Caption({ sx = [], className, text }: CaptionProps) {
  return (
    <Typography sx={sx} className={className} variant="caption" color="textSecondary">
      {text}
    </Typography>
  );
}

interface ValueOption {
  text: string;
  subtitle?: string;
  subtitleLink?: string;
  icon?: ReactNode;
}

interface ValueProps {
  sx?: SxProps;
  className?: string;
  value: ValueOption;
}

function Value({ sx = [], className, value }: ValueProps) {
  const { text, subtitle, subtitleLink, icon } = value;

  return (
    <Stack sx={sx} className={className} direction="row" spacing={1}>
      <Stack sx={{ flex: 1, alignItems: 'flex-start' }}>
        <Typography sx={{ fontWeight: '500' }}>{text}</Typography>
        {subtitle != null &&
          (subtitleLink != null ? (
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ fontWeight: '600' }}
              component={Link}
              href={subtitleLink}
              underline="hover"
            >
              {subtitle}
            </Typography>
          ) : (
            <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '600' }}>
              {subtitle}
            </Typography>
          ))}
      </Stack>

      {icon}
    </Stack>
  );
}

interface InfoProps {
  sx?: SxProps;
  className?: string;
  icon: ReactNode;
  caption: string;
  values: ValueOption[];
}

function Info({ sx = [], className, icon, caption, values }: InfoProps) {
  return (
    <Stack sx={sx} className={className} direction="row" spacing={1} alignItems="flex-start">
      <Icon icon={icon} />

      <Stack sx={{ flex: 1 }}>
        <Caption text={caption} />
        <Stack spacing={0.5}>
          {values.map((value, index) => (
            <Value value={value} key={index} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
