import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { PlannerPastePlannerItemViewModel } from '../../../../viewmodels';
import { CourseSectionPicker } from '../../../shared';
import { Subheader } from '../../../utils';

export interface PlannerPastePlannerItemCourseSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemViewModel;
}

export const PlannerPastePlannerItemCourseSelection = observer(
  ({ sx = [], className, viewModel }: PlannerPastePlannerItemCourseSelectionProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    return (
      <Stack sx={sx} className={className} spacing={0}>
        <Subheader>{strings.courseSelectionSectionTitle()}</Subheader>

        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <CourseSectionPicker
            canSelectNone={!viewModel.requiresCourseSelection}
            selectedCourseId={viewModel.targetCourseId}
            allCourseSections={viewModel.courseSections}
            onChange={(courseSectionId) => viewModel.setCourseId(courseSectionId ?? '')}
          />
        </Stack>
      </Stack>
    );
  }
);
