import { DateService } from '@/services';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { differenceInCalendarDays, format, isBefore } from 'date-fns';
import { ExLocalizedStrings } from '../../resources/strings/ExLocalizedStrings';

export function plannedWorkIsCompleted(plannedWork: PlannedWork, dateService: DateService): boolean {
  const now = dateService.now;

  if (plannedWork.timeSlot?.endTime == null) {
    return false;
  }

  const end = timestampDate(plannedWork.timeSlot.endTime);
  if (plannedWork.timeSlot.isAllDay) {
    return differenceInCalendarDays(end, now) < 0;
  } else {
    return isBefore(end, now);
  }
}

export function plannedWorkDateLabel(
  plannedWork: PlannedWork,
  dateService: DateService,
  localizedStrings: ExLocalizedStrings
): string {
  const strings = localizedStrings.dateTime;
  const startTime = timestampDate(plannedWork.timeSlot!.startTime!);
  const dayDiff = differenceInCalendarDays(startTime, dateService.now);

  if (dayDiff < 0) {
    return format(startTime, 'PPPP');
  }
  if (dayDiff === 0) {
    return format(startTime, `${strings.calendarSameDay}, do`);
  } else if (dayDiff === 1) {
    return format(startTime, `${strings.calendarNextDay}, do`);
  } else if (dayDiff < 7) {
    return format(startTime, 'EEEE do');
  } else {
    return format(startTime, 'PPPP');
  }
}
