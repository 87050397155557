import { PictureSourceKind, PictureUrls } from '@/models';
import { UnsplashTransportService } from '@/services';
import { Photo } from '@studyoco/unsplash-ts';
import { computed, makeObservable } from 'mobx';

export interface UnsplashPictureViewModel {
  readonly id: string;
  readonly source: PictureSourceKind;
  readonly urls: PictureUrls;
  readonly alt: string;
  readonly authorUrl: string;
  readonly authorName: string;
  readonly color: string | undefined;

  download(): Promise<void>;
}

export class AppUnsplashPictureViewModel implements UnsplashPictureViewModel {
  readonly source: PictureSourceKind = 'unsplash';

  constructor(
    private readonly _photo: Photo,
    private readonly _unsplash: UnsplashTransportService
  ) {
    makeObservable(this);
  }

  get id(): string {
    return this._photo.id;
  }

  get urls(): PictureUrls {
    const urls = this._photo.urls;
    return {
      large: urls.full,
      original: urls.raw,
      preview: urls.thumb,
      regular: urls.regular,
      small: urls.small
    };
  }

  @computed
  get alt(): string {
    return this._photo.description ?? '';
  }

  @computed
  get authorUrl(): string {
    return `https://unsplash.com/@${this._photo.user.username}?utm_source=today&utm_medium=referral`;
  }

  @computed
  get authorName(): string {
    return this._photo.user.name;
  }

  @computed
  get color(): string | undefined {
    return this._photo.color ?? undefined;
  }

  async download() {
    await this._unsplash.download(this._photo);
  }
}
