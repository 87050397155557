import { useServices } from '@/hooks';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { addDays, addWeeks, isWeekend, startOfWeek, subWeeks } from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';
import { setDateInSearchParams } from '../../../utils';
import { WorkloadInformationView } from '../../shared';
import { PlannerWorkloadCourseSectionsTableDateControls } from './PlannerWorkloadCourseSectionsTableDateControls';
import { PlannerWorkloadCourseSectionsTableDayHeader } from './PlannerWorkloadCourseSectionsTableDayHeader';
import { PlannerWorkloadCourseSectionsTableSectionInfo } from './PlannerWorkloadCourseSectionsTableSectionInfo';
import { useRegisterPlannerWorkloadForceReload } from './PlannerWorkloadForceReloadContext';

export interface PlannerWorkloadCourseSectionsTableProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
  currentDate: Date;
  goToDate: (date: Date | undefined) => void;
}

export const PlannerWorkloadCourseSectionsTable = observer(
  ({ sx = [], className, viewModel, currentDate, goToDate }: PlannerWorkloadCourseSectionsTableProps) => {
    const { dateService, settings } = useServices();
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();

    const firstDayOfWeek = startOfWeek(currentDate);
    let dates = times(7).map((i) => addDays(firstDayOfWeek, i));
    dates = settings.plannerWorkloadShowWeekends ? dates : dates.filter((d) => !isWeekend(d));

    useEffect(() => {
      void viewModel.loadWorkloadForDates(dates, undefined, true);
    }, [dates]);

    useRegisterPlannerWorkloadForceReload(() => {
      void viewModel.loadWorkloadForDates(dates, undefined, true);
    });

    function goToPreviousWeek() {
      goToDate(subWeeks(currentDate, 1));
    }

    function goToNextWeek() {
      goToDate(addWeeks(currentDate, 1));
    }

    function goToToday() {
      goToDate(undefined);
    }

    function showSectionDetails(sectionId: string, date: Date | undefined) {
      searchParams.set('section', sectionId);
      if (date != null) {
        setDateInSearchParams(date, searchParams, dateService);
      }
      setSearchParams(searchParams);
    }

    return (
      <Stack spacing={3} sx={sx} className={className}>
        <TableContainer sx={{ width: '100%', '& .MuiTableCell-root': { borderColor: theme.palette.divider } }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {dates.map((date) => (
                  <PlannerWorkloadCourseSectionsTableDayHeader
                    key={date.toString()}
                    date={date}
                    getPlannerDay={(d) => viewModel.getPlannerDay(d)}
                  />
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {viewModel.visibleClasses.map((c) => (
                <TableRow key={c.courseSection!.id} hover sx={{ cursor: 'pointer' }}>
                  <TableCell onClick={() => showSectionDetails(c.courseSection!.id, undefined)}>
                    <PlannerWorkloadCourseSectionsTableSectionInfo key={c.courseSection!.id} courseSection={c} />
                  </TableCell>

                  {dates.map((date) => (
                    <TableCell
                      key={date.toString()}
                      sx={{ minWidth: '100px' }}
                      onClick={() => showSectionDetails(c.courseSection!.id, date)}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <WorkloadInformationView
                          date={date}
                          getWorkloadInformation={() =>
                            viewModel.getCourseSectionWorkloadInformationForDate(c.schoolsCourseSection!.id, date)
                          }
                        />
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack>
          <Divider />
          <PlannerWorkloadCourseSectionsTableDateControls
            sx={{ p: 1.5, py: 0.5 }}
            dates={dates}
            goToPreviousWeek={goToPreviousWeek}
            goToNextWeek={goToNextWeek}
            goToToday={goToToday}
            goToDate={goToDate}
          />
        </Stack>
      </Stack>
    );
  }
);
