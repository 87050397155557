import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';

export interface PlannerWorkloadNoTaughtSectionsProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerWorkloadNoTaughtSections = ({ sx = [], className }: PlannerWorkloadNoTaughtSectionsProps) => {
  const { route } = useServices();
  const plannerId = useActivePlannerId();
  const navigate = useNavigateAsync();
  const strings = LocalizedStrings.planner.workload;

  function goHome() {
    const destination = route.resolvePlannerLocation(plannerId);
    void navigate(destination);
  }

  return (
    <Stack sx={[{ alignItems: 'flex-start' }, ...(isSxArray(sx) ? sx : [sx])]} className={className} spacing={1}>
      <Typography color="textSecondary" variant="body2">
        {strings.noTaughtSectionsMessage()}
      </Typography>

      <Button variant="contained-grey" onClick={goHome}>
        {strings.noTaughtSectionsButtonTitle()}
      </Button>
    </Stack>
  );
};
