import { format, isSameDay, parse } from 'date-fns';
import { DateService } from '../services';

export function setDateInSearchParams(date: Date, searchParams: URLSearchParams, dateService: DateService): void {
  if (isSameDay(date, dateService.now)) {
    searchParams.delete('date');
  } else {
    const dateString = format(date, 'y-MM-dd');
    searchParams.set('date', dateString);
  }
}

export function getDateFromSearchParams(searchParams: URLSearchParams, dateService: DateService): Date {
  const dateParam = searchParams.get('date');
  return dateParam ? parse(dateParam, 'y-MM-dd', new Date()) : dateService.now;
}
