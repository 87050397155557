import { alpha, ButtonProps, Interpolation, Theme } from '@mui/material';

export const elevatedButtonVariants: {
  props: Partial<ButtonProps<'button', unknown>>;
  style: Interpolation<{ theme: Theme }>;
}[] = [
  {
    props: { variant: 'contained-grey' },
    style: (theme) => ({
      backgroundColor: getBackgroundColor(theme.theme)
    })
  },
  {
    props: { variant: 'contained-grey', color: 'primary' },
    style: (theme) => ({
      color: theme.theme.palette.primary.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.primary.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'secondary' },
    style: (theme) => ({
      color: theme.theme.palette.secondary.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.secondary.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'error' },
    style: (theme) => ({
      color: theme.theme.palette.error.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.error.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'info' },
    style: (theme) => ({
      color: theme.theme.palette.info.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.info.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'success' },
    style: (theme) => ({
      color: theme.theme.palette.success.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.success.main)
      }
    })
  },
  {
    props: { variant: 'contained-grey', color: 'warning' },
    style: (theme) => ({
      color: theme.theme.palette.warning.main,
      ':hover': {
        backgroundColor: getHoverBackgroundColor(theme.theme.palette.warning.main)
      }
    })
  },
  // The size styling is defined for each variant, so we need to define it here as well.
  // Source: https://github.com/mui/material-ui/blob/master/packages/mui-material/src/Button/Button.js#L262
  // Related issue: https://github.com/mui/material-ui/issues/32427
  {
    props: {
      size: 'small',
      variant: 'contained-grey'
    },
    style: (theme) => ({
      padding: '4px 10px',
      fontSize: theme.theme.typography.pxToRem(13)
    })
  },
  {
    props: {
      size: 'large',
      variant: 'contained-grey'
    },
    style: (theme) => ({
      padding: '8px 22px',
      fontSize: theme.theme.typography.pxToRem(15)
    })
  }
];

function getBackgroundColor(theme: Theme): string {
  switch (theme.palette.mode) {
    case 'dark':
      return alpha(theme.palette.action.active, 0.08);
    case 'light':
      return theme.palette.action.selected;
  }
}

function getHoverBackgroundColor(color: string): string {
  return alpha(color, 0.15);
}
