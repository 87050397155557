import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { DemoTransportService, GrpcTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppDemoTransportService extends AppBaseTransportService implements DemoTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async createDemoSchoolSnapshot(schoolId: string, name: string): Promise<void> {
    await this.performWriteRequest('createDemoSchoolSnapshot', (headers) =>
      this._grpcTransport.demoClient.createDemoSchoolSnapshot(
        {
          schoolId,
          name
        },
        { headers }
      )
    );
  }

  async listDemoSchoolSnapshots(schoolId: string): Promise<Snapshot[]> {
    const response = await this.performReadRequest('listDemoSchoolSnapshots', (headers) =>
      this._grpcTransport.demoClient.listDemoSchoolSnapshots({ schoolId }, { headers })
    );
    return response.snapshots;
  }

  async restoreDemoSchoolSnapshot(schoolId: string, name: string, dayOffset: number): Promise<void> {
    await this.performWriteRequest('restoreDemoSchoolSnapshot', (headers) =>
      this._grpcTransport.demoClient.restoreDemoSchoolSnapshot(
        {
          schoolId,
          name,
          dayOffset
        },
        { headers }
      )
    );
  }
}
