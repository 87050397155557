import { useNavigateAsync, useServices, useViewModel } from '@/hooks';
import {
  Work,
  WorkSchema
} from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { MessageInitShape } from '@bufbuild/protobuf';
import { ArchiveRounded } from '@mui/icons-material';
import { Box, Grid2, Stack, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { plannerCourseSectionDetailsToInfo } from '../../../models';
import { CourseSectionEditDialog, CourseSectionPicker } from '../../shared';
import {
  ConfirmationDialog,
  DateTimePicker,
  Dialog,
  DialogAdditionalAction,
  MultipleActionsAlertDialog,
  RichTextEditor,
  Subheader
} from '../../utils';
import { WorkEditAddAttachment } from './WorkEditAddAttachment';
import { WorkEditAttachmentsList } from './WorkEditAttachmentsList';
import { WorkIconPicker } from './WorkIconPicker';
import { WorkImportanceLevelPicker } from './WorkImportanceLevelPicker';

interface LocationState {
  workEditShowCreateCourseSection?: boolean;
}

export interface WorkEditDialogProps {
  /**
   * Id of the work we wish to edit. Optional. If undefined, it creates a new work.
   */
  workId: string | undefined;
  /**
   * Initial values for a new work. Optional.
   */
  baseParams?: MessageInitShape<typeof WorkSchema>;
  isOpen: boolean;
  locationStateKey: string;
}

export const WorkEditDialog = observer(({ workId, baseParams, locationStateKey, isOpen }: WorkEditDialogProps) => {
  const { route } = useServices();

  const params = useParams();
  const plannerId = params.plannerId ?? '';
  const strings = LocalizedStrings.work.edit;
  const theme = useTheme();

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();
  const [showCancelWorkConfirmAlert, setShowCancelWorkConfirmAlert] = useState(false);

  function onSuccess(work: Work) {
    if (workId == null) {
      const backgroundLocation = location;
      // We remove the state responsible for showing the WorkEdit. Otherwise, it would show below the WorkDetails.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundLocation.state[locationStateKey] = undefined;
      const newState = { backgroundLocation };
      const newLocation = route.resolvePlannerWorkLocation(plannerId, work.id);
      void navigate(newLocation, { state: newState, replace: true });
    } else {
      void navigate(-1);
    }

    return Promise.resolve();
  }

  function close() {
    void navigate(-1);
    return Promise.resolve();
  }

  // Using a ref to prevent the viewModel from being recreated when hot-reloading.
  const viewModelRef = useRef(
    useViewModel(
      (viewModels) => viewModels.createWorkEditViewModel(workId, baseParams, plannerId, onSuccess, close),
      [plannerId]
    )
  );

  const viewModel = viewModelRef.current;

  function showCreateCourseSection() {
    const newState: LocationState = { ...state, workEditShowCreateCourseSection: true };
    void navigate(location, { state: newState });
  }

  function onCreateCourseClose(courseSectionId: string | undefined) {
    if (courseSectionId != null) {
      viewModel.setCourseSection(courseSectionId);
    }
  }

  async function dismissCancelWorkConfirmAlert(hasConfirmed: boolean) {
    setShowCancelWorkConfirmAlert(false);

    if (hasConfirmed) {
      const wasCancelled = await viewModel.cancelWork();
      if (wasCancelled) {
        void navigate(route.resolvePlannerLocation(plannerId), { replace: true });
      }
    }
  }

  const actions: DialogAdditionalAction[] =
    viewModel.hasData && !viewModel.isNewWork
      ? [
          {
            title: strings.cancelWork(),
            icon: <ArchiveRounded />,
            action: () => {
              setShowCancelWorkConfirmAlert(true);
              return Promise.resolve();
            }
          }
        ]
      : [];

  return (
    <>
      <Dialog
        isOpen={isOpen}
        canToggleFullscreen
        width="lg"
        viewModel={viewModel}
        title={workId != null ? strings.editTitle() : strings.addTitle()}
        fullScreenWidth="md"
        submit={() => void viewModel.save()}
        contentPadding={{ top: 1 }}
        additionalActions={actions}
        renderData={() => (
          <Grid2
            container
            spacing={3}
            sx={{
              pb: viewModel.error != null ? 3 : 0,
              width: '100%'
            }}
          >
            <Grid2 size={{ xs: 12, md: 8 }}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Subheader>{strings.titleSectionTitle()}</Subheader>
                  <TextField
                    value={viewModel.title}
                    placeholder={viewModel.icon?.iconName}
                    fullWidth
                    disabled={viewModel.isApplying}
                    onChange={(e) => (viewModel.title = e.target.value)}
                  />
                </Box>

                <Box>
                  <Subheader>{strings.instructionsSectionTitle()}</Subheader>
                  <RichTextEditor
                    id="work-edit"
                    content={viewModel.description}
                    label={strings.instructionsSectionTitle()}
                    sx={{ height: `calc(${theme.typography.body1.lineHeight}rem  * 10)` }}
                    sidebarMode="none"
                    alwaysDisplayLabel={false}
                    textSize="small"
                    onChange={(content) => (viewModel.description = content)}
                    disabled={viewModel.isApplying}
                  />
                </Box>

                {viewModel.attachments.length > 0 && <WorkEditAttachmentsList attachments={viewModel.attachments} />}

                <WorkEditAddAttachment onCreateAttachment={(a) => viewModel.addAttachments(a)} />
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Subheader>{strings.informationSectionTitle()}</Subheader>
                  <Stack direction="column" spacing={2}>
                    <CourseSectionPicker
                      label={strings.courseTitle()}
                      disabled={viewModel.isApplying}
                      selectedCourseId={viewModel.courseSection?.id}
                      allCourseSections={viewModel.allCourseSections.map(plannerCourseSectionDetailsToInfo)}
                      onChange={(id) => viewModel.setCourseSection(id)}
                      onCreateCourseClick={() => showCreateCourseSection()}
                      elevation={2}
                    />
                    <WorkIconPicker
                      workIcons={viewModel.workIcons}
                      label={strings.workIconTitle()}
                      disabled={viewModel.isApplying}
                      value={viewModel.icon}
                      onChange={(id) => viewModel.setWorkIcon(id)}
                      elevation={2}
                    />
                    <WorkImportanceLevelPicker
                      disabled={viewModel.isApplying}
                      value={viewModel.importanceLevel}
                      onChange={(level) => (viewModel.importanceLevel = level)}
                      label={strings.importanceLevelTitle()}
                      elevation={2}
                    />
                  </Stack>
                </Box>

                <Box>
                  <Subheader>{strings.dueDateSectionTitle()}</Subheader>
                  <DateTimePicker
                    direction="column"
                    value={viewModel.dueDate}
                    disabled={viewModel.isApplying}
                    hasTime={!viewModel.isAllDay}
                    datePickerLabel={strings.dueDateDateLabel()}
                    timePickerLabel={strings.dueDateTimeLabel()}
                    datePickerKind="user-dashboard-calendar"
                    highlightedDates={(from, to) => viewModel.getDatesWithOccurrenceForCourseSection(from, to)}
                    getPeriodTimesForDate={(date) => viewModel.getCourseSectionOccurrencesStartTimeForDate(date)}
                    dateFormat={strings.dueDateDateFormat()}
                    onChange={(value, hasTime) => {
                      viewModel.dueDate = value;
                      viewModel.isAllDay = !hasTime;
                    }}
                  />
                </Box>
              </Stack>
            </Grid2>
          </Grid2>
        )}
      />

      {viewModel.showApplyToCopiesConfirmation && (
        <MultipleActionsAlertDialog
          isOpen
          title={strings.applyToCopiesDialog.title()}
          message={strings.applyToCopiesDialog.message()}
          actions={[
            {
              label: strings.applyToCopiesDialog.applyToAllButtonTitle(),
              onClick: () => void viewModel.saveDuplicates(true),
              message: strings.applyToCopiesDialog.applyToAllTooltip()
            },
            {
              label: strings.applyToCopiesDialog.applyOnlyToThisCopyButtonTitle(),
              onClick: () => void viewModel.saveDuplicates(false),
              message: strings.applyToCopiesDialog.applyOnlyToThisCopyTooltip()
            }
          ]}
          onCancel={() => {
            viewModel.showApplyToCopiesConfirmation = false;
            return Promise.resolve();
          }}
        />
      )}

      {(state.workEditShowCreateCourseSection ?? false) && (
        <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} onClose={onCreateCourseClose} />
      )}

      {showCancelWorkConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.cancelWorkConfirmationTitle()}
          message={strings.cancelWorkConfirmationMessage()}
          confirmButtonLabel={strings.cancelWorkConfirmationButton()}
          onSubmit={(hasConfirmed) => void dismissCancelWorkConfirmAlert(hasConfirmed)}
        />
      )}
    </>
  );
});
