import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import {
  ClassRounded,
  CoPresentRounded,
  EventNoteRounded,
  FeedRounded,
  GroupsRounded,
  QueryStatsRounded,
  SchoolRounded
} from '@mui/icons-material';
import { Box, List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { SideBarButton } from '../../shared';
import '../../shared/sidebar/Sidebar.styles.css';

export interface AdminSidebarProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  school: SchoolInformation | undefined;
  onSelectOption: () => void;
}

export const AdminSidebar = observer(({ sx = [], className, schoolId, school, onSelectOption }: AdminSidebarProps) => {
  const { featureFlag, route, userStore } = useServices();
  const strings = LocalizedStrings.admin.sidebar;
  const scheduleCycleIds = school?.school?.scheduleCycleIds.map((id) => id) ?? [];
  const scheduleCycleStores = scheduleCycleIds.map((id) =>
    userStore.getScheduleCycleStore(id, { kind: 'school', id: schoolId })
  );

  const hasScheduleCycleDraft = scheduleCycleStores.find((s) => s.state === 'fulfilled' && s.draftSessionId.length > 0);

  useEffect(() => {
    const fetchScheduleCycles = async () => {
      await Promise.all(scheduleCycleStores.map((s) => s.fetch()));
    };

    void fetchScheduleCycles();
  }, [scheduleCycleStores]);

  return (
    <Box sx={{ ...sx, overflow: 'auto' }} className={clsx(className, 'sidebar', 'sidebar-regular')}>
      <List sx={{ px: 1, py: 2 }}>
        <SideBarButton
          isCompact={false}
          title={strings.schools()}
          icon={<SchoolRounded />}
          onSelect={onSelectOption}
          link={{
            to: route.resolveAdminSchoolsListLocation(),
            mathPattern: { path: route.resolveAdminSchoolsListLocation(), end: true }
          }}
        />
      </List>
      {school != null && (
        <>
          <List sx={{ px: 1 }}>
            <Stack
              sx={{
                px: 1,
                my: 1
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '500'
                }}
              >
                {school.school!.name}
              </Typography>

              {school.school!.subtitle.length > 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary
                  }}
                >
                  {school.school!.subtitle}
                </Typography>
              )}
            </Stack>

            <SideBarButton
              isCompact={false}
              title={strings.general()}
              icon={<FeedRounded />}
              onSelect={onSelectOption}
              link={{
                to: route.resolveAdminSchoolInformationLocation(schoolId),
                mathPattern: { path: route.resolveAdminSchoolInformationLocation(schoolId), end: true }
              }}
            />

            <SideBarButton
              isCompact={false}
              title={strings.classes()}
              icon={<ClassRounded />}
              onSelect={onSelectOption}
              link={{
                to: route.resolveAdminSchoolClassesLocation(schoolId),
                mathPattern: { path: route.resolveAdminSchoolClassesLocation(schoolId), end: false }
              }}
            />

            <SideBarButton
              isCompact={false}
              title={strings.schedules()}
              icon={<EventNoteRounded />}
              onSelect={onSelectOption}
              badge={hasScheduleCycleDraft ? { color: 'primary', label: '1' } : undefined}
              link={{
                to: route.resolveAdminSchoolSchedulesLocation(schoolId),
                mathPattern: { path: route.resolveAdminSchoolSchedulesLocation(schoolId), end: false }
              }}
            />

            <SideBarButton
              isCompact={false}
              title={strings.teachers()}
              icon={<CoPresentRounded />}
              onSelect={onSelectOption}
              link={{
                to: route.resolveAdminSchoolTeachersLocation(schoolId),
                mathPattern: { path: route.resolveAdminSchoolTeachersLocation(schoolId), end: false }
              }}
            />

            <SideBarButton
              isCompact={false}
              title={strings.students()}
              icon={<GroupsRounded />}
              onSelect={onSelectOption}
              link={{
                to: route.resolveAdminSchoolStudentsLocation(schoolId),
                mathPattern: { path: route.resolveAdminSchoolStudentsLocation(schoolId), end: false }
              }}
            />

            {featureFlag.isEnabled('workload-manager') && (
              <SideBarButton
                isCompact={false}
                title={strings.workload()}
                icon={<QueryStatsRounded />}
                onSelect={onSelectOption}
                link={{
                  to: route.resolveAdminSchoolWorkloadLocation(schoolId),
                  mathPattern: { path: route.resolveAdminSchoolWorkloadLocation(schoolId), end: false }
                }}
              />
            )}
          </List>
        </>
      )}
    </Box>
  );
});
