import { ThemeVariant } from '@/services';
import { ImportanceLevel } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/importance_level_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkIcons } from '../WorkIcons';

export function urlForWorkIcon(icon: WorkIcon, themeVariant: ThemeVariant): string {
  return generateWorkIconUrl(icon, themeVariant);
}

export function urlForWorkIconFromWork(icon: WorkIcon, work: Work, themeVariant: ThemeVariant): string {
  return generateWorkIconUrl(
    icon,
    themeVariant,
    workStatusToWorkIconState(work.status, work.externalSource != null),
    'due',
    importanceLevelToWorkIconWorkload(work.importance)
  );
}

export function urlForPublishedWorkWorkIcon(
  icon: WorkIcon,
  importance: ImportanceLevel,
  themeVariant: ThemeVariant
): string {
  return generateWorkIconUrl(icon, themeVariant, 'published', 'due', importanceLevelToWorkIconWorkload(importance));
}

export function urlForExternalSourceBadge(sourceName: string | undefined, icons: WorkIcons): string | undefined {
  if (sourceName == null) {
    return undefined;
  }

  const sourceNamesWithBadge = new Set(['classroom', 'today-curriculum']);
  if (!sourceNamesWithBadge.has(sourceName)) {
    return undefined;
  }

  return icons.externalBadgeUrl.replace('{external_source_name}', sourceName);
}
// prettier-ignore
type WorkIconState =
  'private' |
  'unpublished' |
  'published' |
  'completed' |
  'cancelled';

// prettier-ignore
type WorkIconKind =
  'announced' |
  'due';

// prettier-ignore
type WorkIconWorkload =
  'regular' |
  'medium' |
  'major';

// prettier-ignore
type WorkIconModifier =
  'invisible' |
  'unread' |
  'read';

export function generateWorkIconUrl(
  icon: WorkIcon,
  themeVariant: ThemeVariant,
  state?: WorkIconState,
  kind?: WorkIconKind,
  workload?: WorkIconWorkload,
  modifier?: WorkIconModifier
): string {
  const searchParams = new URLSearchParams({ theme: themeVariant });
  if (state != null) {
    searchParams.append('state', state);
  }
  if (kind != null) {
    searchParams.append('kind', kind);
  }
  if (workload != null) {
    searchParams.append('workload', workload);
  }
  if (modifier != null) {
    searchParams.append('modifier', modifier);
  }

  const searchParamsStr = searchParams.toString();
  const queryString = searchParamsStr.length > 0 ? `?${searchParamsStr}` : '';

  return `${icon.iconUrl}${queryString}`;
}

function workStatusToWorkIconState(workStatus: WorkStatus, hasExternalSource: boolean): WorkIconState {
  switch (workStatus) {
    case WorkStatus.COMPLETED:
      return 'completed';

    case WorkStatus.CANCELLED:
      return 'cancelled';

    case WorkStatus.ACTIVE:
    case WorkStatus.UNSPECIFIED:
    default:
      return hasExternalSource ? 'published' : 'unpublished';
  }
}

function importanceLevelToWorkIconWorkload(workImportanceLevel: ImportanceLevel): WorkIconWorkload {
  switch (workImportanceLevel) {
    case ImportanceLevel.MAJOR:
      return 'medium';

    case ImportanceLevel.CRITICAL:
      return 'major';

    case ImportanceLevel.REMINDER:
    case ImportanceLevel.REGULAR:
    case ImportanceLevel.UNSPECIFIED:
    default:
      return 'regular';
  }
}
