import { CheckRounded } from '@mui/icons-material';
import { ListItemText, MenuItem, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement } from 'react';
import { isSxArray } from '../../../utils';

export interface OptionsPopoverItemProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  isSelected: boolean;
  onClick: () => void;
  startElement?: ReactElement;
}

export const OptionsPopoverItem = observer(
  ({ sx = [], className, title, subtitle, isSelected, startElement, onClick }: OptionsPopoverItemProps) => {
    return (
      <MenuItem
        sx={[{ display: 'flex', alignItems: 'stretch' }, ...(isSxArray(sx) ? sx : [sx])]}
        className={className}
        onClick={() => onClick()}
      >
        {startElement}
        <Stack sx={{ flex: 1, alignItems: 'center' }} direction="row">
          <ListItemText
            primary={title}
            secondary={subtitle}
            slotProps={{
              primary: { overflow: 'hidden', whiteSpace: 'break-spaces' },
              secondary: { overflow: 'hidden', noWrap: true }
            }}
          />
          <CheckRounded color="primary" fontSize="small" sx={{ ml: 1, opacity: isSelected ? 1 : 0 }} />
        </Stack>
      </MenuItem>
    );
  }
);
