import { useServices } from '@/hooks';
import { AdminWorkloadViewModel } from '@/viewmodels';
import { CalendarMonthRounded } from '@mui/icons-material';
import { Button, Divider, Grid2, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { getDateFromSearchParams, setDateInSearchParams } from '../../../utils';
import { PlannerWorkloadItemGroupsList } from '../../planner';
import { PlannerWorkloadPublishedWorkDetailsDialog } from '../../planner/workload/PlannerWorkloadPublishedWorkDetailsDialog';
import { AdminWorkloadDateList } from './AdminWorkloadDateList';
import { AdminWorkloadListDateHeader } from './AdminWorkloadListDateHeader';

export interface AdminWorkloadListViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminWorkloadViewModel;
}

export const AdminWorkloadListView = observer(({ sx = [], className, viewModel }: AdminWorkloadListViewProps) => {
  const { dateService } = useServices();
  const strings = LocalizedStrings.admin.workload;

  const [searchParams, setSearchParams] = useSearchParams();
  const currentDate = getDateFromSearchParams(searchParams, dateService);
  const workId = searchParams.get('workloadWorkId');

  const selectedItem = useMemo(() => {
    if (workId == null) {
      return undefined;
    }
    return viewModel.getPublishedWorkInfo(workId, currentDate);
  }, [workId, viewModel.isFetchingData, currentDate]);

  function clearWorkId() {
    searchParams.delete('workloadWorkId');
    setSearchParams(searchParams);
  }

  function setDate(date: Date | undefined) {
    if (date != null) {
      setDateInSearchParams(date, searchParams, dateService);
    } else {
      searchParams.delete('date');
    }
    setSearchParams(searchParams);
  }

  function showCalendar() {
    searchParams.delete('display');
    setSearchParams(searchParams);
  }

  return (
    <Grid2 container sx={sx} className={className}>
      <Grid2 size={{ xs: 0, sm: 3 }} sx={{ height: '100%', overflow: 'hidden' }}>
        <Stack spacing={0} sx={{ width: '100%', height: '100%', overflow: 'hidden' }} direction="row">
          <Stack sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
            <Stack sx={{ px: 1 }}>
              <Button
                variant="contained-grey"
                startIcon={<CalendarMonthRounded />}
                onClick={showCalendar}
                size="small"
                className="pill-shaped"
              >
                {strings.goBackToCalendarButtonTitle()}
              </Button>
            </Stack>

            <AdminWorkloadDateList
              viewModel={viewModel}
              date={currentDate}
              setDate={setDate}
              sx={{ flex: 1, overflow: 'hidden' }}
            />
          </Stack>

          <Divider orientation="vertical" />
        </Stack>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 9 }} sx={{ height: '100%', overflow: 'hidden' }}>
        <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }} spacing={1}>
          <AdminWorkloadListDateHeader
            date={currentDate}
            getCalendarDay={(date) => viewModel.getCalendarDay(date)}
            sx={{ px: 2 }}
          />

          <PlannerWorkloadItemGroupsList
            sx={{ p: 1, flex: 1 }}
            getGroups={(date) => viewModel.getWorkloadGroupsForDate(date)}
            date={currentDate}
          />
        </Stack>

        {selectedItem != null && (
          <PlannerWorkloadPublishedWorkDetailsDialog isOpen onClose={clearWorkId} item={selectedItem} />
        )}
      </Grid2>
    </Grid2>
  );
});
