import {
  Day,
  SubscriptionInfo,
  UserDashboardInfo,
  UserDashboardKind,
  UserDashboardPlanner,
  UserDashboardSchool,
  UserPreferenceKey,
  createPlanner,
  createPlannerSchoolInformation,
  isDemoId,
  plannerHasRelationshipKindsForUser
} from '@/models';
import {
  ApplicationSettingsStorage,
  AuthenticationService,
  FeatureFlagService,
  LocalizationService,
  UserService
} from '@/services';
import {
  PlannerTransportService,
  ScheduleCycleTransportService,
  SchoolTransportService,
  SubscriptionsTransportService,
  UserTransportService,
  ValidateSharingCodeResponse
} from '@/transports';
import { Locale, NoMatchingPlannerError, getOrCreateInObservableMap } from '@/utils';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { PeriodScheduleCreationMode } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_creation_mode_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { Product } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_pb';
import { ProductPrice } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_price_pb';
import { PreferenceValue } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/preference_value_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { captureException } from '@sentry/react';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { clonePlannerSchoolInformation } from '../../viewmodels';
import { CalendarDataStore, ScheduleCycleDataStore, StoreInvalidator, UserDataStore } from '../contracts';
import {
  AppCurrentUserEmotionalPulseLoadable,
  AppParticipatingSchoolsLoadable,
  AppPlannerLoadable,
  AppPlannersLoadable,
  AppScheduleCycleLoadable,
  AppScheduleCyclesLoadable,
  AppSchoolEntitlementLoadable,
  AppSchoolLoadable,
  AppSubscriptionLoadable,
  AppSubscriptionProductsLoadable,
  AppUserPreferenceLoadable,
  CurrentUserEmotionalPulseLoadable,
  ParticipatingSchoolsLoadable,
  PlannerLoadable,
  PlannersLoadable,
  ScheduleCycleLoadable,
  ScheduleCyclesLoadable,
  SchoolEntitlementLoadable,
  SchoolLoadable,
  SubscriptionLoadable,
  SubscriptionProductsLoadable,
  UserDashboardScheduleCyclesLoadable,
  UserPreferenceLoadable
} from '../loadables';
import { AppCalendarDataStore } from './AppCalendarDataStore';
import { AppScheduleCycleDataStore } from './AppScheduleCycleDataStore';

export class AppUserDataStore implements UserDataStore {
  private readonly _planners: PlannersLoadable;
  private readonly _participatingSchools: ParticipatingSchoolsLoadable;
  private readonly _subscriptionLoadable: SubscriptionLoadable;
  private readonly _subscriptionProductsLoadable: SubscriptionProductsLoadable;
  private readonly _emotionalPulse: CurrentUserEmotionalPulseLoadable;
  @observable private readonly _plannerViewingHistory: string[] = [];

  private readonly _plannerLoadablesById = observable.map<string, PlannerLoadable>();
  private readonly _schoolLoadablesById = observable.map<string, SchoolLoadable>();

  private readonly _plannerScheduleCycleDataStoresById = observable.map<string, ScheduleCycleDataStore>();
  private readonly _schoolScheduleCycleDataStoresById = observable.map<string, ScheduleCycleDataStore>();
  private readonly _plannerCalendarDataStoresById = observable.map<string, CalendarDataStore>();
  private readonly _schoolCalendarDataStoresById = observable.map<string, CalendarDataStore>();
  private readonly _schoolEntitlementById = observable.map<string, SchoolEntitlementLoadable>();
  private readonly _userPreferencesByKey = observable.map<UserPreferenceKey, UserPreferenceLoadable>();

  constructor(
    private readonly _plannerTransport: PlannerTransportService,
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService,
    private readonly _schoolTransport: SchoolTransportService,
    private readonly _subscriptionsTransport: SubscriptionsTransportService,
    private readonly _user: UserService,
    private readonly _userTransport: UserTransportService,
    private readonly _authentication: AuthenticationService,
    featureFlags: FeatureFlagService,
    private readonly _localization: LocalizationService,
    private readonly _storeInvalidator: StoreInvalidator,
    private readonly _settingsStorage: ApplicationSettingsStorage
  ) {
    this._planners = new AppPlannersLoadable(_plannerTransport);
    this._participatingSchools = new AppParticipatingSchoolsLoadable(_schoolTransport);
    this._subscriptionLoadable = new AppSubscriptionLoadable(_subscriptionsTransport);
    this._subscriptionProductsLoadable = new AppSubscriptionProductsLoadable(_subscriptionsTransport, featureFlags);
    this._emotionalPulse = new AppCurrentUserEmotionalPulseLoadable(_userTransport);
    makeObservable(this);

    reaction(
      () => _user.currentUser,
      (newValue, prevValue) => {
        // We only want to invalidate data if we have a different user id. Otherwise, an invalidation would be
        // invalidated when the user profile changes (ex: when assigning persona).
        if (newValue?.userId != prevValue?.userId) {
          void this.invalidateData();
        }
      }
    );

    reaction(
      () => _settingsStorage.isDemoMode,
      () => void this.invalidateData()
    );

    reaction(
      () => _localization.currentLocale,
      () => {
        if (this._user.currentUser != null) {
          void this._subscriptionProductsLoadable.fetch(true);
        }
      }
    );

    reaction(
      () => _storeInvalidator.schoolsRevision + _storeInvalidator.plannersRevision,
      () => void this.invalidateData()
    );
  }

  @computed
  private get plannersById(): Map<string, Planner> {
    if (!this._planners.hasData) {
      return new Map<string, Planner>();
    }

    return this._planners.values.reduce((value, planner) => {
      value.set(planner.id, planner);

      return value;
    }, new Map<string, Planner>());
  }

  @computed
  private get schoolsById(): Map<string, SchoolInformation> {
    if (!this.participatingSchools.hasData) {
      return new Map<string, SchoolInformation>();
    }

    return this.participatingSchools.values.reduce((value, school) => {
      value.set(school.school!.id, school);

      return value;
    }, new Map<string, SchoolInformation>());
  }

  @computed
  get subscription(): SubscriptionLoadable {
    return this._subscriptionLoadable;
  }

  @computed
  get availableSubscriptionProducts(): SubscriptionProductsLoadable {
    return this._subscriptionProductsLoadable;
  }

  @computed
  get emotionalPulse(): CurrentUserEmotionalPulseLoadable {
    return this._emotionalPulse;
  }

  @computed
  get user(): UserProfile {
    return this._user.currentUser!;
  }

  @computed
  get plannersLoadable(): PlannersLoadable {
    return this._planners;
  }

  @computed
  get participatingSchools(): ParticipatingSchoolsLoadable {
    return this._participatingSchools;
  }

  @computed
  get planners(): Planner[] {
    return Array.from(this.plannersById.values()).sort((p1, p2) => {
      const planner1IsDemo = isDemoId(p1.id);
      if (planner1IsDemo !== isDemoId(p2.id)) {
        return planner1IsDemo ? 1 : -1;
      }

      return p1.title.localeCompare(p2.title, this._localization.currentLocale, { sensitivity: 'base' });
    });
  }

  @computed
  get nonDemoPlanners(): Planner[] {
    if (!this.plannersLoadable.hasData) {
      return [];
    }

    if (this._settingsStorage.isDemoMode) {
      return this.plannersLoadable.values;
    }

    return this.plannersLoadable.values.filter((d) => !d.id.startsWith('demo'));
  }

  @computed
  get nonDemoNorTeacherPlanners(): Planner[] {
    return this.nonDemoPlanners.filter((p) =>
      p.relationships.find((rel) => rel.userId === this.user.userId && rel.kind !== PlannerRelationshipKind.TEACHER)
    );
  }

  @computed
  get ownedPlanners(): Planner[] {
    return this.nonDemoPlanners.filter((p) =>
      p.relationships.find(
        (rel) =>
          rel.userId === this.user.userId &&
          (rel.kind === PlannerRelationshipKind.CREATOR ||
            rel.kind === PlannerRelationshipKind.INDIVIDUAL ||
            rel.kind === PlannerRelationshipKind.STUDENT)
      )
    );
  }

  @computed
  get schools(): SchoolInformation[] {
    return Array.from(this.schoolsById.values());
  }

  @computed
  get ownedSchools(): SchoolInformation[] {
    return this.schools.filter((s) => s.owners.some((owner) => owner.userId === this.user.userId));
  }

  @computed
  get idOfLastOwnedPlannerShowed(): string | undefined {
    // Using a history of viewed planner in the edge case where a user has multiple planner.
    const lastNonTeacherPlanner = this.plannerViewingHistory.findLast(
      (p) => !plannerHasRelationshipKindsForUser(this.user.userId, p, PlannerRelationshipKind.TEACHER)
    );

    return lastNonTeacherPlanner?.id ?? this.getDefaultPlanner()?.id;
  }

  getPlannerForId(id: string): Planner | undefined {
    const planner = this.plannersById.get(id);

    if (planner != null) {
      return planner;
    } else if (this.user.roles.includes('super-admin')) {
      const loadable = this._plannerLoadablesById.get(id);
      return (loadable?.hasData ?? false) ? loadable?.data : undefined;
    }

    return undefined;
  }

  getIsImpersonatingInPlanner(id: string): boolean {
    return this.plannersById.get(id) == null;
  }

  getSchoolForId(id: string): SchoolInformation | undefined {
    const school = this.schoolsById.get(id);

    if (school != null) {
      return school;
    } else if (this.user.roles.includes('super-admin')) {
      const loadable = this._schoolLoadablesById.get(id);
      return (loadable?.hasData ?? false) ? loadable?.data : undefined;
    }

    return undefined;
  }

  getDefaultPlanner(): Planner | undefined {
    if (this.planners.length === 0) {
      return undefined;
    }

    const relationshipKindOrder: Record<PlannerRelationshipKind, number> = {
      [PlannerRelationshipKind.CREATOR]: 0,
      [PlannerRelationshipKind.INDIVIDUAL]: 1,
      [PlannerRelationshipKind.STUDENT]: 2,
      [PlannerRelationshipKind.TEACHER]: 3,
      [PlannerRelationshipKind.PARENT]: 4,
      [PlannerRelationshipKind.UNSPECIFIED]: 5
    };

    return chain(this.planners)
      .orderBy([
        (p) => isDemoId(p.id),
        (p) => {
          const relationshipKind =
            p.relationships.find((pr) => pr.userId === this.user.userId)?.kind ?? PlannerRelationshipKind.UNSPECIFIED;

          return relationshipKindOrder[relationshipKind];
        }
      ])
      .first()
      .value();
  }

  getPlannerLoadable(plannerId: string): PlannerLoadable {
    return getOrCreateInObservableMap(
      this._plannerLoadablesById,
      plannerId,
      () => new AppPlannerLoadable(plannerId, this.plannersById.get(plannerId), this._plannerTransport)
    );
  }

  getSchoolLoadable(schoolId: string): SchoolLoadable {
    return getOrCreateInObservableMap(
      this._schoolLoadablesById,
      schoolId,
      () => new AppSchoolLoadable(schoolId, this.schoolsById.get(schoolId), this._schoolTransport)
    );
  }

  @computed
  get plannerViewingHistory(): Planner[] {
    return chain(this._plannerViewingHistory)
      .map((id) => this.getPlannerForId(id))
      .compact()
      .value();
  }

  @action
  addPlannerToHistory(plannerId: string) {
    const lastId = this._plannerViewingHistory[this._plannerViewingHistory.length - 1];

    if (lastId == null || lastId !== plannerId) {
      this._plannerViewingHistory.push(plannerId);
    }
  }

  async updateCurrentUserLocale(locale: Locale): Promise<void> {
    await this._userTransport.setCurrentUserCulture(locale);
    this._user.currentUser = await this._userTransport.getCurrentUserProfile();
  }

  async assignPersona(persona: UserPersona): Promise<void> {
    this._user.currentUser = await this._userTransport.assignPersona(persona);
  }

  async createPlanner(
    relationshipKind: PlannerRelationshipKind,
    title: string | undefined,
    shouldUpdateStoredData: boolean
  ): Promise<string> {
    const planner = await this._plannerTransport.createPlanner(relationshipKind, title ?? '');

    if (shouldUpdateStoredData) {
      this.updatePlannerUserDashboard(planner);
    }

    return planner.id;
  }

  async renamePlanner(plannerId: string, title: string | undefined) {
    const planner = await this._plannerTransport.renamePlanner(plannerId, title ?? '');
    this.updatePlannerUserDashboard(planner);
  }

  async createSchool(
    name: string,
    subtitle: string | undefined,
    sharingMode: SchoolSharingMode,
    shouldUpdateStoredData: boolean,
    attachToPlannerId: string | undefined
  ): Promise<{ schoolId: string; attachError: Error | undefined }> {
    const school = await this._schoolTransport.createSchool(name, subtitle, sharingMode);

    let attachError: Error | undefined;

    try {
      if (attachToPlannerId != null) {
        await this.attachSchoolToPlanner(school.id, attachToPlannerId, shouldUpdateStoredData);
      } else if (shouldUpdateStoredData) {
        await this.participatingSchools.fetch(true);
      }
    } catch (e) {
      captureException(e);
      console.error(`Failed to attach school to planner. Error: ${(e as Error).message}`);
      attachError = e as Error;
    }

    return { schoolId: school.id, attachError };
  }

  async editSchool(schoolId: string, name: string, subtitle: string | undefined): Promise<string> {
    const result = await this._schoolTransport.editSchool(schoolId, name, subtitle);

    const school = this.getSchoolForId(schoolId);
    if (school != null) {
      const newSchool = createPlannerSchoolInformation({
        ...school,
        school: result
      });

      this.participatingSchools.addOrReplace(schoolId, newSchool);
    }

    return schoolId;
  }

  async attachSchoolToPlanner(schoolId: string, plannerId: string, shouldUpdateData: boolean): Promise<void> {
    const { schoolIds, ignoredSchoolIds } = await this._plannerTransport.attachSchoolToPlanner(schoolId, plannerId);

    if (shouldUpdateData) {
      // Updating list of schools to update 'connectedPlannedIds' property.
      await this._participatingSchools.fetch(true);
      const planner = this.getPlannerForId(plannerId);

      if (planner != null) {
        const newPlanner = createPlanner({ ...planner, schoolIds, ignoredSchoolIds });
        this.plannersLoadable.addOrReplace(plannerId, newPlanner);
      }
    }
  }

  async shareSchool(schoolId: string, inheritedScheduleCycleId: string | undefined): Promise<void> {
    await this._schoolTransport.shareSchool(schoolId, inheritedScheduleCycleId);
    await Promise.all([this.participatingSchools.fetch(true), this.plannersLoadable.fetch(true)]);
  }

  async unshareSchool(schoolId: string): Promise<void> {
    await this._schoolTransport.unshareSchool(schoolId);
    await Promise.all([this.participatingSchools.fetch(true), this.plannersLoadable.fetch(true)]);
  }

  async archiveSchool(schoolId: string): Promise<void> {
    await this._schoolTransport.archiveSchool(schoolId);
    await Promise.all([this.participatingSchools.fetch(true), this.plannersLoadable.fetch(true)]);
  }

  async unarchiveSchool(schoolId: string): Promise<void> {
    await this._schoolTransport.unarchiveSchool(schoolId);
    await Promise.all([this.participatingSchools.fetch(true), this.plannersLoadable.fetch(true)]);
  }

  async validatePlannerSharingCode(code: string): Promise<ValidateSharingCodeResponse> {
    return await this._plannerTransport.validateSharingCode(code);
  }

  async usePlannerSharingInvitationCode(
    invitationCode: string,
    shouldUpdateDashboards: boolean
  ): Promise<{ dashboardKind: UserDashboardKind; id: string }> {
    // We currently only support sharing code for Planners. Change this when the API changes.
    const id = await this._plannerTransport.useSharingInvitationCode(invitationCode);

    if (shouldUpdateDashboards) {
      await this._planners.fetch(true);

      if (this.plannersById.get(id) == null) {
        throw new NoMatchingPlannerError();
      }
    }

    return { dashboardKind: 'planner', id };
  }

  async acknowledgePlannerAccessRequest(
    plannerId: string,
    acceptRequest: boolean,
    requesterUserId: string
  ): Promise<Planner> {
    const planner = await this._plannerTransport.acknowledgePlannerAccessRequest(
      plannerId,
      acceptRequest,
      requesterUserId
    );
    this.updatePlannerUserDashboard(planner);
    return planner;
  }

  async usePlannerParticipationCode(code: string, plannerId: string): Promise<void> {
    await this._plannerTransport.useParticipationCodeForPlanner(code, plannerId);
  }

  async useSchoolParticipationCode(code: string, shouldUpdateDashboards: boolean): Promise<string> {
    const { schoolId } = await this._schoolTransport.useParticipationCode(code);

    if (shouldUpdateDashboards) {
      await this._participatingSchools.fetch(true);
    }

    return schoolId;
  }

  async getManageSubscriptionUrl(returnUrl: string): Promise<string> {
    return await this._subscriptionsTransport.getPortalUrl(returnUrl);
  }

  async getSubscribeUrl(productPrice: ProductPrice, returnUrl: string): Promise<string> {
    return await this._subscriptionsTransport.getCheckoutUrl(productPrice.id, returnUrl, returnUrl);
  }

  async createInvoicedSubscription(product: Product, productPriceId: string): Promise<SubscriptionInfo> {
    const subscription = await this._subscriptionsTransport.createInvoicedSubscription(productPriceId);
    const subscriptionInfo: SubscriptionInfo = { subscription, product };
    this._subscriptionLoadable.setValue(subscriptionInfo);
    return subscriptionInfo;
  }

  async createProfilePictureDestination(filename: string): Promise<{ uploadUrl: string; downloadUrl: string }> {
    return await this._userTransport.createProfilePictureDestination(filename);
  }

  async customizeCurrentUser(fullName: string, pictureUrl: string): Promise<void> {
    await this._userTransport.customizeCurrentUser(fullName, pictureUrl);

    // We need to reload the profile, as this call returns a User.
    const userProfile = await this._userTransport.getCurrentUserProfile();
    runInAction(() => (this._user.currentUser = userProfile));
  }

  async permanentlyDeleteCurrentUserData(): Promise<void> {
    if (this._user.currentUser != null) {
      await this._userTransport.permanentlyDeleteUserData(this._user.currentUser.userId);
      await this._authentication.signOut();
    }
  }

  getCalendarStore(userDashboard: UserDashboardInfo): CalendarDataStore {
    const map =
      userDashboard.kind === 'planner' ? this._plannerCalendarDataStoresById : this._schoolCalendarDataStoresById;
    return getOrCreateInObservableMap(
      map,
      userDashboard.id,
      () => new AppCalendarDataStore(userDashboard, this._scheduleCycleTransport, this, this._storeInvalidator)
    );
  }

  getScheduleCycleStore(id: string, userDashboard: UserDashboardInfo): ScheduleCycleDataStore {
    const map =
      userDashboard.kind === 'planner'
        ? this._plannerScheduleCycleDataStoresById
        : this._schoolScheduleCycleDataStoresById;

    return getOrCreateInObservableMap(
      map,
      userDashboard.id,
      () => new AppScheduleCycleDataStore(id, userDashboard, this._scheduleCycleTransport, this._storeInvalidator)
    );
  }

  getScheduleCycle(id: string): ScheduleCycleLoadable {
    return new AppScheduleCycleLoadable(id, this._scheduleCycleTransport);
  }

  getScheduleCycles(ids: string[]): ScheduleCyclesLoadable {
    return new AppScheduleCyclesLoadable(ids, this._scheduleCycleTransport);
  }

  getScheduleCyclesForPlannerAndItsSchools(plannerId: string): UserDashboardScheduleCyclesLoadable[] {
    const planner = this.getPlannerForId(plannerId);

    if (planner == null) {
      return [];
    }

    const schoolsLoadables: UserDashboardScheduleCyclesLoadable[] = chain(planner.schoolIds)
      .map((id) => {
        if (planner.ignoredSchoolIds.includes(id)) {
          return undefined;
        }

        const school = this.getSchoolForId(id);
        return school?.school?.isArchived !== true ? school : undefined;
      })
      .compact()
      .map((school) => {
        const dashboard = {
          id: school.school!.id,
          title: school.school!.name,
          kind: 'school',
          school
        } as UserDashboardSchool;

        return {
          userDashboard: dashboard,
          loadable: this.getScheduleCycles(dashboard.school.school!.scheduleCycleIds)
        };
      })
      .value();

    const plannerAsUserDashboard = {
      id: planner.id,
      title: planner.title,
      kind: 'planner',
      planner
    } as UserDashboardPlanner;

    return [
      ...schoolsLoadables,
      { userDashboard: plannerAsUserDashboard, loadable: this.getScheduleCycles(planner.scheduleCycleIds) }
    ];
  }

  getSchoolSharedSchoolEntitlement(schoolId: string): SchoolEntitlementLoadable {
    return getOrCreateInObservableMap(
      this._schoolEntitlementById,
      schoolId,
      () => new AppSchoolEntitlementLoadable(schoolId, Entitlement.SHARED_SCHOOLS, this._subscriptionsTransport)
    );
  }

  async setEmotionalState(rating: number): Promise<void> {
    const newPulse = await this._userTransport.setEmotionalState(rating);
    this._emotionalPulse.setValue(newPulse);
  }

  async createScheduleCycle(
    name: string,
    startDay: Day,
    endDay: Day,
    cycleDayCount: number,
    isDayOfWeekAligned: boolean,
    firstCycleDay: number,
    terms: Term[],
    shouldCreateMultiplePeriodSchedules: boolean,
    dashboard: UserDashboardInfo
  ): Promise<ScheduleCycle> {
    const volatileScheduleCycle = await this._scheduleCycleTransport.createScheduleCycle(
      name,
      startDay,
      endDay,
      cycleDayCount,
      isDayOfWeekAligned,
      firstCycleDay,
      terms,
      // We do not support picking school days. Monday to Friday is assumed.
      [],
      // We do not support per cycle-day period schedules for now.
      shouldCreateMultiplePeriodSchedules
        ? PeriodScheduleCreationMode.PER_DAY_OF_WEEK
        : PeriodScheduleCreationMode.SINGLE
    );

    const scheduleCycle = await (dashboard.kind === 'planner'
      ? this._scheduleCycleTransport.publishInitialScheduleCycleForPlanner(volatileScheduleCycle, dashboard.id)
      : this._scheduleCycleTransport.publishInitialScheduleCycleForSchool(volatileScheduleCycle, dashboard.id));
    const scheduleCycleIds = await this._scheduleCycleTransport.attachScheduleCycleToUserDashboard(
      scheduleCycle.id,
      dashboard
    );
    this.updateUserDashboardWithScheduleCycleIds(scheduleCycleIds, dashboard);
    return scheduleCycle;
  }

  async createScheduleCycleCopy(
    scheduleCycle: ScheduleCycle,
    name: string,
    startDay: Day,
    endDay: Day,
    shouldKeepTerms: boolean,
    shouldKeepPeriodSchedules: boolean,
    shouldKeepSpecialDays: boolean,
    shouldKeepSpecialDayOccurrences: boolean,
    shouldKeepActivitySchedules: boolean,
    shouldKeepActivityScheduleExceptions: boolean,
    dashboard: UserDashboardInfo
  ): Promise<ScheduleCycle> {
    const volatileScheduleCycle = await this._scheduleCycleTransport.createScheduleCycleCopy(
      scheduleCycle,
      name,
      startDay,
      endDay,
      shouldKeepTerms,
      shouldKeepPeriodSchedules,
      shouldKeepSpecialDays,
      shouldKeepSpecialDayOccurrences,
      shouldKeepActivitySchedules,
      shouldKeepActivityScheduleExceptions
    );

    const newScheduleCycle = await (dashboard.kind === 'planner'
      ? this._scheduleCycleTransport.publishInitialScheduleCycleForPlanner(volatileScheduleCycle, dashboard.id)
      : this._scheduleCycleTransport.publishInitialScheduleCycleForSchool(volatileScheduleCycle, dashboard.id));
    const scheduleCycleIds = await this._scheduleCycleTransport.attachScheduleCycleToUserDashboard(
      scheduleCycle.id,
      dashboard
    );
    this.updateUserDashboardWithScheduleCycleIds(scheduleCycleIds, dashboard);
    return newScheduleCycle;
  }

  async removeScheduleCycleFromUserDashboard(scheduleCycleId: string, dashboard: UserDashboardInfo): Promise<void> {
    const scheduleCycleIds = await this._scheduleCycleTransport.detachScheduleCycleFromUserDashboard(
      scheduleCycleId,
      dashboard
    );
    this.updateUserDashboardWithScheduleCycleIds(scheduleCycleIds, dashboard);
  }

  async ignoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<void> {
    const ignoredScheduleCycleIds = await this._plannerTransport.ignoreScheduleCycleInPlanner(
      plannerId,
      scheduleCycleId
    );
    const planner = this.plannersById.get(plannerId);

    if (planner != null) {
      const newValue = createPlanner({ ...planner, ignoredScheduleCycleIds });
      this.plannersLoadable.addOrReplace(plannerId, newValue);
    }
  }

  async restoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<void> {
    const ignoredScheduleCycleIds = await this._plannerTransport.restoreScheduleCycleInPlanner(
      plannerId,
      scheduleCycleId
    );
    const planner = this.plannersById.get(plannerId);

    if (planner != null) {
      const newValue = createPlanner({ ...planner, ignoredScheduleCycleIds });
      this.plannersLoadable.addOrReplace(plannerId, newValue);
    }
  }

  async ignoreSchoolInPlanner(plannerId: string, schoolId: string, shouldUpdateData: boolean): Promise<void> {
    const response = await this._plannerTransport.ignoreSchoolInPlanner(plannerId, schoolId);

    if (shouldUpdateData) {
      const planner = this.plannersById.get(plannerId);

      if (planner != null) {
        const newValue = createPlanner({
          ...planner,
          schoolIds: response.schoolIds,
          ignoredSchoolIds: response.ignoredSchoolIds
        });
        this.plannersLoadable.addOrReplace(plannerId, newValue);
      }
    }
  }

  getPreference(key: UserPreferenceKey): UserPreferenceLoadable {
    return getOrCreateInObservableMap(
      this._userPreferencesByKey,
      key,
      () => new AppUserPreferenceLoadable(key, this._userTransport)
    );
  }

  async setPreference(key: UserPreferenceKey, value: PreferenceValue | undefined): Promise<void> {
    await this._userTransport.setPreference(key, value);
    this.getPreference(key).setValue({ key, value });
  }

  private updatePlannerUserDashboard(planner: Planner) {
    this._planners.addOrReplace(planner.id, planner);
  }

  private updateUserDashboardWithScheduleCycleIds(scheduleCycleIds: string[], dashboard: UserDashboardInfo) {
    if (dashboard.kind === 'school') {
      const school = this.schoolsById.get(dashboard.id);
      if (school?.school != null) {
        const newSchool = clonePlannerSchoolInformation(school);
        newSchool.school!.scheduleCycleIds = scheduleCycleIds;
        this.participatingSchools.addOrReplace(dashboard.id, newSchool);
      }
    } else {
      const planner = this.plannersById.get(dashboard.id);
      if (planner != null) {
        const newPlanner = createPlanner({ ...planner });
        newPlanner.scheduleCycleIds = scheduleCycleIds;
        this.plannersLoadable.addOrReplace(dashboard.id, newPlanner);
      }
    }
  }

  private async invalidateData() {
    const loadablesToInvalidate = [
      this.plannersLoadable,
      this.subscription,
      this.emotionalPulse,
      this.participatingSchools,
      this.getPreference('seen-curriculum-course-ids')
    ];

    await Promise.all(loadablesToInvalidate.map((l) => l.invalidate(false)));

    if (this._user.currentUser != null) {
      // Start fetching loadables for currentUser, if one exists.
      await Promise.all(loadablesToInvalidate.map((l) => l.fetch(true)));
    }
  }
}
