import { useServices, useViewModel } from '@/hooks';
import { MultipleActionsAlertDialogAction } from '@/viewmodels';
import { Button, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Dialog } from '../dialog';
import { ConfirmationDialogMessage } from './ConfirmationDialogMessage';

export interface MultipleActionsAlertDialogProps {
  readonly isOpen: boolean;
  readonly title: string;
  readonly message: string;
  readonly actions: MultipleActionsAlertDialogAction[];
  readonly onCancel: () => Promise<void>;
}

export const MultipleActionsAlertDialog = observer(
  ({ isOpen, title, message, actions, onCancel }: MultipleActionsAlertDialogProps) => {
    const { localization } = useServices();

    const viewModel = useViewModel(
      (viewModels) => viewModels.createMultipleActionsAlertDialogViewModel(onCancel, actions),
      [actions, onCancel]
    );

    return (
      <Dialog
        isOpen={isOpen}
        viewModel={viewModel}
        title={title}
        width="xs"
        hideDefaultCloseButton
        renderData={() => (
          <Stack spacing={4}>
            <Stack spacing={2}>
              <ConfirmationDialogMessage message={message} />

              {viewModel.multipleActions.map((action, index) => (
                <Stack key={index}>
                  <Button onClick={() => action.onClick()} variant="outlined">
                    {action.label}
                  </Button>
                  {action.message && (
                    <Typography variant="caption" color="textSecondary" sx={{ textAlign: 'center' }}>
                      {action.message}
                    </Typography>
                  )}
                </Stack>
              ))}
            </Stack>

            <Button color="error" variant="contained-grey" onClick={() => void viewModel.dismiss()}>
              {localization.localizedStrings.utils.genericCancel}
            </Button>
          </Stack>
        )}
      />
    );
  }
);
