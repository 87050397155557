import { PlannerItemsItemKind } from '@/viewmodels';
import { PersonRounded, SendRounded, StickyNote2Rounded } from '@mui/icons-material';
import { ListItemIcon, PopoverProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../utils';

export interface PlannerItemsFiltersPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
  possibleKinds: PlannerItemsItemKind[];
  selectedKinds: Set<PlannerItemsItemKind>;
  onKindSelection: (kind: PlannerItemsItemKind) => void;
  minimumDate: Date | undefined;
  maximumDate: Date | undefined;
  showPastItems: boolean;
  showNoDateItems: boolean;
  onMinimumDateValueChanged: (value: Date | undefined) => void;
  onMaximumDateValueChanged: (value: Date | undefined) => void;
  onShowPastItemsValueChanged: (value: boolean) => void;
  onShowNoDateItemsValueChanged: (value: boolean) => void;
}

export const PlannerItemsFiltersPopover = observer(
  ({
    possibleKinds,
    selectedKinds,
    onKindSelection,
    minimumDate,
    maximumDate,
    showPastItems,
    showNoDateItems,
    onMinimumDateValueChanged,
    onMaximumDateValueChanged,
    onShowPastItemsValueChanged,
    onShowNoDateItemsValueChanged,
    ...props
  }: PlannerItemsFiltersPopoverProps) => {
    const strings = LocalizedStrings.planner.items.filters;

    function resetFilters() {
      onMinimumDateValueChanged(undefined);
      onMaximumDateValueChanged(undefined);
    }

    return (
      <OptionsPopover {...props} title={strings.title()} onResetOptions={resetFilters}>
        <OptionsPopoverSection title={strings.displayedKindsLabel()}>
          {possibleKinds.map((kind) => (
            <OptionsPopoverItem
              key={kind}
              startElement={<ListItemIcon>{iconForKind(kind)}</ListItemIcon>}
              title={titleForKind(kind)}
              isSelected={selectedKinds.has(kind)}
              onClick={() => onKindSelection(kind)}
            />
          ))}
        </OptionsPopoverSection>

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            px: 2,
            pt: 2
          }}
        >
          {strings.subtitle()}
        </Typography>

        <OptionsPopoverSection title={strings.minimumDate()}>
          <CalendarDatePicker
            sx={{ mx: 2 }}
            value={minimumDate ?? null}
            maxDate={maximumDate}
            onChange={(value) => {
              onMinimumDateValueChanged(value ?? undefined);
            }}
            kind="user-dashboard-calendar"
            slotProps={{
              actionBar: { actions: minimumDate != null ? ['clear'] : [] }
            }}
          />
        </OptionsPopoverSection>
        <OptionsPopoverSection title={strings.maximumDate()}>
          <CalendarDatePicker
            sx={{ mx: 2 }}
            value={maximumDate ?? null}
            minDate={minimumDate}
            onChange={(value) => {
              onMaximumDateValueChanged(value ?? undefined);
            }}
            kind="user-dashboard-calendar"
            slotProps={{
              actionBar: { actions: maximumDate != null ? ['clear'] : [] }
            }}
          />
        </OptionsPopoverSection>
        <OptionsPopoverSection title={strings.otherSectionTitle()}>
          <OptionsPopoverItem
            title={strings.showPastItems()}
            isSelected={showPastItems}
            onClick={() => onShowPastItemsValueChanged(!showPastItems)}
          />

          <OptionsPopoverItem
            title={strings.showNoDateItems()}
            isSelected={showNoDateItems}
            onClick={() => onShowNoDateItemsValueChanged(!showNoDateItems)}
          />
        </OptionsPopoverSection>
      </OptionsPopover>
    );
  }
);

function titleForKind(kind: PlannerItemsItemKind) {
  const strings = LocalizedStrings.planner.items.filters;

  switch (kind) {
    case 'work':
      return strings.displayedKindsWorkOptionLabel();
    case 'note':
      return strings.displayedKindsNoteOptionLabel();
    case 'publishedWork':
      return strings.displayedKindsPublishedWorkOptionLabel();
  }
}

function iconForKind(kind: PlannerItemsItemKind) {
  switch (kind) {
    case 'work':
      return <PersonRounded fontSize="small" />;
    case 'note':
      return <StickyNote2Rounded fontSize="small" />;
    case 'publishedWork':
      return <SendRounded fontSize="small" />;
  }
}
