import { SchoolEntitlementInfo, SubscriptionInfo } from '@/models';
import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { Product } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_pb';
import { Subscription } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/subscription_pb';
import { find } from 'lodash';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { GrpcTransportService, SubscriptionsTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppSubscriptionsTransportService extends AppBaseTransportService implements SubscriptionsTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getSubscription(): Promise<SubscriptionInfo | undefined> {
    const response = await this.performReadRequest('getSubscription', (headers) =>
      this._grpcTransport.subscriptionsClient.getSubscription({}, { headers })
    );
    const subscription = response.subscription;

    if (subscription == null) {
      return undefined;
    }

    const products = await this.getProducts();
    const subscriptionProduct = products.find((p) => find(p.prices, { id: subscription.productPriceId }) != null);
    return subscriptionProduct != null ? { subscription, product: subscriptionProduct } : undefined;
  }

  async getProducts(): Promise<Product[]> {
    const response = await this.performReadRequest('getProducts', (headers) =>
      this._grpcTransport.subscriptionsClient.getProducts({}, { headers })
    );
    return response.products;
  }

  async getCheckoutUrl(productPriceId: string, successReturnUrl: string, cancelReturnUrl: string): Promise<string> {
    const response = await this.performReadRequest('getCheckoutUrl', (headers) =>
      this._grpcTransport.subscriptionsClient.getCheckoutUrl(
        { productPriceId, successReturnUrl, cancelReturnUrl },
        { headers }
      )
    );
    return response.checkoutUrl;
  }

  async getPortalUrl(returnUrl: string): Promise<string> {
    const response = await this.performReadRequest('getPortalUrl', (headers) =>
      this._grpcTransport.subscriptionsClient.getPortalUrl(
        {
          returnUrl
        },
        { headers }
      )
    );
    return response.portalUrl;
  }

  async createInvoicedSubscription(productPriceId: string): Promise<Subscription> {
    const response = await this.performWriteRequest('createInvoicedSubscription', (headers) =>
      this._grpcTransport.subscriptionsClient.createInvoicedSubscription({ productPriceId }, { headers })
    );

    return response.subscription!;
  }

  async getIsSchoolEntitled(schoolId: string, entitlement: Entitlement): Promise<SchoolEntitlementInfo> {
    const response = await this.performReadRequest('getIsSchoolEntitled', (headers) =>
      this._grpcTransport.subscriptionsClient.getIsSchoolEntitled({ schoolId, entitlement }, { headers })
    );

    return {
      schoolId,
      isEntitled: response.isEntitled,
      notEntitledReasons: response.notEntitledReasons,
      isTrial: response.isTrial
    };
  }
}
