import { useActivePlannerId, useServices } from '@/hooks';
import { isSxArray } from '@/utils';
import { Button, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router';
import LocalizedStrings from 'strings';

export interface PlannerWorkloadEmptyViewProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerWorkloadEmptyView = observer(({ sx = [], className }: PlannerWorkloadEmptyViewProps) => {
  const { route } = useServices();
  const plannerId = useActivePlannerId();
  const theme = useTheme();
  const strings = LocalizedStrings.planner.workload;

  return (
    <Stack
      sx={[{ color: theme.palette.text.secondary, alignItems: 'flex-start' }, ...(isSxArray(sx) ? sx : [sx])]}
      className={className}
      spacing={2}
    >
      <Stack>
        <Typography sx={{ fontWeight: '600' }}>{strings.emptyTitle()}</Typography>
        <Typography variant="body2">{strings.emptySubtitle()}</Typography>
      </Stack>
      <Button variant="contained-grey" component={Link} to={route.resolvePlannerSettingsLocation('courses', plannerId)}>
        {strings.manageClassesButtonTitle()}
      </Button>
    </Stack>
  );
});
