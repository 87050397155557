import { useNavigateAsync, useServices } from '@/hooks';
import { AllPlannerItemsPasteboardContentKind } from '@/services';
import {
  UserDashboardCalendarDayPeriodInfo,
  UserDashboardCalendarDayPeriodInfoItem,
  UserDashboardCalendarItemState
} from '@/viewmodels';
import { timestampFromDate } from '@bufbuild/protobuf/wkt';
import { AddRounded, ContentPasteRounded } from '@mui/icons-material';
import { Box, CardActionArea, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../lists';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { CreateWorkOptionsMenu } from '../../CreateWorkOptionsMenu';
import { UserDashboardPeriodInfoPopover, resolvedPeriodBackgroundColor } from '../shared';
import { UserDashboardCalendarDayNoteView } from './UserDashboardCalendarDayNoteView';
import { UserDashboardCalendarDayWorkView } from './UserDashboardCalendarDayWorkView';

interface LocationState {
  userDashboardCalendarDayPeriodViewShowInfo?: string;
}

export interface UserDashboardCalendarDayPeriodViewProps {
  sx?: SxProps;
  className?: string;
  isReadOnly: boolean;
  canPublishWork: boolean;
  period: UserDashboardCalendarDayPeriodInfo;
}

export const UserDashboardCalendarDayPeriodView = observer(
  ({ sx = [], className, isReadOnly, period, canPublishWork }: UserDashboardCalendarDayPeriodViewProps) => {
    const { pasteboard, navigation, settings } = useServices();
    const theme = useTheme();
    const color = period.courseSection?.courseSection?.color ?? theme.palette.action.disabled;

    const hasWorks = period.items.length > 0;
    const periodStartDate = set(period.date, { ...period.startTime });
    const pasteboardContent = pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind);
    const strings = LocalizedStrings.calendar.day;

    const actionAreaRef = useRef<HTMLButtonElement>(null);
    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigateAsync();
    const [createItemMenuAnchor, setCreateItemMenuAnchor] = useState<HTMLButtonElement | null>(null);

    function onPeriodInfosClick(e: MouseEvent<HTMLButtonElement>) {
      e.stopPropagation();
      const newState: LocationState = {
        ...state,
        userDashboardCalendarDayPeriodViewShowInfo: period.id
      };
      void navigate(location, { state: newState });
    }

    function showCreateWork() {
      setCreateItemMenuAnchor(null);
      navigation.navigateToWorkEdit(navigate, location, undefined, {
        courseSectionId: period.courseSection?.courseSection?.id,
        dueTime: timestampFromDate(periodStartDate),
        isDueAllDay: false
      });
    }

    function showCreateNote() {
      setCreateItemMenuAnchor(null);
      navigation.navigateToNoteEdit(navigate, location, undefined, {
        courseSectionId: period.courseSection?.courseSection?.id,
        time: timestampFromDate(periodStartDate),
        isAllDay: false
      });
    }

    function showCreatePublishedWork() {
      setCreateItemMenuAnchor(null);
      navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
        courseSectionId: period.courseSection?.courseSection?.id,
        dueDate: periodStartDate,
        isDueAllDay: false
      });
    }

    function onPaste() {
      setCreateItemMenuAnchor(null);
      period.onPaste();
    }

    const resolvedCourseTitle = period.courseSection?.courseSection?.title ?? strings.periodOccurrenceNoClass();
    const showPeriodLabel = settings.calendarShowPeriodLabels && period.periodLabel.length > 0;

    const bgColor = resolvedPeriodBackgroundColor(period.courseSection?.courseSection?.color, settings, theme);
    const textColor = getColorForTitle(period.state);

    return (
      <ContextMenuHandler actions={period.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Box
            sx={{
              ...sx,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'stretch',
              borderRadius: 1,
              overflow: 'hidden',
              backgroundColor: bgColor
            }}
            className={className}
            onContextMenu={contextMenuHandler}
          >
            <ColorIndicator isRounded={false} color={color} width={8} />

            <Stack
              sx={{
                flex: 1,
                p: hasWorks ? 0.5 : 0
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  borderRadius: hasWorks ? 1 : 0,
                  px: hasWorks ? 1 : 1.5,
                  py: hasWorks ? 0.5 : 1
                }}
                ref={actionAreaRef}
                onClick={onPeriodInfosClick}
              >
                <Stack
                  sx={{
                    flex: 1
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: 'baseline'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '500',
                        color: textColor
                      }}
                    >
                      {resolvedCourseTitle}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: textColor
                      }}
                    >
                      {period.courseSection?.courseSection?.section}
                    </Typography>
                  </Stack>

                  {period.ordinal > 0 && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: textColor
                      }}
                    >
                      {strings.periodOccurrenceCount(period.ordinal)}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  sx={{
                    alignItems: 'flex-end'
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: textColor
                    }}
                  >
                    {period.roomName}
                  </Typography>
                </Stack>
              </CardActionArea>

              {period.items.length > 0 && <Stack>{period.items.map(renderPeriodItem)}</Stack>}

              {(!isReadOnly || showPeriodLabel) && (
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{
                    justifyContent: 'flex-end',
                    pb: '2px',
                    px: 0.5,
                    alignItems: 'center'
                  }}
                >
                  {!isReadOnly && pasteboardContent != null && (
                    <Tooltip title={strings.periodPasteItemButtonTitle()}>
                      <IconButton onClick={() => period.onPaste()} size="small">
                        <ContentPasteRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {!isReadOnly && (
                    <Tooltip title={strings.periodCreateItemButtonTitle()}>
                      <IconButton size="small" onClick={(e) => setCreateItemMenuAnchor(e.currentTarget)}>
                        <AddRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}

                  {showPeriodLabel && (
                    <Typography
                      variant="h6"
                      sx={{
                        px: 1,
                        color: textColor,
                        fontWeight: '600'
                      }}
                    >
                      {period.periodLabel}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>

            {state.userDashboardCalendarDayPeriodViewShowInfo === period.id && (
              <UserDashboardPeriodInfoPopover
                isOpen={true}
                date={period.date}
                startTime={period.startTime}
                endTime={period.endTime}
                periodLabel={period.periodLabel}
                courseSectionId={period.courseSection?.courseSection?.id}
                onClose={() => void navigate(-1)}
                anchorEl={actionAreaRef.current}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left'
                }}
              />
            )}

            <CreateWorkOptionsMenu
              open={createItemMenuAnchor != null}
              anchorEl={createItemMenuAnchor}
              onClose={() => setCreateItemMenuAnchor(null)}
              canPublishWork={canPublishWork}
              onCreatePersonalWorkOptionSelect={showCreateWork}
              onCreateNoteOptionSelect={showCreateNote}
              onPublishWorkOptionSelect={showCreatePublishedWork}
              onPaste={onPaste}
            />
          </Box>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    default:
      return 'textPrimary';
  }
}

function renderPeriodItem(item: UserDashboardCalendarDayPeriodInfoItem) {
  switch (item.case) {
    case 'note':
      return <UserDashboardCalendarDayNoteView key={item.value.id} note={item.value} showTime />;

    case 'publishedWork':
    case 'work':
      return <UserDashboardCalendarDayWorkView work={item} key={item.value.id} showTime />;
  }
}
