import { useActivePlannerId, useServices } from '@/hooks';
import { localizedCompareWithProperties } from '@/utils';
import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router';

export interface PlannerItemsPageWrapperProps {
  children: ReactNode;
}

export const PlannerItemsPageWrapper = ({ children }: PlannerItemsPageWrapperProps) => {
  const { localization, plannerStore, route } = useServices();
  const params = useParams();
  const courseSectionId = params.courseSectionId;
  const plannerId = useActivePlannerId();

  if (courseSectionId != null) {
    return children;
  }

  const courses = plannerStore.getCourseSectionsInPlanner(plannerId).values;
  if (courses.length > 0) {
    const sortedCourses = Array.from(courses).sort((a, b) =>
      localizedCompareWithProperties(
        [
          { value1: a.courseSection!.title, value2: b.courseSection!.title },
          { value1: a.courseSection!.section, value2: b.courseSection!.section },
          { value1: a.courseSection!.id, value2: b.courseSection!.id }
        ],
        localization.currentLocale
      )
    );

    const newPath = route.resolvePlannerCourseSectionDetailLocation(plannerId, sortedCourses[0].courseSection!.id);
    return <Navigate to={newPath} replace />;
  } else {
    // No courses available, redirect to the home screen.
    const newPath = route.resolvePlannerLocation(plannerId);
    return <Navigate to={newPath} replace />;
  }
};
