import { Currency, plannerSchools, SubscriptionInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, RouteService } from '@/services';
import { Loadable, SubscriptionLoadable, SubscriptionProductsLoadable, UserDataStore } from '@/stores';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { computed, makeObservable, observable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';
import { AppProductViewModel, ProductViewModel } from './ProductViewModel';

export interface SubscriptionsViewModel extends UpdatableViewModel {
  readonly subscription: SubscriptionInfo | undefined;
  readonly products: ProductViewModel[];
  readonly isApplying: boolean;
  readonly error: string | undefined;
  currency: Currency;
}

export class AppSubscriptionsViewModel extends BaseUpdatableViewModel implements SubscriptionsViewModel {
  @observable protected _currency: Currency = 'usd';
  @observable protected _error: string | undefined;

  constructor(
    protected readonly _plannerId: string | undefined,
    protected readonly _onSubscribe: (sharedSchoolIds: string[]) => Promise<void>,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _route: RouteService = ServiceContainer.services.route,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get subscriptionLoadable(): SubscriptionLoadable {
    return this._userStore.subscription;
  }

  @computed
  private get availableProductsLoadable(): SubscriptionProductsLoadable {
    return this._userStore.availableSubscriptionProducts;
  }

  @computed
  private get planner(): Planner | undefined {
    return this._plannerId != null ? this._userStore.getPlannerForId(this._plannerId) : undefined;
  }

  @computed
  private get ownedPersonalSchoolsInPlanner(): SchoolInformation[] {
    const { userId } = this._userStore.user;
    const schools =
      this.planner != null ? plannerSchools(this.planner, this._userStore.schools) : this._userStore.schools;

    return schools.filter((s) =>
      s.owners.some(
        (o) =>
          o.userId === userId && s.school?.isArchived !== true && s.school?.sharingMode !== SchoolSharingMode.SHARED
      )
    );
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.subscriptionLoadable, this.availableProductsLoadable];
  }

  @computed
  get products(): ProductViewModel[] {
    const redirectLocation = this._plannerId != null ? this._route.resolvePlannerLocation(this._plannerId) : '/';
    const redirectUrl = `${window.location.origin}${redirectLocation}`;
    const schoolsIds = this.ownedPersonalSchoolsInPlanner.map((s) => s.school!.id);

    return this.availableProductsLoadable.data.map(
      (product) =>
        new AppProductViewModel(
          product,
          schoolsIds,
          redirectUrl,
          () => this.currency,
          (sharedSchoolIds) => void this._onSubscribe(sharedSchoolIds),
          this._localization,
          this._userStore
        )
    );
  }

  @computed
  get isApplying(): boolean {
    return this.products.some((product) => product.isApplying);
  }

  @computed
  get subscription(): SubscriptionInfo | undefined {
    return this.subscriptionLoadable.data;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get currency(): Currency {
    return this._currency;
  }

  set currency(value: Currency) {
    this._currency = value;
  }
}
