export const CourseSectionColors = [
  '#AE5239', // Terracota
  '#F48E32', // Orange
  '#C99026', // Kaki
  '#FFD33B', // Yellow
  '#AAA85A', // Olive
  '#9FCC2A', // Lime green
  '#4E7C1A', // Leaf green
  '#41AB2A', // Bright green
  '#00AA8E', // Teal
  '#5BBDF5', // Pale blue
  '#0085F4', // Bright blue
  '#3868CB', // Royal-blue
  '#0F39C3', // Dark blue
  '#A9AFFC', // Lavender
  '#9A4FD7', // Mauve
  '#FFADFD', // Pale pink
  '#AE289A', // Violet
  '#F6429A', // Bright pink
  '#D10F48', // Bright red
  '#FFAAAA', // Peach
  '#9C0909', // Dark red
  '#8D8D8D' // Gray
];

export function randomCourseSectionColor() {
  return CourseSectionColors[Math.floor(Math.random() * CourseSectionColors.length)];
}
