import { PlannerSchoolsList, UpdatablePresenter } from '@/components';
import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';

export interface PlannerSchoolSettingsRouteWrapperProps {
  children: ReactNode;
}

export const PlannerSchoolSettingsRouteWrapper = ({ children }: PlannerSchoolSettingsRouteWrapperProps) => {
  const { route } = useServices();
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((viewModels) => viewModels.createPlannerSchoolsListViewModel(plannerId), [plannerId]);
  const params = useParams();
  const schoolId = params.schoolId;
  const location = useLocation();

  if (schoolId != null) {
    return children;
  } else if (!location.pathname.endsWith('/settings')) {
    // Because the schoolId param is now optional, the same path is resolved for the details of
    // a school and the list of schools. So if the path does not ends with /settings, we render
    // the list of schools.
    return <PlannerSchoolsList />;
  }

  return (
    <UpdatablePresenter
      viewModel={viewModel}
      renderData={() => {
        const redirectPath = viewModel.schools.length > 0
          ? route.resolvePlannerSettingsLocation(
            `schools/${viewModel.schools[0].school!.id}/settings`,
            plannerId) 
          : route.resolvePlannerSettingsLocation(`schools`, plannerId);

        return <Navigate to={redirectPath} />;
      }}
    />
  );
};
