import { WorkloadPublishedWorkInfo } from '@/models';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from '../utils';

export interface PlannerWorkloadItemDetailsDialogViewModel extends StaticDialogViewModel {
  item: WorkloadPublishedWorkInfo;
}

export class AppPlannerWorkloadItemDetailsDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements PlannerWorkloadItemDetailsDialogViewModel
{
  item: WorkloadPublishedWorkInfo;

  constructor(item: WorkloadPublishedWorkInfo, onClose: () => Promise<void>) {
    super(onClose);
    this.item = item;
  }
}
