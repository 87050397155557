import { Box, Typography } from '@mui/material';

export interface AdminTableTextCellProps {
  value: string;
}

export function AdminTableTextCell({ value }: AdminTableTextCellProps) {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography>{value}</Typography>
    </Box>
  );
}
