import { createContext, useContext } from 'react';

export class PlannerWorkloadForceReloadContextValue {
  onReload: (() => void) | undefined;
}

export const PlannerWorkloadForceReloadContext = createContext(new PlannerWorkloadForceReloadContextValue());

export const usePlannerWorkloadForceReloadContext = () => useContext(PlannerWorkloadForceReloadContext);
export function useRegisterPlannerWorkloadForceReload(onReload: () => void) {
  const context = usePlannerWorkloadForceReloadContext();
  context.onReload = onReload;
}
