import { ConnectedAppUserDashboard } from '@/models';
import { ClientAppKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/client_app_kind_pb.js';
import { Calendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/calendar_pb';
import { SyncStatus } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/sync_status_pb';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { GoogleCalendarTransportService, GrpcTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppGoogleCalendarTransportService
  extends AppBaseTransportService
  implements GoogleCalendarTransportService
{
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getSyncStatus(dashboard: ConnectedAppUserDashboard): Promise<SyncStatus> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    return await this.performReadRequest(
      'getSyncStatus',
      (headers) =>
        this._grpcTransport.googleCalendarClient.getSyncStatus({ plannerId: dashboard.plannerId }, { headers }),
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async synchronize(dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performReadRequest(
      'synchronize',
      (headers) =>
        this._grpcTransport.googleCalendarClient.synchronize({ plannerId: dashboard.plannerId }, { headers }),
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async registerOfflineAccessCode(code: string, dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performWriteRequest(
      'registerOfflineAccessCode',
      (headers) =>
        this._grpcTransport.googleCalendarClient.registerOfflineAccessCode(
          { code, plannerId: dashboard.plannerId, clientAppKind: ClientAppKind.WEB },
          { headers }
        ),
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async revokeOfflineAccessCode(dashboard: ConnectedAppUserDashboard) {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    await this.performWriteRequest(
      'revokeOfflineAccessCode',
      (headers) =>
        this._grpcTransport.googleCalendarClient.revokeOfflineAccessCode(
          { plannerId: dashboard.plannerId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(dashboard.plannerId), supportsDemoMode: true }
    );
  }

  async getCalendars(dashboard: ConnectedAppUserDashboard): Promise<Calendar[]> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    const response = await this.performReadRequest(
      'getCalendars',
      (headers) =>
        this._grpcTransport.googleCalendarClient.getCalendars({ plannerId: dashboard.plannerId }, { headers }),
      { isDemoObject: this.isDemoId(dashboard.plannerId) }
    );

    return response.calendars;
  }

  async updateCalendarSelection(calendarIds: string[], dashboard: ConnectedAppUserDashboard): Promise<Calendar[]> {
    if (dashboard.case !== 'planner') {
      throw new Error('Google Calendar connection is only available for planners.');
    }

    const response = await this.performWriteRequest(
      'updateCalendarSelection',
      (headers) =>
        this._grpcTransport.googleCalendarClient.updateCalendarSelection(
          { selectedCalendarIds: calendarIds, plannerId: dashboard.plannerId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(dashboard.plannerId) }
    );
    return response.calendars;
  }
}
