import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '@/services';
import { Code, ConnectError } from '@connectrpc/connect';
import { BaseTransportService } from '../contracts';

const GoogleClickIdHeader = 'X-Today-GCLID';
const FacebookClickIdHeader = 'X-Today-FBCLID';
const HubspotContactIdHeader = 'X-Today-HSCID';

interface ReadRequestParams {
  /**
   * Indicates to refresh the idToken. Used for recursion. Default is `false`.
   */
  forceRefreshIdToken?: boolean;

  /**
   * Indicates that the request is for a demo object. If true, X-Today-Demo header will be set to 0 if demo mode is
   * not enabled. Defaults to `false`.
   */
  isDemoObject?: boolean;

  /**
   * If true, the X-Today-Demo header will be set to 1 if demo mode is activated. Will not prevent the header
   * to be set to 0 if `isDemoObject` is set.
   */
  supportsDemoMode?: boolean;

  customHeaders?: Record<string, string>;
}

interface WriteRequestParams {
  /**
   * Indicates to refresh the idToken. Used for recursion only if error is `Unauthenticated`. Default is `false`.
   */
  forceRefreshIdToken?: boolean;

  /**
   * Indicates that the request is for a demo object. If true, X-Today-Demo header will be set to 0 if demo mode is
   * not enabled. Defaults to `false`.
   */
  isDemoObject?: boolean;

  /**
   * If true, the X-Today-Demo header will be set to 1 if demo mode is activated. Will not prevent the header
   * to be set to 0 if `isDemoObject` is set.
   */
  supportsDemoMode?: boolean;

  customHeaders?: Record<string, string>;
}

export abstract class AppBaseTransportService implements BaseTransportService {
  constructor(
    protected readonly _firebase: FirebaseContract,
    protected readonly _localization: LocalizationService,
    protected readonly _settingsStorage: ApplicationSettingsStorage
  ) {}

  /**
   * Send an authenticated request to the api that does not modify or create data.
   * If the request fails, the idToken will be refreshed. Then, if it still fails, the error will be thrown.
   * @param requestName The name of the request. Used in logging.
   * @param request The request function.
   * @param options Additional options fo the request.
   * @private
   * @return Promise<TResponse> The api request response.
   */
  protected async performReadRequest<O>(
    requestName: string,
    request: (headers: HeadersInit) => Promise<O>,
    options: ReadRequestParams = {}
  ): Promise<O> {
    const { forceRefreshIdToken = false, isDemoObject = false, supportsDemoMode = false, customHeaders } = options;

    if (forceRefreshIdToken) {
      await this._firebase.refreshIdToken();
    }

    const headers = this.makeHeaders(isDemoObject, supportsDemoMode, customHeaders);

    try {
      return await request(headers);
    } catch (err) {
      console.log(`API call "${requestName}" failed with ${(err as Error).message}.`);

      if (err instanceof ConnectError && err.code === Code.Unauthenticated && !forceRefreshIdToken) {
        console.log(`Retrying with a force-refreshed access token...`);
        return await this.performReadRequest(requestName, request, {
          ...options,
          forceRefreshIdToken: true
        });
      }

      throw err;
    }
  }

  /**
   * Send an authenticated request to the api that can modify or create data. This request is
   * never retried.
   * @param requestName The name of the request. Used in logging.
   * @param request The request function.
   * @param options Additional options fo the request.
   * @private
   * @return Promise<TResponse> The api request response.
   */
  protected async performWriteRequest<O>(
    requestName: string,
    request: (headers: HeadersInit) => Promise<O>,
    options: WriteRequestParams = {}
  ): Promise<O> {
    const { forceRefreshIdToken = false, isDemoObject = false, supportsDemoMode = false, customHeaders } = options;

    if (forceRefreshIdToken) {
      await this._firebase.refreshIdToken();
    }

    const headers = this.makeHeaders(isDemoObject, supportsDemoMode, customHeaders);

    try {
      return await request(headers);
    } catch (err) {
      console.log(`API call "${requestName}" failed with ${(err as Error).message}.`);

      if (err instanceof ConnectError && err.code === Code.Unauthenticated && !forceRefreshIdToken) {
        console.log(`Retrying with a force-refreshed access token...`);
        return await this.performReadRequest(requestName, request, {
          ...options,
          forceRefreshIdToken: true
        });
      }

      throw err;
    }
  }

  protected isDemoId(id: string) {
    return id.startsWith('demo');
  }

  private makeHeaders(
    isDemoObject: boolean,
    supportsDemoMode: boolean,
    customHeaders: Record<string, string> | undefined
  ): HeadersInit {
    const headers: HeadersInit = {
      Authorization: `Bearer ${this._firebase.idToken}`,
      'Accept-Language': this._localization.currentLocale,
      'X-Today-TimeZone': this._localization.currentTimezone,
      ...customHeaders
    };

    if (this._settingsStorage.googleClickId != null) {
      headers[GoogleClickIdHeader] = this._settingsStorage.googleClickId;
    }

    if (this._settingsStorage.facebookClickId != null) {
      headers[FacebookClickIdHeader] = this._settingsStorage.facebookClickId;
    }

    if (this._settingsStorage.hubspotContactId != null) {
      headers[HubspotContactIdHeader] = this._settingsStorage.hubspotContactId;
    }

    if (this._settingsStorage.isDemoMode && supportsDemoMode) {
      headers['X-Today-Demo'] = '1';
    } else if (isDemoObject) {
      headers['X-Today-Demo'] = '0';
    }

    return headers;
  }
}
