import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

export function useActiveSchoolId(): string {
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    const schoolId = params.schoolId;
    if (schoolId == null) {
      throw new Error('Cannot find active plannerId.');
    }
    return schoolId;
  }, [location, params]);
}

export function useOptionalActiveSchoolId(): string | undefined {
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    return params.schoolId;
  }, [location, params]);
}
