import i18n from 'translate';

export const NoteEditApplyToCopiesDialogStrings = {
  applyOnlyToThisCopyButtonTitle: () => i18n.t('note.edit.applyToCopiesDialog.applyOnlyToThisCopyButtonTitle'),
  applyOnlyToThisCopyTooltip: () => i18n.t('note.edit.applyToCopiesDialog.applyOnlyToThisCopyTooltip'),
  applyToAllButtonTitle: () => i18n.t('note.edit.applyToCopiesDialog.applyToAllButtonTitle'),
  applyToAllTooltip: () => i18n.t('note.edit.applyToCopiesDialog.applyToAllTooltip'),
  message: () => i18n.t('note.edit.applyToCopiesDialog.message'),
  title: () => i18n.t('note.edit.applyToCopiesDialog.title')
};

export const NoteEditStrings = {
  addTitle: () => i18n.t('note.edit.addTitle'),
  applyToCopiesDialog: NoteEditApplyToCopiesDialogStrings,
  archiveNote: () => i18n.t('note.edit.archiveNote'),
  archiveNoteConfirmationButton: () => i18n.t('note.edit.archiveNoteConfirmationButton'),
  archiveNoteConfirmationMessage: () => i18n.t('note.edit.archiveNoteConfirmationMessage'),
  archiveNoteConfirmationTitle: () => i18n.t('note.edit.archiveNoteConfirmationTitle'),
  cancelButtonTitle: () => i18n.t('note.edit.cancelButtonTitle'),
  copyNote: () => i18n.t('note.edit.copyNote'),
  courseTitle: () => i18n.t('note.edit.courseTitle'),
  createButtonTitle: () => i18n.t('note.edit.createButtonTitle'),
  dateInputDateFormat: () => i18n.t('note.edit.dateInputDateFormat'),
  dateInputLabel: () => i18n.t('note.edit.dateInputLabel'),
  distributeNote: () => i18n.t('note.edit.distributeNote'),
  duplicateNote: () => i18n.t('note.edit.duplicateNote'),
  editTitle: () => i18n.t('note.edit.editTitle'),
  fullScreenButtonTooltip: () => i18n.t('note.edit.fullScreenButtonTooltip'),
  numberOfCopies: (count: number) => i18n.t('note.edit.numberOfCopies', { count }),
  reduceSizeButtonTooltip: () => i18n.t('note.edit.reduceSizeButtonTooltip'),
  repeatNote: () => i18n.t('note.edit.repeatNote'),
  saveButtonTitle: () => i18n.t('note.edit.saveButtonTitle'),
  textLabel: () => i18n.t('note.edit.textLabel'),
  timeInputLabel: () => i18n.t('note.edit.timeInputLabel'),
  timeSectionTitle: () => i18n.t('note.edit.timeSectionTitle'),
  unlinkNote: () => i18n.t('note.edit.unlinkNote'),
  unlinkNoteConfirmationConfirm: () => i18n.t('note.edit.unlinkNoteConfirmationConfirm'),
  unlinkNoteConfirmationMessage: () => i18n.t('note.edit.unlinkNoteConfirmationMessage'),
  unlinkNoteConfirmationTitle: () => i18n.t('note.edit.unlinkNoteConfirmationTitle')
};
