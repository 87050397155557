import { observer } from 'mobx-react-lite';
import { ColorIndicator } from '../../../lists';
import { OptionsPopoverItem, OptionsPopoverItemProps } from '../../../utils';

export interface UserDashboardCalendarWeekFiltersCourseItemProps extends OptionsPopoverItemProps {
  color: string;
}

export const UserDashboardCalendarFiltersCourseItem = observer(
  ({ color, ...props }: UserDashboardCalendarWeekFiltersCourseItemProps) => {
    return (
      <OptionsPopoverItem
        {...props}
        startElement={<ColorIndicator isRounded color={color} sx={{ mr: 1, my: 0.25 }} />}
      />
    );
  }
);
