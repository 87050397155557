import { useServices } from '@/hooks';
import { AdminWorkloadViewModel } from '@/viewmodels';
import { alpha, Divider, ListItemButton, Stack, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { format, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { WorkloadInformationView } from '../../shared';

export interface AdminWorkloadDateListItemProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  isSelected: boolean;
  onSelect: (date: Date) => void;
  showDivider: boolean;
  viewModel: AdminWorkloadViewModel;
}

export const AdminWorkloadDateListItem = observer(
  ({ sx = [], className, date, isSelected, onSelect, showDivider, viewModel }: AdminWorkloadDateListItemProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const isToday = isSameDay(date, dateService.now);
    const calendarDay = viewModel.getCalendarDay(date);

    return (
      <>
        <ListItemButton
          sx={sx}
          className={className}
          onClick={() => onSelect(date)}
          selected={isSelected}
          disableGutters
        >
          <Stack spacing={1} direction="row" alignItems="center" mx={1} sx={{ width: '100%' }}>
            <Stack sx={{ flex: 1 }} spacing={1} direction="row" alignItems="center">
              <Stack spacing={0} direction="row" alignItems="baseline">
                <Typography variant="caption" color="textSecondary">
                  {format(date, 'EE')}&nbsp;
                  {isToday && <>&nbsp;</>}
                </Typography>

                <Box sx={{ position: 'relative' }}>
                  {isToday && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: `calc(100% + ${theme.spacing(1)})`,
                        height: `calc(100% + ${theme.spacing(0.5)})`,
                        top: `calc(-1 * ${theme.spacing(0.25)})`,
                        left: `calc(-1 * ${theme.spacing(0.5)})`,
                        backgroundColor: alpha(theme.palette.error.main, 0.5),
                        borderRadius: 1_000
                      }}
                    />
                  )}

                  <Typography variant="body2" sx={{ fontWeight: '500', position: 'relative' }}>
                    {format(date, 'PP')}
                  </Typography>
                </Box>
              </Stack>

              {calendarDay != null && !calendarDay.isDayOfWeekCycleDayName && (
                <Typography variant="caption" color="textSecondary">
                  {calendarDay.cycleDayName}
                </Typography>
              )}
            </Stack>

            <WorkloadInformationView date={date} getWorkloadInformation={() => viewModel.getInformationForDate(date)} />
          </Stack>
        </ListItemButton>

        {showDivider && <Divider />}
      </>
    );
  }
);
