import {
  ScheduleCycleActivitySchedules,
  ScheduleCycleBellTimesPage,
  ScheduleCycleDetailsEditPage,
  ScheduleCycleMasterSchedulePage,
  ScheduleCyclePage,
  ScheduleCycleSpecialDaysPage
} from '@/components';
import { RouteObject } from 'react-router';
import { ScheduleCycleConfigRouteWrapper } from './ScheduleCycleConfigRouteWrapper';

export const ScheduleCycleConfigChildrenRoutes: RouteObject[] = [
  { path: 'activity-schedules', element: <ScheduleCycleActivitySchedules filters={undefined} /> },
  { path: 'specialdays', element: <ScheduleCycleSpecialDaysPage /> },
  { path: 'belltimes', element: <ScheduleCycleBellTimesPage /> },
  { path: 'master-schedule', element: <ScheduleCycleMasterSchedulePage /> },
  { path: '', element: <ScheduleCycleDetailsEditPage /> }
];

export const ScheduleCycleConfigRoutes: RouteObject[] = [
  {
    path: ':scheduleCycleId?',
    element: (
      <ScheduleCycleConfigRouteWrapper>
        <ScheduleCyclePage />
      </ScheduleCycleConfigRouteWrapper>
    ),
    children: ScheduleCycleConfigChildrenRoutes
  },
  {
    path: ':scheduleCycleId?/s/:schoolId',
    element: (
      <ScheduleCycleConfigRouteWrapper>
        <ScheduleCyclePage />
      </ScheduleCycleConfigRouteWrapper>
    ),
    children: ScheduleCycleConfigChildrenRoutes
  }
];
