import {
  CommonRichText,
  convertRichTextToPlainText,
  timeOfDayToString,
  timestampToTimeOfDay,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon,
  urlForWorkIconFromWork,
  WorkIconInfo,
  WorkIcons
} from '@/models';
import { FeatureFlagService, PasteboardService } from '@/services';
import { PlannerDataStore, WorkDataStore } from '@/stores';
import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { PlannerDayAnnotation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_annotation_pb';
import { PublishedWorkDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/published_work_details_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { format, set } from 'date-fns';
import {
  contextMenuActionsForCourseOccurrence,
  contextMenuActionsForNote,
  contextMenuActionsForPlannedWork,
  contextMenuActionsForPublishedWork,
  contextMenuActionsForWork
} from '../../../context-menu';
import { UserDashboardCalendarAnnotationInfo } from './UserDashboardCalendarAnnotationInfo';
import { UserDashboardCalendarEventInfo } from './UserDashboardCalendarEventInfo';
import { UserDashboardCalendarItemDescriptionInfo } from './UserDashboardCalendarItemDescriptionInfo';
import { getUserDashboardCalendarItemState, UserDashboardCalendarItemState } from './UserDashboardCalendarItemState';
import { UserDashboardCalendarNoteInfo } from './UserDashboardCalendarNoteInfo';
import { UserDashboardCalendarPeriodInfo } from './UserDashboardCalendarPeriodInfo';
import { UserDashboardCalendarPlannedWorkInfo } from './UserDashboardCalendarPlannedWorkInfo';
import { UserDashboardCalendarPublishedWorkInfo } from './UserDashboardCalendarPublishedWorkInfo';
import { UserDashboardCalendarWorkInfo } from './UserDashboardCalendarWorkInfo';

export function plannerDayAnnotationToInfo(annotation: PlannerDayAnnotation): UserDashboardCalendarAnnotationInfo {
  return { id: annotation.title, title: annotation.title, color: annotation.color, symbol: annotation.symbol };
}

export function plannerCalendarEventToInfo(calendarEvent: CalendarEvent, now: Date): UserDashboardCalendarEventInfo {
  const state = getUserDashboardCalendarItemState(
    timestampDate(calendarEvent.startTime!),
    calendarEvent.isAllDay ? timestampDate(calendarEvent.endTime!) : undefined,
    calendarEvent.isAllDay,
    now
  );

  return {
    id: calendarEvent.id,
    title: calendarEvent.title || 'Calendar event',
    color: calendarEvent.color,
    externalUrl: calendarEvent.externalSource?.url,
    state,
    contextMenuActions: () => []
  };
}

export function plannerWorkToInfo(
  work: Work,
  courseSectionsById: Map<string, CourseSectionDetails>,
  workIcons: WorkIcons,
  now: Date,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  workStore: WorkDataStore,
  plannerStore: PlannerDataStore,
  featureFlag: FeatureFlagService
): UserDashboardCalendarWorkInfo {
  const icon = workIcons.iconsById.get(work.iconId)!;
  const iconInfo: WorkIconInfo = {
    id: icon.iconId,
    title: icon.iconName,
    lightUrl: urlForWorkIconFromWork(icon, work, 'light'),
    darkUrl: urlForWorkIconFromWork(icon, work, 'dark'),
    externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, workIcons)
  };

  // A work without a due date should not appear in the calendar, but in the event that it does,
  // treating it as upcoming.
  const state: UserDashboardCalendarItemState =
    work.dueTime != null
      ? getUserDashboardCalendarItemState(timestampDate(work.dueTime), undefined, work.isDueAllDay, now, work.status)
      : 'upcoming';

  return {
    id: work.id,
    title: work.title,
    description: richTextToInfo(work.description),
    color: courseSectionsById.get(work.courseSectionId)?.courseSection?.color ?? '',
    icon: iconInfo,
    state,
    contextMenuActions: () =>
      contextMenuActionsForWork(work, isReadOnly, pasteboard, workStore, plannerStore, featureFlag)
  };
}

export function plannerPlannedWorkToInfo(
  plannedWork: PlannedWork,
  work: Work,
  courseSectionsById: Map<string, CourseSectionDetails>,
  workIcons: WorkIcons,
  now: Date,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  workStore: WorkDataStore,
  featureFlag: FeatureFlagService
): UserDashboardCalendarPlannedWorkInfo {
  const icon = workIcons.iconsById.get(work.iconId)!;
  const iconInfo: WorkIconInfo = {
    id: icon.iconId,
    title: icon.iconName,
    lightUrl: urlForWorkIconFromWork(icon, work, 'light'),
    darkUrl: urlForWorkIconFromWork(icon, work, 'dark'),
    externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, workIcons)
  };

  const state = getUserDashboardCalendarItemState(
    timestampDate(plannedWork.timeSlot!.startTime!),
    timestampDate(plannedWork.timeSlot!.endTime!),
    false,
    now
  );

  return {
    id: plannedWork.id,
    title: work.title,
    description: richTextToInfo(work.description),
    color: courseSectionsById.get(work.courseSectionId)?.courseSection?.color ?? '',
    icon: iconInfo,
    workId: work.id,
    startTime: timestampToTimeOfDay(plannedWork.timeSlot!.startTime!),
    endTime: timestampToTimeOfDay(plannedWork.timeSlot!.endTime!),
    state,
    contextMenuActions: () =>
      contextMenuActionsForPlannedWork(work, plannedWork, isReadOnly, pasteboard, workStore, featureFlag)
  };
}

export function plannerCourseSectionOccurrenceToInfo(
  courseOccurrence: CourseSectionOccurrence,
  courseSectionsById: Map<string, CourseSectionDetails>,
  date: Date,
  now: Date,
  isReadOnly: boolean,
  canCreatePublishedWork: boolean,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): UserDashboardCalendarPeriodInfo {
  const courseSection = courseSectionsById.get(courseOccurrence.courseSectionId);
  const startTime = courseOccurrence.startTime!;
  const endTime = courseOccurrence.endTime!;
  const startDate = set(date, { hours: startTime.hours, minutes: startTime.minutes });
  const endDate = set(date, { hours: endTime.hours, minutes: endTime.minutes });

  const state = getUserDashboardCalendarItemState(startDate, endDate, false, now);
  const formattedStartTime = timeOfDayToString(startTime);
  const formattedEndTime = timeOfDayToString(startTime);
  const formattedDate = format(date, 'P');

  const id = [
    courseSection?.courseSection?.id ?? 'n/a',
    formattedDate,
    formattedStartTime,
    formattedEndTime,
    courseOccurrence.periodLabel,
    courseOccurrence.absoluteOrdinal
  ].join('-');

  return {
    ...courseOccurrence,
    id,
    date: date,
    courseSection,
    startTime,
    endTime,
    state,
    onPaste: () => pasteboard.setPasteContext({ date: date, period: courseOccurrence }),
    contextMenuActions: () =>
      contextMenuActionsForCourseOccurrence(
        courseOccurrence,
        date,
        isReadOnly,
        canCreatePublishedWork,
        pasteboard,
        featureFlag
      )
  };
}

export function publishedWorkToInfo(
  publishedWorkDetails: PublishedWorkDetails,
  courseSectionsById: Map<string, CourseSectionDetails>,
  workIcons: WorkIcons,
  now: Date,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): UserDashboardCalendarPublishedWorkInfo {
  const publishedWork = publishedWorkDetails.publishedWork!;
  const courseSection = courseSectionsById.get(publishedWorkDetails.courseSectionId);

  const icon = workIcons.iconsById.get(publishedWork.iconId)!;
  const iconInfo: WorkIconInfo = {
    id: icon.iconId,
    title: icon.iconName,
    lightUrl: urlForPublishedWorkWorkIcon(icon, publishedWork.importance, 'light'),
    darkUrl: urlForPublishedWorkWorkIcon(icon, publishedWork.importance, 'dark'),
    externalBadgeUrl: urlForExternalSourceBadge(publishedWork.externalSource?.sourceName, workIcons)
  };

  const state: UserDashboardCalendarItemState = getUserDashboardCalendarItemState(
    timestampDate(publishedWork.dueTime!),
    undefined,
    publishedWork.isDueAllDay,
    now
  );

  return {
    id: publishedWork.id,
    title: publishedWork.title,
    description: richTextToInfo(publishedWork.description),
    icon: iconInfo,
    color: courseSection?.courseSection?.color ?? '',
    state,
    schoolId: publishedWork.schoolId,
    contextMenuActions: () => contextMenuActionsForPublishedWork(publishedWork, isReadOnly, pasteboard, featureFlag)
  };
}

export function plannerNoteToInfo(
  note: Note,
  courseSectionsById: Map<string, CourseSectionDetails>,
  now: Date,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): UserDashboardCalendarNoteInfo {
  const courseSection = courseSectionsById.get(note.courseSectionId);

  // A note without a time should not appear in the calendar, but in the event that it does,
  // treating it as upcoming.
  const state: UserDashboardCalendarItemState =
    note.time != null
      ? getUserDashboardCalendarItemState(timestampDate(note.time), undefined, note.isAllDay, now)
      : 'upcoming';

  return {
    id: note.id,
    color: courseSection?.courseSection?.color ?? '',
    state,
    text: richTextToInfo(note.text),
    contextMenuActions: () => contextMenuActionsForNote(note, pasteboard, featureFlag)
  };
}

function richTextToInfo(richText: CommonRichText | undefined): UserDashboardCalendarItemDescriptionInfo | undefined {
  return richText != null ? { richText, plainText: convertRichTextToPlainText(richText) } : undefined;
}
