import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSxArray } from '../../../utils';
import { SearchBar } from '../../utils';

export interface TableHeaderProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  displaySearchBar: boolean;
  searchBarPlaceholder?: string;
  onSearchTextChange?: (searchText: string) => void;
}

export function TableHeader({
  sx = [],
  className,
  title,
  subtitle,
  displaySearchBar,
  searchBarPlaceholder,
  onSearchTextChange
}: TableHeaderProps) {
  return (
    <Stack
      className={className}
      direction="row"
      sx={[
        {
          m: 1,
          ml: 2,
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <Stack direction="column">
        <Typography
          variant="body1"
          sx={{
            fontWeight: '600'
          }}
        >
          {title}
        </Typography>

        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Stack>
      {displaySearchBar && (
        <SearchBar
          onSearch={(searchText) => onSearchTextChange?.(searchText ?? '')}
          placeholder={searchBarPlaceholder}
          sx={{ width: 420 }}
        />
      )}
    </Stack>
  );
}
