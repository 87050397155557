import { useServices } from '@/hooks';
import { CalendarMonthRounded } from '@mui/icons-material';
import GoPreviousDayIcon from '@mui/icons-material/ArrowBackRounded';
import GoNextDayIcon from '@mui/icons-material/ArrowForwardRounded';
import { Box, Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDatePicker } from '../../shared';

export interface PlannerWorkloadCourseSectionsTableDateControlsProps {
  sx?: SxProps;
  className?: string;
  dates: Date[];
  goToPreviousWeek: () => void;
  goToNextWeek: () => void;
  goToToday: () => void;
  goToDate: (date: Date) => void;
}

export const PlannerWorkloadCourseSectionsTableDateControls = observer(
  ({
    sx = [],
    className,
    dates,
    goToDate,
    goToToday,
    goToPreviousWeek,
    goToNextWeek
  }: PlannerWorkloadCourseSectionsTableDateControlsProps) => {
    const { dateService } = useServices();
    const [datePickerButtonRef, setDatePickerButtonRef] = useState<HTMLButtonElement | undefined>();
    const strings = LocalizedStrings.planner.workload;

    const boundDates = useMemo(() => ({ first: dates[0], last: dates[dates.length - 1] }), [dates]);
    const showTodayButton = useMemo(() => !dates.some((d) => isSameDay(d, dateService.now)), [dates, dateService.now]);

    return (
      <Stack direction="row" sx={sx} className={className} alignItems="center" spacing={1}>
        <Typography variant="subtitle1" sx={{ fontWeight: '500', flex: 1 }}>
          <Typography variant="body2" color="textSecondary" component="span">
            {format(boundDates.first, 'EEEE')}
          </Typography>
          &nbsp;
          {format(boundDates.first, 'PPP')}
          <Typography color="textSecondary" component="span">
            &nbsp;-&nbsp;
          </Typography>
          <Typography variant="body2" color="textSecondary" component="span">
            {format(boundDates.last, 'EEEE')}
          </Typography>
          &nbsp;
          {format(boundDates.last, 'PPP')}
        </Typography>

        {showTodayButton && <Button onClick={() => goToToday()}>{strings.goToTodayButtonTitle()}</Button>}

        <IconButton onClick={() => goToPreviousWeek()}>
          <GoPreviousDayIcon />
        </IconButton>
        <Box>
          <IconButton onClick={(e) => setDatePickerButtonRef(e.currentTarget)}>
            <CalendarMonthRounded />
          </IconButton>

          {datePickerButtonRef != null && (
            <Popover
              open={true}
              anchorEl={datePickerButtonRef}
              slotProps={{ paper: { sx: { width: 400 } } }}
              onClose={() => setDatePickerButtonRef(undefined)}
            >
              <UserDashboardCalendarDatePicker
                selectedDates={() => dates}
                highlightedDates={() => []}
                onSelectDate={(date) => {
                  goToDate(date);
                  setDatePickerButtonRef(undefined);
                }}
              />
            </Popover>
          )}
        </Box>
        <IconButton onClick={() => goToNextWeek()}>
          <GoNextDayIcon />
        </IconButton>
      </Stack>
    );
  }
);
