import {
  CloseRounded,
  InfoOutlined,
  ReportGmailerrorredRounded,
  TaskAltRounded,
  WarningAmberRounded
} from '@mui/icons-material';
import {
  alpha,
  Box,
  Button,
  CardActionArea,
  darken,
  IconButton,
  lighten,
  Stack,
  Tooltip,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import { isSxArray } from '../../utils';
import { RefreshButton } from './RefreshButton';

export type BannerSeverity = 'none' | 'error' | 'info' | 'success' | 'warning';

export type BannerActionClickHandler = (() => void) | (() => Promise<void>);

export type BannerAction =
  | { case: 'icon'; value: ReactNode; onClick: BannerActionClickHandler; tooltip?: string; disabled?: boolean }
  | {
      case: 'icon-text';
      value: { text: string; icon?: ReactNode };
      onClick: BannerActionClickHandler;
      tooltip?: string;
      disabled?: boolean;
    };

export interface BannerProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  showIcon?: boolean;
  severity: BannerSeverity;
  actions?: BannerAction[];
  onClose?: () => void;
  icon?: ReactNode;
}

export function Banner({
  sx = [],
  className,
  children,
  showIcon = true,
  severity,
  actions = [],
  onClick,
  onClose,
  icon
}: BannerProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const backgroundColor = useMemo(() => {
    switch (severity) {
      case 'none':
        return alpha(theme.palette.action.selected, 0.05);
      case 'info':
        return alpha(theme.palette.primary.main, 0.1);
      case 'error':
        return alpha(theme.palette.error.main, isDarkMode ? 0.1 : 0.2);
      case 'warning':
        return alpha(theme.palette.warning.main, 0.1);
      case 'success':
        return alpha(theme.palette.success.main, 0.1);
    }
  }, [severity, theme]);

  const textColor = useMemo(() => {
    switch (severity) {
      case 'none':
        return theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary;
      case 'info':
        return isDarkMode ? lighten(theme.palette.primary.main, 0.7) : darken(theme.palette.primary.main, 0.2);
      case 'error':
        return isDarkMode ? lighten(theme.palette.error.main, 0.7) : darken(theme.palette.error.main, 0.3);
      case 'warning':
        return isDarkMode ? lighten(theme.palette.warning.main, 0.7) : darken(theme.palette.warning.main, 0.3);
      case 'success':
        return isDarkMode ? lighten(theme.palette.success.main, 0.7) : darken(theme.palette.success.main, 0.3);
    }
  }, [severity, theme]);

  const resolvedIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    switch (severity) {
      case 'none':
      case 'info':
        return <InfoOutlined fontSize="small" color="inherit" />;
      case 'success':
        return <TaskAltRounded fontSize="small" color="inherit" />;
      case 'warning':
        return <WarningAmberRounded fontSize="small" color="inherit" />;
      case 'error':
        return <ReportGmailerrorredRounded fontSize="small" color="inherit" />;
    }
  }, [severity, theme]);

  const hasActions = actions.length > 0 || onClose != null;

  return (
    <CardActionArea
      sx={[
        {
          display: 'flex',
          gap: 1,
          backgroundColor,
          py: 0.25,
          px: 1,
          borderRadius: 1,
          color: textColor,
          alignItems: hasActions ? 'center' : 'flex-start',
          cursor: onClick != null ? 'pointer' : 'default'
        },
        onClick == null && {
          '& .MuiCardActionArea-focusHighlight': {
            opacity: '0 !important'
          },
          '& .MuiTouchRipple-root': {
            opacity: '0 !important'
          }
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
      component="div"
      onClick={onClick}
      className={className}
    >
      <Stack
        display="inline-flex"
        direction="row"
        alignItems="flex-start"
        spacing={1}
        flex={1}
        // Very specific number to align the content vertically when there are actions
        py={'5px'}
      >
        {showIcon && resolvedIcon}

        <Box
          sx={{
            flex: 1,
            ...theme.typography.subtitle2,
            fontSize: '0.8rem',
            '& .MuiAlertTitle-root': { ...theme.typography.body2, fontWeight: '600', mb: 0 }
          }}
        >
          {children}
        </Box>
      </Stack>

      {hasActions && (
        <Stack direction="row" spacing={0.5}>
          {actions.map((action, index) => (
            <Action key={index} action={action} />
          ))}

          {onClose != null && (
            <IconButton size="small" color="inherit">
              <CloseRounded fontSize="small" color="inherit" />
            </IconButton>
          )}
        </Stack>
      )}
    </CardActionArea>
  );
}

interface ActionProps {
  action: BannerAction;
}
function Action({ action }: ActionProps) {
  const [isProcessing, setIsProcessing] = useState(false);

  async function onClick() {
    setIsProcessing(true);
    await action.onClick();
    setIsProcessing(false);
  }

  switch (action.case) {
    case 'icon-text': {
      const text = action.value.text;
      const icon = action.value.icon;

      return (
        <Tooltip title={action.tooltip} disableInteractive>
          <Button color="inherit" size="small" loading={isProcessing} onClick={() => void onClick()} startIcon={icon}>
            {text}
          </Button>
        </Tooltip>
      );
    }

    case 'icon':
      return (
        <Tooltip title={action.tooltip} disableInteractive>
          <RefreshButton isRefreshing={isProcessing} reloadData={() => void onClick()} />
        </Tooltip>
      );
  }
}
