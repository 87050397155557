const basePlannerRoute = '/p/:plannerId';
const baseSchoolAdminRoute = '/school-admin/:schoolId';

export const RouteTemplates = {
  onboarding: '/onboarding',
  noPlanner: '/noPlanner',
  login: '/login',
  root: '/',

  planners: '/p',
  planner: basePlannerRoute,
  plannerWork: `${basePlannerRoute}/w/:workId`,
  plannerCourseSectionDetails: `${basePlannerRoute}/c/:courseSectionId`,
  plannerCourses: `${basePlannerRoute}/courses`,
  plannerItems: `${basePlannerRoute}/items`,
  plannerWorkload: `${basePlannerRoute}/workload`,
  plannerSchedules: `${basePlannerRoute}/schedules`,
  plannerShare: `${basePlannerRoute}/share`,
  plannerSubscribe: `${basePlannerRoute}/subscribe`,
  plannerConnectedApps: `${basePlannerRoute}/connected-apps`,

  publishedWork: `${basePlannerRoute}/:schoolId/w/:workId`,

  connectToExternalSource: `${basePlannerRoute}/connect-external`,
  schoolCourseSectionDetails: `${basePlannerRoute}/:schoolId/c/:courseId/students`,
  schoolCourseSectionDetailsStudentList: `${basePlannerRoute}/:schoolId/c/:courseId/students/list`,
  schoolStudentDetails: `${basePlannerRoute}/:schoolId/c/:courseId/students/s/:studentId`,

  adminSchoolsList: '/school-admin',
  adminSchoolGeneralInfo: `${baseSchoolAdminRoute}/dashboard`,
  adminSchoolClasses: `${baseSchoolAdminRoute}/classes`,
  adminSchoolClassDetails: `${baseSchoolAdminRoute}/classes/:courseSectionId`,
  adminSchoolSchedules: `${baseSchoolAdminRoute}/schedules`,
  adminSchoolSchedule: `${baseSchoolAdminRoute}/schedules/:scheduleCycleId`,
  adminSchoolTeachers: `${baseSchoolAdminRoute}/teachers`,
  adminSchoolTeacherDetails: `${baseSchoolAdminRoute}/teachers/:teacherId`,
  adminSchoolStudents: `${baseSchoolAdminRoute}/students`,
  adminSchoolStudentDetails: `${baseSchoolAdminRoute}/students/:studentId`,
  adminSchoolWorkload: `${baseSchoolAdminRoute}/workload`
};

export interface RouteNameInfo {
  /**
   * A template for the route. Use `RouteTemplates` to provide a value.
   */
  routeTemplate: string;
  /**
   * The key in the localization resource file
   */
  localizationKey: string;
}

export const RouteNames: RouteNameInfo[] = [
  { routeTemplate: RouteTemplates.schoolCourseSectionDetails, localizationKey: 'schoolCourseSectionDetails' },
  { routeTemplate: RouteTemplates.adminSchoolGeneralInfo, localizationKey: 'schoolGeneralInformation' },
  { routeTemplate: RouteTemplates.adminSchoolClasses, localizationKey: 'schoolClasses' },
  { routeTemplate: RouteTemplates.adminSchoolClassDetails, localizationKey: 'schoolClassDetails' },
  { routeTemplate: RouteTemplates.adminSchoolSchedules, localizationKey: 'schoolSchedules' },
  { routeTemplate: RouteTemplates.adminSchoolSchedule, localizationKey: 'schoolScheduleDetails' },
  { routeTemplate: RouteTemplates.adminSchoolTeachers, localizationKey: 'schoolTeachers' },
  { routeTemplate: RouteTemplates.adminSchoolTeacherDetails, localizationKey: 'schoolTeacherDetails' },
  { routeTemplate: RouteTemplates.adminSchoolStudents, localizationKey: 'schoolStudents' },
  { routeTemplate: RouteTemplates.adminSchoolStudentDetails, localizationKey: 'schoolStudentDetails' },
  { routeTemplate: RouteTemplates.adminSchoolWorkload, localizationKey: 'schoolWorkload' }
];
