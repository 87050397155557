import { UserDashboardCalendarDayAllDayItemInfo, UserDashboardCalendarDaySpecificDateViewModel } from '@/viewmodels';
import { Box, Grid2, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useNavigateAsync, useServices } from '../../../../hooks';
import { isSxArray } from '../../../../utils';
import { Banner } from '../../../utils';
import { UserDashboardCalendarDayAnnotationsView } from './UserDashboardCalendarDayAnnotationsView';
import { UserDashboardCalendarDayCalendarEventView } from './UserDashboardCalendarDayCalendarEventView';
import { UserDashboardCalendarDayErrorIndicator } from './UserDashboardCalendarDayErrorIndicator';
import { UserDashboardCalendarDayHeaderView } from './UserDashboardCalendarDayHeaderView';
import { UserDashboardCalendarDayLoadingIndicator } from './UserDashboardCalendarDayLoadingIndicator';
import { UserDashboardCalendarDayNoteView } from './UserDashboardCalendarDayNoteView';
import { UserDashboardCalendarDaySpecificDateTimedItemsView } from './UserDashboardCalendarDaySpecificDateTimedItemsView';
import { UserDashboardCalendarDayWorkView } from './UserDashboardCalendarDayWorkView';

export interface UserDashboardCalendarDaySpecificDateViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarDaySpecificDateViewModel;
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
}

export const UserDashboardCalendarDaySpecificDateView = observer(
  ({
    sx = [],
    className,
    viewModel,
    hasCalendarSyncError,
    retryFetchData
  }: UserDashboardCalendarDaySpecificDateViewProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();

    const { allDayItems } = viewModel;
    const strings = LocalizedStrings.calendar.day;
    const navigate = useNavigateAsync();

    function goToPlanningScreen() {
      const destination = `${route.resolvePlannerLocation(plannerId)}/plan`;
      void navigate(destination);
    }

    return (
      <Box
        className={className}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflow: 'hidden'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <UserDashboardCalendarDayHeaderView
          day={viewModel}
          hasCalendarSyncError={hasCalendarSyncError}
          retryFetchData={() => retryFetchData()}
        />
        {viewModel.annotations.length > 0 && (
          <UserDashboardCalendarDayAnnotationsView annotations={viewModel.annotations} sx={{ my: 1 }} />
        )}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto'
          }}
        >
          {viewModel.state === 'fulfilled' && (
            <Grid2
              container
              columnSpacing={{ xs: 0, md: 3 }}
              rowSpacing={{ xs: 0, md: 3 }}
              sx={{
                p: { xs: 2, md: 3 },
                pt: { xs: 1, md: 1 },
                width: '100%'
              }}
            >
              <Grid2 size={{ xs: 12, md: 7 }}>
                {viewModel.inboxWorkCount > 0 && (
                  <Banner sx={{ mb: 2 }} onClick={() => goToPlanningScreen()} severity="info">
                    {strings.inboxWorkAlert(viewModel.inboxWorkCount)}
                  </Banner>
                )}

                <UserDashboardCalendarDaySpecificDateTimedItemsView viewModel={viewModel} />
              </Grid2>

              <Grid2 size={{ xs: 12, md: 5 }}>
                <Stack spacing={1}>
                  {allDayItems.length > 0 && (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: '600'
                      }}
                    >
                      {strings.allDayItemsSectionTitle()}
                    </Typography>
                  )}
                  <Stack
                    sx={{
                      pl: 1
                    }}
                  >
                    {allDayItems.map((item) => renderAllDayItem(item))}
                  </Stack>
                </Stack>
              </Grid2>
            </Grid2>
          )}

          {viewModel.state === 'error' && (
            <UserDashboardCalendarDayErrorIndicator sx={{ p: { xs: 2, md: 3 } }} retry={retryFetchData} />
          )}

          {viewModel.state === 'loading' && <UserDashboardCalendarDayLoadingIndicator sx={{ p: { xs: 2, md: 3 } }} />}
        </Box>
      </Box>
    );
  }
);

function renderAllDayItem(item: UserDashboardCalendarDayAllDayItemInfo) {
  switch (item.case) {
    case 'calendarEvent':
      return <UserDashboardCalendarDayCalendarEventView key={item.value.id} calendarEvent={item.value} />;
    case 'note':
      return <UserDashboardCalendarDayNoteView key={item.value.id} note={item.value} showTime />;
    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarDayWorkView work={item} key={item.value.id} showTime />;
  }
}
