import { Button, Stack, SxProps, Typography } from '@mui/material';
import { useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';

export interface PlannerWorkloadUnknownSectionProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerWorkloadUnknownSection = ({ sx = [], className }: PlannerWorkloadUnknownSectionProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const strings = LocalizedStrings.planner.workload;

  function showMyClasses() {
    searchParams.delete('section');
    setSearchParams(searchParams);
  }

  return (
    <Stack sx={[{ alignItems: 'flex-start' }, ...(isSxArray(sx) ? sx : [sx])]} className={className} spacing={1}>
      <Typography color="textSecondary" variant="body2">
        {strings.unknownSectionMessage()}
      </Typography>
      <Button variant="contained-grey" onClick={showMyClasses}>
        {strings.unknownSectionButtonTitle()}
      </Button>
    </Stack>
  );
};
