import { useServices } from '@/hooks';
import { WorkloadSummaryInfo } from '@/models';
import { isSxArray, setDateInSearchParams } from '@/utils';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { CircleRounded } from '@mui/icons-material';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import clsx from 'clsx';
import { format, isFirstDayOfMonth, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';
import { WorkloadInformationView } from '../../shared';

export interface AdminWorkloadCalendarDayProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  isInCurrentMonth: boolean;
  getWorkloadInformation: (date: Date) => WorkloadSummaryInfo | undefined;
  getCalendarDay: (date: Date) => CalendarDay | undefined;
}

export const AdminWorkloadCalendarDay = observer(
  ({
    sx = [],
    className,
    date,
    isInCurrentMonth,
    getWorkloadInformation,
    getCalendarDay
  }: AdminWorkloadCalendarDayProps) => {
    const { dateService } = useServices();
    const isToday = isSameDay(date, dateService.now);
    const [searchParams, setSearchParams] = useSearchParams();
    const calendarDay = getCalendarDay(date);

    function showDetails() {
      setDateInSearchParams(date, searchParams, dateService);
      searchParams.set('display', 'list');
      setSearchParams(searchParams);
    }

    return (
      <CardActionArea
        className={clsx('school-admin-workload-table-day', className)}
        sx={[
          {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
        onClick={showDetails}
      >
        <Stack
          sx={{ position: 'absolute', alignItems: 'baseline', top: 0, left: 0 }}
          direction="row"
          spacing={isToday ? 0 : -0.5}
        >
          <Box
            sx={{
              position: 'relative',
              width: 28,
              height: 28,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {isToday && <CircleRounded sx={{ width: '100%', height: '100%', position: 'absolute' }} color="error" />}

            <Typography
              color={isInCurrentMonth ? 'textPrimary' : 'textDisabled'}
              sx={{ fontWeight: 600, position: 'relative', color: isToday ? 'white' : undefined }}
            >
              {date.getDate()}
            </Typography>
          </Box>

          {isFirstDayOfMonth(date) && (
            <Typography color="textDisabled" variant="caption">
              {format(date, 'MMMM')}
            </Typography>
          )}
        </Stack>

        {calendarDay != null && !calendarDay.isDayOfWeekCycleDayName && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 28,
              height: 28,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="subtitle2" color={isInCurrentMonth ? 'textSecondary' : 'textDisabled'}>
              {calendarDay.cycleDayName}
            </Typography>
          </Box>
        )}

        <Stack sx={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <WorkloadInformationView date={date} getWorkloadInformation={() => getWorkloadInformation(date)} />
        </Stack>
      </CardActionArea>
    );
  }
);
