import { Day, plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerDataStore, UserDataStore, WorkDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable } from 'mobx';
import { AppPlannerUserDashboardCalendarDaySpecificDateViewModel } from './PlannerUserDashboardCalendarDaySpecificDateViewModel';
import { UserDashboardCalendarDaySpecificDateViewModel } from './UserDashboardCalendarDaySpecificDateViewModel';
import { AppUserDashboardCalendarDayViewModel } from './UserDashboardCalendarDayViewModel';

export class AppPlannerUserDashboardCalendarDayViewModel extends AppUserDashboardCalendarDayViewModel {
  constructor(
    plannerId: string,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    workStore: WorkDataStore = ServiceContainer.services.workStore
  ) {
    const calendarStore = plannerStore.getCalendarStore(plannerId);
    super(plannerId, calendarStore, workStore);
    makeObservable(this);
  }

  @computed
  private get planner(): Planner | undefined {
    return this._userStore.getPlannerForId(this._dashboardId);
  }

  protected makeViewModelForDay(day: Day, dayString: string): UserDashboardCalendarDaySpecificDateViewModel {
    const planner = this.planner;
    const isReadOnly =
      planner != null
        ? !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS)
        : true;

    return new AppPlannerUserDashboardCalendarDaySpecificDateViewModel(
      day,
      isReadOnly,
      this._dashboardId,
      () => this._calendarStore.days.get(dayString) ?? (this._calendarStore.error != null ? 'error' : undefined),
      async () => this._calendarStore.fetchDays(day, day),
      () => this._calendarStore.moreRecentDraftScheduleCycleIds[0]
    );
  }
}
