import { dateToPBDate, Day, dayToString } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService } from '@/services';
import { PlannerCalendarStore, PlannerDataStore } from '@/stores';
import { computed, makeObservable } from 'mobx';
import { UserDashboardCalendarAnnotationInfo } from '../../shared';
import {
  AppUserDashboardCalendarDatePickerViewModel,
  UserDashboardCalendarDatePickerDayInfo
} from './UserDashboardCalendarDatePickerViewModel';

export class AppPlannerUserDashboardCalendarDatePickerViewModel extends AppUserDashboardCalendarDatePickerViewModel {
  protected readonly _calendarStore: PlannerCalendarStore;

  constructor(
    plannerId: string,
    initialDate: Date | undefined,
    selectedDates: () => Date[],
    highlightedDates: (startDate: Date, endDate: Date) => Date[],
    minDate: Date | undefined,
    maxDate: Date | undefined,
    plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    dateService: DateService = ServiceContainer.services.dateService
  ) {
    super(initialDate, selectedDates, highlightedDates, minDate, maxDate, dateService);
    this._calendarStore = plannerStore.getCalendarStore(plannerId);
    makeObservable(this);
  }

  @computed
  get isFetching(): boolean {
    return this._calendarStore.isFetching;
  }

  protected fetchDays(startDate: Day, endDate: Day): Promise<void> {
    return this._calendarStore.fetchDays(startDate, endDate);
  }

  getDayInfoForDate(date: Date): UserDashboardCalendarDatePickerDayInfo {
    const day = dateToPBDate(date);
    const plannerDay = this._calendarStore.days.get(dayToString(day));

    const annotations: UserDashboardCalendarAnnotationInfo[] =
      plannerDay?.annotations.map((a) => ({ id: a.symbol, color: a.color, symbol: a.symbol, title: a.title })) ?? [];

    const isDayOfWeekCycleDayName = plannerDay?.isDayOfWeekCycleDayName ?? true;

    return {
      day,
      cycleDayName: !isDayOfWeekCycleDayName ? (plannerDay?.cycleDayName ?? '') : '',
      annotations,
      isSelected: this.getDateIsSelected(date),
      isHighlighted: this.getDateIsHighlighted(date),
      isDisabled: this.getDateIsDisabled(date)
    };
  }
}
