import { useServices } from '@/hooks';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';
import { getDateFromSearchParams, setDateInSearchParams } from '../../../utils';
import { PlannerWorkloadCourseSectionDetails } from './PlannerWorkloadCourseSectionDetails';
import { PlannerWorkloadCourseSectionsTable } from './PlannerWorkloadCourseSectionsTable';

export interface PlannerWorkloadProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
}

export const PlannerWorkload = observer(({ sx = [], className, viewModel }: PlannerWorkloadProps) => {
  const { dateService } = useServices();
  const [searchParams, setSearchParams] = useSearchParams();
  const sectionId = searchParams.get('section') ?? '';
  const currentDate = getDateFromSearchParams(searchParams, dateService);

  function goToDate(date: Date | undefined) {
    if (date != null) {
      setDateInSearchParams(date, searchParams, dateService);
    } else {
      searchParams.delete('date');
    }
    setSearchParams(searchParams);
  }

  function goBackToSummary() {
    searchParams.delete('section');
    setSearchParams(searchParams);
  }

  return sectionId.length > 0 ? (
    <PlannerWorkloadCourseSectionDetails
      sx={sx}
      className={className}
      sectionId={sectionId}
      date={currentDate}
      goBackToSummary={goBackToSummary}
      setDate={goToDate}
      viewModel={viewModel}
    />
  ) : (
    <PlannerWorkloadCourseSectionsTable
      viewModel={viewModel}
      sx={sx}
      className={className}
      currentDate={currentDate}
      goToDate={goToDate}
    />
  );
});
