import { useServices } from '@/hooks';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Inventory2Rounded } from '@mui/icons-material';
import { AlertTitle, Box, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { Banner, ConfirmationDialog } from '../../utils';

export interface SchoolSettingsArchivedSchoolBannerProps {
  sx?: SxProps;
  className?: string;
  canUnarchive: boolean;
  school: SchoolInformation;
}

export const SchoolSettingsArchivedSchoolBanner = observer(
  ({ sx = [], school, canUnarchive, className }: SchoolSettingsArchivedSchoolBannerProps) => {
    const { userStore } = useServices();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isUnarchiving, setIsUnarchiving] = useState(false);
    const [hasError, setHasError] = useState(false);

    const theme = useTheme();
    const strings = LocalizedStrings.settings.school.details.archivedBanner;

    async function onConfirmation(hasConfirmed: boolean) {
      setShowConfirmation(false);

      if (hasConfirmed) {
        setHasError(false);
        setIsUnarchiving(true);

        try {
          await userStore.unarchiveSchool(school.school!.id);
        } catch (e) {
          setHasError(true);
          captureException(e);
        } finally {
          setIsUnarchiving(false);
        }
      }
    }

    return (
      <Box sx={sx} className={className}>
        <Banner
          severity="error"
          icon={<Inventory2Rounded fontSize="inherit" color="inherit" />}
          actions={
            canUnarchive
              ? [
                  {
                    case: 'icon-text',
                    value: { text: strings.unarchiveButtonLabel() },
                    disabled: isUnarchiving,
                    onClick: () => setShowConfirmation(true)
                  }
                ]
              : undefined
          }
        >
          <AlertTitle>{strings.title()}</AlertTitle>
          {strings.message()}
        </Banner>
        {hasError && (
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.error.main,
              p: 1
            }}
          >
            {strings.unarchiveError()}
          </Typography>
        )}
        {showConfirmation && (
          <ConfirmationDialog
            isOpen={true}
            title={strings.unarchiveConfirmationTitle()}
            message={strings.unarchiveConfirmationMessage(school.school!.name)}
            confirmButtonLabel={strings.unarchiveConfirmationSubmit()}
            onSubmit={(hasConfirmed) => void onConfirmation(hasConfirmed)}
          />
        )}
      </Box>
    );
  }
);
