import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { PlannerPastePlannerItemViewModel } from '../../../../viewmodels';
import { Subheader } from '../../../utils';

export interface PlannerPastePlannerItemGeneralOptionsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemViewModel;
}

export const PlannerPastePlannerItemGeneralOptions = observer(
  ({ sx = [], className, viewModel }: PlannerPastePlannerItemGeneralOptionsProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    return (
      <Stack sx={sx} className={className}>
        <Subheader>{strings.generalOptionsSectionTitle()}</Subheader>

        <FormControlLabel
          sx={{ m: 0 }}
          slotProps={{ typography: { variant: 'body2' } }}
          control={
            <Checkbox
              checked={viewModel.linkToOriginalItem}
              onChange={(_, checked) => (viewModel.linkToOriginalItem = checked)}
            />
          }
          label={strings.linkCopyToOriginalOptionLabel()}
        />
      </Stack>
    );
  }
);
