import { Day } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsStorage } from '@/services';
import { PlannerDataStore, WorkDataStore } from '@/stores';
import { UserDashboardCalendarMonthDayViewModel } from './UserDashboardCalendarMonthDayViewModel';

import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { computed, makeObservable } from 'mobx';
import { AppPlannerUserDashboardCalendarMonthDayViewModel } from './PlannerUserDashboardCalendarMonthDayViewModel';
import { AppUserDashboardCalendarMonthViewModel } from './UserDashboardCalendarMonthViewModel';

export class AppPlannerUserDashboardCalendarMonthViewModel extends AppUserDashboardCalendarMonthViewModel {
  constructor(
    plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    workStore: WorkDataStore = ServiceContainer.services.workStore,
    settingsStorage: ApplicationSettingsStorage = ServiceContainer.services.settingsStorage
  ) {
    const calendarStore = _plannerStore.getCalendarStore(plannerId);
    super(plannerId, calendarStore, workStore, settingsStorage);
    makeObservable(this);
  }

  @computed
  get courseSections(): CourseSectionDetails[] {
    return this._plannerStore.getCourseSectionsInPlanner(this._dashboardId).visibleCourseSections;
  }

  protected makeViewModelForDay(day: Day, dayString: string): UserDashboardCalendarMonthDayViewModel {
    return new AppPlannerUserDashboardCalendarMonthDayViewModel(
      day,
      this._dashboardId,
      () => this._calendarStore.days.get(dayString),
      () => this.courseSectionsFilter
    );
  }
}
