import {
  dateToPBDate,
  dateToString,
  timeOfDayAndDateToDate,
  timestampDateOptional,
  WorkloadGroupDetails,
  WorkloadGroupsInfo,
  WorkloadPublishedWorkInfo,
  WorkloadSummaryInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { CalendarDataStore } from '@/stores';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { CalendarPeriod } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_period_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { WorkloadGroup } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_group_pb';
import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { isWithinInterval } from 'date-fns';
import { computed, makeObservable } from 'mobx';

export interface AdminWorkloadViewModel {
  readonly isFetchingData: boolean;
  readonly hasFetchError: boolean;

  loadWorkloadForDates(dates: Date[], force: boolean): Promise<void>;
  getInformationForDate(date: Date): WorkloadSummaryInfo | undefined;
  getWorkloadGroupsForDate(date: Date): WorkloadGroupsInfo | undefined;
  getCalendarDay(date: Date): CalendarDay | undefined;
  getPublishedWorkInfo(id: string, date: Date): WorkloadPublishedWorkInfo | undefined;
}

export class AppAdminWorkloadViewModel implements AdminWorkloadViewModel {
  private readonly _calendarStore: CalendarDataStore;

  constructor(
    private readonly _schoolId: string,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _workloadStore = ServiceContainer.services.workloadStore
  ) {
    makeObservable(this);
    this._calendarStore = _userStore.getCalendarStore({ id: _schoolId, kind: 'school' });
  }

  @computed
  get isFetchingData(): boolean {
    return this._workloadStore.isFetching || this._calendarStore.isFetching;
  }

  @computed
  get hasFetchError(): boolean {
    return this._workloadStore.error != null || this._calendarStore.error != null;
  }

  getCalendarDay(date: Date): CalendarDay | undefined {
    return this._calendarStore.days.get(dateToString(date));
  }

  getInformationForDate(date: Date): WorkloadSummaryInfo | undefined {
    return this._workloadStore.getSchoolWorkloadInformationForDate(this._schoolId, dateToPBDate(date));
  }

  async loadWorkloadForDates(dates: Date[], force: boolean): Promise<void> {
    const sortedDates = dates.sort((a, b) => a.getTime() - b.getTime());
    const startDate = dateToPBDate(sortedDates[0]);
    const endDate = dateToPBDate(sortedDates[sortedDates.length - 1]);
    const days = dates.map((d) => dateToPBDate(d));

    await Promise.all([
      this._calendarStore.fetchDays(startDate, endDate, force),
      this._workloadStore.fetchSchoolWorkloadInformation(this._schoolId, days, force)
    ]);
  }

  getWorkloadGroupsForDate(date: Date): WorkloadGroupsInfo | undefined {
    const dailyWorkload = this._workloadStore.getSchoolWorkloadDetailsForDate(this._schoolId, dateToPBDate(date));

    if (dailyWorkload == null) {
      return undefined;
    }

    const aboveThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.ABOVE_THRESHOLD) ?? [];
    const atThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.AT_THRESHOLD) ?? [];
    const underThresholdGroups = dailyWorkload?.groups.filter((g) => g.status === WorkloadStatus.UNDER_THRESHOLD) ?? [];

    return {
      aboveThresholdGroups: aboveThresholdGroups.map((g) => this.convertGroupToInfo(g, date)),
      atThresholdGroups: atThresholdGroups.map((g) => this.convertGroupToInfo(g, date)),
      underloadedGroups: underThresholdGroups.map((g) => this.convertGroupToInfo(g, date))
    };
  }

  getPublishedWorkInfo(id: string, date: Date): WorkloadPublishedWorkInfo | undefined {
    const dailyWorkload = this._workloadStore.getSchoolWorkloadDetailsForDate(this._schoolId, dateToPBDate(date));

    if (dailyWorkload == null) {
      return undefined;
    }

    for (const group of dailyWorkload.groups) {
      for (const work of group.importantPublishedWorks) {
        if (work.id === id) {
          const periods = this.getPeriodsForDate(date);
          return this.mapPublishedWorkToInfo(work, periods);
        }
      }
    }

    return undefined;
  }

  private convertGroupToInfo(group: WorkloadGroup, date: Date): WorkloadGroupDetails {
    const periods = this.getPeriodsForDate(date);
    const works = group.importantPublishedWorks.map((pw) => this.mapPublishedWorkToInfo(pw, periods));
    return { works, students: group.students, status: group.status };
  }

  private mapPublishedWorkToInfo(work: PublishedWork, periods: CalendarPeriod[]): WorkloadPublishedWorkInfo {
    const courseSection = this._workloadStore.getCourseSection(work.courseSectionId);
    const periodTag = this.getPeriodLabelForDate(timestampDateOptional(work.dueTime), work.isDueAllDay, periods);

    return {
      publishedWork: work,
      courseSection,
      periodTag,
      plannerCourseSection: undefined,
      isTaughtSection: courseSection?.details?.teachers.some((t) => t.userId === this._userStore.user.userId) ?? false
    };
  }

  private getPeriodsForDate(date: Date): CalendarPeriod[] {
    return this._calendarStore.days.get(dateToString(date))?.periods ?? [];
  }

  private getPeriodLabelForDate(
    date: Date | undefined,
    isAllDay: boolean,
    periods: CalendarPeriod[]
  ): string | undefined {
    if (date == null || isAllDay) {
      return undefined;
    }

    return periods.find(({ startTime, endTime }) => {
      const start = timeOfDayAndDateToDate(date, startTime!);
      const end = timeOfDayAndDateToDate(date, endTime!);
      return isWithinInterval(date, { start, end });
    })?.label;
  }
}
