import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../lists';

export interface PlannerWorkloadCourseSectionsTableSectionInfoProps {
  sx?: SxProps;
  className?: string;
  courseSection: CourseSectionDetails;
  size?: 'small' | 'medium';
}

export const PlannerWorkloadCourseSectionsTableSectionInfo = observer(
  ({ sx = [], className, courseSection, size = 'small' }: PlannerWorkloadCourseSectionsTableSectionInfoProps) => {
    const section = courseSection.courseSection?.section ?? '';
    const strings = LocalizedStrings.planner.workload;

    return (
      <Stack sx={sx} className={className} direction="row" spacing={1}>
        <ColorIndicator color={courseSection.courseSection!.color} />
        <Stack>
          <Typography variant={size === 'small' ? 'subtitle2' : 'subtitle1'} sx={{ fontWeight: '600' }}>
            {courseSection.courseSection!.title}
          </Typography>
          <Typography variant="caption" color="textSecondary" fontStyle={section.length > 0 ? 'normal' : 'italic'}>
            {section.length > 0 ? section : strings.noSectionNumber()}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
