import { ConnectedAppsService } from '@/services';
import { UserDataStore } from '@/stores';
import { ParticipationRequest } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_pb';
import { ParticipationRequestState } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_state_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { captureException } from '@sentry/react';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export interface PlannerParticipationRequestViewModel {
  readonly error: string | undefined;
  readonly requesterName: string;
  readonly requestTime: Date;

  /**
   * Acknowledge a participation request.
   * @param grantAccess Has granted access to planner.
   * @return Returns true if there are still pending requests
   */
  acknowledgeRequest(grantAccess: boolean): Promise<boolean>;
}

export class AppPlannerParticipationRequestViewModel implements PlannerParticipationRequestViewModel {
  @observable private _error: string | undefined;

  constructor(
    private readonly _request: ParticipationRequest,
    private readonly _plannerId: string,
    private readonly _userStore: UserDataStore,
    private readonly _connectedApps: ConnectedAppsService
  ) {
    makeObservable(this);
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get requesterName(): string {
    return this._request.requesterDisplayName;
  }

  @computed
  get requestTime(): Date {
    return timestampDate(this._request.requestedTime!);
  }

  async acknowledgeRequest(grantAccess: boolean): Promise<boolean> {
    runInAction(() => (this._error = undefined));

    try {
      if (grantAccess) {
        await this._userStore.usePlannerParticipationCode(this._request.participationCode, this._plannerId);
      }

      const planner = await this._userStore.acknowledgePlannerAccessRequest(
        this._plannerId,
        grantAccess,
        this._request.requesterUserId
      );

      // We do not want the acknowledgement to fail if the refresh of the sync status fails. So not awaiting.
      void this._connectedApps.refreshSyncStatus(this._plannerId);

      return (
        planner.participationRequests.filter(
          (req) =>
            req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNREAD ||
            req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNSPECIFIED
        ).length > 0
      );
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._error = error.message));
      return true;
    }
  }
}
