import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { chain, orderBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { plannedWorkIsCompleted, plannerHasAccessKindsForUser, timestampDateOptional } from '../../models';
import { ServiceContainer } from '../../providers';
import {
  AppWorkPlannedWorkStepsEditViewModel,
  WorkPlannedWorkStepsEditViewModel
} from './WorkPlannedWorkStepsEditViewModel';
import { WorkStepInfo } from './WorkStepInfo';

export interface WorkPlannedWorkInfo {
  readonly workId: string;
  readonly plannedWork: PlannedWork;
  readonly steps: WorkStepInfo[];
}

export interface WorkPlannedWorksViewModel {
  readonly isReadOnly: boolean;
  readonly workId: string;
  readonly pastPlannedWorks: WorkPlannedWorkInfo[];
  readonly nextPlannedWorks: WorkPlannedWorkInfo[];
  readonly canLinkPlannedWorkToSteps: boolean;
  makeLinkStepsViewModel(plannedWork: PlannedWork): WorkPlannedWorkStepsEditViewModel;
  cancelPlannerWork(id: string): Promise<void>;
}

export class AppWorkPlannedWorksViewModel implements WorkPlannedWorksViewModel {
  constructor(
    private readonly _work: () => Work,
    private readonly _steps: () => WorkStepInfo[],
    private readonly _cancelPlannedWork: (id: string) => Promise<void>,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _dateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  @computed
  private get work(): Work {
    return this._work();
  }

  @computed
  private get steps(): WorkStepInfo[] {
    return this._steps();
  }

  @computed
  private get sortedPlannedWorks(): PlannedWork[] {
    return orderBy(this.work.plannedWorks, (pw) => timestampDate(pw.timeSlot!.startTime!));
  }

  @computed
  get workId(): string {
    return this.work.id;
  }

  @computed
  get isReadOnly(): boolean {
    const planner = this._userStore.getPlannerForId(this.work.plannerId);

    if (planner == null) {
      return true;
    }

    return !plannerHasAccessKindsForUser(this._userStore.user.userId, planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get pastPlannedWorks(): WorkPlannedWorkInfo[] {
    return chain(this.sortedPlannedWorks)
      .filter((pw) => plannedWorkIsCompleted(pw, this._dateService))
      .orderBy((pw) => timestampDateOptional(pw.timeSlot?.startTime), 'desc')
      .map((pw) => ({ workId: this.workId, plannedWork: pw, steps: this.getStepsForIds(pw.workStepIds) }))
      .value();
  }

  @computed
  get nextPlannedWorks(): WorkPlannedWorkInfo[] {
    return chain(this.sortedPlannedWorks)
      .filter((pw) => !plannedWorkIsCompleted(pw, this._dateService))
      .orderBy((pw) => timestampDateOptional(pw.timeSlot?.endTime), 'asc')
      .map((pw) => ({ workId: this.workId, plannedWork: pw, steps: this.getStepsForIds(pw.workStepIds) }))
      .value();
  }

  @computed
  get canLinkPlannedWorkToSteps(): boolean {
    return this.steps.length > 0;
  }

  makeLinkStepsViewModel(plannedWork: PlannedWork): WorkPlannedWorkStepsEditViewModel {
    return new AppWorkPlannedWorkStepsEditViewModel(this.workId, this.work.syncToken, plannedWork, () => this._steps());
  }

  cancelPlannerWork(id: string): Promise<void> {
    return this._cancelPlannedWork(id);
  }

  private getStepsForIds(stepIds: string[]): WorkStepInfo[] {
    return this.steps.filter((s) => stepIds.includes(s.id));
  }
}
