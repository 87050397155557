import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { createWorkStep } from '../create-models';
import { EditableModel, EditableProperty } from './core';

export class EditableWorkStep extends EditableModel<WorkStep> {
  private _title: EditableProperty<string>;
  private _duration: EditableProperty<number>;
  private _isCompleted: EditableProperty<boolean>;

  constructor(workStep?: WorkStep) {
    super(workStep == null, workStep ?? createWorkStep({ id: uuidv4() }));
    makeObservable(this);

    this.setFields([
      (this._title = new EditableProperty<string>(workStep?.title)),
      (this._duration = new EditableProperty<number>(workStep?.duration)),
      (this._isCompleted = new EditableProperty<boolean>(workStep?.isCompleted))
    ]);
  }

  // Editable properties

  @computed
  get title(): string {
    return this._title.value ?? '';
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get duration(): number {
    return this._duration.value ?? 0;
  }

  set duration(value: number) {
    this._duration.value = value;
  }

  @computed
  get isCompleted(): boolean {
    return this._isCompleted.value ?? false;
  }

  set isCompleted(value: boolean) {
    this._isCompleted.value = value;
  }

  @computed
  get id(): string {
    return this.initialModel.id;
  }

  @computed
  get updatedModel(): WorkStep {
    return createWorkStep({
      ...this.initialModel,
      title: this._title.value ?? '',
      isCompleted: this._isCompleted.value ?? false,
      duration: this._duration.value ?? 0
    });
  }

  readonly hasChangesToSharedProperties = false;
}
