import { useActivePlannerId, useNavigateAsync, useViewModel } from '@/hooks';
import { PlannerItemKind, PlannerRepeatItemViewModel } from '@/viewmodels';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, Dialog, Subheader } from '../../../utils';
import { PlannerCopyOriginalItemCard } from '../PlannerCopyOriginalItemCard';
import { PlannerRepeatItemCycleDayPatternOptions } from './PlannerRepeatItemCycleDayPatternOptions';
import { PlannerRepeatItemPatternKindSelection } from './PlannerRepeatItemPatternKindSelection';
import { PlannerRepeatItemWeekPatternOptions } from './PlannerRepeatItemWeekPatternOptions';

export interface PlannerRepeatItemDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  itemKind: PlannerItemKind;
}

export const PlannerRepeatItemDialog = observer(
  ({ sx = [], className, isOpen, itemKind }: PlannerRepeatItemDialogProps) => {
    const strings = LocalizedStrings.planner.repeatPlannerItem;
    const navigate = useNavigateAsync();
    const plannerId = useActivePlannerId();

    const viewModel = useViewModel(
      (viewModels) => viewModels.createPlannerRepeatItemViewModel(plannerId, itemKind, close),
      [plannerId, itemKind, close]
    );

    function close() {
      return navigate(-1);
    }

    return (
      <Dialog
        title={strings.title()}
        isOpen={isOpen}
        sx={sx}
        className={className}
        width="sm"
        viewModel={viewModel}
        renderData={() => (
          <Stack
            spacing={1}
            sx={{
              width: '100%'
            }}
          >
            <PlannerCopyOriginalItemCard item={viewModel.item} />

            <Stack spacing={1}>
              <Stack direction="row" spacing={3}>
                <Stack sx={{ flex: 1 }}>
                  <Subheader>{strings.untilDateLabel()}</Subheader>
                  <CalendarDatePicker
                    value={viewModel.untilDate}
                    disabled={viewModel.untilCount > 0}
                    minDate={viewModel.minimumDate}
                    highlightedDates={(startDate, endDate) =>
                      viewModel.getDatesWithOccurrenceForCourseSection(startDate, endDate)
                    }
                    onChange={(date) => {
                      if (date != null) {
                        viewModel.untilDate = date;
                      }
                    }}
                    kind={'user-dashboard-calendar'}
                  />
                </Stack>

                <Typography sx={{ alignSelf: 'flex-end', pb: 1 }} color="textSecondary" variant="subtitle2">
                  {strings.untilOrLabel()}
                </Typography>

                <Stack sx={{ flex: 1 }}>
                  <Subheader>{strings.untilCountLabel()}</Subheader>
                  <TextField
                    type="number"
                    // Converting to string to remove leading zeros
                    value={viewModel.untilCount.toString()}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (!isNaN(value) && value >= 0) {
                        viewModel.untilCount = value;
                      }
                    }}
                  />
                </Stack>
              </Stack>

              {viewModel.untilCount > 0 && (
                <Button size="small" sx={{ alignSelf: 'flex-end' }} onClick={() => (viewModel.untilCount = 0)}>
                  {strings.resetUntilCount()}
                </Button>
              )}
            </Stack>

            <Stack>
              <Subheader>{strings.patternSelectionSectionTitle()}</Subheader>

              <Stack spacing={2}>
                <PlannerRepeatItemPatternKindSelection viewModel={viewModel} />
                {renderPatternOptions(viewModel)}
              </Stack>
            </Stack>
          </Stack>
        )}
      />
    );
  }
);

function renderPatternOptions(viewModel: PlannerRepeatItemViewModel) {
  switch (viewModel.pattern?.case) {
    case 'week':
      return (
        <PlannerRepeatItemWeekPatternOptions
          pattern={viewModel.pattern}
          onChange={(pattern) => (viewModel.pattern = pattern)}
        />
      );

    case 'cycleDay':
      return (
        <PlannerRepeatItemCycleDayPatternOptions
          scheduleCycle={viewModel.scheduleCycle}
          pattern={viewModel.pattern}
          onChange={(pattern) => (viewModel.pattern = pattern)}
        />
      );

    default:
      return null;
  }
}
