import { useServices } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { isSxArray } from '../../../utils';
import { ErrorIndicator } from '../ErrorIndicator';

export interface UpdatablePresenterErrorProps {
  error: Error;
  className?: string;
  sx?: SxProps;

  /**
   * The indicator size. Optional. Default is `normal`.
   */
  size?: 'small' | 'normal';

  /**
   * The error message. Optional.
   * @param error The error from which to get the message.
   * @return The error message or undefined.
   */
  errorMessageSelector?: (error: Error) => string | undefined;

  /**
   * The component to display when an error occurs. Optional. `ErrorIndicator` will be used if not specified.
   * @param message The error message. Optional.
   */
  customErrorComponent?: (message?: string) => ReactNode;

  /**
   * Action triggered when the retry button is clicked. If using a custom component, simply pass an empty action.
   */
  reloadData: () => void;
}

export const UpdatablePresenterError = observer(
  ({
    customErrorComponent,
    errorMessageSelector,
    error,
    className,
    sx = [],
    reloadData
  }: UpdatablePresenterErrorProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const errorMessage = errorMessageSelector?.(error);
    if (customErrorComponent != null) {
      return customErrorComponent?.(errorMessage);
    }

    return (
      <Stack
        className={className}
        direction="column"
        spacing={2}
        sx={[
          {
            alignItems: 'center',
            justifyContent: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <ErrorIndicator
          message={errorMessage}
          color="textSecondary"
          action={{ title: strings.errorRetryLabel, onClick: () => reloadData() }}
        />
      </Stack>
    );
  }
);
