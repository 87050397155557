import { getOptionalDashConcatenation } from '@/models';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { PlannerItemsViewModel } from '@/viewmodels';
import { FilterListRounded } from '@mui/icons-material';
import { Badge, Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../lists';
import { SearchBar } from '../../utils';
import { PlannerItemsFiltersPopover } from './PlannerItemsFiltersPopover';

export interface PlannerItemsHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerItemsViewModel;
}

export const PlannerItemsHeader = observer(({ sx = [], className, viewModel }: PlannerItemsHeaderProps) => {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = LocalizedStrings.planner.items;

  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLButtonElement | undefined>();
  const filtersBadgeCount = Number(viewModel.minimumDate != null) + Number(viewModel.maximumDate != null);

  function onFilterButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setFilterMenuAnchor(e.currentTarget);
  }

  return (
    <Box sx={sx} className={className}>
      <Stack spacing={isExtraSmallScreen ? 2 : 1}>
        <Stack
          direction={isExtraSmallScreen ? 'column' : 'row'}
          spacing={1}
          sx={{
            alignItems: !isExtraSmallScreen ? 'flex-start' : undefined
          }}
        >
          <Stack
            spacing={1}
            direction="column"
            sx={{
              flex: 1
            }}
          >
            <Stack
              spacing={1}
              direction={isExtraSmallScreen ? 'row' : 'column'}
              sx={{
                alignItems: isExtraSmallScreen ? 'center' : 'flex-start'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {viewModel.courseSection != null ? (
                  <>
                    <ColorIndicator color={viewModel.courseSection.courseSection!.color} sx={{ mr: 1 }} />

                    <Stack>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {viewModel.courseSection.courseSection!.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.text.secondary
                        }}
                      >
                        {getOptionalDashConcatenation(
                          viewModel.courseSection.courseSection!.section,
                          viewModel.courseSection.courseSection?.externalSource?.sourceName != null
                            ? strings.externalSourceName(
                                localizedExternalSourceName(
                                  viewModel.courseSection.courseSection.externalSource.sourceName
                                )
                              )
                            : undefined
                        )}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Typography
                    variant={isExtraSmallScreen ? 'body1' : 'h6'}
                    sx={{
                      fontWeight: isExtraSmallScreen ? 500 : undefined
                    }}
                  >
                    {strings.allItemsTitle()}
                  </Typography>
                )}
              </Box>

              {isExtraSmallScreen && (
                <IconButton onClick={onFilterButtonPressed}>
                  <Badge badgeContent={filtersBadgeCount} showZero={false} color="primary">
                    <FilterListRounded />
                  </Badge>
                </IconButton>
              )}
            </Stack>
          </Stack>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              alignItems: 'center'
            }}
          >
            {!isExtraSmallScreen && (
              <IconButton onClick={onFilterButtonPressed}>
                <Badge badgeContent={filtersBadgeCount} showZero={false} color="primary">
                  <FilterListRounded />
                </Badge>
              </IconButton>
            )}

            <SearchBar sx={{ flex: 1 }} onSearch={(value) => (viewModel.searchText = value ?? '')} />
          </Stack>
        </Stack>
      </Stack>

      <PlannerItemsFiltersPopover
        open={filterMenuAnchor != null}
        possibleKinds={viewModel.possibleFilterKinds}
        selectedKinds={viewModel.currentFilterKinds}
        onKindSelection={(kind) => viewModel.toggleFilterKind(kind)}
        anchorEl={filterMenuAnchor}
        close={() => setFilterMenuAnchor(undefined)}
        minimumDate={viewModel.minimumDate}
        maximumDate={viewModel.maximumDate}
        showPastItems={viewModel.showPastItems}
        showNoDateItems={viewModel.showNoDateItems}
        onMinimumDateValueChanged={(v) => (viewModel.minimumDate = v)}
        onMaximumDateValueChanged={(v) => (viewModel.maximumDate = v)}
        onShowPastItemsValueChanged={(v) => (viewModel.showPastItems = v)}
        onShowNoDateItemsValueChanged={(v) => (viewModel.showNoDateItems = v)}
      />
    </Box>
  );
});
