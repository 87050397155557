import {
  AssignmentRounded,
  CalendarMonthRounded,
  CalendarViewWeekRounded,
  EditRounded,
  PersonRounded,
  QueryStatsRounded,
  StarRounded,
  UpcomingRounded,
  VisibilityOffRounded
} from '@mui/icons-material';
import ParticipationRequestsIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import { Divider, IconButton, ListItemButton, ListItemText, Paper, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { RouteTemplates } from '../../../RouteTemplates';
import { useActivePlannerId, useBackButtonListener, useNavigateAsync, useServices, useViewModel } from '../../../hooks';
import { SideBar, SideBarButton, SideBarSection, StudentPlannerPickerDialog } from '../../shared';
import { StudentEmotionalSurveyCard } from '../../student';
import { PlannerParticipationRequestsDialog } from '../management';
import { MainScreenPlannerSideBarCourse } from './MainScreenPlannerSideBarCourse';
import { MainScreenPlannerSideBarDemoCoursesVisibilityButton } from './MainScreenPlannerSideBarDemoCoursesVisibilityButton';
import { MainScreenPlannerSideBarSchoolCourse } from './MainScreenPlannerSideBarSchoolCourse';

interface LocationState {
  mainScreenPlannerSideBarShowParticipationRequests?: boolean;
  mainScreenPlannerSideBarShowStudentPicker?: boolean;
}

export interface MainScreenPlannerSideBarProps {
  sx?: SxProps;
  className?: string;
  forceFullScreenWidth?: boolean;
  onClose?: () => void;
  id: string;
}

export const MainScreenPlannerSideBar = observer(
  ({ sx = [], className, forceFullScreenWidth, onClose, id }: MainScreenPlannerSideBarProps) => {
    const { featureFlag, route } = useServices();
    const plannerId = useActivePlannerId();
    const viewModel = useViewModel((viewModels) => viewModels.createPlannerSideBarViewModel(plannerId), [plannerId]);

    const [emotionalSurveyPopoverRef, setEmotionalSurveyPopoverRef] = useState<HTMLDivElement | null>(null);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigateAsync();
    const strings = LocalizedStrings.sidebar;

    function onButtonSelect(action: (() => void) | undefined) {
      onClose?.();
      action?.();
    }

    function showPendingRequests() {
      const newState: LocationState = { ...state, mainScreenPlannerSideBarShowParticipationRequests: true };
      void navigate(location, { state: newState });
    }

    function showStudentPicker() {
      onClose?.();
      const newState: LocationState = { ...state, mainScreenPlannerSideBarShowStudentPicker: true };
      void navigate(location, { state: newState });
    }

    useBackButtonListener((e) => {
      if (emotionalSurveyPopoverRef != null) {
        e.stopPropagation();
        setEmotionalSurveyPopoverRef(null);
      }
    });

    return (
      <>
        <SideBar
          sx={sx}
          className={className}
          id={id}
          forceFullScreenWidth={forceFullScreenWidth}
          viewModel={viewModel}
          onClose={onClose}
          showSettings={viewModel.showSettingsButton}
          scrollableItems={(isCompact) => (
            <>
              <SideBarSection isCompact={isCompact} itemsSpacing={isCompact ? 0.5 : undefined}>
                <SideBarButton
                  isCompact={isCompact}
                  onSelect={() => onButtonSelect(undefined)}
                  title={strings.shared.today()}
                  icon={<StarRounded />}
                  link={{
                    to: `${route.resolvePlannerLocation(plannerId)}/home`,
                    mathPattern: { path: '/p/:plannerId/home', end: true }
                  }}
                />

                <SideBarButton
                  isCompact={isCompact}
                  onSelect={() => onButtonSelect(undefined)}
                  title={strings.shared.week()}
                  icon={<CalendarViewWeekRounded />}
                  link={{
                    to: `${route.resolvePlannerLocation(plannerId)}/week`,
                    mathPattern: { path: '/p/:plannerId/week', end: true }
                  }}
                />

                <SideBarButton
                  isCompact={isCompact}
                  onSelect={() => onButtonSelect(undefined)}
                  title={strings.shared.month()}
                  icon={<CalendarMonthRounded />}
                  link={{
                    to: `${route.resolvePlannerLocation(plannerId)}/month`,
                    mathPattern: { path: '/p/:plannerId/month', end: true }
                  }}
                />

                <SideBarButton
                  isCompact={isCompact}
                  onSelect={() => onButtonSelect(undefined)}
                  title={strings.planner.planWork()}
                  icon={<UpcomingRounded />}
                  badge={
                    viewModel.inboxWorkCount > 0
                      ? { color: 'primary', label: viewModel.inboxWorkCount.toString() }
                      : undefined
                  }
                  link={{
                    to: `${route.resolvePlannerLocation(plannerId)}/plan`,
                    mathPattern: { path: '/p/:plannerId/plan', end: true }
                  }}
                />

                <SideBarButton
                  isCompact={isCompact}
                  onSelect={() => onButtonSelect(undefined)}
                  title={strings.planner.items()}
                  icon={<AssignmentRounded />}
                  link={{
                    to: route.resolvePlannerItemsLocation(plannerId),
                    mathPattern: { path: RouteTemplates.plannerItems, end: true }
                  }}
                />

                {featureFlag.isEnabled('workload-manager') && viewModel.hasTaughtSections && (
                  <SideBarButton
                    isCompact={isCompact}
                    onSelect={() => onButtonSelect(undefined)}
                    title={strings.planner.workload()}
                    icon={<QueryStatsRounded />}
                    link={{
                      to: route.resolvePlannerWorkloadLocation(plannerId),
                      mathPattern: { path: RouteTemplates.plannerWorkload, end: true }
                    }}
                  />
                )}
              </SideBarSection>

              {viewModel.courseSectionsSections.map((section, index) =>
                // Hiding empty sections when in compact mode
                !isCompact || section.courseSections.length > 0 ? (
                  <Fragment key={`planner-course-sections-${index}`}>
                    {isCompact && <Divider />}

                    <SideBarSection
                      title={!isCompact ? section.title : undefined}
                      isCompact={isCompact}
                      action={
                        section.isDemoClasses
                          ? {
                              icon: (sx) => <VisibilityOffRounded sx={sx} />,
                              onClick: () => void viewModel.toggleDemoCoursesVisibility(),
                              tooltip: strings.school.hideDemoCoursesTooltip()
                            }
                          : undefined
                      }
                    >
                      {section.courseSections.map((course) =>
                        course.kind === 'school' ? (
                          <MainScreenPlannerSideBarSchoolCourse
                            key={course.id}
                            course={course}
                            isCompact={isCompact}
                            close={() => onButtonSelect(undefined)}
                          />
                        ) : (
                          <MainScreenPlannerSideBarCourse
                            key={course.id}
                            course={course}
                            isCompact={isCompact}
                            close={() => onButtonSelect(undefined)}
                          />
                        )
                      )}

                      {section.courseSections.length === 0 && (
                        <Stack direction="row" mx={0.5} spacing={0.5} alignItems="center">
                          <Typography variant="caption" color="textSecondary">
                            {strings.planner.noVisibleClasses()}
                          </Typography>

                          <Link to={route.resolvePlannerSettingsLocation('courses', plannerId)}>
                            <IconButton size="small">
                              <EditRounded fontSize="small" />
                            </IconButton>
                          </Link>
                        </Stack>
                      )}
                    </SideBarSection>
                  </Fragment>
                ) : null
              )}
            </>
          )}
          staticItems={(isCompact) => (
            <Stack spacing={1}>
              {viewModel.showEmotionalSurveyReminder &&
                (isCompact ? (
                  <ListItemButton
                    dense
                    sx={{ borderRadius: 1 }}
                    onClick={(e) => setEmotionalSurveyPopoverRef(e.currentTarget)}
                  >
                    <ListItemText>{strings.planner.emotionalSurveyOptionCompactTitle()}</ListItemText>
                  </ListItemButton>
                ) : (
                  <Paper elevation={1}>
                    <StudentEmotionalSurveyCard canDismiss />
                  </Paper>
                ))}

              {viewModel.options.length > 0 && (
                <Stack spacing={0.5}>
                  {viewModel.options.map((option) => {
                    switch (option) {
                      case 'pending-requests':
                        return (
                          <SideBarButton
                            key={option}
                            isCompact={isCompact}
                            onSelect={showPendingRequests}
                            title={strings.planner.pendingTeacherRequestsTitle()}
                            icon={<ParticipationRequestsIcon />}
                            tooltip={strings.planner.pendingTeacherRequestsTooltip(
                              viewModel.pendingParticipationRequestsCount
                            )}
                            badge={{ color: 'warning', label: `${viewModel.pendingParticipationRequestsCount}` }}
                          />
                        );

                      case 'open-student-planner':
                        return (
                          <SideBarButton
                            key={option}
                            isCompact={isCompact}
                            onSelect={showStudentPicker}
                            title={strings.school.openStudentPlannerTitle()}
                            icon={<PersonRounded />}
                            tooltip={strings.school.openStudentPlannerTooltip()}
                          />
                        );

                      case 'hide-demo-class':
                      case 'show-demo-class':
                        return (
                          <MainScreenPlannerSideBarDemoCoursesVisibilityButton
                            key={option}
                            isCompact={isCompact}
                            demoCoursesAreVisible={option === 'hide-demo-class'}
                            toggleDemoCoursesVisibility={() => viewModel.toggleDemoCoursesVisibility()}
                          />
                        );
                    }
                  })}
                </Stack>
              )}
            </Stack>
          )}
        />

        {(state.mainScreenPlannerSideBarShowParticipationRequests ?? false) && (
          <PlannerParticipationRequestsDialog isOpen={true} plannerId={plannerId} />
        )}

        {state.mainScreenPlannerSideBarShowStudentPicker && <StudentPlannerPickerDialog isOpen={true} />}

        <Popover
          open={emotionalSurveyPopoverRef != null}
          anchorEl={emotionalSurveyPopoverRef}
          slotProps={{ paper: { sx: { width: 240 } } }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          onClose={() => setEmotionalSurveyPopoverRef(null)}
        >
          <StudentEmotionalSurveyCard canDismiss close={() => setEmotionalSurveyPopoverRef(null)} />
        </Popover>
      </>
    );
  }
);
