import { useNavigateAsync, useServices } from '@/hooks';
import { isSxArray } from '@/utils';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { ArrowBackRounded, AssignmentRounded, PersonRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { PlannerWorkloadCourseSectionDetailsPlannerDayHeader } from './PlannerWorkloadCourseSectionDetailsPlannerDayHeader';
import { PlannerWorkloadCourseSectionsTableSectionInfo } from './PlannerWorkloadCourseSectionsTableSectionInfo';
import { PlannerWorkloadCourseSectionStudentsList } from './PlannerWorkloadCourseSectionStudentsList';
import { PlannerWorkloadDateList } from './PlannerWorkloadDateList';
import { PlannerWorkloadItemGroupsList } from './PlannerWorkloadItemGroupsList';
import { PlannerWorkloadPublishedWorkDetailsDialog } from './PlannerWorkloadPublishedWorkDetailsDialog';
import { PlannerWorkloadStudentDetails } from './PlannerWorkloadStudentDetails';
import { PlannerWorkloadUnknownSection } from './PlannerWorkloadUnknownSection';

type TabKind = 'items' | 'students';

export interface PlannerWorkloadCourseSectionDetailsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
  sectionId: string;
  date: Date;
  goBackToSummary: () => void;
  setDate: (date: Date | undefined) => void;
}

export const PlannerWorkloadCourseSectionDetails = observer(
  ({
    sx = [],
    className,
    viewModel,
    sectionId,
    date,
    goBackToSummary,
    setDate
  }: PlannerWorkloadCourseSectionDetailsProps) => {
    const { navigation, pasteboard } = useServices();
    const [showOnlyOccurrences, setShowOnlyOccurrences] = useState(false);
    const strings = LocalizedStrings.planner.workload;
    const [searchParams, setSearchParams] = useSearchParams();
    const tab: TabKind = (searchParams.get('tab') as TabKind) || 'items';
    const studentId = searchParams.get('studentId');
    const workId = searchParams.get('workloadWorkId');

    const location = useLocation();
    const navigate = useNavigateAsync();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const courseSection = useMemo(
      () => viewModel.allClasses.find((c) => c.courseSection!.id === sectionId),
      [viewModel.allClasses, sectionId]
    );

    function didSelectTab(tab: TabKind) {
      searchParams.set('tab', tab);
      searchParams.delete('studentId');
      setSearchParams(searchParams);
    }

    const selectedItem = useMemo(() => {
      if (workId == null || courseSection?.schoolsCourseSection == null) {
        return undefined;
      }
      return viewModel.getPublishedWorkInfo(workId, courseSection.schoolsCourseSection.id, date);
    }, [workId, viewModel.isFetchingData, date, courseSection]);

    function clearWorkId() {
      searchParams.delete('workloadWorkId');
      setSearchParams(searchParams);
    }

    if (courseSection?.schoolsCourseSection == null) {
      return <PlannerWorkloadUnknownSection sx={{ p: 2 }} />;
    }

    const schoolCourseSectionId = courseSection.schoolsCourseSection.id;

    function onCreatePublishedWork() {
      const firstOccurrence = viewModel.getFirstOccurrenceForCourseSectionOnDay(date, sectionId);

      navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
        dueDate: firstOccurrence?.startTime ?? set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        isDueAllDay: firstOccurrence == null,
        courseSectionId: sectionId
      });
    }

    function onPaste() {
      const firstOccurrence = viewModel.getFirstOccurrenceForCourseSectionOnDay(date, sectionId);
      pasteboard.setPasteContext({ date: firstOccurrence?.startTime ?? date, courseSectionId: sectionId });
    }

    return (
      <Grid2 container sx={[{ pt: 1 }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
        <Grid2 size={{ xs: 0, sm: 3 }} sx={{ height: '100%', overflow: 'hidden' }}>
          <Stack spacing={0} sx={{ width: '100%', height: '100%', overflow: 'hidden' }} direction="row">
            <Stack sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
              <Stack sx={{ flex: 1, height: '100%', overflow: 'hidden' }} spacing={1}>
                <Link
                  underline="hover"
                  onClick={goBackToSummary}
                  variant="subtitle2"
                  sx={{
                    px: 2,
                    color: (theme) => theme.palette.text.secondary,
                    cursor: 'pointer'
                  }}
                >
                  ← {strings.goBackToAllClasses()}
                </Link>

                <PlannerWorkloadCourseSectionsTableSectionInfo
                  sx={{ px: 2 }}
                  courseSection={courseSection}
                  size="medium"
                />

                <PlannerWorkloadDateList
                  date={date}
                  courseSection={courseSection}
                  viewModel={viewModel}
                  setDate={setDate}
                  showOnlyOccurrences={showOnlyOccurrences}
                  sx={{ flex: 1 }}
                />
              </Stack>

              <Stack>
                <Divider />
                <FormControl size="small" sx={{ px: 2, py: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showOnlyOccurrences}
                        onChange={(e) => setShowOnlyOccurrences(e.currentTarget.checked)}
                      />
                    }
                    label={strings.onlyShowDatesWithOccurrence()}
                    slotProps={{
                      typography: { variant: 'subtitle2', color: 'textSecondary' }
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>

            <Divider orientation="vertical" />
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 9 }} sx={{ height: '100%', overflow: 'hidden' }}>
          <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }} spacing={1}>
            {isExtraSmallScreen && (
              <Stack direction="row" spacing={1} sx={{ px: 1 }}>
                <Box sx={{ alignSelf: 'center' }}>
                  <IconButton size="small" onClick={goBackToSummary}>
                    <ArrowBackRounded fontSize="small" />
                  </IconButton>
                </Box>

                <PlannerWorkloadCourseSectionsTableSectionInfo
                  sx={{ flex: 1 }}
                  courseSection={courseSection}
                  size="small"
                />
              </Stack>
            )}

            <Tabs
              value={tab}
              onChange={(_, v: TabKind) => didSelectTab(v)}
              variant="fullWidth"
              sx={{ minHeight: '0px !important' }}
            >
              <Tab
                sx={{ minHeight: '0px !important' }}
                value="items"
                label={strings.itemsTab()}
                icon={<AssignmentRounded />}
                iconPosition="start"
              />
              <Tab
                sx={{ minHeight: '0px !important' }}
                value="students"
                label={strings.studentsTab()}
                icon={<PersonRounded />}
                iconPosition="start"
              />
            </Tabs>

            <PlannerWorkloadCourseSectionDetailsPlannerDayHeader
              sx={{ px: 2, pt: 1 }}
              date={date}
              getPlannerDay={(d) => viewModel.getPlannerDay(d)}
            />

            <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
              {tab === 'items' ? (
                <PlannerWorkloadItemGroupsList
                  sx={{ p: 1, flex: 1 }}
                  getGroups={(date) => viewModel.getCourseSectionWorkloadGroupsForDate(schoolCourseSectionId, date)}
                  date={date}
                  onCreatePublishedWork={onCreatePublishedWork}
                  onPaste={onPaste}
                />
              ) : studentId != null ? (
                <PlannerWorkloadStudentDetails
                  sx={{ flex: 1, py: 1 }}
                  studentId={studentId}
                  schoolCourseSectionId={schoolCourseSectionId}
                  date={date}
                  viewModel={viewModel}
                />
              ) : (
                <PlannerWorkloadCourseSectionStudentsList
                  sx={{ py: 1, maxWidth: 450, flex: 1 }}
                  schoolCourseSectionId={schoolCourseSectionId}
                  date={date}
                  viewModel={viewModel}
                />
              )}

              {selectedItem != null && (
                <PlannerWorkloadPublishedWorkDetailsDialog isOpen onClose={clearWorkId} item={selectedItem} />
              )}
            </Box>
          </Stack>
        </Grid2>
      </Grid2>
    );
  }
);
