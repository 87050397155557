import { AccessRightSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_right_pb';
import { AttachmentSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/attachment_pb';
import { CalendarEventSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { CalendarSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_pb';
import { CourseSectionDetailsSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionOccurrenceSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { CourseSectionSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { CustomWorkActionSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_work_action_pb';
import { ExternalSourceSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/external_source_pb';
import { NoteDuplicationDetailsSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_duplication_details_pb';
import { NoteSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { ParticipationRequestSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_pb';
import { PlannedWorkSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { PlannerDayAnnotationSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_annotation_pb';
import { PlannerDaySchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItemSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { PlannerListGroupSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_group_pb';
import { PlannerListSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_pb';
import { PlannerSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipDetailsSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_details_pb';
import { PlannerRelationshipSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_pb';
import { PlannerSharingInvitationSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_sharing_invitation_pb';
import { PublishedWorkDetailsSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/published_work_details_pb';
import { RichTextSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { SchoolInformationSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { TimeSlotGroupSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/time_slot_group_pb';
import { UserProfileSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/user_profile_pb';
import { UserSummarySchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/user_summary_pb';
import { WorkDuplicationDetailsSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_duplication_details_pb';
import { WorkIconSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { WorkSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStepSchema } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createPlannerAccessRight(init?: MessageInitShape<typeof AccessRightSchema>) {
  return create(AccessRightSchema, init);
}

export function createPlannerAttachment(init?: MessageInitShape<typeof AttachmentSchema>) {
  return create(AttachmentSchema, init);
}

export function createPlannerCalendar(init?: MessageInitShape<typeof CalendarSchema>) {
  return create(CalendarSchema, init);
}

export function createCalendarEvent(init?: MessageInitShape<typeof CalendarEventSchema>) {
  return create(CalendarEventSchema, init);
}

export function createPlannerCourseSection(init?: MessageInitShape<typeof CourseSectionSchema>) {
  return create(CourseSectionSchema, init);
}

export function createCourseSectionDetails(init?: MessageInitShape<typeof CourseSectionDetailsSchema>) {
  return create(CourseSectionDetailsSchema, init);
}

export function createCourseSectionOccurrence(init?: MessageInitShape<typeof CourseSectionOccurrenceSchema>) {
  return create(CourseSectionOccurrenceSchema, init);
}

export function createCustomWorkAction(init?: MessageInitShape<typeof CustomWorkActionSchema>) {
  return create(CustomWorkActionSchema, init);
}

export function createPlannerExternalSource(init?: MessageInitShape<typeof ExternalSourceSchema>) {
  return create(ExternalSourceSchema, init);
}

export function createNote(init?: MessageInitShape<typeof NoteSchema>) {
  return create(NoteSchema, init);
}

export function createNoteDuplicationDetails(init?: MessageInitShape<typeof NoteDuplicationDetailsSchema>) {
  return create(NoteDuplicationDetailsSchema, init);
}

export function createParticipationRequest(init?: MessageInitShape<typeof ParticipationRequestSchema>) {
  return create(ParticipationRequestSchema, init);
}

export function createPlannedWork(init?: MessageInitShape<typeof PlannedWorkSchema>) {
  return create(PlannedWorkSchema, init);
}

export function createPlanner(init?: MessageInitShape<typeof PlannerSchema>) {
  return create(PlannerSchema, init);
}

export function createPlannerDay(init?: MessageInitShape<typeof PlannerDaySchema>) {
  return create(PlannerDaySchema, init);
}

export function createPlannerDayAnnotation(init?: MessageInitShape<typeof PlannerDayAnnotationSchema>) {
  return create(PlannerDayAnnotationSchema, init);
}
export function createPlannerItem(init?: MessageInitShape<typeof PlannerItemSchema>) {
  return create(PlannerItemSchema, init);
}

export function createPlannerList(init?: MessageInitShape<typeof PlannerListSchema>) {
  return create(PlannerListSchema, init);
}

export function createPlannerListGroup(init?: MessageInitShape<typeof PlannerListGroupSchema>) {
  return create(PlannerListGroupSchema, init);
}

export function createPlannerRelationship(init?: MessageInitShape<typeof PlannerRelationshipSchema>) {
  return create(PlannerRelationshipSchema, init);
}

export function createPlannerRelationshipDetails(init?: MessageInitShape<typeof PlannerRelationshipDetailsSchema>) {
  return create(PlannerRelationshipDetailsSchema, init);
}

export function createPlannerSharingInvitation(init?: MessageInitShape<typeof PlannerSharingInvitationSchema>) {
  return create(PlannerSharingInvitationSchema, init);
}

export function createPlannerPublishedWorkDetails(init?: MessageInitShape<typeof PublishedWorkDetailsSchema>) {
  return create(PublishedWorkDetailsSchema, init);
}

export function createPlannerRichText(init?: MessageInitShape<typeof RichTextSchema>) {
  return create(RichTextSchema, init);
}

export function createPlannerSchoolInformation(init?: MessageInitShape<typeof SchoolInformationSchema>) {
  return create(SchoolInformationSchema, init);
}

export function createTimeSlotGroup(init?: MessageInitShape<typeof TimeSlotGroupSchema>) {
  return create(TimeSlotGroupSchema, init);
}

export function createPlannerUserProfile(init?: MessageInitShape<typeof UserProfileSchema>) {
  return create(UserProfileSchema, init);
}

export function createUserSummary(init?: MessageInitShape<typeof UserSummarySchema>) {
  return create(UserSummarySchema, init);
}

export function createWork(init?: MessageInitShape<typeof WorkSchema>) {
  return create(WorkSchema, init);
}

export function createWorkDuplicationDetails(init?: MessageInitShape<typeof WorkDuplicationDetailsSchema>) {
  return create(WorkDuplicationDetailsSchema, init);
}

export function createWorkIcon(init?: MessageInitShape<typeof WorkIconSchema>) {
  return create(WorkIconSchema, init);
}

export function createWorkStep(init?: MessageInitShape<typeof WorkStepSchema>) {
  return create(WorkStepSchema, init);
}
