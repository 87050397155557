import {
  CalendarDayOptions,
  CalendarFilters,
  CalendarWeekDensity,
  DefaultCalendarMonthFilters,
  DefaultCalendarWeekFilters,
  Picture
} from '@/models';
import { computed, makeObservable } from 'mobx';
import { ApplicationSettingsService, ApplicationSettingsStorage } from '../contracts';

// The application settings service sit atop all other services and uses the settings storage,
// as the default value of some settings can depend on others.
export class AppApplicationSettingsService implements ApplicationSettingsService {
  constructor(private readonly _settingsStorage: ApplicationSettingsStorage) {
    makeObservable(this);
  }

  @computed
  get backgroundImage(): Picture | undefined {
    return this._settingsStorage.backgroundImage;
  }

  set backgroundImage(value: Picture | undefined) {
    this._settingsStorage.backgroundImage = value;
  }

  @computed
  get reduceTransparency(): boolean {
    return this._settingsStorage.reduceTransparency ?? false;
  }

  set reduceTransparency(value: boolean) {
    this._settingsStorage.reduceTransparency = value;
  }

  @computed
  get useCustomPrimaryColor(): boolean {
    return this._settingsStorage.useCustomPrimaryColor ?? false;
  }

  set useCustomPrimaryColor(value: boolean) {
    this._settingsStorage.useCustomPrimaryColor = value;
  }

  @computed
  get customPrimaryColor(): string | undefined {
    return this._settingsStorage.customPrimaryColor;
  }

  set customPrimaryColor(value: string | undefined) {
    this._settingsStorage.customPrimaryColor = value;
  }

  @computed
  get isSchoolSidebarCollapsed(): boolean {
    return this._settingsStorage.isSidebarCollapsed ?? false;
  }

  set isSchoolSidebarCollapsed(value: boolean) {
    this._settingsStorage.isSidebarCollapsed = value;
  }

  @computed
  get calendarDayOptions(): CalendarDayOptions {
    return (
      this._settingsStorage.calendarDayOptions ?? {
        expandWorkDetails: true,
        alwaysShowCurrentDatePastItems: false
      }
    );
  }

  set calendarDayOptions(value: CalendarDayOptions) {
    this._settingsStorage.calendarDayOptions = value;
  }

  @computed
  get calendarWeekFilters(): CalendarFilters {
    return this._settingsStorage.calendarWeekFilters ?? DefaultCalendarWeekFilters;
  }

  set calendarWeekFilters(value: CalendarFilters) {
    this._settingsStorage.calendarWeekFilters = value;
  }

  @computed
  get calendarWeekDensity(): CalendarWeekDensity {
    return this._settingsStorage.calendarWeekDensity ?? 'regular';
  }

  set calendarWeekDensity(value: CalendarWeekDensity) {
    this._settingsStorage.calendarWeekDensity = value;
  }

  @computed
  get calendarWeekShowWeekends(): boolean {
    return this._settingsStorage.calendarWeekShowWeekends ?? true;
  }

  set calendarWeekShowWeekends(value: boolean) {
    this._settingsStorage.calendarWeekShowWeekends = value;
  }

  @computed
  get calendarMonthFilters(): CalendarFilters {
    return this._settingsStorage.calendarMonthFilters ?? DefaultCalendarMonthFilters;
  }

  set calendarMonthFilters(value: CalendarFilters) {
    this._settingsStorage.calendarMonthFilters = value;
  }

  @computed
  get calendarShowPeriodLabels(): boolean {
    return this._settingsStorage.calendarShowPeriodLabels ?? true;
  }

  set calendarShowPeriodLabels(value: boolean) {
    this._settingsStorage.calendarShowPeriodLabels = value;
  }

  @computed
  get calendarShowFreePeriods(): boolean {
    return this._settingsStorage.calendarShowFreePeriods ?? true;
  }

  set calendarShowFreePeriods(value: boolean) {
    this._settingsStorage.calendarShowFreePeriods = value;
  }

  @computed
  get plannerWorkloadShowWeekends(): boolean {
    return this._settingsStorage.plannerWorkloadShowWeekends ?? true;
  }

  set plannerWorkloadShowWeekends(value: boolean) {
    this._settingsStorage.plannerWorkloadShowWeekends = value;
  }
}
