import { useServices } from '@/hooks';
import { isSxArray } from '@/utils';
import { AdminWorkloadViewModel } from '@/viewmodels';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { alpha, IconButton, List, Stack, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  addDays,
  differenceInCalendarDays,
  endOfWeek,
  isSameDay,
  isWithinInterval,
  startOfWeek,
  subDays
} from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { AdminWorkloadDateListItem } from './AdminWorkloadDateListItem';
import { useRegisterAdminWorkloadForceReload } from './AdminWorkloadForceReloadContext';

export interface AdminWorkloadDateListProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminWorkloadViewModel;
  date: Date;
  setDate: (date: Date | undefined) => void;
}

export const AdminWorkloadDateList = observer(
  ({ sx = [], className, viewModel, date, setDate }: AdminWorkloadDateListProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.admin.workload;

    useRegisterAdminWorkloadForceReload(() => {
      const today = dateService.now;
      const range = computeDateRange(date, today);
      const dates = times(7).map((i) => addDays(range.start, i));
      void viewModel.loadWorkloadForDates(dates, true);
    });

    const initialDates = useMemo(() => {
      const today = dateService.now;
      const range = computeDateRange(date, today);
      return times(7).map((i) => addDays(range.start, i));
    }, []);

    useEffect(() => {
      void viewModel.loadWorkloadForDates(initialDates, true);
    }, [initialDates]);

    const [allDates, setAllDates] = useState(initialDates);

    function loadMoreBefore() {
      const baseDate = allDates[0];

      const newDates: Date[] = [];
      for (let i = 7; i > 0; i--) {
        newDates.push(subDays(baseDate, i));
      }
      setAllDates([...newDates, ...allDates]);
      void viewModel.loadWorkloadForDates(newDates, true);
    }

    function loadMoreAfter() {
      const baseDate = allDates[allDates.length - 1];
      const newDates: Date[] = [];

      for (let i = 1; i < 8; i++) {
        newDates.push(addDays(baseDate, i));
      }
      setAllDates([...allDates, ...newDates]);
      void viewModel.loadWorkloadForDates(newDates, true);
    }

    return (
      <Stack sx={[{ position: 'relative' }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
        <List sx={{ flex: 1, overflow: 'auto', py: 2 }}>
          {allDates.map((d, i) => (
            <AdminWorkloadDateListItem
              key={d.toString()}
              date={d}
              onSelect={(d) => setDate(d)}
              isSelected={isSameDay(d, date)}
              viewModel={viewModel}
              showDivider={i < allDates.length - 1}
            />
          ))}
        </List>

        <Tooltip title={strings.loadWeekBeforeTooltip()}>
          <IconButton
            size="small"
            onClick={loadMoreBefore}
            sx={[
              {
                left: theme.spacing(1),
                position: 'absolute',
                top: theme.spacing(1),
                backgroundColor: alpha(theme.palette.background.default, 0.6),
                backdropFilter: 'blur(12px)'
              }
            ]}
          >
            <ArrowUpwardRounded sx={{ color: theme.palette.text.secondary }} fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title={strings.loadWeekAfterTooltip()}>
          <IconButton
            size="small"
            onClick={loadMoreAfter}
            sx={[
              {
                left: theme.spacing(1),
                position: 'absolute',
                bottom: theme.spacing(1),
                backgroundColor: alpha(theme.palette.background.default, 0.6),
                backdropFilter: 'blur(12px)'
              }
            ]}
          >
            <ArrowDownwardRounded sx={{ color: theme.palette.text.secondary }} fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }
);

interface DateRange {
  start: Date;
  end: Date;
}

function computeDateRange(inputDate: Date, today: Date): DateRange {
  const isToday = isSameDay(inputDate, today);
  const startOfInputWeek = startOfWeek(inputDate); // Sunday
  const endOfInputWeek = endOfWeek(inputDate); // Saturday

  if (differenceInCalendarDays(inputDate, today) < 0) {
    const sixDaysAfter = addDays(inputDate, 6);
    if (differenceInCalendarDays(sixDaysAfter, today) > 0 || isToday) {
      return { start: inputDate, end: addDays(inputDate, 6) };
    }
    return { start: startOfInputWeek, end: endOfInputWeek };
  }

  if (isToday) {
    return { start: today, end: addDays(today, 6) };
  }

  // If date is in the future
  const threeDaysBefore = addDays(inputDate, -3);
  const threeDaysAfter = addDays(inputDate, 3);
  if (isWithinInterval(today, { start: threeDaysBefore, end: inputDate })) {
    return { start: today, end: addDays(today, 6) };
  }
  return { start: threeDaysBefore, end: threeDaysAfter };
}
