import { CheckRounded } from '@mui/icons-material';
import { alpha, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface PlannerPastePlannerItemOptionProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  icon?: ReactNode;
  title: string;
  onSelect: () => void;
}

export const PlannerPastePlannerItemOption = observer(
  ({ sx = [], className, icon, isSelected, title, onSelect }: PlannerPastePlannerItemOptionProps) => {
    const theme = useTheme();

    return (
      <CardActionArea
        onClick={onSelect}
        sx={{
          ...sx,
          borderRadius: 1,
          p: 1,
          color: isSelected ? theme.palette.primary.main : undefined,
          backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.06) : undefined
        }}
        className={className}
      >
        <Stack
          direction="row"
          spacing={1.5}
          sx={{
            alignItems: 'center'
          }}
        >
          {icon}

          <Typography
            variant="body2"
            sx={{
              flex: 1
            }}
          >
            {title}
          </Typography>

          {isSelected && <CheckRounded color="primary" fontSize="small" />}
        </Stack>
      </CardActionArea>
    );
  }
);
