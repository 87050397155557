import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection as PlannerCourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_details_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { Day, PlannerContents, PlannerDailyContents } from '../../models';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { GrpcTransportService, PlannerTransportService, ValidateSharingCodeResponse } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppPlannerTransportService extends AppBaseTransportService implements PlannerTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getPlanners(): Promise<Planner[]> {
    const response = await this.performReadRequest(
      'getPlanners',
      (headers) => this._grpcTransport.plannersClient.getPlanners({}, { headers }),
      { supportsDemoMode: true }
    );
    return response.planners;
  }

  async getPlanner(plannerId: string): Promise<Planner> {
    return await this.performReadRequest('getPlanner', (headers) =>
      this._grpcTransport.plannersClient.getPlanner({ plannerId }, { headers })
    );
  }

  async getDemoPlanners(): Promise<Planner[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetPlanners will already return the demo planners.
      return [];
    }

    const response = await this.performReadRequest(
      'getDemoPlanners',
      (headers) => this._grpcTransport.plannersClient.getPlanners({}, { headers }),
      { isDemoObject: true }
    );
    return response.planners;
  }

  async createPlanner(relationshipKind: PlannerRelationshipKind, title: string): Promise<Planner> {
    return await this.performWriteRequest('createPlanner', (headers) =>
      this._grpcTransport.plannersClient.createPlanner(
        {
          relationshipKind,
          title
        },
        { headers }
      )
    );
  }

  async renamePlanner(plannerId: string, title: string): Promise<Planner> {
    return await this.performWriteRequest(
      'renamePlanner',
      (headers) => this._grpcTransport.plannersClient.renamePlanner({ plannerId, title }, { headers }),
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async attachSchoolToPlanner(
    schoolId: string,
    plannerId: string
  ): Promise<{ schoolIds: string[]; ignoredSchoolIds: string[] }> {
    const response = await this.performWriteRequest('attachSchoolToPlanner', (headers) =>
      this._grpcTransport.plannersClient.attachSchoolToPlanner(
        {
          schoolId,
          plannerId
        },
        { headers }
      )
    );

    return { schoolIds: response.schoolIds, ignoredSchoolIds: response.ignoredSchoolIds };
  }

  async ignoreSchoolInPlanner(
    plannerId: string,
    schoolId: string
  ): Promise<{
    schoolIds: string[];
    ignoredSchoolIds: string[];
  }> {
    const response = await this.performWriteRequest('ignoreSchoolInPlanner', (headers) =>
      this._grpcTransport.plannersClient.ignoreSchool(
        {
          schoolId,
          plannerId
        },
        { headers }
      )
    );

    return { ...response };
  }

  async getPlannerContents(plannerId: string, timezone: string): Promise<PlannerContents> {
    const response = await this.performReadRequest(
      'getPlannerContents',
      (headers) => this._grpcTransport.plannersClient.getPlannerContents({ plannerId, timezone }, { headers }),
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return {
      inboxList: response.inboxList!,
      todayList: response.todayList!,
      upcomingList: response.upcomingList!,
      doneList: response.doneList!
    };
  }

  async getPlannerDailyContents(
    plannerId: string,
    startDay: Day,
    endDay: Day,
    includeFreePeriods: boolean,
    timezone: string
  ): Promise<PlannerDailyContents> {
    const response = await this.performReadRequest('getPlannerDailyContents', (headers) =>
      this._grpcTransport.plannersClient.getPlannerDailyContents(
        { plannerId, startingDay: startDay, endingDay: endDay, timezone, includeFreePeriods },
        { headers }
      )
    );

    return {
      days: response.days,
      scheduleCycleIds: response.scheduleCycleIds,
      moreRecentDraftScheduleCycleIds: response.moreRecentDraftScheduleCycleIds
    };
  }

  async getInboxWorkCountForPlanner(plannerId: string): Promise<number> {
    const response = await this.performReadRequest('getInboxWorkCount', (headers) =>
      this._grpcTransport.plannersClient.getInboxWorkCount(
        {
          plannerId,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    return response.inboxWorkCount;
  }

  async getCourseSectionsInPlanner(plannerId: string): Promise<PlannerCourseSection[]> {
    const response = await this.performReadRequest(
      'getCourseSectionsInPlanner',
      (headers) => this._grpcTransport.plannersClient.getCourseSections({ plannerId }, { headers }),
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );
    return response.courseSections;
  }

  async getDetailedCourseSectionsInPlanner(plannerId: string): Promise<CourseSectionDetails[]> {
    const response = await this.performReadRequest(
      'getDetailedCourseSectionsInPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.getDetailedCourseSections(
          { plannerId, timezone: this._localization.currentTimezone },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return response.courseSections;
  }

  async getDemoDetailedCourseSectionsInPlanner(plannerId: string): Promise<CourseSectionDetails[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetSchoolCourseSections will already return the demo course sections.
      return [];
    }

    const response = await this.performReadRequest(
      'getDemoDetailedCourseSectionsInPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.getDetailedCourseSections(
          { plannerId, timezone: this._localization.currentTimezone },
          { headers }
        ),
      { isDemoObject: true }
    );

    return response.courseSections;
  }

  async createCourseSectionInPlanner(
    plannerId: string,
    title: string,
    section: string,
    backgroundColor: string
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'createCourseSectionInPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.createCourseSection(
          { title, section, color: backgroundColor, plannerId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async updateCourseSectionInPlanner(
    courseId: string,
    title: string,
    section: string,
    backgroundColor: string,
    isVisible: boolean,
    syncToken: bigint
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'updateCourseSectionInPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.updateCourseSection(
          { courseSectionId: courseId, title, section, color: backgroundColor, syncToken, isVisible },
          { headers }
        ),
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async deleteCourseSectionInPlanner(courseId: string, syncToken: bigint, shouldAlsoCancelWork: boolean) {
    await this.performWriteRequest(
      'deleteCourseSectionInPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.deleteCourseSection(
          { courseSectionId: courseId, shouldAlsoCancelWork, syncToken },
          { headers }
        ),
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async changeCourseSectionVisibilityInPlanner(
    courseId: string,
    syncToken: bigint,
    isVisible: boolean
  ): Promise<PlannerCourseSection> {
    return await this.performWriteRequest(
      'changeCourseSectionVisibility',
      (headers) =>
        this._grpcTransport.plannersClient.changeCourseSectionVisibility(
          { courseSectionId: courseId, syncToken, isVisible },
          { headers }
        ),
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async ensureCustomWorksCompleted(
    plannerId: string,
    effect: CustomActionEffect,
    externalSourceName: string,
    externalId: string
  ): Promise<number> {
    const response = await this.performWriteRequest(
      'ensureCustomWorksCompleted',
      (headers) =>
        this._grpcTransport.plannersClient.ensureCustomWorkCompleted(
          { plannerId, customActionEffect: effect, externalSourceName, externalId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.affectedWorkCount;
  }

  async getPlannerRelationshipDetails(plannerId: string): Promise<PlannerRelationshipDetails[]> {
    const response = await this.performReadRequest(
      'getPlannerRelationshipDetails',
      (headers) => this._grpcTransport.plannersClient.getPlannerRelationshipDetails({ plannerId }, { headers }),
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.details;
  }

  async useSharingInvitationCode(invitationCode: string): Promise<string> {
    const response = await this.performWriteRequest('useSharingInvitationCode', (headers) =>
      this._grpcTransport.plannersClient.useSharingInvitationCode({ invitationCode }, { headers })
    );

    return response.plannerId;
  }

  async getSharingInvitationCode(
    plannerId: string,
    accessKind: AccessKind,
    plannerRelationshipKind: PlannerRelationshipKind
  ): Promise<string> {
    const response = await this.performReadRequest(
      'getSharingInvitationCode',
      (headers) =>
        this._grpcTransport.plannersClient.getSharingInvitationCode(
          { plannerId, recipientAccessKind: accessKind, recipientRelationshipKind: plannerRelationshipKind },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId) }
    );
    return response.invitationCode;
  }

  async getTeacherParticipationCode(): Promise<string> {
    const response = await this.performReadRequest('getTeacherParticipationCode', (headers) =>
      this._grpcTransport.plannersClient.getTeacherParticipationCode({}, { headers })
    );
    return response.participationCode;
  }

  async validateSharingCode(code: string): Promise<ValidateSharingCodeResponse> {
    const response = await this.performReadRequest('validateSharingCode', (headers) =>
      this._grpcTransport.plannersClient.validateSharingCode({ sharingCode: code }, { headers })
    );

    return {
      isValid: response.isValid,
      kind: response.codeKind,
      senderDisplayName: response.senderDisplayName.length == 0 ? undefined : response.senderDisplayName,
      relationshipKind: response.relationshipKind
    };
  }

  async useParticipationCodeForPlanner(code: string, plannerId: string) {
    await this.performReadRequest(
      'useParticipationCodeForPlanner',
      (headers) =>
        this._grpcTransport.plannersClient.useParticipationCode({ participationCode: code, plannerId }, { headers }),
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async requestPlannerAccess(plannerIds: string[], accessKind: AccessKind, relationshipKind: PlannerRelationshipKind) {
    await this.performWriteRequest('requestPlannerAccess', (headers) =>
      this._grpcTransport.plannersClient.requestPlannerAccess(
        {
          plannerIds,
          accessKind,
          relationshipKind
        },
        { headers }
      )
    );
  }

  async acknowledgePlannerAccessRequest(
    plannerId: string,
    acceptRequest: boolean,
    requesterUserId: string
  ): Promise<Planner> {
    return await this.performWriteRequest(
      'acknowledgePlannerAccessRequest',
      (headers) =>
        this._grpcTransport.plannersClient.acknowledgePlannerAccessRequest(
          { plannerId, acceptRequest, requesterUserId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId) }
    );
  }

  async ignoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<string[]> {
    const response = await this.performWriteRequest('ignoreScheduleCycleInPlanner', (headers) =>
      this._grpcTransport.plannersClient.ignoreScheduleCycle({ plannerId, scheduleCycleId }, { headers })
    );

    return response.ignoredScheduleCycleIds;
  }

  async restoreScheduleCycleInPlanner(plannerId: string, scheduleCycleId: string): Promise<string[]> {
    const response = await this.performWriteRequest('restoreScheduleCycleInPlanner', (headers) =>
      this._grpcTransport.plannersClient.restoreScheduleCycle({ plannerId, scheduleCycleId }, { headers })
    );

    return response.ignoredScheduleCycleIds;
  }
}
