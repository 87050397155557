import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { differenceInMinutes } from 'date-fns';
import { timestampDateOptional } from '../TimestampUtils';

export function timeSlotDuration(timeSlot: TimeSlot | undefined): number | undefined {
  const startTime = timestampDateOptional(timeSlot?.startTime);
  const endTime = timestampDateOptional(timeSlot?.endTime);

  if (startTime == null || endTime == null) {
    return undefined;
  }

  return differenceInMinutes(endTime, startTime);
}

export function isDemoId(id: string): boolean {
  return id.startsWith('demo');
}
