import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { Student } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/student_pb';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import {
  AppStudentsPlannerSummaryTableRowViewModel,
  StudentsPlannerSummaryTableRowViewModel
} from './StudentsPlannerSummaryTableRowViewModel';

export interface StudentsPlannerSummaryViewModel {
  searchText: string;
  readonly allStudents: StudentsPlannerSummaryTableRowViewModel[];
  readonly students: StudentsPlannerSummaryTableRowViewModel[];
  readonly studentsUsingAppCount: number;
  readonly totalStudentsCount: number;
}

export class AppStudentsPlannerSummaryViewModel implements StudentsPlannerSummaryViewModel {
  @observable private _searchText = '';

  constructor(
    private readonly _students: Student[],
    private readonly _courseSectionId: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    readonly studentsUsingAppCount: number,
    readonly totalStudentsCount: number
  ) {
    makeObservable(this);
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    runInAction(() => (this._searchText = value));
  }

  @computed
  get allStudents(): StudentsPlannerSummaryTableRowViewModel[] {
    return Array.from(this._students)
      .sort((s1, s2) =>
        s1.fullName.localeCompare(s2.fullName, this._localization.currentLocale, { sensitivity: 'base' })
      )
      .map(
        (student) => new AppStudentsPlannerSummaryTableRowViewModel(student, this._courseSectionId, this._localization)
      );
  }

  @computed
  get students(): StudentsPlannerSummaryTableRowViewModel[] {
    if (this.searchText.length === 0) {
      return this.allStudents;
    }

    const searchText = this.searchText.toLocaleLowerCase(this._localization.currentLocale);
    return this.allStudents.filter((s) =>
      s.name.toLocaleLowerCase(this._localization.currentLocale).includes(searchText)
    );
  }
}
