import i18n from 'translate';

export const PublishedWorkEditApplyToCopiesDialogStrings = {
  applyOnlyToThisCopyButtonTitle: () => i18n.t('publishedWork.edit.applyToCopiesDialog.applyOnlyToThisCopyButtonTitle'),
  applyOnlyToThisCopyTooltip: () => i18n.t('publishedWork.edit.applyToCopiesDialog.applyOnlyToThisCopyTooltip'),
  applyToAllButtonTitle: () => i18n.t('publishedWork.edit.applyToCopiesDialog.applyToAllButtonTitle'),
  applyToAllTooltip: () => i18n.t('publishedWork.edit.applyToCopiesDialog.applyToAllTooltip'),
  message: () => i18n.t('publishedWork.edit.applyToCopiesDialog.message'),
  title: () => i18n.t('publishedWork.edit.applyToCopiesDialog.title')
};

export const PublishedWorkEditStrings = {
  addTitle: () => i18n.t('publishedWork.edit.addTitle'),
  applyToCopiesDialog: PublishedWorkEditApplyToCopiesDialogStrings,
  availableImmediatelyOption: () => i18n.t('publishedWork.edit.availableImmediatelyOption'),
  cancelButtonTitle: () => i18n.t('publishedWork.edit.cancelButtonTitle'),
  coursePickerSectionTitle: () => i18n.t('publishedWork.edit.coursePickerSectionTitle'),
  coursePickerTitle: () => i18n.t('publishedWork.edit.coursePickerTitle'),
  createButtonTitle: () => i18n.t('publishedWork.edit.createButtonTitle'),
  dueDateDateFormat: () => i18n.t('publishedWork.edit.dueDateDateFormat'),
  dueDateDateLabel: () => i18n.t('publishedWork.edit.dueDateDateLabel'),
  dueDateErrorLabel: () => i18n.t('publishedWork.edit.dueDateErrorLabel'),
  dueDateSectionTitle: () => i18n.t('publishedWork.edit.dueDateSectionTitle'),
  dueDateTimeLabel: () => i18n.t('publishedWork.edit.dueDateTimeLabel'),
  editTitle: () => i18n.t('publishedWork.edit.editTitle'),
  fullScreenButtonTooltip: () => i18n.t('publishedWork.edit.fullScreenButtonTooltip'),
  importanceLevelTitle: () => i18n.t('publishedWork.edit.importanceLevelTitle'),
  informationSectionTitle: () => i18n.t('publishedWork.edit.informationSectionTitle'),
  instructionsLabel: () => i18n.t('publishedWork.edit.instructionsLabel'),
  instructionsSectionTitle: () => i18n.t('publishedWork.edit.instructionsSectionTitle'),
  maxGradeInputLabel: () => i18n.t('publishedWork.edit.maxGradeInputLabel'),
  publishToExternalSourceOption: (sourceName: string) =>
    i18n.t('publishedWork.edit.publishToExternalSourceOption', { sourceName }),
  publishToSectionTitle: () => i18n.t('publishedWork.edit.publishToSectionTitle'),
  reduceSizeButtonTooltip: () => i18n.t('publishedWork.edit.reduceSizeButtonTooltip'),
  saveButtonTitle: () => i18n.t('publishedWork.edit.saveButtonTitle'),
  scheduledPublishTimePickerLabel: () => i18n.t('publishedWork.edit.scheduledPublishTimePickerLabel'),
  titleLabel: () => i18n.t('publishedWork.edit.titleLabel'),
  titleSectionTitle: () => i18n.t('publishedWork.edit.titleSectionTitle'),
  workIconTitle: () => i18n.t('publishedWork.edit.workIconTitle')
};
