import { Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerListItemInfoIconKind } from '../../../../viewmodels';
import { CalendarEventIcon, ColorIndicator, WorkIcon } from '../../../lists';
import { PlannedWorkIcon } from '../../../shared';

export interface PlannerListItemAvatarProps {
  icon: PlannerListItemInfoIconKind | undefined;
  color: string | undefined;

  className?: string;

  sx?: SxProps;
}

export const PlannerListItemAvatar = observer(({ className, sx = [], icon, color }: PlannerListItemAvatarProps) => (
  <Stack sx={sx} className={className} direction="row" spacing={0.5}>
    {(icon === 'calendar-event' || icon?.case === 'work') && <ColorIndicator color={color} />}

    <Box>
      <Icon icon={icon} color={color} />
    </Box>
  </Stack>
));

interface IconProps {
  icon: PlannerListItemInfoIconKind | undefined;
  color: string | undefined;
}

const Icon = ({ icon, color }: IconProps) => {
  switch (icon) {
    case 'calendar-event':
      return <CalendarEventIcon size={32} />;
    case undefined:
      return null;
    default:
      if (icon.case === 'work') {
        return <WorkIcon icon={icon.value} size={32} />;
      } else {
        return <PlannedWorkIcon icon={icon.value} color={color} sx={{ height: 32 }} />;
      }
  }
};
