import { RouteTemplates } from '@/RouteTemplates';
import { InitializingScreen, NotFoundScreen, ServerErrorScreen } from '@/components';
import { useIsMatchPath, useServices } from '@/hooks';
import { StoredActiveDashboard } from '@/services';
import { isError } from '@/utils';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useLocation, useParams } from 'react-router';
import { PlannerRoute } from './PlannerRoute';

export const OnboardedRoute = observer(() => {
  const { featureFlag, userStore, route, settingsStorage, user, workStore } = useServices();
  const params = useParams();
  const isPlanners = useIsMatchPath({ path: 'p', end: false });
  const isAdmin = useIsMatchPath({ path: 'school-admin', end: false });
  const isSharedSchoolEnabled = featureFlag.isEnabled('shared-schools');
  const location = useLocation();

  if (!isPlanners && !isAdmin) {
    return <NotFoundScreen />;
  }

  if (user.currentUser == null) {
    return <Navigate to={RouteTemplates.login} replace state={{ referrer: location }} />;
  }

  // If we are still fetching basic info for user, we display the initializing screen on the current location.
  if (
    !userStore.plannersLoadable.hasData ||
    !userStore.participatingSchools.hasData ||
    !userStore.subscription.hasData ||
    !workStore.workIcons.hasData ||
    !userStore.getPreference('seen-curriculum-course-ids').hasData
  ) {
    return <InitializingScreen />;
  }

  if (isError(userStore.plannersLoadable.state)) {
    return <ServerErrorScreen error={userStore.plannersLoadable.state} />;
  }

  if (isError(userStore.subscription.state)) {
    return <ServerErrorScreen error={userStore.subscription.state} />;
  }

  const hasSchools = userStore.schools.length > 0;
  // Filtering out demo planners and planners where user has a teacher relationship. For the latter, a
  // teacher who only has access to its students planners should be shown the "create planner screen".
  const hasPlanners = userStore.nonDemoNorTeacherPlanners.length > 0;

  if (!hasPlanners) {
    return <Navigate to={hasSchools ? RouteTemplates.noPlanner : RouteTemplates.onboarding} replace />;
  }

  if (isAdmin && isSharedSchoolEnabled) {
    return <Outlet />;
  }

  const plannerId = params.plannerId;

  if (plannerId != null) {
    return <PlannerRoute plannerId={plannerId} />;
  }

  let defaultDashboard: StoredActiveDashboard | undefined = settingsStorage.activeDashboard;
  if (defaultDashboard != null && userStore.plannersLoadable.data.get(defaultDashboard.id) == null) {
    // Stored dashboard doesn't exist, so we are not using it.
    defaultDashboard = undefined;
  }

  if (defaultDashboard == null) {
    const defaultPlanner = userStore.getDefaultPlanner();
    if (defaultPlanner != null) {
      defaultDashboard = { id: defaultPlanner.id, kind: 'planner' };
    }
  }

  if (defaultDashboard != null && defaultDashboard.kind === 'planner') {
    // For Intercom, we need to have generic urls which don't include a planner id.
    // So if there are no id passed and the url starts with /p, we redirect to the default planner.
    // Example: /p/week -> /p/123/week
    const newPath = location.pathname.startsWith('/p/')
      ? `/p/${defaultDashboard.id}${location.pathname.slice(2)}`
      : route.resolvePlannerLocation(defaultDashboard.id);
    return <Navigate to={newPath} replace />;
  }

  // We couldn't find a dashboard to show. Redirecting to the onboarding.
  return <Navigate to={RouteTemplates.onboarding} replace />;
});
