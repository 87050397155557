import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';
import { isSxArray } from '../../utils';

export interface LoadingIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;
  /**
   * The loading message. Optional.
   */
  message?: string;
  /**
   * The size. Optional. Default is `normal`.
   */
  size?: 'tiny' | 'small' | 'normal';
  /**
   * The circular progress color. Optional. Default is `primary`.
   */
  color?: 'primary' | 'secondary' | 'inherit';
  orientation?: 'horizontal' | 'vertical';
}

export const LoadingIndicator = ({
  className,
  style,
  message,
  size = 'normal',
  color = 'primary',
  orientation = 'vertical',
  sx = []
}: LoadingIndicatorProps) => {
  const theme = useTheme();
  const isNormal = size === 'normal';
  const isTiny = size === 'tiny';

  const progressSize = isNormal ? 30 : isTiny ? 16 : 24;
  const messageVariant = isNormal ? 'subtitle1' : isTiny ? 'caption' : 'subtitle2';

  return (
    <Stack
      className={className}
      style={style}
      sx={[
        {
          alignItems: 'center',
          justifyContent: 'center'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
      direction={orientation === 'vertical' ? 'column' : 'row'}
      spacing={isNormal ? 2 : 1}
    >
      <CircularProgress color={color} size={progressSize} />

      {message != null && message.length > 0 && (
        <Box>
          <Typography
            variant={messageVariant}
            sx={{
              color: theme.palette.text.secondary,
              textAlign: 'center',
              fontWeight: isTiny ? 500 : undefined
            }}
          >
            {message}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
