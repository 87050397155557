import { css } from '@emotion/css';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CSSProperties, MouseEvent, ReactNode, useState } from 'react';

export interface EmptyListIndicatorButton {
  title: string;
  icon?: ReactNode;
  action: (e: MouseEvent<HTMLElement>) => Promise<void>;
}

export interface EmptyListIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;
  /**
   * The title. Optional.
   */
  title?: string;
  /**
   * The subtitle. Optional.
   */
  subtitle?: string;

  /**
   * The icon to display.
   * @param className The icon's className
   * @return ReactNode The icon element
   */
  renderIcon: (className: string) => ReactNode;
  /**
   * The button. Optional.
   */
  button?: EmptyListIndicatorButton;
  size?: 'small' | 'regular';
}

export const EmptyListIndicator = observer(
  ({
    className,
    style,
    sx = [],
    renderIcon,
    title,
    subtitle = '',
    button,
    size = 'regular'
  }: EmptyListIndicatorProps) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const theme = useTheme();

    async function onButtonClick(e: MouseEvent<HTMLElement>, action: (e: MouseEvent<HTMLElement>) => Promise<void>) {
      setIsProcessing(true);
      await action(e);
      setIsProcessing(false);
    }

    const iconClass = css({
      color: theme.palette.text.secondary,
      fontSize: size === 'regular' ? 60 : 40,
      maxWidth: '100%',
      height: 'auto'
    });

    const subtitleVariant = size === 'regular' ? 'body2' : 'caption';
    const subtitleClass = css({
      margin: 0,
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      textAlign: 'center',
      font: theme.typography[subtitleVariant].font,
      fontFamily: theme.typography[subtitleVariant].fontFamily,
      fontSize: theme.typography[subtitleVariant].fontSize,
      fontWeight: theme.typography[subtitleVariant].fontWeight
    });

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={className}
        style={style}
        sx={sx}
      >
        {renderIcon(iconClass)}
        <Box sx={{ mt: 2 }}>
          <Typography
            variant={size === 'regular' ? 'h6' : 'body1'}
            sx={{
              color: (theme) => theme.palette.text.secondary,
              textAlign: 'center',
              fontWeight: size === 'small' ? ' 600' : undefined
            }}
          >
            {title}
          </Typography>

          {subtitle.length > 0 && <pre className={subtitleClass}>{subtitle}</pre>}
        </Box>
        {button != null && (
          <Box
            sx={{
              marginTop: 2
            }}
          >
            <Button
              loading={isProcessing}
              variant="contained-grey"
              onClick={(e) => void onButtonClick(e, button.action)}
              startIcon={button.icon}
            >
              {button.title}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);
