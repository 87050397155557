import { Day } from '../../models';
import { DailyWorkloadResponse, SchoolTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolDailyWorkloadLoadable = LoadableValue<DailyWorkloadResponse>;

export class AppSchoolDailyWorkloadLoadable extends BaseLoadableValue<DailyWorkloadResponse> {
  constructor(
    private readonly _schoolId: string,
    private readonly _date: Day,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected loadData(): Promise<DailyWorkloadResponse> {
    // TODO: This is using the existing API which will return data only for the sections the user has access to.
    // https://linear.app/studyoco/issue/GO-1158/validate-api-for-school-workload
    return this._schoolTransport.getDailyWorkload(this._schoolId, [], this._date, this._date);
  }
}
