import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CheckRounded } from '@mui/icons-material';
import { alpha, Box, Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../../hooks';
import { CourseSectionOccurrenceInfo } from '../../../../models';
import { formatCalendarItemTimesLong } from '../../../shared';

export interface PlannerPastePlannerItemPeriodViewProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  period: CourseSectionOccurrenceInfo;
  courseSection: CourseSectionDetails | undefined;
  onSelect: () => void;
}

export const PlannerPastePlannerItemPeriodView = observer(
  ({ sx = [], className, period, courseSection, onSelect, isSelected }: PlannerPastePlannerItemPeriodViewProps) => {
    const { settings } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.planner.pastePlannerItem;

    const color = courseSection?.courseSection?.color ?? theme.palette.action.disabled;
    const courseTitle = courseSection?.courseSection?.title ?? strings.noClassLabel();

    return (
      <Card
        sx={{
          ...sx,
          border: `2px solid ${isSelected ? color : 'transparent'}`,
          backgroundColor: alpha(color, courseSection?.courseSection?.color != null ? 0.1 : 0.02),
          backgroundImage: isSelected ? 'none' : undefined,
          position: 'relative'
        }}
        className={className}
        elevation={0}
      >
        <CardActionArea onClick={onSelect} sx={{ borderRadius: 1 }}>
          <Stack direction="row" spacing={1}>
            <Box sx={{ backgroundColor: color, width: 6 }} />

            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                flex: 1,
                py: 0.5,
                pr: 2
              }}
            >
              <Stack
                sx={{
                  flex: 1
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                  {courseTitle}
                </Typography>
                <Typography variant="caption">
                  {formatCalendarItemTimesLong(period.startTime, period.endTime)}
                </Typography>
              </Stack>

              {isSelected && <CheckRounded color="primary" />}
            </Stack>
          </Stack>

          {settings.calendarShowPeriodLabels && (
            <Typography variant="subtitle2" sx={{ position: 'absolute', bottom: 2, right: theme.spacing(1) }}>
              {period.periodLabel}
            </Typography>
          )}
        </CardActionArea>
      </Card>
    );
  }
);
