import { ReactNode } from 'react';
import { Banner } from '../../utils';

export interface WorkStatusBannerProps {
  readonly isReadOnly: boolean;
  readonly actionIsEnabled: boolean;
  readonly actionTitle: string;
  readonly actionEndIcon?: ReactNode;
  readonly actionOnClick: () => void;
  readonly title: string;
  readonly kind: 'error' | 'success';
}

export const WorkStatusBanner = ({
  kind,
  title,
  actionIsEnabled,
  actionEndIcon,
  actionOnClick,
  actionTitle,
  isReadOnly
}: WorkStatusBannerProps) => {
  return (
    <Banner
      severity={kind}
      actions={
        !isReadOnly
          ? [
              {
                case: 'icon-text',
                value: { icon: actionEndIcon, text: actionTitle },
                disabled: !actionIsEnabled,
                onClick: actionOnClick
              }
            ]
          : undefined
      }
    >
      {title}
    </Banner>
  );
};
