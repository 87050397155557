import { DateService } from '@/services';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { differenceInCalendarDays, differenceInMilliseconds, isBefore, startOfDay } from 'date-fns';
import { chain, reduce } from 'lodash';
import { timestampDateOptional } from '../TimestampUtils';
import { timeSlotDuration } from './GeneralUtils';

export function workIsLate(work: Work, dateService: DateService): boolean {
  if (work.status === WorkStatus.COMPLETED || work.dueTime == null) {
    return false;
  }

  let now = dateService.now;
  if (work.isDueAllDay) {
    now = startOfDay(now);
  }

  return isBefore(timestampDate(work.dueTime), now);
}

export function numberOfDaysInInboxForWork(work: Work): number | undefined {
  const firstPlannedWork = work.plannedWorks.find((pw) => pw.createdTime != null);

  if (firstPlannedWork?.createdTime == null) {
    return undefined;
  }

  return differenceInCalendarDays(timestampDate(firstPlannedWork.createdTime), timestampDate(work.createdTime!));
}

export function totalPlannedMinutesForWork(work: Work): number | undefined {
  if (work.plannedWorks.length === 0) {
    return undefined;
  }

  return reduce(work.plannedWorks, (prev, curr) => (prev += timeSlotDuration(curr.timeSlot) ?? 0), 0);
}

export function workCompletionDate(work: Work): Date | undefined {
  return work.status === WorkStatus.COMPLETED ? timestampDateOptional(work.statusChangedTime) : undefined;
}

export function workCurrentAndUpcomingPlannedWorks(work: Work, dateService: DateService): PlannedWork[] {
  const now = dateService.now;

  return chain(work.plannedWorks)
    .orderBy((p) => timestampDate(p.timeSlot!.startTime!), 'asc')
    .filter((plannedWork) => differenceInMilliseconds(timestampDate(plannedWork.timeSlot!.endTime!), now) >= 0)
    .value();
}
