import { UserPreferenceKey } from '@/models';
import { TeacherPreferences } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_preferences_pb';
import { EmotionalPulse } from '@buf/studyo_studyo-today-sel.bufbuild_es/studyo/today/sel/v1/resources/emotional_pulse_pb';
import { Platform } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/platform_pb';
import { PreferenceValue } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/preference_value_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { AutoDetectPersonaAndCreatePlannerResponse, GrpcTransportService, UserTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppUserTransportService extends AppBaseTransportService implements UserTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async assignPersona(persona: UserPersona): Promise<UserProfile> {
    return await this.performWriteRequest('assignPersona', (headers) =>
      this._grpcTransport.usersClient.assignPersona({ persona }, { headers })
    );
  }

  async completeOnboardingProcess(schoolIds: string[] | undefined): Promise<void> {
    await this.performReadRequest(
      'completeOnboardingProcess',
      (headers) => this._grpcTransport.usersClient.completeOnboardingProcess({}, { headers }),
      schoolIds != null ? { customHeaders: { 'X-Today-School-Ids': schoolIds.join('|') } } : undefined
    );
  }

  async getCurrentEmotionalPulse(): Promise<EmotionalPulse | undefined> {
    const response = await this.performReadRequest('getCurrentEmotionalPulse', (headers) =>
      this._grpcTransport.selClient.getCurrentEmotionalPulse({}, { headers })
    );

    return response.emotionalPulse;
  }

  async setEmotionalState(rating: number): Promise<EmotionalPulse> {
    return await this.performWriteRequest('setEmotionalState', (headers) =>
      this._grpcTransport.selClient.setEmotionalState({ rating }, { headers })
    );
  }

  async getCurrentUserProfile(): Promise<UserProfile> {
    return await this.performReadRequest('getCurrentUserProfile', (headers) =>
      this._grpcTransport.usersClient.getCurrentUserProfile({}, { headers })
    );
  }

  async autoDetectPersonaAndCreatePlanner(): Promise<AutoDetectPersonaAndCreatePlannerResponse> {
    const response = await this.performWriteRequest('autoDetectPersonaAndCreatePlanner', (headers) =>
      this._grpcTransport.plannersClient.autoDetectPersonaAndCreatePlanner({}, { headers })
    );

    return {
      personaAutoDetectedAndPlannerCreated: response.personaAutoDetectedAndPlannerCreated,
      sharedParticipatingSchoolIds: response.sharedParticipatingSchoolIds
    };
  }

  async getIntercomUserHash(): Promise<string> {
    const response = await this.performReadRequest('getIntercomUserHash', (headers) =>
      this._grpcTransport.usersClient.getPlatformIntercomUserHash({ platform: Platform.WEB }, { headers })
    );
    return response.userHash;
  }

  async setCurrentUserCulture(cultureName: string): Promise<void> {
    await this.performWriteRequest('setCurrentUserCulture', (headers) =>
      this._grpcTransport.usersClient.setCurrentUserCulture(
        {
          cultureName
        },
        { headers }
      )
    );
  }

  async getTeacherPreferences(): Promise<TeacherPreferences> {
    return await this.performReadRequest('getTeacherPreferences', (headers) =>
      this._grpcTransport.schoolsClient.getTeacherPreferences({}, { headers })
    );
  }

  async updateTeacherPreferences(userId: string, showDemoCourseSection: boolean): Promise<TeacherPreferences> {
    return await this.performWriteRequest('updateTeacherPreferences', (headers) =>
      this._grpcTransport.schoolsClient.updateTeacherPreferences({ userId, showDemoCourseSection }, { headers })
    );
  }

  async getPreference(key: UserPreferenceKey): Promise<PreferenceValue | undefined> {
    const response = await this.performReadRequest('getPreference', (headers) =>
      this._grpcTransport.usersClient.getPreference({ key }, { headers })
    );
    return response.value;
  }

  async setPreference(key: UserPreferenceKey, value: PreferenceValue | undefined): Promise<void> {
    await this.performWriteRequest('setPreference', (headers) =>
      this._grpcTransport.usersClient.setPreference({ key, value }, { headers })
    );
  }

  async createProfilePictureDestination(filename: string): Promise<{ uploadUrl: string; downloadUrl: string }> {
    return await this.performWriteRequest('createProfilePictureDestination', (headers) =>
      this._grpcTransport.usersClient.createProfilePictureDestination({ filename }, { headers })
    );
  }

  async customizeCurrentUser(fullName: string, pictureUrl: string): Promise<void> {
    await this.performWriteRequest('customizeCurrentUser', (headers) =>
      this._grpcTransport.usersClient.customizeCurrentUser(
        {
          fullName,
          pictureUrl
        },
        { headers }
      )
    );
  }

  async permanentlyDeleteUserData(userId: string): Promise<void> {
    await this.performWriteRequest('permanentlyDeleteUserData', (headers) =>
      this._grpcTransport.usersClient.permanentlyDeleteUserData(
        {
          userId
        },
        { headers }
      )
    );
  }
}
