import { DateService } from '@/services';
import { format } from 'date-fns';
import { lowerFirst } from 'lodash';
import { EnglishDateStrings, FrenchDateStrings } from '../utils/DateStrings';

export interface WorkDetailsStrings {
  readonly addStepToPlannedWork: string;
  readonly attachmentsSectionTitle: string;
  readonly attachmentsShowLess: string;
  readonly attachmentsShowMore: string;
  readonly availableTimeSlotsErrorMessage: (error: string) => string;
  readonly cancelDialog: string;
  readonly cancelPlannedWork: string;
  readonly cancelWork: string;
  readonly cancelWorkConfirmationButton: string;
  readonly cancelWorkConfirmationMessage: string;
  readonly cancelWorkConfirmationTitle: string;
  readonly cancelledAlertActionTitle: string;
  readonly cancelledAlertTitle: string;
  readonly closeDialog: string;
  readonly collapseToggleLabel: string;
  readonly completeErrorMessage: (error: string) => string;
  readonly completedAlertActionTitle: string;
  readonly completedAlertTitle: (completedDate: Date | undefined) => string;
  readonly copyWork: string;
  readonly datePickerLabel: string;
  readonly defaultWorkTitle: string;
  readonly deleteWorkStepTooltip: string;
  readonly distributeWork: string;
  readonly doneButtonLabel: string;
  readonly dueDateAllDayFormat: string;
  readonly dueDateFormat: (date: Date, dateService: DateService) => string;
  readonly dueDateFullFormat: string;
  readonly dueDateSectionTitle: string;
  readonly dueDateTimeFormat: (date: Date, dateService: DateService) => string;
  readonly dueTodayAlertMessage: string;
  readonly duplicateWork: string;
  readonly durationFormat: (duration: number) => string;
  readonly durationPickerLabel: string;
  readonly editPlannedWork: string;
  readonly editWorkButtonLabel: string;
  readonly editWorkStepTooltip: string;
  readonly expandToggleLabel: string;
  readonly extraDaysButtonLabel: string;
  readonly gradeSectionTitle: string;
  readonly gradeText: (grade: number) => string;
  readonly gradedSubtitle: string;
  readonly instructionsTitle: string;
  readonly lateAlertMessage: string;
  readonly maxPointsText: (maxPoints: number) => string;
  readonly noAttachmentsMessage: string;
  readonly noCourseTitle: string;
  readonly noDescription: string;
  readonly noDueDateSubtitle: string;
  readonly noDurationMessage: string;
  readonly noExternalUrlMessage: string;
  readonly noExternalUrlTitle: string;
  readonly noFreeTimeMessage: (date: Date, duration: number, dateService: DateService) => string;
  readonly notGradedSubtitle: string;
  readonly numberOfCopies: (numberOfCopies: number) => string;
  readonly openWorkLinkButtonLabel: (externalSourceName: string) => string;
  readonly planErrorMessage: (error: string) => string;
  readonly plannedDateFormat: (date: Date, duration: number, dateService: DateService) => string;
  readonly plannedWorkActionMenuDeleteOption: string;
  readonly plannedWorkActionMenuEditOption: string;
  readonly plannedWorkActionMenuLinkStepsOption: string;
  readonly plannedWorkCardAddPlannedWorkButtonTitle: string;
  readonly plannedWorkCardAddWorkStep: string;
  readonly plannedWorkCardMoreLabel: (moreCount: number) => string;
  readonly plannedWorkCardNextSessionHeader: string;
  readonly plannedWorkCardNoPlannedWork: string;
  readonly plannedWorkCardShowAllButtonLabel: string;
  readonly plannedWorkCardSubtitle: string;
  readonly plannedWorkCardTitle: string;
  readonly plannedWorkCardUnplannedStepsGroupTitle: string;
  readonly plannedWorkDetailsPrimary: (date: Date, duration: number, dateService: DateService) => string;
  readonly plannedWorkDetailsSecondary: (startTime: Date, duration: number) => string;
  readonly plannedWorkLinkStepsErrorMessage: string;
  readonly plannedWorkLinkStepsTitle: string;
  readonly plannedWorkListCloseButtonLabel: string;
  readonly plannedWorkListPastSectionTitle: string;
  readonly plannedWorkListTitle: string;
  readonly plannedWorkListUpcomingSectionTitle: string;
  readonly planningDateFormat: (date: Date, dateService: DateService) => string;
  readonly remainingDaysFormat: (remainingDays: number) => string;
  readonly repeatWork: string;
  readonly saveDialog: string;
  readonly startTimeLabel: string;
  readonly stepEditCancelButtonTitle: string;
  readonly stepEditDatePickerNoValueLabel: string;
  readonly stepEditDatePickerPastDatesSectionTitle: string;
  readonly stepEditDatePickerTitle: string;
  readonly stepEditDatePickerUpcomingDatesSectionTitle: string;
  readonly stepEditDatePickerValueLabel: (numberOfDates: number) => string;
  readonly stepEditDurationPickerCustomValuePlaceholder: string;
  readonly stepEditDurationPickerNoValueLabel: string;
  readonly stepEditDurationPickerTitle: string;
  readonly stepEditDurationPickerValueLabel: (minutes: number) => string;
  readonly stepEditSaveButtonLabel: string;
  readonly stepEditTitlePlaceholder: string;
  readonly stepsListAddButtonTitle: string;
  readonly stepsListSubtitle: string;
  readonly stepsListTitle: string;
  readonly timeFormat: (date: Date) => string;
  readonly title: string;
  readonly unlinkWork: string;
  readonly unlinkWorkConfirmationConfirm: string;
  readonly unlinkWorkConfirmationMessage: string;
  readonly unlinkWorkConfirmationTitle: string;
  readonly unmarkedGradeSubtitle: string;
}

export const EnglishWorkDetailsStrings: WorkDetailsStrings = {
  addStepToPlannedWork: 'Add task',
  attachmentsSectionTitle: 'Attachments',
  attachmentsShowLess: 'Show less',
  attachmentsShowMore: 'Show all',
  availableTimeSlotsErrorMessage: (error) => `An error occurred while loading available time slots. Details: ${error}`,
  cancelDialog: 'Cancel',
  cancelPlannedWork: 'Cancel',
  cancelWork: 'Archive',
  cancelWorkConfirmationButton: 'Archive',
  cancelWorkConfirmationMessage: 'Archiving this item will remove it from your planner.',
  cancelWorkConfirmationTitle: 'Are you sure you want to archive this item?',
  cancelledAlertActionTitle: 'Restore',
  cancelledAlertTitle: 'The item is archived.',
  closeDialog: 'Close',
  collapseToggleLabel: 'Collapse',
  completeErrorMessage: (error) => `An error occurred while completing the item. Details: ${error}`,
  completedAlertActionTitle: 'Restore',
  completedAlertTitle: (completedDate) =>
    completedDate != null
      ? `This item was completed on ${format(completedDate, 'PPP')}. Congrats! 🎉`
      : `This item is completed. Congrats! 🎉`,
  copyWork: 'Copy',
  datePickerLabel: 'When?',
  defaultWorkTitle: 'Unknown',
  deleteWorkStepTooltip: 'Delete',
  distributeWork: 'Distribute',
  doneButtonLabel: 'Mark as done',
  dueDateAllDayFormat: "'Due on' PPP",
  dueDateFormat: EnglishDateStrings.calendarDateFormat,
  dueDateFullFormat: "'Due on' PPPp",
  dueDateSectionTitle: 'Due date',
  dueDateTimeFormat: EnglishDateStrings.calendarDateTimeFormat,
  dueTodayAlertMessage: 'This work is due today! You must either mark it as done or plan to do it today.',
  duplicateWork: 'Duplicate',
  durationFormat: EnglishDateStrings.shortDurationFormat,
  durationPickerLabel: 'How much time?',
  editPlannedWork: 'Edit',
  editWorkButtonLabel: 'Edit',
  editWorkStepTooltip: 'Edit',
  expandToggleLabel: 'Expand',
  extraDaysButtonLabel: 'More…',
  gradeSectionTitle: 'Grade',
  gradeText: (grade) => (grade < 0 ? '?' : grade.toString()),
  gradedSubtitle: 'Your teacher has graded this item.',
  instructionsTitle: 'Instructions',
  lateAlertMessage: 'This item is late! You must either mark it as done or plan to do it today.',
  maxPointsText: (maxPoints) => `${maxPoints}`,
  noAttachmentsMessage: 'There are no attachments for this item',
  noCourseTitle: 'No class',
  noDescription: 'No instructions',
  noDueDateSubtitle: 'None',
  noDurationMessage: 'Select a duration',
  noExternalUrlMessage:
    "This external item doesn't contain a link. You will have to manually open it on that platform.",
  noExternalUrlTitle: 'Cannot open item',
  noFreeTimeMessage: (date, duration, dateService) => {
    const durationFormat = EnglishDateStrings.durationFormat(duration, '-');
    const dateFormat = EnglishDateStrings.shortCalendarDateFormat(date, dateService);
    return `There is no ${durationFormat} time slot available ${lowerFirst(dateFormat)}`;
  },
  notGradedSubtitle: "Your teacher hasn't graded this item yet.",
  numberOfCopies: (numberOfCopies) =>
    `This item is duplicated ${numberOfCopies} ${numberOfCopies === 1 ? 'time' : 'times'}.`,
  openWorkLinkButtonLabel: (externalSourceName) => `View in ${externalSourceName}`,
  planErrorMessage: (error) => `An error occurred while saving work session. Details: ${error}`,
  plannedDateFormat: (date, duration, dateService) => {
    return `${EnglishDateStrings.calendarDateFormat(date, dateService)} - ${EnglishDateStrings.durationFormat(
      duration
    )}`;
  },
  plannedWorkActionMenuDeleteOption: 'Delete',
  plannedWorkActionMenuEditOption: 'Edit',
  plannedWorkActionMenuLinkStepsOption: 'Assign tasks',
  plannedWorkCardAddPlannedWorkButtonTitle: 'Plan now',
  plannedWorkCardAddWorkStep: 'Task',
  plannedWorkCardMoreLabel: (moreCount) => `${moreCount} more upcoming`,
  plannedWorkCardNextSessionHeader: 'Next session:',
  plannedWorkCardNoPlannedWork: 'No upcoming session',
  plannedWorkCardShowAllButtonLabel: 'Show all',
  plannedWorkCardSubtitle: 'Schedule time to work on this item',
  plannedWorkCardTitle: 'Work sessions',
  plannedWorkCardUnplannedStepsGroupTitle: 'Unplanned tasks',
  plannedWorkDetailsPrimary: (date, duration, dateService) =>
    `${EnglishDateStrings.calendarDateFormat(date, dateService)} at ${format(
      date,
      EnglishDateStrings.shortTimeFormat
    )} for ${EnglishDateStrings.durationFormat(duration)}`,
  plannedWorkDetailsSecondary: (startTime, duration) =>
    `${format(startTime, EnglishDateStrings.shortTimeFormat)} for ${EnglishDateStrings.durationFormat(duration)}`,
  plannedWorkLinkStepsErrorMessage: 'An error occurred while saving. Please try again.',
  plannedWorkLinkStepsTitle: 'Assign tasks',
  plannedWorkListCloseButtonLabel: 'Close',
  plannedWorkListPastSectionTitle: 'Past',
  plannedWorkListTitle: 'Work sessions',
  plannedWorkListUpcomingSectionTitle: 'Upcoming',
  planningDateFormat: EnglishDateStrings.shortCalendarDateFormat,
  remainingDaysFormat: (remainingDays) => {
    const dayStr = Math.abs(remainingDays) === 1 ? 'day' : 'days';
    return remainingDays < 0 ? `${dayStr} late` : dayStr;
  },
  repeatWork: 'Repeat',
  saveDialog: 'Save',
  startTimeLabel: 'at',
  stepEditCancelButtonTitle: 'Cancel',
  stepEditDatePickerNoValueLabel: 'Add a date…',
  stepEditDatePickerPastDatesSectionTitle: 'Past',
  stepEditDatePickerTitle: 'Select one or more work session…',
  stepEditDatePickerUpcomingDatesSectionTitle: 'Upcoming',
  stepEditDatePickerValueLabel: (numberOfDates: number) =>
    numberOfDates === 1 ? `${numberOfDates} date` : `${numberOfDates} dates`,
  stepEditDurationPickerCustomValuePlaceholder: 'Enter custom…',
  stepEditDurationPickerNoValueLabel: 'Add duration…',
  stepEditDurationPickerTitle: 'Duration (in minutes)',
  stepEditDurationPickerValueLabel: (minutes) => (minutes === 1 ? `${minutes} minute` : `${minutes} minutes`),
  stepEditSaveButtonLabel: 'Save',
  stepEditTitlePlaceholder: 'Title…',
  stepsListAddButtonTitle: 'Add…',
  stepsListSubtitle: 'Split this item into smaller chunks',
  stepsListTitle: 'Tasks',
  timeFormat: (date) => format(date, EnglishDateStrings.shortTimeFormat),
  title: 'Details',
  unlinkWork: 'Unlink from copies',
  unlinkWorkConfirmationConfirm: 'Unlink',
  unlinkWorkConfirmationMessage: 'This item will become independent from its copies. This action cannot be undone.',
  unlinkWorkConfirmationTitle: 'Unlink from copies',
  unmarkedGradeSubtitle: "Your teacher won't grade this item."
};

export const FrenchWorkDetailsStrings: WorkDetailsStrings = {
  addStepToPlannedWork: 'Ajouter une tâche',
  attachmentsSectionTitle: 'Documents',
  attachmentsShowLess: 'Réduire',
  attachmentsShowMore: 'Tout afficher',
  availableTimeSlotsErrorMessage: (error) =>
    `Une erreur est survenue lors du chargement des plages horaires disponibles. Détails : ${error}`,
  cancelDialog: 'Annuler',
  cancelPlannedWork: 'Annuler',
  cancelWork: 'Archiver',
  cancelWorkConfirmationButton: 'Archiver',
  cancelWorkConfirmationMessage: 'Un item archivé ne sera pas affiché dans l’agenda.',
  cancelWorkConfirmationTitle: 'Êtes-vous sûr de vouloir archiver cet item?',
  cancelledAlertActionTitle: 'Restaurer',
  cancelledAlertTitle: 'Cet item est archivé.',
  closeDialog: 'Fermer',
  collapseToggleLabel: 'Réduire',
  completeErrorMessage: (error) => `Une erreur est survenue lors de la complétion du devoir. Détails : ${error}`,
  completedAlertActionTitle: 'Restaurer',
  completedAlertTitle: (completedDate) =>
    completedDate != null
      ? `Cet item a été complété le ${format(completedDate, 'PPP')}. Félicitations! 🎉`
      : `Cet item est complété. Félicitations! 🎉`,
  copyWork: 'Copier',
  datePickerLabel: 'Quand?',
  defaultWorkTitle: 'Inconnu',
  deleteWorkStepTooltip: 'Supprimer',
  distributeWork: 'Distribuer',
  doneButtonLabel: 'Compléter',
  dueDateAllDayFormat: "'Pour le' PPP",
  dueDateFormat: FrenchDateStrings.calendarDateFormat,
  dueDateFullFormat: "'Pour le' PPPp",
  dueDateSectionTitle: 'Date de remise',
  dueDateTimeFormat: FrenchDateStrings.calendarDateTimeFormat,
  dueTodayAlertMessage: 'Ce devoir est pour aujourd’hui! Tu dois le compléter ou le planifier pour aujourd’hui.',
  duplicateWork: 'Dupliquer',
  durationFormat: FrenchDateStrings.shortDurationFormat,
  durationPickerLabel: 'Combien de temps?',
  editPlannedWork: 'Modifier',
  editWorkButtonLabel: 'Modifier',
  editWorkStepTooltip: 'Modifier',
  expandToggleLabel: 'Tout afficher',
  extraDaysButtonLabel: 'Plus…',
  gradeSectionTitle: 'Note',
  gradeText: (grade) => (grade < 0 ? '?' : grade.toString()),
  gradedSubtitle: 'Ton enseignant a noté ton devoir.',
  instructionsTitle: 'Instructions',
  lateAlertMessage: 'Ce devoir est en retard! Tu dois le compléter ou le planifier pour aujourd’hui.',
  maxPointsText: (maxPoints) => `${maxPoints}`,
  noAttachmentsMessage: 'Aucun document fourni pour ce devoir',
  noCourseTitle: 'Aucun cours',
  noDescription: 'Aucune instruction',
  noDueDateSubtitle: 'Aucune',
  noDurationMessage: 'Choisis une durée',
  noExternalUrlMessage:
    'Ce devoir externe ne contient pas de lien. Vous devrez l’ouvrir manuellement sur cette plateforme.',
  noExternalUrlTitle: 'Impossible d’ouvrir',
  noFreeTimeMessage: (date, duration, dateService) => {
    const durationFormat = FrenchDateStrings.durationFormat(duration, '-');
    const dateFormat = FrenchDateStrings.shortCalendarDateFormat(date, dateService);
    return `Aucune plage de ${durationFormat} disponible ${lowerFirst(dateFormat)}`;
  },
  notGradedSubtitle: 'Ton enseignant n’a pas encore noté ton devoir.',
  numberOfCopies: (numberOfCopies) => `Cet item est dupliqué ${numberOfCopies} fois.`,
  openWorkLinkButtonLabel: (externalSourceName) => `Ouvrir dans ${externalSourceName}`,
  planErrorMessage: (error) => `Une erreur est survenue lors de la sauvegarde de la planification. Détails: ${error}`,
  plannedDateFormat: (date, duration, dateService) => {
    return `${FrenchDateStrings.calendarDateFormat(date, dateService)} - ${FrenchDateStrings.durationFormat(duration)}`;
  },
  plannedWorkActionMenuDeleteOption: 'Supprimer',
  plannedWorkActionMenuEditOption: 'Modifier',
  plannedWorkActionMenuLinkStepsOption: 'Assigner des tâches',
  plannedWorkCardAddPlannedWorkButtonTitle: 'Planifier',
  plannedWorkCardAddWorkStep: 'Tâche',
  plannedWorkCardMoreLabel: (moreCount) => `${moreCount} de plus à venir`,
  plannedWorkCardNextSessionHeader: 'Prochaine session:',
  plannedWorkCardNoPlannedWork: 'Aucune session à venir',
  plannedWorkCardShowAllButtonLabel: 'Tout afficher',
  plannedWorkCardSubtitle: 'Planifie du temps pour travailler sur cet item',
  plannedWorkCardTitle: 'Sessions de travail',
  plannedWorkCardUnplannedStepsGroupTitle: 'Tâches non planifiées',
  plannedWorkDetailsPrimary: (date, duration, dateService) =>
    `${FrenchDateStrings.calendarDateFormat(date, dateService)} à ${format(
      date,
      FrenchDateStrings.shortTimeFormat
    )} pendant ${FrenchDateStrings.durationFormat(duration)}`,
  plannedWorkDetailsSecondary: (startTime, duration) =>
    `${format(startTime, FrenchDateStrings.shortTimeFormat)} pendant ${FrenchDateStrings.durationFormat(duration)}`,
  plannedWorkLinkStepsErrorMessage: 'Une erreur est survenue lors de le sauvegarde. Veuillez réessayer.',
  plannedWorkLinkStepsTitle: 'Assigner des tâches',
  plannedWorkListCloseButtonLabel: 'Fermer',
  plannedWorkListPastSectionTitle: 'Passée(s)',
  plannedWorkListTitle: 'Sessions de travail',
  plannedWorkListUpcomingSectionTitle: 'À venir',
  planningDateFormat: FrenchDateStrings.shortCalendarDateFormat,
  remainingDaysFormat: (remainingDays) => {
    const dayStr = Math.abs(remainingDays) <= 1 ? 'jour' : 'jours';
    return remainingDays < 0 ? `${dayStr} retard` : dayStr;
  },
  repeatWork: 'Répéter',
  saveDialog: 'Enregistrer',
  startTimeLabel: 'à',
  stepEditCancelButtonTitle: 'Annuler',
  stepEditDatePickerNoValueLabel: 'Ajouter une date…',
  stepEditDatePickerPastDatesSectionTitle: 'Passée(s)',
  stepEditDatePickerTitle: 'Sélectionnez une ou plusieurs session de travail…',
  stepEditDatePickerUpcomingDatesSectionTitle: 'À venir',
  stepEditDatePickerValueLabel: (numberOfDates: number) =>
    numberOfDates <= 1 ? `${numberOfDates} date` : `${numberOfDates} dates`,
  stepEditDurationPickerCustomValuePlaceholder: 'Entrer une valeur…',
  stepEditDurationPickerNoValueLabel: 'Ajouter une durée…',
  stepEditDurationPickerTitle: 'Durée (en minutes)',
  stepEditDurationPickerValueLabel: (minutes) => (minutes <= 1 ? `${minutes} minute` : `${minutes} minutes`),
  stepEditSaveButtonLabel: 'Sauvegarder',
  stepEditTitlePlaceholder: 'Titre…',
  stepsListAddButtonTitle: 'Ajouter…',
  stepsListSubtitle: 'Séparer cet item en morceaux plus petits',
  stepsListTitle: 'Tâches',
  timeFormat: (date) => format(date, FrenchDateStrings.shortTimeFormat),
  title: 'Détails',
  unlinkWork: 'Dissocier des copies',
  unlinkWorkConfirmationConfirm: 'Dissocier',
  unlinkWorkConfirmationMessage: 'Cet item deviendra indépendant des autres copies. Cette action est irréversible.',
  unlinkWorkConfirmationTitle: 'Dissocier des copies',
  unmarkedGradeSubtitle: 'Ton enseignant ne notera pas ce devoir.'
};
