import { Day, dateToPBDate, dateToString } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService } from '@/services';
import { computeDatesForMonthByWeek } from '@/utils';
import {
  addMonths,
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { UserDashboardCalendarAnnotationInfo } from '../../shared';

export interface UserDashboardCalendarDatePickerDayInfo {
  readonly day: Day;
  readonly cycleDayName: string;
  readonly annotations: UserDashboardCalendarAnnotationInfo[];
  readonly isHighlighted: boolean;
  readonly isSelected: boolean;
  readonly isDisabled: boolean;
}

export interface UserDashboardCalendarDatePickerViewModel {
  readonly currentDate: Date;
  readonly weeks: Date[][];
  readonly isFetching: boolean;
  goToPreviousMonth(): void;
  goToNextMonth(): void;
  getDayInfoForDate(date: Date): UserDashboardCalendarDatePickerDayInfo;
}

export abstract class AppUserDashboardCalendarDatePickerViewModel implements UserDashboardCalendarDatePickerViewModel {
  @observable protected _currentDate: Date;

  protected constructor(
    initialDate: Date | undefined,
    protected readonly _selectedDates: () => Date[],
    protected readonly _highlightedDates: (startDate: Date, endDate: Date) => Date[],
    protected readonly _minDate: Date | undefined,
    protected readonly _maxDate: Date | undefined,
    protected readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
    this._currentDate = initialDate ?? _dateService.now;

    autorun(() => {
      const startDate = startOfWeek(subMonths(this.currentDate, 1));
      const endDate = endOfWeek(addMonths(this.currentDate, 1));
      void this.fetchDays(dateToPBDate(startDate), dateToPBDate(endDate));
    });
  }

  @computed
  private get firstDisplayedDate(): Date {
    return startOfWeek(startOfMonth(this.currentDate));
  }

  @computed
  private get lastDisplayedDate(): Date {
    return endOfWeek(endOfMonth(this.currentDate));
  }

  @computed
  get currentDate(): Date {
    return this._currentDate;
  }

  abstract get isFetching(): boolean;

  @computed
  get weeks(): Date[][] {
    return computeDatesForMonthByWeek(this.currentDate);
  }

  @computed
  protected get highlightedDates(): Date[] {
    return this._highlightedDates(this.firstDisplayedDate, this.lastDisplayedDate);
  }

  @action
  goToNextMonth() {
    this._currentDate = addMonths(this.currentDate, 1);
  }

  @action
  goToPreviousMonth() {
    this._currentDate = subMonths(this.currentDate, 1);
  }

  protected abstract fetchDays(startDate: Day, endDate: Day): Promise<void>;

  abstract getDayInfoForDate(date: Date): UserDashboardCalendarDatePickerDayInfo;

  protected getDateIsSelected(date: Date): boolean {
    return this._selectedDates().findIndex((d) => dateToString(d) === dateToString(date)) >= 0;
  }

  protected getDateIsHighlighted(date: Date): boolean {
    return this.highlightedDates.findIndex((d) => dateToString(d) === dateToString(date)) >= 0;
  }

  protected getDateIsDisabled(date: Date): boolean {
    if (this._minDate == null && this._maxDate == null) {
      return false;
    }

    if (this._minDate != null && this._maxDate) {
      return differenceInCalendarDays(date, this._minDate) < 0 || differenceInCalendarDays(date, this._maxDate) > 0;
    }

    if (this._minDate != null) {
      return differenceInCalendarDays(date, this._minDate) < 0;
    }

    if (this._maxDate != null) {
      return differenceInCalendarDays(date, this._maxDate) > 0;
    }

    return false;
  }
}
