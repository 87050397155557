import { ConnectedAppUserDashboard, createClassroomSyncSchoolSubject } from '@/models';
import { Course } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { SyncSchoolSubject } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_school_subject_pb';
import { SyncStatus } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_status_pb';
import { ClientAppKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/client_app_kind_pb.js';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { ClassroomTransportService, GrpcTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

type DashboardSubject =
  | { value: string; case: 'plannerId' }
  | { value: string; case: 'schoolId' }
  | { value: SyncSchoolSubject; case: 'schoolSubject' }
  | { case: undefined; value?: undefined };

export class AppClassroomTransportService extends AppBaseTransportService implements ClassroomTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getSyncStatus(dashboard: ConnectedAppUserDashboard): Promise<SyncStatus> {
    return await this.performReadRequest(
      'getSyncStatus',
      (headers) =>
        this._grpcTransport.classroomClient.getSyncStatus(
          { subject: this.dashboardToSubject(dashboard) },
          { headers: headers }
        ),
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async synchronize(dashboard: ConnectedAppUserDashboard) {
    await this.performReadRequest(
      'synchronize',
      (headers) =>
        this._grpcTransport.classroomClient.synchronize({ subject: this.dashboardToSubject(dashboard) }, { headers }),
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async registerOfflineAccessCode(code: string, dashboard: ConnectedAppUserDashboard) {
    await this.performWriteRequest(
      'registerOfflineAccessCode',
      (headers) =>
        this._grpcTransport.classroomClient.registerOfflineAccessCode(
          { code, clientAppKind: ClientAppKind.WEB, subject: this.dashboardToSubject(dashboard) },
          { headers }
        ),
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async revokeOfflineAccessCode(dashboard: ConnectedAppUserDashboard) {
    await this.performWriteRequest(
      'revokeOfflineAccessCode',
      (headers) =>
        this._grpcTransport.classroomClient.revokeOfflineAccessCode(
          { subject: this.dashboardToSubject(dashboard) },
          { headers }
        ),
      { isDemoObject: this.dashboardIsDemo(dashboard), supportsDemoMode: true }
    );
  }

  async getCourses(dashboard: ConnectedAppUserDashboard): Promise<Course[]> {
    const response = await this.performReadRequest(
      'getCourses',
      (headers) =>
        this._grpcTransport.classroomClient.getCourses({ subject: this.dashboardToSubject(dashboard) }, { headers }),
      { isDemoObject: this.dashboardIsDemo(dashboard) }
    );

    return response.courses;
  }

  async updateCourseSelection(courseIds: string[], dashboard: ConnectedAppUserDashboard): Promise<Course[]> {
    const response = await this.performWriteRequest(
      'updateCourseSelection',
      (headers) =>
        this._grpcTransport.classroomClient.updateCourseSelection(
          { selectedCourseIds: courseIds, subject: this.dashboardToSubject(dashboard) },
          { headers }
        ),
      { isDemoObject: this.dashboardIsDemo(dashboard) }
    );

    return response.courses;
  }

  private dashboardToSubject(dashboard: ConnectedAppUserDashboard): DashboardSubject {
    switch (dashboard.case) {
      case 'planner':
        return { case: 'plannerId', value: dashboard.plannerId };
      case 'school':
        return { case: 'schoolId', value: dashboard.schoolId };
      case 'plannerSchool':
        return {
          case: 'schoolSubject',
          value: createClassroomSyncSchoolSubject({ plannerId: dashboard.plannerId, schoolId: dashboard.schoolId })
        };
    }
  }

  private dashboardIsDemo(dashboard: ConnectedAppUserDashboard): boolean {
    if (dashboard.case === 'school') {
      return this.isDemoId(dashboard.schoolId);
    } else {
      return this.isDemoId(dashboard.plannerId);
    }
  }
}
