import { IdentitySchema } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/identity_pb';
import { PreferenceValueSchema } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/preference_value_pb';
import { TodayUserSchema } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/today_user_pb';
import { UserProfileSchema } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createIdentity(init?: MessageInitShape<typeof IdentitySchema>) {
  return create(IdentitySchema, init);
}

export function createPreferenceValue(init?: MessageInitShape<typeof PreferenceValueSchema>) {
  return create(PreferenceValueSchema, init);
}

export function createTodayUser(init?: MessageInitShape<typeof TodayUserSchema>) {
  return create(TodayUserSchema, init);
}

export function createUserProfile(init?: MessageInitShape<typeof UserProfileSchema>) {
  return create(UserProfileSchema, init);
}
