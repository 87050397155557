import { AllDayOfWeek, createWeekItemRepetition, DayOfWeek, ItemRepeatPatternWeekKind } from '@/models';
import { Box, Chip, FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ControlledSelect, Subheader } from '../../../utils';

export interface PlannerRepeatItemWeekPatternOptionsProps {
  sx?: SxProps;
  className?: string;
  pattern: ItemRepeatPatternWeekKind;
  onChange: (pattern: ItemRepeatPatternWeekKind) => void;
}

export const PlannerRepeatItemWeekPatternOptions = observer(
  ({ sx = [], className, pattern, onChange }: PlannerRepeatItemWeekPatternOptionsProps) => {
    const strings = LocalizedStrings.planner.repeatPlannerItem;

    function onWeekSkipCountChange(value: string | number) {
      if (typeof value === 'number') {
        onChange({ ...pattern, value: createWeekItemRepetition({ ...pattern.value, recurrence: value }) });
      }
    }

    function onDayOfWeekSelect(value: DayOfWeek) {
      const newDayOfWeeks = [...pattern.value.daysOfWeek];
      const existingIndex = newDayOfWeeks.indexOf(value);

      if (existingIndex >= 0) {
        newDayOfWeeks.splice(existingIndex, 1);
      } else {
        newDayOfWeeks.push(value);
      }

      onChange({
        ...pattern,
        value: createWeekItemRepetition({ ...pattern.value, daysOfWeek: newDayOfWeeks })
      });
    }

    return (
      <Stack sx={sx} className={className} spacing={2}>
        <FormControl>
          <InputLabel id="repeat-week-count-option">{strings.everyWeekCountSelectLabel()}</InputLabel>
          <ControlledSelect
            label={strings.everyWeekCountSelectLabel()}
            labelId={'repeat-week-count-option'}
            value={pattern.value.recurrence}
            onChange={(e) => onWeekSkipCountChange(e.target.value)}
          >
            {times(4).map((v) => (
              <MenuItem key={v} value={v + 1}>
                {strings.everyXWeekSelectOption(v + 1)}
              </MenuItem>
            ))}
          </ControlledSelect>
        </FormControl>
        <Stack>
          <Subheader>{strings.dayOfWeekSelectionSectionTitle()}</Subheader>

          <Box
            sx={{
              display: 'flex',
              rowGap: 1,
              columnGap: 1,
              overflow: 'hidden',
              flexWrap: 'wrap'
            }}
          >
            {AllDayOfWeek.map((dow) => (
              <Chip
                key={dow}
                label={LocalizedStrings.dateTime.dayOfWeekTitle[dow]()}
                variant={pattern.value.daysOfWeek.includes(dow) ? 'filled' : 'outlined'}
                color={pattern.value.daysOfWeek.includes(dow) ? 'primary' : 'default'}
                onClick={() => onDayOfWeekSelect(dow)}
              />
            ))}
          </Box>
        </Stack>
      </Stack>
    );
  }
);
