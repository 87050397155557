import { ServiceContainer } from '@/providers';
import { AttachmentService } from '@/services';
import { Attachment } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/attachment_pb';
import { ExternalSource } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/external_source_pb';
import { ImportanceLevel } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/importance_level_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import {
  Work,
  WorkSchema
} from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { MessageInitShape } from '@bufbuild/protobuf';
import { isSameMinute } from 'date-fns';
import { action, computed, IObservableArray, makeObservable, observable, override } from 'mobx';
import { AttachmentInfo } from '../AttachmentInfo';
import { createWork } from '../create-models';
import { timestampDateOptional, timestampFromDateOptional } from '../TimestampUtils';
import { EditableModel, EditableProperty } from './core';
import { EditableAttachmentInfo } from './EditableAttachmentInfo';

export class EditableWork extends EditableModel<Work> {
  private readonly _title: EditableProperty<string>;
  private readonly _iconId: EditableProperty<string>;
  private readonly _dueDate: EditableProperty<Date>;
  private readonly _description: EditableProperty<RichText>;
  private readonly _isAllDay: EditableProperty<boolean>;
  private readonly _courseSectionId: EditableProperty<string | undefined>;
  private readonly _importance: EditableProperty<ImportanceLevel>;
  private readonly _attachmentsInfos: IObservableArray<EditableAttachmentInfo>;

  constructor(
    plannerId: string,
    defaultWorkIconId: string,
    defaultValues: MessageInitShape<typeof WorkSchema> | undefined,
    private readonly _work?: Work,
    private readonly _attachmentService: AttachmentService = ServiceContainer.services.attachmentService
  ) {
    super(
      _work == null,
      _work ??
        createWork({
          plannerId,
          iconId: defaultWorkIconId,
          title: '',
          dueTime: undefined,
          courseSectionId: undefined,
          description: undefined,
          isDueAllDay: true,
          importance: ImportanceLevel.REGULAR,
          ...defaultValues
        })
    );

    makeObservable(this);

    this._attachmentsInfos = observable.array(
      this.initialModel.attachments?.map((a) => {
        const info = _attachmentService.attachmentInfoFromPlannerAttachment(a);
        return new EditableAttachmentInfo(false, info);
      }) ?? []
    );

    this.setFields([
      (this._title = new EditableProperty<string>(this.initialModel.title, (v1, v2) => (v1 ?? '') === (v2 ?? ''))),
      (this._iconId = new EditableProperty<string>(this.initialModel.iconId)),
      (this._dueDate = new EditableProperty<Date>(
        timestampDateOptional(this.initialModel.dueTime),
        (value1, value2) => {
          if (value1 == null && value2 == null) {
            return true;
          }

          return value1 != null && value2 != null ? isSameMinute(value1, value2) : false;
        }
      )),
      (this._courseSectionId = new EditableProperty<string | undefined>(this.initialModel.courseSectionId)),
      (this._description = new EditableProperty<RichText>(
        this.initialModel.description,
        (v1, v2) => (v1 ?? '') === (v2 ?? '')
      )),
      (this._isAllDay = new EditableProperty<boolean>(this.initialModel.isDueAllDay)),
      (this._importance = new EditableProperty<ImportanceLevel>(this.initialModel.importance))
    ]);
  }

  @override
  get hasChanges(): boolean {
    return super.hasChanges || this._attachmentsInfos.some((a) => a.markedAsDeleted || a.shouldBeCreated);
  }

  // Editable properties

  @computed
  get title(): string {
    return this._title.value ?? '';
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get iconId(): string {
    return this._iconId.value!;
  }

  set iconId(value: string) {
    this._iconId.value = value;
  }

  @computed
  get dueDate(): Date | undefined {
    return this._dueDate.value;
  }

  set dueDate(value: Date | undefined) {
    this._dueDate.value = value;
  }

  @computed
  get description(): RichText | undefined {
    return this._description.value;
  }

  set description(value: RichText | undefined) {
    this._description.value = value;
  }

  @computed
  get isAllDay(): boolean {
    return this._isAllDay.value ?? false;
  }

  set isAllDay(value: boolean) {
    this._isAllDay.value = value;
  }

  get courseSectionId(): string | undefined {
    return this._courseSectionId.value;
  }

  set courseSectionId(value: string | undefined) {
    this._courseSectionId.value = value;
  }

  @computed
  get importance(): ImportanceLevel {
    return this._importance.value ?? ImportanceLevel.REGULAR;
  }

  set importance(value: ImportanceLevel) {
    this._importance.value = value;
  }

  @computed
  get attachmentsInfos(): EditableAttachmentInfo[] {
    return this._attachmentsInfos;
  }

  @computed
  get attachments(): Attachment[] {
    return this.attachmentsInfos
      .filter((a) => !a.markedAsDeleted)
      .map((a) => this._attachmentService.attachmentInfoToPlannerAttachment(a.updatedModel));
  }

  @computed
  get updatedModel(): Work {
    return createWork({
      ...this.initialModel,
      title: this._title.value ?? '',
      description: this._description.value,
      iconId: this._iconId.value ?? '',
      isDueAllDay: this._isAllDay.value ?? true,
      dueTime: timestampFromDateOptional(this._dueDate.value),
      courseSectionId: this._courseSectionId.value ?? '',
      importance: this._importance.value ?? ImportanceLevel.REGULAR,
      attachments: this.attachments
    });
  }

  @computed
  get hasChangesToSharedProperties(): boolean {
    return (
      this.getHasChangesToSharedProperties([this._title, this._description, this._iconId, this._importance]) ||
      this._attachmentsInfos.some((a) => a.markedAsDeleted || a.shouldBeCreated)
    );
  }

  @action
  addAttachment(attachment: AttachmentInfo) {
    this._attachmentsInfos.push(new EditableAttachmentInfo(true, attachment));
  }

  // Readonly properties

  @computed
  get externalSource(): ExternalSource | undefined {
    return this._work?.externalSource;
  }

  @computed
  get duplicationIds(): string[] {
    return this._work?.duplicationIds ?? [];
  }
}
