import { useServices } from '@/hooks';
import { isSxArray } from '@/utils';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { ArrowBackRounded, OpenInNewRounded } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  List,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { Link, useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { WorkloadInformationIcon } from '../../shared';
import { PlannerWorkloadPublishedWorkListItem } from './PlannerWorkloadPublishedWorkListItem';

export interface PlannerWorkloadStudentDetailsProps {
  sx?: SxProps;
  className?: string;
  studentId: string;
  schoolCourseSectionId: string;
  date: Date;
  viewModel: PlannerWorkloadPageViewModel;
}

export const PlannerWorkloadStudentDetails = observer(
  ({ sx = [], className, studentId, schoolCourseSectionId, date, viewModel }: PlannerWorkloadStudentDetailsProps) => {
    const { route } = useServices();
    const [searchParams, setSearchParams] = useSearchParams();
    const strings = LocalizedStrings.planner.workload;
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const data = viewModel.getWorkloadDetailsForStudent(studentId, schoolCourseSectionId, date);

    function goBackToStudentList() {
      searchParams.delete('studentId');
      setSearchParams(searchParams);
    }

    if (data == null) {
      return <Typography>{strings.studentNoData()}</Typography>;
    }

    return (
      <Box
        sx={[{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }, ...(isSxArray(sx) ? sx : [sx])]}
        className={className}
      >
        <Stack sx={{ overflow: 'hidden', flex: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ px: 1 }}>
            <IconButton onClick={goBackToStudentList} size={isExtraSmallScreen ? 'small' : undefined}>
              <ArrowBackRounded fontSize={isExtraSmallScreen ? 'small' : undefined} />
            </IconButton>

            <WorkloadInformationIcon
              kind="single-student"
              status={data.status}
              size={isExtraSmallScreen ? 'small' : 'regular'}
            />

            <Stack sx={{ flex: 1, alignItems: 'flex-start' }}>
              <Typography sx={{ fontWeight: 600 }} variant={isExtraSmallScreen ? 'body2' : 'body1'}>
                {data.student.fullName}
              </Typography>

              <Typography
                variant="caption"
                color="textSecondary"
                component={MuiLink}
                underline="hover"
                href={`mailto:${data.student.emailAddress}`}
              >
                {data.student.emailAddress}
              </Typography>
            </Stack>

            <Tooltip title={strings.openStudentPlannerTooltip()}>
              <IconButton
                component={Link}
                to={route.resolvePlannerLocation(data.student.plannerId)}
                size={isExtraSmallScreen ? 'small' : undefined}
              >
                <OpenInNewRounded fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>

          <List sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
            <Typography
              variant={isExtraSmallScreen ? 'caption' : 'subtitle2'}
              color="textSecondary"
              sx={{ fontWeight: '600', px: 2, py: isExtraSmallScreen ? 0.5 : 1 }}
            >
              {strings.studentWorkListHeader()}
            </Typography>

            {data.works.map((work) => (
              <Fragment key={work.publishedWork.id}>
                <PlannerWorkloadPublishedWorkListItem
                  key={work.publishedWork.id}
                  work={work}
                  sx={{ py: isExtraSmallScreen ? 1.5 : undefined, overflow: 'hidden' }}
                />
                <Divider variant="middle" />
              </Fragment>
            ))}
          </List>
        </Stack>
      </Box>
    );
  }
);
