import { Timestamp, timestampDate, timestampFromDate } from '@bufbuild/protobuf/wkt';
import { dateToTimeOfDay, TimeOfDay } from './TimeOfDay';

export function timestampToTimeOfDay(timestamp: Timestamp): TimeOfDay {
  const date = timestampDate(timestamp);

  return dateToTimeOfDay(date);
}

export function timestampDateOptional(timestamp: Timestamp | undefined): Date | undefined {
  return timestamp != null ? timestampDate(timestamp) : undefined;
}

export function timestampFromDateOptional(date: Date | undefined): Timestamp | undefined {
  return date != null ? timestampFromDate(date) : undefined;
}
