import { DayOfWeek } from '@/models';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface AdminWorkloadCalendarDayOfWeekHeaderProps {
  sx?: SxProps;
  className?: string;
  dayOfWeek: DayOfWeek;
}

export const AdminWorkloadCalendarDayOfWeekHeader = observer(
  ({ sx = [], dayOfWeek, className }: AdminWorkloadCalendarDayOfWeekHeaderProps) => {
    return (
      <Stack className={clsx('school-admin-workload-table-day-header', className)} sx={sx}>
        <Typography sx={{ textAlign: 'center', flex: 1 }} variant="subtitle2">
          {LocalizedStrings.dateTime.dayOfWeekTitle[dayOfWeek]()}
        </Typography>
      </Stack>
    );
  }
);
