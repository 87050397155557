import {
  convertRichTextToPlainText,
  plannerHasAccessKindsForUser,
  timeSlotDuration,
  timestampDateOptional,
  urlForExternalSourceBadge,
  urlForWorkIconFromWork,
  WorkIconInfo,
  WorkIcons,
  workIsLate
} from '@/models';
import { ServiceContainer } from '@/providers';
import { UserDashboardPlannerItemsLocationState } from '@/services';
import { RelativeDateKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_kind_pb';
import { RelativeDate } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_pb';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { captureException } from '@sentry/react';
import {
  differenceInCalendarDays,
  format,
  formatDuration,
  intervalToDuration,
  intlFormatDistance,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay
} from 'date-fns';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  ContextMenuAction,
  contextMenuActionsForPlannedWork,
  contextMenuActionsForWork,
  ContextMenuActionsGroup
} from '../../shared';
import { AppWorkStepInfo } from '../../work';
import { cloneWorkStep } from './CloneModels';
import {
  PlannerListItemInfo,
  PlannerListItemInfoIconKind,
  PlannerListItemInfoInlineAction,
  PlannerListItemInfoKind,
  PlannerListItemInfoPrimaryDetailKind,
  PlannerListItemInfoState,
  PlannerListItemInfoStepsInfo,
  PlannerListItemInfoUrl
} from './PlannerListItemInfo';

export class WorkPlannerListItemInfo implements PlannerListItemInfo {
  constructor(
    private readonly _work: Work,
    private readonly _plannedWork: PlannedWork | undefined,
    private readonly _groupRelativeDate: RelativeDate | undefined,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _workStore = ServiceContainer.services.workStore,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _localization = ServiceContainer.services.localization,
    private readonly _route = ServiceContainer.services.route,
    private readonly _dateService = ServiceContainer.services.dateService,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _featureFlag = ServiceContainer.services.featureFlag
  ) {
    makeObservable(this);
  }

  readonly kind: PlannerListItemInfoKind = 'work';

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this.plannerId)!;
  }

  @computed
  private get hasEditAccessRight(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  private get workIcons(): WorkIcons {
    return this._workStore.workIcons.data;
  }

  @computed
  private get courseSectionsById(): Map<string, CourseSectionDetails> {
    return this._plannerStore.getCourseSectionsInPlanner(this._work.plannerId).data;
  }

  @computed
  private get courseSection(): CourseSectionDetails | undefined {
    if (this._work.courseSectionId == null) {
      return undefined;
    }

    return this.courseSectionsById.get(this._work.courseSectionId);
  }

  @computed
  get id(): string {
    return this._work.id;
  }

  @computed
  get uniqueId(): string {
    return this.plannedWorkId != null ? `${this.id}-${this.plannedWorkId}` : this.id;
  }

  @computed
  get plannerId(): string {
    return this._work.plannerId;
  }

  @computed
  get plannedWorkId(): string | undefined {
    return this._plannedWork?.id;
  }

  @computed
  get title(): string {
    return this._work.title;
  }

  @computed
  get description(): string | undefined {
    return convertRichTextToPlainText(this._work.description);
  }

  @computed
  get icon(): PlannerListItemInfoIconKind | undefined {
    const workIcon = this.workIcons.iconsById.get(this._work.iconId);
    if (workIcon != null) {
      const iconInfo: WorkIconInfo = {
        id: workIcon.iconId,
        title: workIcon.iconName,
        lightUrl: urlForWorkIconFromWork(workIcon, this._work, 'light'),
        darkUrl: urlForWorkIconFromWork(workIcon, this._work, 'dark'),
        externalBadgeUrl: urlForExternalSourceBadge(this._work.externalSource?.sourceName, this.workIcons)
      };

      return { case: this._plannedWork != null ? 'planned-work' : 'work', value: iconInfo };
    }
    return undefined;
  }

  @computed
  get color(): string | undefined {
    return this.courseSection?.courseSection?.color;
  }

  @computed
  get contextMenuActions(): ContextMenuAction[] | ContextMenuActionsGroup[] {
    return this._plannedWork != null
      ? contextMenuActionsForPlannedWork(
          this._work,
          this._plannedWork,
          !this.hasEditAccessRight,
          this._pasteboard,
          this._workStore,
          this._featureFlag
        )
      : contextMenuActionsForWork(
          this._work,
          !this.hasEditAccessRight,
          this._pasteboard,
          this._workStore,
          this._plannerStore,
          this._featureFlag
        );
  }

  @computed
  get inlineActions(): PlannerListItemInfoInlineAction[] {
    return [
      {
        kind: 'plan-work-session',
        hidden: this._plannedWork != null || this._work.status !== WorkStatus.ACTIVE || !this.hasEditAccessRight,
        onClick: {
          url: undefined,
          state: {
            plannedWorkEdit: { workId: this.id, id: this.plannedWorkId }
          } as UserDashboardPlannerItemsLocationState
        }
      },
      {
        kind: 'complete',
        hidden:
          this._work.status !== WorkStatus.ACTIVE ||
          !this.hasEditAccessRight ||
          (this._work.isStatusChangedExternally && this._work.externalSource?.url == null),
        onClick: this._work.isStatusChangedExternally
          ? { externalUrl: this._work.externalSource?.url, target: '_blank' }
          : () => this.setWorkStatus(WorkStatus.COMPLETED)
      }
    ];
  }

  @computed
  get primaryDetail(): string | undefined {
    if (this._work.status === WorkStatus.COMPLETED) {
      return this.formattedGrade;
    }

    if (this._plannedWork == null) {
      return this.formattedDate;
    }

    return this.formattedRelativeDate;
  }

  @computed
  get primaryDetailKind(): PlannerListItemInfoPrimaryDetailKind {
    if (this._plannedWork != null || this._work.dueTime == null) {
      return 'default';
    }

    if (this._work.isDueAllDay) {
      const dayDiff = differenceInCalendarDays(timestampDate(this._work.dueTime), this._dateService.now);
      return dayDiff < 0 ? 'error' : dayDiff === 0 ? 'warning' : 'default';
    }

    if (workIsLate(this._work, this._dateService)) {
      return 'error';
    }

    if (isSameDay(timestampDate(this._work.dueTime), this._dateService.now)) {
      return 'warning';
    }

    return 'default';
  }

  @computed
  get secondaryDetail(): string | undefined {
    if (this._plannedWork == null) {
      return undefined;
    }

    const relativeDateKindShouldShowTime: RelativeDateKind[] = [
      RelativeDateKind.YESTERDAY,
      RelativeDateKind.TODAY,
      RelativeDateKind.TOMORROW,
      RelativeDateKind.IN_DAYS
    ];
    if (this._groupRelativeDate == null || relativeDateKindShouldShowTime.includes(this._groupRelativeDate.kind)) {
      return this.formattedDuration;
    }

    return this.formattedTimeOnly;
  }

  @computed
  get state(): PlannerListItemInfoState {
    if (this._work.status === WorkStatus.COMPLETED) {
      return 'completed';
    }

    if (
      this._plannedWork?.timeSlot?.startTime == null ||
      this._plannedWork?.timeSlot?.endTime == null ||
      (this._plannedWork?.timeSlot?.isAllDay ?? false)
    ) {
      return 'active';
    }

    const now = this._dateService.now;
    return isBefore(timestampDate(this._plannedWork.timeSlot.startTime), now) &&
      isAfter(timestampDate(this._plannedWork.timeSlot.endTime), now)
      ? 'current'
      : 'active';
  }

  @computed
  get url(): PlannerListItemInfoUrl {
    return {
      kind: 'internal',
      to: this._route.resolvePlannerWorkLocation(this._work.plannerId, this._work.id),
      setBackgroundLocation: true
    };
  }

  @computed
  get stepsInfo(): PlannerListItemInfoStepsInfo | undefined {
    const allSteps = this._work.steps;
    const completedStepsCount = allSteps.filter((s) => s.isCompleted).length;
    const steps =
      this._plannedWork != null
        ? chain(this._plannedWork.workStepIds)
            .map((id) => allSteps.find((s) => s.id === id))
            .compact()
            .value()
        : allSteps;

    const stepInfos = steps.map((s) => new AppWorkStepInfo(s, (isCompleted) => void this.onStepChange(s, isCompleted)));
    return { steps: stepInfos, completedStepsCount, totalStepsCount: allSteps.length };
  }

  @computed
  private get formattedGrade(): string | undefined {
    if (this._work.maxGrade === 0) {
      return undefined;
    }

    const grade = this._work.grade >= 0 ? this._work.grade : '?';
    return `${grade}/${this._work.maxGrade}`;
  }

  @computed
  private get formattedDuration(): string | undefined {
    if (this._plannedWork?.timeSlot == null || this._plannedWork.timeSlot.isAllDay) {
      return undefined;
    }

    const plannedWorkDuration = timeSlotDuration(this._plannedWork.timeSlot);
    if (plannedWorkDuration == null) {
      return undefined;
    }

    const duration = intervalToDuration({ start: 0, end: plannedWorkDuration * 60 * 1_000 });
    return formatDuration(duration);
  }

  @computed
  private get formattedDate(): string | undefined {
    const dueDate = timestampDateOptional(this._work.dueTime);

    if (dueDate == null) {
      return undefined;
    }

    const now = this._dateService.now;
    const dayDiff = differenceInCalendarDays(dueDate, now);

    // If Yesterday, Today or Tomorrow.
    if (this._work.isDueAllDay && dayDiff >= -1 && dayDiff <= 1) {
      return intlFormatDistance(dueDate, now, { unit: 'day', locale: this._localization.currentLocale });
    }

    const isToday = differenceInCalendarDays(dueDate, now) !== 0;
    // If the item is not due today, we want the difference in calendar days to be displayed so using the same time
    // for dueDate and now.
    const resolvedDueDate = isToday && !this._work.isDueAllDay ? startOfDay(dueDate) : dueDate;
    const resolvedNow = isToday && !this._work.isDueAllDay ? startOfDay(now) : now;

    const diff = differenceInCalendarDays(resolvedDueDate, resolvedNow) * 24 * 60;
    return this._localization.localizedStrings.dateTime.shortDurationFormat(diff);
  }

  @computed
  private get formattedTimeOnly(): string | undefined {
    const strings = this._localization.localizedStrings.dateTime;

    if (this._plannedWork?.timeSlot?.startTime != null) {
      if (this._plannedWork.timeSlot.isAllDay) {
        return strings.allDayPlannedWork;
      }

      return format(timestampDate(this._plannedWork.timeSlot.startTime), strings.plannedWorkStartTimeFormat);
    }
    if (this._work.isDueAllDay) {
      return strings.allDayPlannedWork;
    }

    return this._work.dueTime != null
      ? format(timestampDate(this._work.dueTime), strings.plannedWorkStartTimeFormat)
      : undefined;
  }

  @computed
  private get formattedRelativeDate(): string | undefined {
    const plannedWorkDate = timestampDateOptional(this._plannedWork?.timeSlot?.startTime);
    if (plannedWorkDate == null) {
      return undefined;
    }

    const relativeDateKindShouldShowTime: RelativeDateKind[] = [
      RelativeDateKind.YESTERDAY,
      RelativeDateKind.TODAY,
      RelativeDateKind.TOMORROW,
      RelativeDateKind.IN_DAYS
    ];

    if (this._groupRelativeDate == null || relativeDateKindShouldShowTime.includes(this._groupRelativeDate.kind)) {
      return this.formattedTimeOnly;
    }

    const relativeDateKindShouldShowDayOfWeek: RelativeDateKind[] = [
      RelativeDateKind.THIS_WEEK,
      RelativeDateKind.NEXT_WEEK
    ];

    if (relativeDateKindShouldShowDayOfWeek.includes(this._groupRelativeDate.kind)) {
      // Displaying day of week for planned work this or next week.
      return format(plannedWorkDate, 'iiii');
    }

    // Otherwise, displaying date.
    return format(plannedWorkDate, 'PP');
  }

  private async setWorkStatus(workStatus: WorkStatus) {
    await this._workStore.setWorkStatus(this._work.id, workStatus, this._work.syncToken);
    await this._plannerStore.fetchPlannerContents(this._work.plannerId);
  }

  private async onStepChange(step: WorkStep, isCompleted: boolean) {
    const clonedStep = cloneWorkStep(step);
    const steps = this._work.steps;
    step.isCompleted = isCompleted;

    try {
      const result = await this._workStore.setWorkSteps(this._work.id, steps, this._work.syncToken);
      this._work.syncToken = result.syncToken;
    } catch (e) {
      captureException(e);
      step.isCompleted = clonedStep.isCompleted;
    }
  }
}
