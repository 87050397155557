import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { dateToString } from './Day';
import { compareTimeOfDays, TimeOfDay } from './TimeOfDay';

export interface CourseSectionOccurrenceInfo {
  readonly date: Date;
  readonly periodLabel: string;
  readonly startTime: TimeOfDay;
  readonly endTime: TimeOfDay;
  readonly courseSectionId: string;
}

export function courseSectionOccurrenceToInfo(
  occurrence: CourseSectionOccurrence,
  date: Date
): CourseSectionOccurrenceInfo {
  return {
    date,
    periodLabel: occurrence.periodLabel,
    startTime: occurrence.startTime!,
    endTime: occurrence.endTime!,
    courseSectionId: occurrence.courseSectionId
  };
}

export function courseSectionOccurrenceInfoAreEquals(
  lhs: CourseSectionOccurrenceInfo,
  rhs: CourseSectionOccurrenceInfo
): boolean {
  return (
    dateToString(lhs.date) === dateToString(rhs.date) &&
    lhs.periodLabel === rhs.periodLabel &&
    compareTimeOfDays(lhs.startTime, rhs.startTime) === 0 &&
    compareTimeOfDays(lhs.endTime, rhs.endTime) === 0 &&
    lhs.courseSectionId === rhs.courseSectionId
  );
}
