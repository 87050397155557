import { DateSchema, Date as PBDate } from '@buf/googleapis_googleapis.bufbuild_es/google/type/date_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';
import { areIntervalsOverlapping, differenceInCalendarDays, getDate, getMonth, getYear, set } from 'date-fns';

export const DaySchema = DateSchema;

export interface Day {
  readonly year: number;
  readonly month: number;
  readonly day: number;
}

export function createDay(init?: MessageInitShape<typeof DaySchema>) {
  return create(DaySchema, init);
}

export function dayToString(day: Day) {
  const year = String(day.year).padStart(4, '0');
  const month = String(day.month).padStart(2, '0');
  const date = String(day.day).padStart(2, '0');
  return `${year}-${month}-${date}`;
}

export function dayFromString(dayString: string): Day {
  const elements = dayString.split('-');
  const year = Number(elements[0]);
  const month = Number(elements[1]);
  const day = Number(elements[2]);
  return { year, month, day };
}

export function dateToString(date: Date) {
  const day = dateToPBDate(date);
  return dayToString(day);
}

export function dayToDate(day: Day, atMidnight = false): Date {
  return set(new Date(), {
    year: day.year,
    month: day.month - 1,
    date: day.day,
    ...(atMidnight ? { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 } : {})
  });
}

export function dateToPBDate(date: Date): PBDate {
  const day: Day = { day: getDate(date), month: getMonth(date) + 1, year: getYear(date) };
  return dayToPBDate(day);
}

export function dayToPBDate(day: Day): PBDate {
  return createDay({ day: day.day, month: day.month, year: day.year });
}

export function compareDays(day1: Day, day2: Day): number {
  return differenceInCalendarDays(dayToDate(day1), dayToDate(day2));
}

export function rangesOfDaysAreOverlapping(
  range1: { start: Day; end: Day },
  range2: { start: Day; end: Day }
): boolean {
  return areIntervalsOverlapping(
    { start: dayToDate(range1.start, true), end: dayToDate(range1.end, true) },
    { start: dayToDate(range2.start, true), end: dayToDate(range2.end, true) },
    { inclusive: true }
  );
}
