import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useActivePlannerId, useServices } from '../../hooks';
import { isSxArray } from '../../utils';
import { Banner, BannerAction, BannerSeverity } from '../utils';

export interface UserDashboardBannerProps {
  sx?: SxProps;
  className?: string;
  identifier: string;
  isVisible: boolean;
  title: string;
  severity?: BannerSeverity;
  canDismiss?: boolean;
  // If false, the dismiss state is not stored in the LocalStorage. Defaults to true.
  canDismissPermanently?: boolean;
  actions?: BannerAction[];
}

export const UserDashboardBanner = observer(
  ({
    sx = [],
    className,
    identifier,
    canDismiss = true,
    canDismissPermanently = true,
    isVisible,
    actions = [],
    title,
    severity = 'none'
  }: UserDashboardBannerProps) => {
    const { settingsStorage } = useServices();
    const plannerId = useActivePlannerId();

    // Hiding by default until the stored settings has been fetched
    const [isBannerDismissed, setIsBannerDismissed] = useState(true);

    useEffect(() => {
      const fetchSetting = async () => {
        const isDismissed = await settingsStorage.userDashboardBannerIsDismissed(plannerId, identifier);
        setIsBannerDismissed(isDismissed ?? false);
      };

      void fetchSetting();
    }, [plannerId, identifier]);

    if (!isVisible || isBannerDismissed) {
      return null;
    }

    function dismissBanner() {
      if (!canDismiss) {
        return;
      }

      setIsBannerDismissed(true);

      if (canDismissPermanently) {
        settingsStorage.setUserDashboardBannerIsDismissed(true, plannerId, identifier);
      }
    }

    const isBlurred = settingsStorage.backgroundImage != null && settingsStorage.reduceTransparency !== true;

    return (
      <Banner
        severity={severity}
        onClose={dismissBanner}
        className={className}
        actions={actions}
        sx={[{ backdropFilter: isBlurred ? 'blur(12px)' : undefined }, ...(isSxArray(sx) ? sx : [sx])]}
      >
        {title}
      </Banner>
    );
  }
);
