import { useServices } from '@/hooks';
import { SortedDayOfWeek } from '@/models';
import { computeDatesForMonthByWeek, getDateFromSearchParams, setDateInSearchParams } from '@/utils';
import { AdminWorkloadViewModel } from '@/viewmodels';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSameMonth } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';
import { useRegisterPlannerWorkloadForceReload } from '../../planner/workload/PlannerWorkloadForceReloadContext';
import { AdminWorkloadCalendarDay } from './AdminWorkloadCalendarDay';
import { AdminWorkloadCalendarDayOfWeekHeader } from './AdminWorkloadCalendarDayOfWeekHeader';
import { AdminWorkloadCalendarHeader } from './AdminWorkloadCalendarHeader';

export interface AdminWorkloadCalendarProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminWorkloadViewModel;
}

export const AdminWorkloadCalendar = observer(({ sx = [], className, viewModel }: AdminWorkloadCalendarProps) => {
  const { dateService } = useServices();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDate = getDateFromSearchParams(searchParams, dateService);
  const weeks = computeDatesForMonthByWeek(currentDate);

  useEffect(() => {
    const dates = weeks.flat();
    void viewModel.loadWorkloadForDates(dates, true);
  }, [weeks]);

  useRegisterPlannerWorkloadForceReload(() => {
    const dates = weeks.flat();
    void viewModel.loadWorkloadForDates(dates, true);
  });

  function setCurrentDate(date: Date | undefined) {
    if (date != null) {
      setDateInSearchParams(date, searchParams, dateService);
    } else {
      searchParams.delete('date');
    }
    setSearchParams(searchParams);
  }

  return (
    <Stack sx={sx} className={className}>
      <AdminWorkloadCalendarHeader currentDate={currentDate} onDateChange={setCurrentDate} viewModel={viewModel} />

      <Stack sx={{ flex: 1 }}>
        <Stack direction="row" className="school-admin-workload-table-header">
          {SortedDayOfWeek.map((dow) => (
            <AdminWorkloadCalendarDayOfWeekHeader dayOfWeek={dow} key={dow} />
          ))}
        </Stack>

        <Stack sx={{ flex: 1 }}>
          {weeks.map((week, index) => (
            <Stack
              key={index}
              sx={{ height: `calc(1/${weeks.length} * 100%)` }}
              direction="row"
              className="school-admin-workload-table-week-row"
            >
              {week.map((day) => (
                <AdminWorkloadCalendarDay
                  key={day.toISOString()}
                  date={day}
                  isInCurrentMonth={isSameMonth(day, currentDate)}
                  getWorkloadInformation={(day) => viewModel.getInformationForDate(day)}
                  getCalendarDay={(day) => viewModel.getCalendarDay(day)}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
});
