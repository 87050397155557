import { calendarEventDuration } from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService } from '@/services';
import { RelativeDateKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_kind_pb';
import { RelativeDate } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_pb';
import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { format, formatDuration, intervalToDuration, isAfter, isBefore, isSameDay } from 'date-fns';
import { computed, makeObservable } from 'mobx';
import { ContextMenuActionsGroup } from '../../shared';
import {
  PlannerListItemInfo,
  PlannerListItemInfoInlineAction,
  PlannerListItemInfoKind,
  PlannerListItemInfoState,
  PlannerListItemInfoStepsInfo,
  PlannerListItemInfoUrl
} from './PlannerListItemInfo';

export class CalendarEventPlannerListItemInfo implements PlannerListItemInfo {
  constructor(
    private readonly _calendarEvent: CalendarEvent,
    private readonly _groupRelativeDate: RelativeDate | undefined,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  readonly kind: PlannerListItemInfoKind = 'calendar-event';

  @computed
  get id(): string {
    return this._calendarEvent.id;
  }

  @computed
  get uniqueId(): string {
    return this.id;
  }

  @computed
  get plannedWorkId(): string | undefined {
    return undefined;
  }

  @computed
  get title(): string {
    return this._calendarEvent.title;
  }

  @computed
  get description(): string | undefined {
    return this._calendarEvent.description?.text;
  }

  readonly icon = 'calendar-event';

  @computed
  get color(): string | undefined {
    return this._calendarEvent.color;
  }

  @computed
  get contextMenuActions(): ContextMenuActionsGroup[] {
    return [
      {
        actions: [
          {
            icon: { case: 'predefined', value: 'show-details' },
            hidden: this._calendarEvent.externalSource?.url == null,
            onClick: {
              externalUrl: this._calendarEvent.externalSource?.url,
              target: '_blank'
            }
          }
        ]
      }
    ];
  }

  readonly inlineActions: PlannerListItemInfoInlineAction[] = [];

  @computed
  get primaryDetail(): string | undefined {
    return this.formattedRelativeDate;
  }

  readonly primaryDetailKind = 'default';

  @computed
  get secondaryDetail(): string | undefined {
    if (this._calendarEvent.isAllDay) {
      return undefined;
    }

    const relativeDateKindShouldShowTime: RelativeDateKind[] = [
      RelativeDateKind.YESTERDAY,
      RelativeDateKind.TODAY,
      RelativeDateKind.TOMORROW
    ];

    if (this._groupRelativeDate == null || relativeDateKindShouldShowTime.includes(this._groupRelativeDate.kind)) {
      return this.formattedDuration;
    }

    return this.formattedTimeOnly;
  }

  @computed
  get state(): PlannerListItemInfoState {
    const now = this._dateService.now;

    if (
      !this._calendarEvent.isAllDay &&
      this._calendarEvent.endTime != null &&
      isBefore(timestampDate(this._calendarEvent.endTime), now)
    ) {
      return 'completed';
    }

    if (this._calendarEvent.startTime == null || this._calendarEvent.endTime == null) {
      return 'active';
    }

    if (isSameDay(timestampDate(this._calendarEvent.startTime), now) && this._calendarEvent.isAllDay) {
      return 'active';
    }

    return isBefore(timestampDate(this._calendarEvent.startTime), now) &&
      isAfter(timestampDate(this._calendarEvent.endTime), now)
      ? 'current'
      : 'active';
  }

  @computed
  get url(): PlannerListItemInfoUrl | undefined {
    if (this._calendarEvent.externalSource == null) {
      return undefined;
    }

    return {
      kind: 'external',
      to: this._calendarEvent.externalSource.url,
      target: '_blank'
    };
  }

  @computed
  get stepsInfo(): PlannerListItemInfoStepsInfo | undefined {
    return undefined;
  }

  @computed
  protected get formattedDuration(): string | undefined {
    const eventDuration = calendarEventDuration(this._calendarEvent);
    if (this._calendarEvent.isAllDay || eventDuration == null) {
      return undefined;
    }

    const duration = intervalToDuration({ start: 0, end: eventDuration * 60 * 1_000 });
    return formatDuration(duration);
  }

  @computed
  protected get formattedTimeOnly(): string | undefined {
    if (this._calendarEvent.startTime == null) {
      return undefined;
    }

    const strings = this._localization.localizedStrings.dateTime;

    if (this._calendarEvent.isAllDay) {
      return strings.allDayPlannedWork;
    }

    return format(timestampDate(this._calendarEvent.startTime), strings.plannedWorkStartTimeFormat);
  }

  @computed
  protected get formattedRelativeDate(): string | undefined {
    if (this._calendarEvent.startTime == null) {
      return undefined;
    }

    const relativeDateKindShouldShowTime: RelativeDateKind[] = [
      RelativeDateKind.YESTERDAY,
      RelativeDateKind.TODAY,
      RelativeDateKind.TOMORROW
    ];

    if (this._groupRelativeDate == null || relativeDateKindShouldShowTime.includes(this._groupRelativeDate.kind)) {
      return this.formattedTimeOnly;
    }

    const relativeDateKindShouldShowDayOfWeek: RelativeDateKind[] = [
      RelativeDateKind.THIS_WEEK,
      RelativeDateKind.NEXT_WEEK
    ];

    if (relativeDateKindShouldShowDayOfWeek.includes(this._groupRelativeDate.kind)) {
      // Displaying day of week for planned work this or next week.
      return format(timestampDate(this._calendarEvent.startTime), 'iiii');
    }

    // Otherwise, displaying date.
    return format(timestampDate(this._calendarEvent.startTime), 'PP');
  }
}
