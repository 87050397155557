import { useServices } from '@/hooks';
import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Circle } from '@mui/icons-material';
import { alpha, Divider, ListItemButton, Stack, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { format, isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { WorkloadInformationView } from '../../shared';

export interface PlannerWorkloadDateListItemProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
  date: Date;
  courseSection: CourseSectionDetails;
  isSelected: boolean;
  onSelect: (date: Date) => void;
  hasOccurrence: boolean;
  showDivider?: boolean;
}

export const PlannerWorkloadDateListItem = observer(
  ({
    sx = [],
    className,
    viewModel,
    date,
    isSelected,
    courseSection,
    onSelect,
    hasOccurrence,
    showDivider = true
  }: PlannerWorkloadDateListItemProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const plannerDay = viewModel.getPlannerDay(date);
    const isToday = isSameDay(date, dateService.now);

    return (
      <>
        <ListItemButton
          sx={sx}
          className={className}
          onClick={() => onSelect(date)}
          selected={isSelected}
          disableGutters
        >
          <Stack spacing={1} direction="row" alignItems="center" mx={1} sx={{ width: '100%' }}>
            <Stack sx={{ flex: 1 }} spacing={1} direction="row" alignItems="center">
              <Circle color="primary" sx={{ width: 8, height: 8, opacity: hasOccurrence ? 1 : 0 }} />
              <Stack spacing={0} direction="row" alignItems="baseline">
                <Typography variant="caption" color="textSecondary">
                  {format(date, 'EE')}&nbsp;
                  {isToday && <>&nbsp;</>}
                </Typography>

                <Box sx={{ position: 'relative' }}>
                  {isToday && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: `calc(100% + ${theme.spacing(1)})`,
                        height: `calc(100% + ${theme.spacing(0.5)})`,
                        top: `calc(-1 * ${theme.spacing(0.25)})`,
                        left: `calc(-1 * ${theme.spacing(0.5)})`,
                        backgroundColor: alpha(theme.palette.error.main, 0.5),
                        borderRadius: 1_000
                      }}
                    />
                  )}

                  <Typography
                    color={hasOccurrence ? 'textPrimary' : 'textSecondary'}
                    variant="body2"
                    sx={{ fontWeight: '500', position: 'relative' }}
                  >
                    {format(date, 'PP')}
                  </Typography>
                </Box>
              </Stack>

              {plannerDay != null && !plannerDay.isDayOfWeekCycleDayName && (
                <Typography variant="caption" color="textSecondary">
                  {plannerDay.cycleDayName}
                </Typography>
              )}
            </Stack>

            {courseSection.schoolsCourseSection?.id && (
              <WorkloadInformationView
                date={date}
                getWorkloadInformation={() =>
                  viewModel.getCourseSectionWorkloadInformationForDate(courseSection.schoolsCourseSection!.id, date)
                }
              />
            )}
          </Stack>
        </ListItemButton>

        {showDivider && <Divider />}
      </>
    );
  }
);
