import { PlannerWorkloadPageViewModel } from '@/viewmodels';
import { ErrorRounded, FilterListRounded } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { Box, CircularProgress, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { usePlannerWorkloadForceReloadContext } from './PlannerWorkloadForceReloadContext';
import { PlannerWorkloadOptionsPopover } from './PlannerWorkloadOptionsPopover';

export interface PlannerWorkloadHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerWorkloadPageViewModel;
}

export const PlannerWorkloadHeader = observer(({ sx = [], className, viewModel }: PlannerWorkloadHeaderProps) => {
  const [optionsPopoverAnchorEl, setOptionsPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const strings = LocalizedStrings.planner.workload;
  const forceReloadContext = usePlannerWorkloadForceReloadContext();

  function onReloadButtonClick() {
    forceReloadContext.onReload?.();
  }

  return (
    <Stack direction="row" alignItems="flex-start" sx={sx} className={className}>
      <Stack sx={{ flex: 1 }}>
        <Typography variant="h6">{strings.title()}</Typography>
        <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '500' }}>
          {strings.subtitle()}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {viewModel.isFetchingData ? (
          <Tooltip title={strings.loadingDataTooltip()}>
            <CircularProgress size={20} />
          </Tooltip>
        ) : (
          <IconButton onClick={() => onReloadButtonClick()}>
            <RefreshIcon />
          </IconButton>
        )}

        {viewModel.hasFetchError && !viewModel.isFetchingData && (
          <Tooltip title={strings.loadDataErrorTooltip()}>
            <ErrorRounded color="error" />
          </Tooltip>
        )}

        {viewModel.hasData && viewModel.allClasses.length > 0 && (
          <Box>
            <IconButton onClick={(e) => setOptionsPopoverAnchorEl(e.currentTarget)}>
              <FilterListRounded />
            </IconButton>

            <PlannerWorkloadOptionsPopover
              close={() => setOptionsPopoverAnchorEl(null)}
              open={optionsPopoverAnchorEl != null}
              anchorEl={optionsPopoverAnchorEl}
              courseSections={viewModel.allClasses}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
});
