import { useServices } from '@/hooks';
import EmptyListIcon from '@mui/icons-material/WbSunnyRounded';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator } from '../../../utils';

export interface PlannerUpcomingListEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
  size?: 'small' | 'regular';
}

export const PlannerUpcomingListEmptyIndicator = observer(
  ({ sx, className, size }: PlannerUpcomingListEmptyIndicatorProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.workList;

    return (
      <EmptyListIndicator
        sx={sx}
        className={className}
        size={size}
        title={strings.upcomingEmptyListTitle}
        subtitle={strings.upcomingEmptyListSubtitle}
        renderIcon={(className) => <EmptyListIcon className={className} />}
      />
    );
  }
);
