import { Box, Typography, TypographyProps } from '@mui/material';

export interface TableTextCellProps extends TypographyProps {
  /**
   * Value displayed by default if the text is empty. Defaults to '-'.
   */
  defaultTextValue?: string;
}
export function TableTextCell({ defaultTextValue = '', children, ...props }: TableTextCellProps) {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography {...props} variant="body2">
        {children ?? defaultTextValue}
      </Typography>
    </Box>
  );
}
