import { TextFormat } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/text_format_pb';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CommonRichText } from '../../../models';
import './RichTextViewer.styles.css';
import { RichTextViewerHtml } from './RichTextViewerHtml';
import { RichTextViewerPlainText } from './RichTextViewerPlainText';
import { RichTextViewerTextSize } from './RichTextViewerUtils';

export interface RichTextViewerProps {
  sx?: SxProps;
  className?: string;
  id: string;
  label: string;
  content: CommonRichText | undefined;
  textSize?: RichTextViewerTextSize;
  canSelectText?: boolean;
}

export const RichTextViewer = observer(
  ({ sx, className, id, content, label, textSize, canSelectText }: RichTextViewerProps) => {
    switch (content?.format ?? TextFormat.PLAIN_TEXT) {
      case TextFormat.HTML:
        return (
          <RichTextViewerHtml
            id={id}
            content={content?.text}
            sx={sx}
            className={className}
            label={label}
            textSize={textSize}
            canSelectText={canSelectText}
          />
        );

      default:
        return (
          <RichTextViewerPlainText
            content={content?.text}
            textSize={textSize}
            canSelectText={canSelectText}
            sx={sx}
            className={className}
          />
        );
    }
  }
);
