import { useViewModel } from '@/hooks';
import { isSxArray } from '@/utils';
import { Box, SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { AdminWorkloadViewModel } from '../../../viewmodels';
import { AdminContentCard } from '../shared';
import { AdminWorkloadCalendar } from './AdminWorkloadCalendar';
import {
  AdminWorkloadForceReloadContext,
  AdminWorkloadForceReloadContextValue
} from './AdminWorkloadForceReloadContext';
import { AdminWorkloadListView } from './AdminWorkloadListView';
import './AdminWorkloadPage.styles.css';

type AdminWorkloadDisplayKind = 'calendar' | 'list';

export interface AdminWorkloadPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminWorkloadPage = observer(({ sx = [], className }: AdminWorkloadPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const [searchParams] = useSearchParams();
  const displayKind = (searchParams.get('display') as AdminWorkloadDisplayKind | null) ?? 'calendar';
  const strings = LocalizedStrings.admin.workload;

  const viewModel = useViewModel((viewModels) => viewModels.createAdminWorkloadViewModel(schoolId), [schoolId]);

  return (
    <Box
      sx={[{ display: 'flex', flexDirection: 'column' }, ...(isSxArray(sx) ? sx : [sx])]}
      className={clsx('school-admin-workload', className)}
    >
      <AdminWorkloadForceReloadContext value={new AdminWorkloadForceReloadContextValue()}>
        <AdminContentCard title={strings.title()} subtitle={strings.subtitle()} sx={{ flex: 1 }}>
          {renderSubview(displayKind, viewModel)}
        </AdminContentCard>
      </AdminWorkloadForceReloadContext>
    </Box>
  );
});

function renderSubview(displayKind: AdminWorkloadDisplayKind, viewModel: AdminWorkloadViewModel) {
  switch (displayKind) {
    case 'calendar':
      return <AdminWorkloadCalendar sx={{ flex: 1, pt: 1 }} viewModel={viewModel} />;
    case 'list':
      return <AdminWorkloadListView sx={{ flex: 1, pt: 1 }} viewModel={viewModel} />;
  }
}
