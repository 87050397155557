import { Course } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/resources/course_pb';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { CurriculumTransportService, GrpcTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppCurriculumTransportService extends AppBaseTransportService implements CurriculumTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getCourses(): Promise<Course[]> {
    const response = await this.performReadRequest('getCurriculumCourses', (headers) =>
      this._grpcTransport.curriculumClient.getCourses({}, { headers })
    );

    return response.courses;
  }

  async getEnrolledCourseIdsInPlanner(plannerId: string): Promise<string[]> {
    const response = await this.performReadRequest('getEnrolledCourseIdsInPlanner', (headers) =>
      this._grpcTransport.curriculumClient.getEnrolledCourseIds({ plannerId }, { headers })
    );

    return response.courseIds;
  }

  async setEnrolledCourseIdsInPlanner(plannerId: string, courseIds: string[]): Promise<void> {
    await this.performWriteRequest('setEnrolledCourseIdsInPlanner', (headers) =>
      this._grpcTransport.curriculumClient.setEnrolledCourseIds({ plannerId, courseIds }, { headers })
    );
  }
}
