import { WorkloadGroupDetails } from '@/models';
import LocalizedStrings from '@/resources/strings';
import { isSxArray } from '@/utils';
import { KeyboardArrowDownRounded, PersonRounded } from '@mui/icons-material';
import { alpha, Collapse, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { PlannerWorkloadItemsGroupStudentListPopover } from './PlannerWorkloadItemsGroupStudentListPopover';
import { PlannerWorkloadPublishedWorkListItem } from './PlannerWorkloadPublishedWorkListItem';

const collapseButtonVariants = {
  rotate: { rotate: '180deg', transition: { duration: 0.2 } },
  stop: { rotate: 0, transition: { duration: 0.2 } }
};

export interface PlannerWorkloadItemsGroupProps {
  sx?: SxProps;
  className?: string;
  group: WorkloadGroupDetails;
}

export const PlannerWorkloadItemsGroup = observer(({ sx = [], className, group }: PlannerWorkloadItemsGroupProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const theme = useTheme();
  const [studentListAnchorEl, setStudentListAnchorEl] = useState<HTMLButtonElement | null>(null);
  const strings = LocalizedStrings.planner.workload;

  return (
    <Stack sx={[{ borderRadius: 1, overflow: 'hidden' }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
      <Stack
        sx={{
          backgroundColor: theme.palette.divider,
          px: 1,
          py: 0.5
        }}
        direction="row"
        alignItems="center"
        spacing={0.5}
      >
        <Typography variant="subtitle2" sx={{ flex: 1 }}>
          {strings.workloadGroupStudentsCount(group.students.length)}
        </Typography>

        <Box>
          <Tooltip title={strings.workloadGroupViewStudentsTooltip()}>
            <IconButton size="small" onClick={(e) => setStudentListAnchorEl(e.currentTarget)}>
              <PersonRounded fontSize="small" />
            </IconButton>
          </Tooltip>

          {studentListAnchorEl != null && (
            <PlannerWorkloadItemsGroupStudentListPopover
              group={group}
              anchorEl={studentListAnchorEl}
              open
              onClose={() => setStudentListAnchorEl(null)}
            />
          )}
        </Box>

        <IconButton size="small" onClick={() => setIsExpanded((prev) => !prev)}>
          <motion.div
            variants={collapseButtonVariants}
            animate={isExpanded ? 'rotate' : 'stop'}
            style={{ display: 'flex' }}
          >
            <KeyboardArrowDownRounded fontSize="small" />
          </motion.div>
        </IconButton>
      </Stack>

      <Collapse in={isExpanded}>
        <Stack sx={{ py: 1, backgroundColor: alpha(theme.palette.divider, 0.05) }} spacing={0.5}>
          {group.works.map((work) => (
            <PlannerWorkloadPublishedWorkListItem key={work.publishedWork.id} work={work} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
});
