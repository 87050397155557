import { useServices } from '@/hooks';
import { CommonRichText } from '@/models';
import { BaseDialogActionButtonConfiguration, DialogActionButtonConfiguration } from '@/viewmodels';
import { TextFormat } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/text_format_pb';
import { OpenInFullRounded } from '@mui/icons-material';
import { Dialog, Divider, IconButton, SxProps } from '@mui/material';
import {
  RichTextEditor as GoRichTextEditor,
  RichTextEditorRef,
  SidebarMode,
  TextSize
} from '@studyoco/rich-text-editor';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ResponsiveDialogHeader } from '../dialog';
import './RichTextEditor.styles.css';
import { RichTextEditorNotchedInput } from './RichTextEditorNotchedInput';

export interface RichTextEditorProps {
  sx?: SxProps;
  className?: string;
  id: string;
  autoFocus?: boolean;
  label: string;
  alwaysDisplayLabel?: boolean;
  content: CommonRichText | undefined;
  onChange: (content: CommonRichText) => void;
  placeholder?: string;
  sidebarMode?: SidebarMode;
  textSize?: TextSize;
  disabled?: boolean;
}

export const RichTextEditor = ({
  sx = [],
  className,
  id,
  autoFocus = false,
  content,
  label,
  alwaysDisplayLabel = true,
  onChange,
  sidebarMode,
  placeholder,
  textSize,
  disabled = false
}: RichTextEditorProps) => {
  const editorRef = useRef<RichTextEditorRef>(null);
  const fullscreenEditorRef = useRef<RichTextEditorRef>(null);
  const { environment, intercom, localization } = useServices();
  const [showFullscreen, setShowFullscreen] = useState(false);
  const fileHostBasename = `${environment.fileHostBasename}/files`;

  useEffect(() => {
    if (showFullscreen) {
      intercom.hide();
    } else {
      intercom.show();
    }
  }, [showFullscreen, intercom]);

  const buttonAnimation = {
    hidden: {
      opacity: 0,
      transition: {
        type: 'easeInOut',
        duration: 0.2
      }
    },
    hover: {
      opacity: 1,
      transition: {
        type: 'easeInOut',
        duration: 0.2
      }
    }
  };

  const onContentChange = (content: string) => {
    onChange({ text: content, format: TextFormat.HTML });

    if (showFullscreen) {
      editorRef.current?.setContent(content);
    } else {
      fullscreenEditorRef.current?.setContent(content);
    }
  };

  const fullScreenButtonConfig = useMemo(
    () =>
      new BaseDialogActionButtonConfiguration(
        'secondary',
        'top-only',
        'hidden',
        'reduce',
        undefined,
        () => '', // No title, will always be shown as an icon.
        'contained',
        () => {
          setShowFullscreen(false);
          return Promise.resolve();
        }
      ),
    []
  );

  const rightDialogActions: DialogActionButtonConfiguration[] = useMemo(
    () => [fullScreenButtonConfig],
    [fullScreenButtonConfig]
  );

  return (
    <RichTextEditorNotchedInput
      id="rich-text-editor"
      label={alwaysDisplayLabel ? label : ''}
      sx={sx}
      className={className}
      component={motion.section}
      initial="hidden"
      whileHover="hover"
    >
      <div className="rich-text-editor-content-container">
        <GoRichTextEditor
          id={id}
          ref={editorRef}
          autofocus={autoFocus}
          content={content?.text ?? ''}
          onChange={onContentChange}
          locale={localization.currentLocale}
          disabled={disabled}
          options={{
            transloaditKey: environment.transloadit.key,
            fileHostBasename,
            isReadOnly: false,
            sidebarMode,
            placeholder,
            textSize
          }}
          className="rich-text-editor-content"
          contentSx={{ p: 1.5, width: '100%' }}
        />
      </div>

      <motion.div className="rich-text-editor-full-screen-button" variants={buttonAnimation}>
        <IconButton onClick={() => setShowFullscreen(true)} size="small">
          <OpenInFullRounded fontSize="small" />
        </IconButton>
      </motion.div>

      <Dialog
        open={showFullscreen}
        onClose={() => setShowFullscreen(false)}
        PaperProps={{ className: 'rich-text-editor-full-screen-dialog' }}
      >
        <ResponsiveDialogHeader
          title={label}
          leftButtons={[]}
          rightButtons={rightDialogActions}
          isDraggable={false}
          id={'rich-text-editor-fullscreen'}
        />

        <Divider />

        <GoRichTextEditor
          id={`${id}-full-screen`}
          ref={fullscreenEditorRef}
          autofocus
          content={content?.text ?? ''}
          onChange={onContentChange}
          locale={localization.currentLocale}
          disabled={disabled}
          options={{
            transloaditKey: environment.transloadit.key,
            fileHostBasename,
            isReadOnly: false,
            sidebarMode: 'both',
            placeholder
          }}
          className="rich-text-editor-full-screen-dialog-editor"
          contentSx={{ p: 3, width: '100%' }}
        />
      </Dialog>
    </RichTextEditorNotchedInput>
  );
};
