import { useServices } from '@/hooks';
import { WorkIconInfo } from '@/models';
import { ThemeVariant } from '@/services';
import { css } from '@emotion/css';
import DefaultIcon from '@mui/icons-material/HelpOutlineRounded';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { isSxArray } from '../../utils';
import { ColorIndicator } from './ColorIndicator';

export interface WorkIconProps {
  sx?: SxProps;
  className?: string;

  /**
   * The work icon urls. Optional. Will fall back to `HelpOutlineRounded` icon
   */
  icon?: WorkIconInfo;

  color?: string;

  /**
   * The icon's theme to use. Optional.
   */
  themeVariant?: ThemeVariant;

  /**
   * Indicates whether to display the work's course section color. Optional. Default is `false`.
   * The disabled theme color will be used when work has no course section. The color won't be displayed when a work is ot specified.
   */
  displayCourseSectionColor?: boolean;

  /**
   * The icon size. Optional. Default is 24.
   */
  size?: number | string;
}

export const WorkIcon = observer(
  ({ sx = [], className, icon, color, size = 24, themeVariant, displayCourseSectionColor }: WorkIconProps) => {
    const { theme } = useServices();
    const muiTheme = useTheme();
    const variant = themeVariant ?? theme.variant;
    const url = variant === 'light' ? icon?.lightUrl : icon?.darkUrl;

    const badgeClassName = css({
      position: 'absolute',
      top: 0,
      left: 0,
      width: size,
      height: size
    });

    const externalSourceBadge = renderExternalBadgeForUrl(icon?.externalBadgeUrl, badgeClassName);

    return (
      <Box
        sx={[
          {
            display: 'flex'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        {/* Course section color */}
        {displayCourseSectionColor && <ColorIndicator sx={{ mr: 0.5 }} color={color} />}
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            width: size,
            height: size
          }}
        >
          {/* Icon */}
          {url != null ? (
            <img className={className} alt={`${icon?.title} icon`} src={url} style={{ width: size, height: size }} />
          ) : (
            <DefaultIcon
              className={className}
              style={{
                width: size,
                height: size,
                color: muiTheme.palette.text.primary
              }}
            />
          )}

          {externalSourceBadge}
        </Box>
      </Box>
    );
  }
);

function renderExternalBadgeForUrl(url: string | undefined, className: string) {
  if (url == null) {
    return undefined;
  }

  return <img className={className} alt={`item-source-badge`} src={url} />;
}
