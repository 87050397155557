import { Day, dayToPBDate, SchoolAccountCourseSections, UserDailyActivity, UserDailyEmotionalStatus } from '@/models';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '@/services';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { AccessKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/access_kind_pb';
import { AccountInfo } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_info_pb';
import { AccountKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_kind_pb';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { School } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { zipWith } from 'lodash';
import {
  DailyWorkloadResponse,
  GrpcTransportService,
  SchoolTransportService,
  UseSchoolParticipationCodeResponse
} from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppSchoolTransportService extends AppBaseTransportService implements SchoolTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getParticipatingSchools(): Promise<SchoolInformation[]> {
    const response = await this.performReadRequest('getParticipatingSchools', (headers) =>
      this._grpcTransport.plannersClient.getParticipatingSchools({}, { headers })
    );

    return response.schools;
  }

  async createSchool(name: string, subtitle: string | undefined, sharingMode: SchoolSharingMode): Promise<School> {
    return await this.performWriteRequest('createSchool', (headers) =>
      this._grpcTransport.schoolsClient.createSchool(
        {
          name,
          subtitle,
          sharingMode
        },
        { headers }
      )
    );
  }

  async editSchool(schoolId: string, name: string, subtitle: string | undefined): Promise<School> {
    return await this.performWriteRequest(
      'editSchool',
      (headers) => this._grpcTransport.schoolsClient.renameSchool({ schoolId, name, subtitle }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async shareSchool(schoolId: string, inheritedScheduleCycleId: string | undefined): Promise<void> {
    await this.performWriteRequest(
      'shareSchool',
      (headers) => this._grpcTransport.schoolsClient.shareSchool({ schoolId, inheritedScheduleCycleId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async unshareSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'unshareSchool',
      (headers) => this._grpcTransport.schoolsClient.unshareSchool({ schoolId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async archiveSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'archiveSchool',
      (headers) => this._grpcTransport.schoolsClient.archiveSchool({ schoolId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async unarchiveSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'unarchiveSchool',
      (headers) => this._grpcTransport.schoolsClient.unarchiveSchool({ schoolId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async getCourseSectionInSchool(schoolId: string, courseId: string, timezone: string): Promise<CourseSection> {
    return await this.performReadRequest(
      'getSchoolCourseSection',
      (headers) =>
        this._grpcTransport.schoolsClient.getSchoolCourseSection(
          { schoolId, courseSectionId: courseId, timezone },
          { headers }
        ),
      { isDemoObject: this.isDemoId(courseId), supportsDemoMode: true }
    );
  }

  async getSchoolAccounts(
    schoolId: string,
    accountKinds: AccountKind[],
    accountIds: string[] | undefined,
    includeDeletedAccounts: boolean
  ): Promise<Account[]> {
    const response = await this.performReadRequest('getSchoolAccounts', (headers) =>
      this._grpcTransport.schoolsClient.getSchoolAccounts(
        {
          schoolId,
          accountKinds,
          accountIds,
          includeDeletedAccounts
        },
        { headers }
      )
    );

    return response.accounts;
  }

  async getSchoolAccount(accountId: string, schoolId: string): Promise<Account> {
    return await this.performReadRequest('getSchoolAccount', (headers) =>
      this._grpcTransport.schoolsClient.getSchoolAccount(
        {
          accountId,
          schoolId
        },
        { headers }
      )
    );
  }

  async batchCreateSchoolAccounts(schoolId: string, accounts: AccountInfo[], kind: AccountKind): Promise<Account[]> {
    const response = await this.performWriteRequest('batchCreateSchoolAccounts', (headers) =>
      this._grpcTransport.schoolsClient.batchCreateSchoolAccounts(
        {
          schoolId,
          accountKind: kind,
          sendInvitationEmail: true,
          accountInfos: accounts
        },
        { headers }
      )
    );

    return response.accounts;
  }

  async sendTeacherInvitationEmail(schoolId: string, accountIds: string[]): Promise<void> {
    await this.performWriteRequest('sendTeacherInvitationEmailRequest', (headers) =>
      this._grpcTransport.schoolsClient.sendTeacherInvitationEmail({ schoolId, accountIds }, { headers })
    );
  }

  async getSchoolCourseSections(schoolId: string): Promise<CourseSection[]> {
    const response = await this.performReadRequest(
      'getSchoolCourseSections',
      (headers) => this._grpcTransport.schoolsClient.getCourseSections({ schoolId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId), supportsDemoMode: true }
    );
    return response.courseSections;
  }

  async getSchoolDemoCourseSections(schoolId: string): Promise<CourseSection[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetSchoolCourseSections will already return the demo course sections.
      return [];
    }

    const response = await this.performReadRequest(
      'getSchoolDemoCourseSections',
      (headers) => this._grpcTransport.schoolsClient.getCourseSections({ schoolId }, { headers }),
      { isDemoObject: true }
    );
    return response.courseSections;
  }

  async getSchoolCourseSectionsForAccount(schoolId: string, accountId: string): Promise<SchoolAccountCourseSections> {
    const response = await this.performReadRequest(
      'getSchoolCourseSectionsForAccount',
      (headers) => this._grpcTransport.schoolsClient.getAccountCourseSections({ schoolId, accountId }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) || this.isDemoId(accountId) }
    );

    return { student: response.studentCourseSections, teacher: response.teacherCourseSections };
  }

  async updateCourseSectionColorInSchool(courseId: string, backgroundColor: string): Promise<CourseSection> {
    return await this.performWriteRequest(
      'updateCourseSectionColorInSchool',
      (headers) =>
        this._grpcTransport.schoolsClient.setCourseSectionColor(
          { courseSectionId: courseId, color: backgroundColor },
          { headers }
        ),
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async getStudentDailyActivity(
    accountId: string,
    schoolId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<UserDailyActivity[]> {
    const response = await this.performReadRequest(
      'getStudentDailyActivity',
      (headers) =>
        this._grpcTransport.schoolsClient.getStudentDailyActivity(
          { schoolId, accountId, minDate: dayToPBDate(minDate), maxDate: dayToPBDate(maxDate) },
          { headers }
        ),
      { isDemoObject: this.isDemoId(accountId), supportsDemoMode: true }
    );

    return zipWith(response.dates, response.values, (date, value) => ({
      date: { year: date.year, month: date.month, day: date.day },
      value
    }));
  }

  async getStudentDailyEmotionalStatus(
    accountId: string,
    schoolId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<UserDailyEmotionalStatus[]> {
    const response = await this.performReadRequest(
      'getStudentDailyEmotionalStatus',
      (headers) =>
        this._grpcTransport.schoolsClient.getStudentDailyEmotionalPulseRating(
          { schoolId, accountId, minDate: dayToPBDate(minDate), maxDate: dayToPBDate(maxDate) },
          { headers }
        ),
      { isDemoObject: this.isDemoId(accountId), supportsDemoMode: true }
    );

    return zipWith(response.dates, response.values, (date, value) => ({
      date: { year: date.year, month: date.month, day: date.day },
      value
    }));
  }

  async getParticipationCode(schoolId: string, accountKind: AccountKind): Promise<string> {
    const response = await this.performReadRequest(
      'getParticipationCode',
      (headers) => this._grpcTransport.schoolsClient.getParticipationCode({ schoolId, accountKind }, { headers }),
      { isDemoObject: this.isDemoId(schoolId) }
    );

    return response.participationCode;
  }

  async useParticipationCode(code: string): Promise<UseSchoolParticipationCodeResponse> {
    const response = await this.performWriteRequest('useSchoolParticipationCode', (headers) =>
      this._grpcTransport.schoolsClient.useParticipationCode({ participationCode: code }, { headers })
    );

    return { schoolId: response.schoolId, accountKind: response.accountKind };
  }

  async getSchool(schoolId: string): Promise<School> {
    return await this.performReadRequest('getSchool', (headers) =>
      this._grpcTransport.schoolsClient.getSchool({ schoolId }, { headers })
    );
  }

  async changeSchoolOwnership(schoolId: string, newOwnerUserId: string): Promise<void> {
    await this.performWriteRequest('changeSchoolOwnership', (headers) =>
      this._grpcTransport.schoolsClient.changeSchoolOwnership(
        {
          schoolId,
          newOwnerUserId
        },
        { headers }
      )
    );
  }

  async addSchoolAccess(schoolId: string, userId: string, accessKind: AccessKind): Promise<void> {
    await this.performWriteRequest('addSchoolAccess', (headers) =>
      this._grpcTransport.schoolsClient.addSchoolAccess(
        {
          schoolId,
          userId,
          accessKind
        },
        { headers }
      )
    );
  }

  async removeSchoolAccess(schoolId: string, userId: string): Promise<void> {
    await this.performWriteRequest('removeSchoolAccess', (headers) =>
      this._grpcTransport.schoolsClient.removeSchoolAccess(
        {
          schoolId,
          userId
        },
        { headers }
      )
    );
  }

  async getDailyWorkload(
    schoolId: string,
    courseSectionIds: string[],
    startDate: Day,
    endDate: Day
  ): Promise<DailyWorkloadResponse> {
    const response = await this.performReadRequest(
      'getDailyWorkload',
      (headers) =>
        this._grpcTransport.schoolsClient.getDailyWorkload(
          {
            schoolId,
            sampledCourseSectionIds: courseSectionIds,
            startDate: dayToPBDate(startDate),
            endDate: dayToPBDate(endDate),
            timezone: this._localization.currentTimezone
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(schoolId) }
    );

    return {
      dailyWorkloads: response.dailyWorkloads,
      courseSections: response.courseSections
    };
  }
}
