import { ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import { UpdatablePresenter } from '../components';
import { PlannerScheduleCycleList } from '../components/planner/settings/PlannerScheduleCycleList';
import { useActivePlannerId, useServices, useViewModel } from '../hooks';

export interface ScheduleCycleConfigRouteWrapperProps {
  children?: ReactNode;
}

export const ScheduleCycleConfigRouteWrapper = ({ children }: ScheduleCycleConfigRouteWrapperProps) => {
  const { route } = useServices();
  const params = useParams();
  const scheduleCycleId = params.scheduleCycleId;
  const plannerId = useActivePlannerId();
  const location = useLocation();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createSchedulesListViewModel(plannerId, 'planner', false),
    [plannerId]
  );

  if (scheduleCycleId != null) {
    return children;
  } else if (location.pathname.endsWith('/schedules')) {
    // Because the scheduleCycleId param is now optional, the same path is resolved for the details of
    // a schedule cycle and the list of schedule cycles. So if the path ends with /schedules, we render
    // the list of schedule cycles.
    return <PlannerScheduleCycleList />;
  }

  return (
    <UpdatablePresenter
      viewModel={viewModel}
      renderData={() => {
        if (viewModel.schedules.length > 0) {
          const path = location.pathname;
          const schedulesParam = '/schedules';
          const schedulesParamIndex = path.indexOf(`${schedulesParam}/`);

          const schedule = viewModel.schedules[0];
          let newScheduleParam = `/schedules/${schedule.scheduleCycle.scheduleCycle.id}`;
          if (schedule.userDashboard.kind === 'school') {
            newScheduleParam += `/s/${schedule.userDashboard.id}`;
          }

          const newPath = `${path.slice(0, schedulesParamIndex)}${newScheduleParam}${path.slice(schedulesParamIndex + schedulesParam.length)}`;
          return <Navigate to={newPath} replace />;
        } else {
          // No schedules available, redirect to the list.
          const newPath = route.resolvePlannerSettingsLocation('schedules', plannerId);
          return <Navigate to={newPath} replace />;
        }
      }}
    />
  );
};
