import i18n from 'translate';

export const WorkEditApplyToCopiesDialogStrings = {
  applyOnlyToThisCopyButtonTitle: () => i18n.t('work.edit.applyToCopiesDialog.applyOnlyToThisCopyButtonTitle'),
  applyOnlyToThisCopyTooltip: () => i18n.t('work.edit.applyToCopiesDialog.applyOnlyToThisCopyTooltip'),
  applyToAllButtonTitle: () => i18n.t('work.edit.applyToCopiesDialog.applyToAllButtonTitle'),
  applyToAllTooltip: () => i18n.t('work.edit.applyToCopiesDialog.applyToAllTooltip'),
  message: () => i18n.t('work.edit.applyToCopiesDialog.message'),
  title: () => i18n.t('work.edit.applyToCopiesDialog.title')
};

export const WorkEditStrings = {
  addCourseTitle: () => i18n.t('work.edit.addCourseTitle'),
  addTitle: () => i18n.t('work.edit.addTitle'),
  applyToCopiesDialog: WorkEditApplyToCopiesDialogStrings,
  cancelButtonTitle: () => i18n.t('work.edit.cancelButtonTitle'),
  cancelWork: () => i18n.t('work.edit.cancelWork'),
  cancelWorkConfirmationButton: () => i18n.t('work.edit.cancelWorkConfirmationButton'),
  cancelWorkConfirmationMessage: () => i18n.t('work.edit.cancelWorkConfirmationMessage'),
  cancelWorkConfirmationTitle: () => i18n.t('work.edit.cancelWorkConfirmationTitle'),
  courseExternalSourceName: (sourceName: string) => i18n.t('work.edit.courseExternalSourceName', { sourceName }),
  courseNoneValue: () => i18n.t('work.edit.courseNoneValue'),
  courseTitle: () => i18n.t('work.edit.courseTitle'),
  createButtonTitle: () => i18n.t('work.edit.createButtonTitle'),
  dueDateDateFormat: () => i18n.t('work.edit.dueDateDateFormat'),
  dueDateDateLabel: () => i18n.t('work.edit.dueDateDateLabel'),
  dueDateSectionTitle: () => i18n.t('work.edit.dueDateSectionTitle'),
  dueDateTimeLabel: () => i18n.t('work.edit.dueDateTimeLabel'),
  editTitle: () => i18n.t('work.edit.editTitle'),
  fullScreenButtonTooltip: () => i18n.t('work.edit.fullScreenButtonTooltip'),
  importanceCriticalTitle: () => i18n.t('work.edit.importanceCriticalTitle'),
  importanceLevelTitle: () => i18n.t('work.edit.importanceLevelTitle'),
  importanceMajorTitle: () => i18n.t('work.edit.importanceMajorTitle'),
  importanceRegularTitle: () => i18n.t('work.edit.importanceRegularTitle'),
  importanceReminderTitle: () => i18n.t('work.edit.importanceReminderTitle'),
  informationSectionTitle: () => i18n.t('work.edit.informationSectionTitle'),
  instructionsLabel: () => i18n.t('work.edit.instructionsLabel'),
  instructionsSectionTitle: () => i18n.t('work.edit.instructionsSectionTitle'),
  reduceSizeButtonTooltip: () => i18n.t('work.edit.reduceSizeButtonTooltip'),
  saveButtonTitle: () => i18n.t('work.edit.saveButtonTitle'),
  titleLabel: () => i18n.t('work.edit.titleLabel'),
  titleSectionTitle: () => i18n.t('work.edit.titleSectionTitle'),
  workIconTitle: () => i18n.t('work.edit.workIconTitle')
};
