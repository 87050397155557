import {
  compareDays,
  CourseSectionInfo,
  createActivitySchedule,
  createPeriod,
  createPeriodSchedule,
  createPeriodScheduleOccurrence,
  createSpecialDay,
  createSpecialDayOccurrence,
  createTerm,
  Day,
  DayOfWeek,
  dayToPBDate,
  rangesOfDaysAreOverlapping,
  ScheduleCycleInfo,
  SuggestedPeriod,
  TimeOfDay,
  UserDashboardInfo
} from '@/models';
import { ScheduleCycleTransportService } from '@/transports';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { ActivitySchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_schedule_pb';
import { Calendar } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { PeriodSchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { SpecialDayOccurrence } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_occurrence_pb';
import { SpecialDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { captureException } from '@sentry/react';
import { chain, isEqual, remove } from 'lodash';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { v4 as uuidV4 } from 'uuid';
import { isError } from '../../utils';
import { cloneScheduleCycle, ScheduleCycleKind } from '../../viewmodels';
import {
  CreateOrUpdateCycleDayActivityInfos,
  CreateOrUpdateSpecialDayInfos,
  PublishScheduleCycleChangesResult,
  ScheduleCycleDataStore,
  ScheduleCycleDataStoreAddActivityScheduleWhen,
  ScheduleCyclePeriodAt,
  SpecialDayOccurrenceWhen,
  StoreInvalidator
} from '../contracts';

export class AppScheduleCycleDataStore implements ScheduleCycleDataStore {
  @observable private _scheduleCycleInfo: ScheduleCycleInfo | Error | undefined;
  @observable private _failedSaveDraftScheduleCycle: ScheduleCycle | undefined;

  constructor(
    public readonly id: string,
    private readonly _userDashboard: UserDashboardInfo,
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService,
    private readonly _storeInvalidator: StoreInvalidator
  ) {
    makeObservable(this);

    reaction(
      () => _storeInvalidator.plannersRevision + _storeInvalidator.schoolsRevision,
      () => this.invalidate()
    );
  }

  @computed
  private get scheduleCycleInfo(): ScheduleCycleInfo {
    if (isError(this._scheduleCycleInfo) || this._scheduleCycleInfo == null) {
      throw new Error('Cannot access schedule cycle as it has not been loaded.');
    }

    return this._scheduleCycleInfo;
  }

  @computed
  get state(): 'fulfilled' | 'pending' | Error {
    if (isError(this._scheduleCycleInfo)) {
      return this._scheduleCycleInfo;
    }

    return this._scheduleCycleInfo != null ? 'fulfilled' : 'pending';
  }

  @computed
  get scheduleCycle(): ScheduleCycle {
    if (isError(this._scheduleCycleInfo) || this._scheduleCycleInfo == null) {
      throw new Error('Cannot access schedule cycle has it has not been loaded.');
    }

    return this._scheduleCycleInfo.scheduleCycle;
  }

  @computed
  get draftSessionId(): string {
    return this.scheduleCycleInfo.draftSessionId;
  }

  @computed
  get revision(): number {
    return this.scheduleCycle.revision;
  }

  @computed
  get hasSaveDraftError(): boolean {
    return this._failedSaveDraftScheduleCycle != null;
  }

  async fetch() {
    if (isError(this._scheduleCycleInfo)) {
      runInAction(() => (this._scheduleCycleInfo = undefined));
    }

    try {
      const scheduleCycle = await this._scheduleCycleTransport.getScheduleCycle(this.id, {
        case: 'allowDraftScheduleCycle',
        value: true
      });
      runInAction(() => (this._scheduleCycleInfo = scheduleCycle));
    } catch (e) {
      if (this._scheduleCycleInfo == null) {
        runInAction(() => (this._scheduleCycleInfo = e as Error));
      } else {
        captureException(e);
      }
    }
  }

  // Remove once implemented
  /* istanbul ignore next */
  async undo() {
    if (this.draftSessionId.length === 0) {
      throw new Error('No changes to undo.');
    }

    const draftSessionId = this.scheduleCycleInfo.draftSessionId;
    const response = await this.discardLastDraftScheduleCycle(
      this.scheduleCycle.id,
      draftSessionId,
      this._userDashboard
    );

    runInAction(
      () =>
        (this._scheduleCycleInfo = {
          scheduleCycle: response.scheduleCycle,
          draftSessionId: response.isDraft ? draftSessionId : '',
          availableDraftSessionId: ''
        })
    );
    this._storeInvalidator.invalidateCalendar();
  }

  async cancelChanges() {
    if (this.draftSessionId.length === 0) {
      throw new Error('No changes to cancel.');
    }

    const draftSessionId = this.scheduleCycleInfo.draftSessionId;
    const scheduleCycle = await this.discardAllDraftScheduleCycles(
      this.scheduleCycle.id,
      draftSessionId,
      this._userDashboard
    );
    runInAction(
      () =>
        (this._scheduleCycleInfo = {
          scheduleCycle: scheduleCycle,
          draftSessionId: '',
          availableDraftSessionId: ''
        })
    );
    this._storeInvalidator.invalidateCalendar();
  }

  async saveChanges(): Promise<PublishScheduleCycleChangesResult> {
    if (this.draftSessionId.length === 0) {
      throw new Error('No changes to save');
    }

    const draftSessionId = this.scheduleCycleInfo.draftSessionId;
    const { scheduleCycle, hasDroppedChanges } = await this.publishDraftScheduleCycle(
      this.scheduleCycle.id,
      draftSessionId,
      this._userDashboard
    );

    runInAction(
      () =>
        (this._scheduleCycleInfo = {
          scheduleCycle,
          draftSessionId: '',
          availableDraftSessionId: ''
        })
    );
    this._storeInvalidator.invalidateCalendar();

    return { hasDroppedChanges };
  }

  async retrySaveDraft(): Promise<void> {
    if (this._failedSaveDraftScheduleCycle != null) {
      await this.createDraft(this._failedSaveDraftScheduleCycle);
    }
  }

  async updateScheduleDetails(
    name: string,
    startDay: Day,
    endDay: Day,
    daysPerCycle: number,
    isDayOfWeekAligned: boolean
  ): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    scheduleCycleCopy.name = name;
    scheduleCycleCopy.startDay = dayToPBDate(startDay);
    scheduleCycleCopy.endDay = dayToPBDate(endDay);
    scheduleCycleCopy.isDayOfWeekAligned = isDayOfWeekAligned;
    scheduleCycleCopy.cycleDayCount = daysPerCycle;
    await this.createDraft(scheduleCycleCopy);
  }

  async updateScheduleCycleDayNames(cycleDaysNames: string[]): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    scheduleCycleCopy.cycleDayNames = cycleDaysNames;
    await this.createDraft(scheduleCycleCopy);
  }

  async createOrUpdateTerm(termId: string | undefined, name: string, startDay: Day, endDay: Day): Promise<Term> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    let term: Term;

    if (termId == null) {
      term = createTerm({ id: uuidV4(), name, startDay, endDay, revision: this.revision });
      scheduleCycleCopy.terms.push(term);
    } else {
      const existingTerm = scheduleCycleCopy.terms.find((t) => t.id === termId);
      if (existingTerm == null) {
        throw new Error(`No term found with id ${termId}`);
      }

      term = existingTerm;
      existingTerm.name = name;
      existingTerm.startDay = dayToPBDate(startDay);
      existingTerm.endDay = dayToPBDate(endDay);
      existingTerm.revision = this.revision;
    }

    await this.createDraft(scheduleCycleCopy);
    return term;
  }

  async deleteTerm(termId: string): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    const termIndex = scheduleCycleCopy.terms.findIndex((t) => t.id === termId);

    if (termIndex < 0) {
      throw new Error(`No term found with id ${termId}`);
    }

    const term = scheduleCycleCopy.terms[termIndex];
    term.shouldDelete = true;
    term.revision = this.revision;
    await this.createDraft(scheduleCycleCopy);
  }

  async getSuggestedPeriods(periodScheduleId: string, time: TimeOfDay): Promise<SuggestedPeriod> {
    return await this._scheduleCycleTransport.getSuggestedPeriods(periodScheduleId, time, this.scheduleCycle);
  }

  async getVolatileCalendar(minimumDay: Day, maximumDay: Day): Promise<Calendar> {
    return await this._scheduleCycleTransport.getVolatileCalendar(minimumDay, maximumDay, [this.scheduleCycle]);
  }

  async createNewPeriodSchedule(
    name: string,
    sourcePeriodScheduleId: string | undefined,
    scheduleTags: string[],
    when: ({ case: 'cycleDay'; value: number } | { case: 'dayOfWeek'; value: DayOfWeek })[]
  ): Promise<PeriodSchedule> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    let periods: Period[] = [];

    if (sourcePeriodScheduleId != null && sourcePeriodScheduleId.length > 0) {
      const sourcePeriodSchedule = this.scheduleCycle.periodSchedules.find((s) => s.id === sourcePeriodScheduleId);

      if (sourcePeriodSchedule == null) {
        throw new Error(`No PeriodSchedule found for id ${sourcePeriodScheduleId}`);
      }

      periods = sourcePeriodSchedule.periods.map((p) => createPeriod({ ...p, id: uuidV4() }));
    }

    const newPeriodSchedule = createPeriodSchedule({
      id: uuidV4(),
      name,
      periods,
      scheduleTags,
      revision: this.revision
    });
    scheduleCycleCopy.periodSchedules.push(newPeriodSchedule);

    when.forEach((w) => {
      const newOccurrence = createPeriodScheduleOccurrence({
        periodScheduleId: newPeriodSchedule.id,
        when: w,
        revision: this.revision
      });
      scheduleCycleCopy.periodScheduleOccurrences.push(newOccurrence);
    });

    await this.createDraft(scheduleCycleCopy);
    return newPeriodSchedule;
  }

  async updatePeriodSchedule(
    id: string,
    name: string,
    scheduleTags: string[],
    when: ({ case: 'cycleDay'; value: number } | { case: 'dayOfWeek'; value: DayOfWeek })[]
  ): Promise<PeriodSchedule> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    const periodSchedule = scheduleCycleCopy.periodSchedules.find((s) => s.id === id);
    if (periodSchedule == null) {
      throw new Error(`No PeriodSchedule found for id ${id}`);
    }

    periodSchedule.name = name;
    periodSchedule.scheduleTags = scheduleTags;
    periodSchedule.revision = this.revision;

    scheduleCycleCopy.periodScheduleOccurrences
      .filter((o) => o.periodScheduleId === id)
      .forEach((o) => {
        o.revision = this.revision;
        o.shouldDelete = true;
      });

    when.forEach((w) => {
      const newOccurrence = createPeriodScheduleOccurrence({
        periodScheduleId: periodSchedule.id,
        when: w,
        revision: this.revision
      });
      scheduleCycleCopy.periodScheduleOccurrences.push(newOccurrence);
    });

    await this.createDraft(scheduleCycleCopy);
    return periodSchedule;
  }

  async deletePeriodSchedule(id: string): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    const periodScheduleIndex = scheduleCycleCopy.periodSchedules.findIndex((s) => s.id === id);
    if (periodScheduleIndex < 0) {
      throw new Error(`No PeriodSchedule found for id ${id}`);
    }

    const periodSchedule = scheduleCycleCopy.periodSchedules[periodScheduleIndex];
    periodSchedule.revision = this.revision;
    periodSchedule.shouldDelete = true;

    scheduleCycleCopy.periodScheduleOccurrences
      .filter((occ) => occ.periodScheduleId === id)
      .forEach((o) => {
        o.revision = this.revision;
        o.shouldDelete = true;
      });

    scheduleCycleCopy.specialDays.forEach((specialDay) => {
      const periodScheduleIndex = specialDay.periodScheduleIds.indexOf(id);

      if (periodScheduleIndex >= 0) {
        specialDay.periodScheduleIds.splice(periodScheduleIndex, 1);
        specialDay.revision = this.revision;
      }
    });

    await this.createDraft(scheduleCycleCopy);
  }

  async createOrUpdateCycleDayActivities(infos: CreateOrUpdateCycleDayActivityInfos): Promise<void> {
    const activitySchedules = infos.activitySchedules.filter(
      (activitySchedule) =>
        // Removing values that don't have an original ActivitySchedule id and don't have an activity set.
        !(activitySchedule.id.length === 0 && activitySchedule.activity == null)
    );

    const result = await this._scheduleCycleTransport.createOrUpdateCycleDayActivity(
      this.scheduleCycle,
      infos.cycleDay,
      infos.label,
      infos.startTime,
      infos.endTime,
      infos.originalPeriodId,
      activitySchedules
    );

    await this.createDraft(result);
  }

  async createOrUpdatePeriod(
    periodScheduleId: string,
    periodId: string | undefined,
    label: string,
    startTime: TimeOfDay,
    endTime: TimeOfDay
  ): Promise<Period> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    const periodSchedule = scheduleCycleCopy.periodSchedules.find((s) => s.id === periodScheduleId);
    let period: Period;

    if (periodSchedule == null) {
      throw new Error(`No PeriodSchedule found for id ${periodScheduleId}`);
    }

    if (periodId != null) {
      const existingPeriod = periodSchedule.periods.find((p) => p.id === periodId);

      if (existingPeriod == null) {
        throw new Error(`No Period found for id ${periodId} in PeriodSchedule ${periodScheduleId}`);
      }

      period = existingPeriod;
      existingPeriod.label = label;
      existingPeriod.startTime = startTime;
      existingPeriod.endTime = endTime;
    } else {
      const newPeriod = createPeriod({ id: uuidV4(), startTime, endTime, label });
      period = newPeriod;
      periodSchedule.periods.push(newPeriod);
    }

    periodSchedule.revision = this.revision;
    await this.createDraft(scheduleCycleCopy);
    return period;
  }

  async deletePeriod(periodId: string): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    let hasDeletedAPeriod = false;

    for (const schedule of scheduleCycleCopy.periodSchedules) {
      const deletedPeriods = remove(schedule.periods, (p) => p.id === periodId);
      if (deletedPeriods.length > 0) {
        hasDeletedAPeriod = true;
        schedule.revision = this.revision;
        break;
      }
    }

    if (!hasDeletedAPeriod) {
      throw new Error(`No Period found for id ${periodId}`);
    }

    await this.createDraft(scheduleCycleCopy);
  }

  async createOrUpdateSpecialDay(
    id: string | undefined,
    infos: CreateOrUpdateSpecialDayInfos,
    occurrences: SpecialDayOccurrenceWhen[]
  ): Promise<SpecialDay> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    let specialDay: SpecialDay;

    if (id != null) {
      const sp = scheduleCycleCopy.specialDays.find((sp) => sp.id === id);
      if (sp == null) {
        throw new Error(`No SpecialDay found for id ${id}`);
      }
      specialDay = sp;
    } else {
      specialDay = createSpecialDay({ id: uuidV4() });
      scheduleCycleCopy.specialDays.push(specialDay);
    }

    specialDay.title = infos.title;
    specialDay.isTitleVisible = infos.isTitleVisible;
    specialDay.symbol = infos.symbol;
    specialDay.color = infos.color;
    specialDay.isSymbolVisible = infos.isSymbolVisible;
    specialDay.cycleDay = infos.cycleDay;
    specialDay.cycleDayEffect = infos.cycleDayEffect;
    specialDay.periodScheduleIds = infos.periodScheduleIds;
    specialDay.revision = this.revision;

    const allOccurrences = scheduleCycleCopy.specialDayOccurrences;
    // Removing all existing occurrences for the special day.
    allOccurrences
      .filter((o) => o.specialDayId === specialDay.id)
      .forEach((o) => {
        o.revision = this.revision;
        o.shouldDelete = true;
      });
    // Adding new occurrences and existing one back.
    occurrences.forEach((o) =>
      allOccurrences.push(createSpecialDayOccurrence({ specialDayId: specialDay.id, when: o, revision: this.revision }))
    );
    scheduleCycleCopy.specialDayOccurrences = allOccurrences;

    await this.createDraft(scheduleCycleCopy);
    return specialDay;
  }

  async addOrRemoveSpecialDayOccurrence(specialDayId: string, when: SpecialDayOccurrenceWhen): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    if (scheduleCycleCopy.specialDays.findIndex((sp) => sp.id === specialDayId) < 0) {
      throw new Error(`No SpecialDay found for id ${specialDayId}`);
    }

    const existingOccurrenceIndex = this.findExistingSpecialDayOccurrenceIndex(
      scheduleCycleCopy.specialDayOccurrences,
      specialDayId,
      when
    );

    if (existingOccurrenceIndex >= 0) {
      const occurrence = scheduleCycleCopy.specialDayOccurrences[existingOccurrenceIndex];
      occurrence.shouldDelete = true;
      occurrence.revision = this.revision;
    } else {
      const newOccurrence = createSpecialDayOccurrence({ specialDayId, when, revision: this.revision });
      scheduleCycleCopy.specialDayOccurrences.push(newOccurrence);
    }

    await this.createDraft(scheduleCycleCopy);
  }

  async addSpecialDayOccurrence(specialDayId: string, when: SpecialDayOccurrenceWhen): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    if (scheduleCycleCopy.specialDays.findIndex((sp) => sp.id === specialDayId) < 0) {
      throw new Error(`No SpecialDay found for id ${specialDayId}`);
    }

    const existingOccurrenceIndex = this.findExistingSpecialDayOccurrenceIndex(
      scheduleCycleCopy.specialDayOccurrences,
      specialDayId,
      when
    );

    if (existingOccurrenceIndex < 0) {
      const newOccurrence = createSpecialDayOccurrence({ specialDayId, when, revision: this.revision });
      scheduleCycleCopy.specialDayOccurrences.push(newOccurrence);

      await this.createDraft(scheduleCycleCopy);
    }
  }

  async deleteSpecialDay(id: string): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    scheduleCycleCopy.specialDays
      .filter((sp) => sp.id === id)
      .forEach((sp) => {
        sp.revision = this.revision;
        sp.shouldDelete = true;
      });

    scheduleCycleCopy.specialDayOccurrences
      .filter((occ) => occ.specialDayId === id)
      .forEach((o) => {
        o.revision = this.revision;
        o.shouldDelete = true;
      });
    await this.createDraft(scheduleCycleCopy);
  }

  async createOrUpdateActivitySchedule(
    id: string | undefined,
    activity: Activity,
    when: ScheduleCycleDataStoreAddActivityScheduleWhen,
    at: ScheduleCyclePeriodAt,
    roomName: string,
    termId: string,
    scheduleTag: string
  ): Promise<ActivitySchedule> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    let activitySchedule: ActivitySchedule;

    if (id != null) {
      const existingActivitySchedule = scheduleCycleCopy.activitySchedules.find((s) => s.id === id);
      if (existingActivitySchedule == null) {
        throw new Error(`No ActivitySchedule found for id ${id}`);
      }

      activitySchedule = existingActivitySchedule;

      existingActivitySchedule.when =
        when.case === 'cycleDay' || when.case === 'dayOfWeek'
          ? when
          : /* istanbul ignore next */ { case: 'day', value: dayToPBDate(when.value) };
      existingActivitySchedule.at = at;
      existingActivitySchedule.activity = activity;
      existingActivitySchedule.termId = termId;
      existingActivitySchedule.roomName = roomName;
      existingActivitySchedule.scheduleTag = scheduleTag;
      existingActivitySchedule.revision = this.revision;
    } else {
      activitySchedule = createActivitySchedule({
        id: uuidV4(),
        activity,
        when,
        at,
        termId,
        roomName,
        scheduleTag,
        revision: this.revision
      });

      scheduleCycleCopy.activitySchedules.push(activitySchedule);
    }
    await this.createDraft(scheduleCycleCopy);
    return activitySchedule;
  }

  async removeActivitySchedule(activityScheduleId: string): Promise<void> {
    const scheduleCycleCopy = this.getScheduleCycleCopy();
    const index = scheduleCycleCopy.activitySchedules.findIndex((s) => s.id === activityScheduleId);

    if (index < 0) {
      throw new Error(`No ActivitySchedule found for id ${activityScheduleId}`);
    }

    const schedule = scheduleCycleCopy.activitySchedules[index];
    schedule.shouldDelete = true;
    schedule.revision = this.revision;
    await this.createDraft(scheduleCycleCopy);
  }

  getActivityScheduleHasConflict(
    activityScheduleId: string | undefined,
    when: ScheduleCycleDataStoreAddActivityScheduleWhen,
    at: ScheduleCyclePeriodAt,
    termId: string,
    scheduleTag: string,
    courses: { id: string; externalSourceName: string | undefined }[]
  ): boolean {
    const makeUniqueCourseId = (id: string, externalSourceName: string | undefined) =>
      `${id}~~~${externalSourceName ?? ''}`;

    const resolvedCourses = courses.map((c) => makeUniqueCourseId(c.id, c.externalSourceName));

    return this.scheduleCycle.activitySchedules.some((activitySchedule) => {
      if (activitySchedule.shouldDelete || activitySchedule.id === activityScheduleId) {
        // No conflict if it's the same ActivitySchedule.
        return false;
      }

      const activityId = activitySchedule.activity?.activityId;
      if (
        activityId != null &&
        !resolvedCourses.includes(makeUniqueCourseId(activityId, activitySchedule.activity?.sourceName))
      ) {
        // No conflict if it's not an Activity of the current user.
        return false;
      }

      if (activitySchedule.when.case !== when.case) {
        return false;
      }

      if (activitySchedule.when.case === 'day' && when.case === 'day') {
        if (compareDays(activitySchedule.when.value, when.value) !== 0) {
          return false;
        }
      } else if (activitySchedule.when.value != when.value) {
        return false;
      }

      return (
        isEqual(activitySchedule.at, at) &&
        activitySchedule.termId === termId &&
        activitySchedule.scheduleTag === scheduleTag
      );
    });
  }

  getPeriodScheduleForCycleDay(
    cycleDay: number,
    scheduleKind: ScheduleCycleKind,
    scheduleTag: string
  ): PeriodSchedule | undefined {
    const periodScheduleIdsForTag = chain(this.scheduleCycle.periodSchedules)
      .map((ps) => {
        if (ps.shouldDelete) {
          return undefined;
        }

        if (scheduleTag.length > 0) {
          return ps.scheduleTags.includes(scheduleTag) ? ps.id : undefined;
        } else {
          return ps.scheduleTags.length === 0 ? ps.id : undefined;
        }
      })
      .compact()
      .value();

    let scheduleId = this.scheduleCycle.periodScheduleOccurrences.find(
      (o) =>
        !o.shouldDelete &&
        periodScheduleIdsForTag.includes(o.periodScheduleId) &&
        o.when.case === 'cycleDay' &&
        o.when.value === cycleDay
    )?.periodScheduleId;

    if (scheduleId == null) {
      if (scheduleKind === 'cycle-day') {
        // If the schedule is by cycle-day, we find the most used PeriodSchedule based on dayOfWeek occurrences.
        const dayOfWeekOccurrencesByCount = chain(this.scheduleCycle.periodScheduleOccurrences)
          .filter(
            (o) =>
              !o.shouldDelete && periodScheduleIdsForTag.includes(o.periodScheduleId) && o.when.case === 'dayOfWeek'
          )
          .map((o) => o.periodScheduleId)
          .countBy()
          .value();

        const allIds = Object.keys(dayOfWeekOccurrencesByCount);
        if (allIds.length > 0) {
          scheduleId = allIds.reduce((a, b) =>
            dayOfWeekOccurrencesByCount[a] >= dayOfWeekOccurrencesByCount[b] ? a : b
          );
        }
      } else {
        // If schedule is by week or rotating-weeks, we look for a match based on the day of week. If not, we
        // return no PeriodSchedule.
        const dow: DayOfWeek = ((cycleDay - 1) % 7) + 1;

        scheduleId = this.scheduleCycle.periodScheduleOccurrences.find(
          (o) =>
            !o.shouldDelete &&
            periodScheduleIdsForTag.includes(o.periodScheduleId) &&
            o.when.case === 'dayOfWeek' &&
            o.when.value === dow
        )?.periodScheduleId;
      }
    }

    return this.scheduleCycle.periodSchedules.find((s) => s.id === scheduleId);
  }

  getNextAvailableMasterSchedulePeriod(
    courses: { id: string; externalSourceName: string | undefined }[],
    scheduleKind: ScheduleCycleKind,
    scheduleTag: string
  ): { cycleDay: number; period: Period } | undefined {
    for (let cycleDay = 1; cycleDay <= this.scheduleCycle.cycleDayCount; cycleDay += 1) {
      const periodSchedule = this.getPeriodScheduleForCycleDay(cycleDay, scheduleKind, scheduleTag);
      const availablePeriod = chain(periodSchedule?.periods ?? [])
        .sortBy((p) => p.startTime!.hours * 60 + p.startTime!.minutes)
        .find(
          (p) =>
            // No conflict means that the period is available.
            !this.getActivityScheduleHasConflict(
              undefined,
              { case: 'cycleDay', value: cycleDay },
              { case: 'periodLabel', value: p.label },
              '',
              '',
              courses
            )
        )
        .value();

      if (availablePeriod != null) {
        return { cycleDay, period: availablePeriod };
      }
    }

    return undefined;
  }

  getTermAndConflictingTermsForId(termId: string): Term[] {
    const term = termId.length > 0 ? this.scheduleCycle.terms.find((t) => t.id === termId) : undefined;

    if (term == null) {
      return [];
    }

    return this.scheduleCycle.terms.filter(
      (t) =>
        !t.shouldDelete &&
        (t.id === term.id ||
          rangesOfDaysAreOverlapping(
            { start: t.startDay!, end: t.endDay! },
            { start: term.startDay!, end: term.endDay! }
          ))
    );
  }

  getActivitySchedulesForPeriod(
    at: ScheduleCyclePeriodAt,
    cycleDay: number,
    termIds: string[],
    scheduleTag: string,
    activities: Activity[]
  ): ActivitySchedule[] {
    if (termIds.length === 0) {
      throw new Error('At least one termId must be provided');
    }

    return this.scheduleCycle.activitySchedules.filter((activitySchedule) =>
      this.isActivityScheduleMatching(activitySchedule, at, cycleDay, termIds, scheduleTag, activities)
    );
  }

  getActivityScheduleForPeriod(
    at: ScheduleCyclePeriodAt,
    cycleDay: number,
    termId: string,
    scheduleTag: string,
    activities: Activity[]
  ): ActivitySchedule | undefined {
    return this.scheduleCycle.activitySchedules.find((activitySchedule) =>
      this.isActivityScheduleMatching(activitySchedule, at, cycleDay, [termId], scheduleTag, activities)
    );
  }

  getPrimaryActivitySchedule(
    activitySchedules: ActivitySchedule[],
    termId: string,
    scheduleTag: string
  ): ActivitySchedule | undefined {
    if (activitySchedules.length === 0) {
      return undefined;
    }

    const activityScheduleForTerm = activitySchedules.find(
      (a) => !a.shouldDelete && a.scheduleTag === scheduleTag && a.termId === termId
    );
    if (activityScheduleForTerm != null) {
      return activityScheduleForTerm;
    }

    const match = activitySchedules.find((a) => a.scheduleTag === scheduleTag && a.termId.length === 0);
    return scheduleTag.length > 0 ? match : (match ?? activitySchedules[0]);
  }

  getCourseSectionForActivitySchedule(
    activitySchedule: ActivitySchedule | undefined,
    courseSections: CourseSectionInfo[]
  ): CourseSectionInfo | undefined {
    return this.getCourseSectionForActivity(activitySchedule?.activity, courseSections);
  }

  getCourseSectionForActivity(
    activity: Activity | undefined,
    courseSections: CourseSectionInfo[]
  ): CourseSectionInfo | undefined {
    if (activity == null) {
      return undefined;
    }

    const findFn: (cs: CourseSectionInfo) => boolean =
      activity.sourceName.length > 0
        ? (cs) =>
            cs.externalSource?.externalId === activity.activityId &&
            cs.externalSource?.sourceName === activity.sourceName
        : (cs) => cs.id === activity.activityId;

    return courseSections.find(findFn);
  }

  private getScheduleCycleCopy(): ScheduleCycle {
    return cloneScheduleCycle(this.scheduleCycle);
  }

  private async createDraft(scheduleCycle: ScheduleCycle): Promise<void> {
    const draftSessionId = this.scheduleCycleInfo.draftSessionId || uuidV4();

    try {
      await this.saveDraftScheduleCycle(scheduleCycle, draftSessionId, this._userDashboard);
      runInAction(() => (this._scheduleCycleInfo = { scheduleCycle, draftSessionId, availableDraftSessionId: '' }));
      this._storeInvalidator.invalidateCalendar();
    } catch (e) {
      captureException(e);
      runInAction(() => (this._failedSaveDraftScheduleCycle = scheduleCycle));
    }
  }

  private isActivityScheduleMatching(
    activitySchedule: ActivitySchedule,
    at: ScheduleCyclePeriodAt,
    cycleDay: number,
    termIds: string[],
    scheduleTag: string,
    activities: Activity[]
  ): boolean {
    if (activitySchedule.shouldDelete) {
      return false;
    }

    if (termIds.length > 0 && activitySchedule.termId.length > 0 && !termIds.includes(activitySchedule.termId)) {
      return false;
    }

    if (activitySchedule.scheduleTag !== scheduleTag) {
      return false;
    }

    if (activitySchedule.when.case !== 'cycleDay' || activitySchedule.when.value !== cycleDay) {
      return false;
    }

    const matchesActivities = activities.some(
      (a) =>
        a.activityId === activitySchedule.activity?.activityId && a.sourceName === activitySchedule.activity?.sourceName
    );

    if (!matchesActivities) {
      return false;
    }

    if (at.case === 'periodLabel' && activitySchedule.at.case === 'periodLabel') {
      return activitySchedule.at.value === at.value;
    } else if (at.case === 'period' && activitySchedule.at.case === 'period') {
      return (
        isEqual(activitySchedule.at.value.startTime, at.value.startTime) &&
        isEqual(activitySchedule.at.value.endTime, at.value.endTime) &&
        isEqual(activitySchedule.at.value?.label, at.value.label)
      );
    }

    return false;
  }

  private findExistingSpecialDayOccurrenceIndex(
    occurrences: SpecialDayOccurrence[],
    specialDayId: string,
    when: SpecialDayOccurrenceWhen
  ) {
    return occurrences.findIndex((o) => {
      if (o.shouldDelete || o.specialDayId !== specialDayId || o.when.case !== when.case) {
        return false;
      }

      if (o.when.case === 'day' && when.case === 'day') {
        return compareDays(o.when.value, when.value) === 0;
      }

      return o.when.value === when.value;
    });
  }

  private async saveDraftScheduleCycle(
    scheduleCycle: ScheduleCycle,
    draftSessionId: string,
    userDashboard: UserDashboardInfo
  ): Promise<{ scheduleCycle: ScheduleCycle | undefined; hasMoreRecentRevision: boolean }> {
    switch (userDashboard.kind) {
      case 'planner':
        return await this._scheduleCycleTransport.saveDraftScheduleCycleForPlanner(
          scheduleCycle,
          draftSessionId,
          userDashboard.id
        );
      case 'school':
        return await this._scheduleCycleTransport.saveDraftScheduleCycleForSchool(
          scheduleCycle,
          draftSessionId,
          userDashboard.id
        );
    }
  }

  private async publishDraftScheduleCycle(
    scheduleCycleId: string,
    draftSessionId: string,
    userDashboard: UserDashboardInfo
  ): Promise<{ scheduleCycle: ScheduleCycle; hasDroppedChanges: boolean }> {
    switch (userDashboard.kind) {
      case 'planner': {
        const scheduleCycle = await this._scheduleCycleTransport.publishDraftScheduleCycleForPlanner(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
        return { scheduleCycle, hasDroppedChanges: false };
      }

      case 'school':
        return await this._scheduleCycleTransport.publishDraftScheduleCycleForSchool(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
    }
  }

  private async discardLastDraftScheduleCycle(
    scheduleCycleId: string,
    draftSessionId: string,
    userDashboard: UserDashboardInfo
  ): Promise<{ scheduleCycle: ScheduleCycle; isDraft: boolean }> {
    switch (userDashboard.kind) {
      case 'planner':
        return await this._scheduleCycleTransport.discardLastDraftScheduleCycleForPlanner(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
      case 'school':
        return await this._scheduleCycleTransport.discardLastDraftScheduleCycleForSchool(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
    }
  }

  private async discardAllDraftScheduleCycles(
    scheduleCycleId: string,
    draftSessionId: string,
    userDashboard: UserDashboardInfo
  ): Promise<ScheduleCycle> {
    switch (userDashboard.kind) {
      case 'planner':
        return await this._scheduleCycleTransport.discardAllDraftScheduleCyclesForPlanner(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
      case 'school':
        return await this._scheduleCycleTransport.discardAllDraftScheduleCyclesForSchool(
          scheduleCycleId,
          draftSessionId,
          userDashboard.id
        );
    }
  }

  @action
  private invalidate() {
    this._scheduleCycleInfo = undefined;
    this._failedSaveDraftScheduleCycle = undefined;
  }
}
