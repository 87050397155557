import { timestampDateOptional, WorkIcons } from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsService, DateService, LocalizationService, RouteService } from '@/services';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { compareAsc, isAfter, isEqual } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';
import { AppStudentInsightsWorkViewModel, StudentInsightsWorkViewModel } from './StudentInsightsWorkViewModel';

export interface StudentInsightsWorksViewModel {
  readonly courseSectionTitle: string;
  readonly works: StudentInsightsWorkViewModel[];
  readonly initialWorkIndex: number | undefined;
  searchText: string;
}

export class AppStudentInsightsWorksViewModel implements StudentInsightsWorksViewModel {
  @observable private _searchText = '';

  constructor(
    private readonly _works: Work[],
    private readonly _workIcons: WorkIcons,
    readonly courseSectionTitle: string,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _route: RouteService = ServiceContainer.services.route,
    private readonly _settings: ApplicationSettingsService = ServiceContainer.services.settings,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);
  }

  @computed
  private get sortedWorks(): StudentInsightsWorkViewModel[] {
    return this._works
      .slice()
      .sort((w1, w2) => {
        if (w1.status === WorkStatus.COMPLETED && w2.status !== WorkStatus.COMPLETED) {
          return -1;
        }

        if (w1.status !== WorkStatus.COMPLETED && w2.status === WorkStatus.COMPLETED) {
          return 1;
        }

        const w1DueDate = timestampDateOptional(w1.dueTime);
        const w2DueDate = timestampDateOptional(w2.dueTime);

        if (w1DueDate != null && w2DueDate != null) {
          return compareAsc(w1DueDate, w2DueDate);
        } else if (w1DueDate == null) {
          return 1;
        } else if (w2DueDate == null) {
          return -1;
        }

        return 0;
      })
      .map((w) => new AppStudentInsightsWorkViewModel(w, this._workIcons, this._route));
  }

  @computed
  get hasWorks(): boolean {
    return this._works.length > 0;
  }

  @computed
  get works(): StudentInsightsWorkViewModel[] {
    const searchText = this.searchText.toLocaleLowerCase(this._localization.currentLocale);

    return this.sortedWorks.filter((w) => {
      if (searchText.length === 0) {
        return true;
      }

      return w.title.toLocaleLowerCase(this._localization.currentLocale).includes(searchText);
    });
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get initialWorkIndex(): number | undefined {
    const now = this._dateService.now;
    const index = this.sortedWorks.findIndex((w) => {
      if (w.dueDate == null) {
        return false;
      }

      return isEqual(w.dueDate, now) || isAfter(w.dueDate, now);
    });
    return index >= 0 ? index : undefined;
  }
}
