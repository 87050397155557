import { isSxArray } from '@/utils';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../lists';

export interface PlannerDeleteDuplicatedItemCourseSectionHeaderProps {
  sx?: SxProps;
  className?: string;
  courseSection: CourseSectionDetails | undefined;
}

export const PlannerDeleteDuplicatedItemCourseSectionHeader = observer(
  ({ sx = [], courseSection, className }: PlannerDeleteDuplicatedItemCourseSectionHeaderProps) => {
    const theme = useTheme();
    const strings = LocalizedStrings.planner.archiveDuplicatedItem;

    return (
      <Stack
        direction="row"
        spacing={1}
        className={className}
        sx={[
          {
            backgroundColor: alpha(courseSection?.courseSection?.color ?? theme.palette.action.disabled, 0.2),
            borderRadius: 1,
            px: 1,
            py: 0.5
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <ColorIndicator color={courseSection?.courseSection?.color} />

        <Stack sx={{ flex: 1 }}>
          <Typography variant="subtitle2">
            {courseSection?.courseSection?.title ?? strings.noCourseSectionLabel()}
          </Typography>

          <Typography variant="caption" color="textSecondary">
            {courseSection?.courseSection?.section}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
