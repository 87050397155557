import {
  createDailyWorkload,
  Day,
  dayToString,
  StudentWorkloadDetails,
  StudentWorkloadStatus,
  WorkloadSummaryInfo
} from '@/models';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { DailyWorkload } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/daily_workload_pb';
import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { keyBy } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { SchoolTransportService } from '../../transports';
import { WorkloadDataStore } from '../contracts';
import {
  AppCourseSectionDailyWorkloadLoadable,
  AppSchoolDailyWorkloadLoadable,
  CourseSectionDailyWorkloadLoadable,
  SchoolDailyWorkloadLoadable
} from '../loadables';

type CourseSectionWorkloadInformationByDay = Map<string, CourseSectionDailyWorkloadLoadable>;
type SchoolWorkloadInformationByDay = Map<string, SchoolDailyWorkloadLoadable>;

export class AppWorkloadDataStore implements WorkloadDataStore {
  @observable private _isFetching = false;
  @observable private _error: string | undefined;
  private _workloadInformationByCourseSection = observable.map<string, CourseSectionWorkloadInformationByDay>();
  private _workloadInformationBySchool = observable.map<string, SchoolWorkloadInformationByDay>();
  private _allCourseSections = observable.map<string, CourseSection>();

  constructor(private readonly _schoolTransport: SchoolTransportService) {
    makeObservable(this);
  }

  @computed
  get isFetching(): boolean {
    return this._isFetching;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  getCourseSection(id: string): CourseSection | undefined {
    return this._allCourseSections.get(id);
  }

  getCourseSectionWorkloadInformationForDate(courseSectionId: string, date: Day): WorkloadSummaryInfo | undefined {
    const dailyWorkload = this.getCourseSectionWorkloadDetailsForDate(courseSectionId, date);
    return this.convertDailyWorkloadToSummary(dailyWorkload);
  }

  getCourseSectionWorkloadDetailsForDate(courseSectionId: string, date: Day): DailyWorkload | undefined {
    const loadable = this._workloadInformationByCourseSection.get(courseSectionId)?.get(dayToString(date));
    if (loadable?.hasData) {
      return (
        loadable.data.dailyWorkloads.at(0) ??
        createDailyWorkload({
          date,
          summary: { totalPublishedWorkCount: 0, importantPublishedWorkCount: 0, totalStudentCount: 0 },
          groups: []
        })
      );
    }
    return undefined;
  }

  getSchoolWorkloadInformationForDate(schoolId: string, date: Day): WorkloadSummaryInfo | undefined {
    const dailyWorkload = this.getSchoolWorkloadDetailsForDate(schoolId, date);
    return this.convertDailyWorkloadToSummary(dailyWorkload);
  }

  async fetchCourseSectionsWorkloadInformation(
    ids: { courseId: string; schoolId: string }[],
    days: Day[],
    force: boolean
  ): Promise<void> {
    runInAction(() => {
      this._isFetching = true;
      this._error = undefined;
    });

    const combinations = ids.flatMap((id) => days.map((day) => ({ id, day })));

    const lodables = combinations.map(({ id, day }) => {
      if (this._workloadInformationByCourseSection.get(id.courseId) == null) {
        this._workloadInformationByCourseSection.set(
          id.courseId,
          new Map<string, CourseSectionDailyWorkloadLoadable>()
        );
      }

      let loadable = this._workloadInformationByCourseSection.get(id.courseId)?.get(dayToString(day));
      if (loadable == null) {
        loadable = new AppCourseSectionDailyWorkloadLoadable(id.courseId, id.schoolId, day, this._schoolTransport);
        this._workloadInformationByCourseSection.get(id.courseId)?.set(dayToString(day), loadable);
      }
      return loadable;
    });

    try {
      await Promise.all(lodables.map((l) => l.fetch(force)));
    } finally {
      // No need to catch the error here, as the error will be set in the loadable
      runInAction(() => {
        for (const loadable of lodables) {
          if (loadable.hasData) {
            const courses = keyBy(loadable.data.courseSections, 'id');
            this._allCourseSections.merge(courses);
          }
        }

        this._isFetching = false;
      });
    }
  }

  async fetchSchoolWorkloadInformation(id: string, days: Day[], force: boolean): Promise<void> {
    const lodables = days.map((day) => {
      if (this._workloadInformationBySchool.get(id) == null) {
        this._workloadInformationBySchool.set(id, new Map<string, SchoolDailyWorkloadLoadable>());
      }

      let loadable = this._workloadInformationBySchool.get(id)?.get(dayToString(day));
      if (loadable == null) {
        loadable = new AppSchoolDailyWorkloadLoadable(id, day, this._schoolTransport);
        this._workloadInformationBySchool.get(id)?.set(dayToString(day), loadable);
      }
      return loadable;
    });

    try {
      await Promise.all(lodables.map((l) => l.fetch(force)));
    } finally {
      // No need to catch the error here, as the error will be set in the loadable
      runInAction(() => {
        for (const loadable of lodables) {
          if (loadable.hasData) {
            const courses = keyBy(loadable.data.courseSections, 'id');
            this._allCourseSections.merge(courses);
          }
        }

        this._isFetching = false;
      });
    }
  }

  getSchoolWorkloadDetailsForDate(schoolId: string, date: Day): DailyWorkload | undefined {
    const loadable = this._workloadInformationBySchool.get(schoolId)?.get(dayToString(date));
    if (loadable?.hasData) {
      return (
        loadable.data.dailyWorkloads.at(0) ??
        createDailyWorkload({
          date,
          summary: { totalPublishedWorkCount: 0, importantPublishedWorkCount: 0, totalStudentCount: 0 },
          groups: []
        })
      );
    }
    return undefined;
  }

  getWorkloadStatusForStudents(courseSectionId: string, date: Day): StudentWorkloadStatus[] {
    const dailyWorkload = this.getCourseSectionWorkloadDetailsForDate(courseSectionId, date);

    if (dailyWorkload == null) {
      return [];
    }

    const response: StudentWorkloadStatus[] = [];

    dailyWorkload.groups.forEach((group) => {
      return group.students.forEach((student) => response.push({ student, status: group.status }));
    });

    return response;
  }

  getWorkloadDetailsForStudent(
    studentId: string,
    courseSectionId: string,
    date: Day
  ): StudentWorkloadDetails | undefined {
    const dailyWorkload = this.getCourseSectionWorkloadDetailsForDate(courseSectionId, date);

    if (dailyWorkload == null) {
      return undefined;
    }

    const group = dailyWorkload.groups.find((g) => g.students.some((s) => s.accountId === studentId));
    const student = group?.students.find((s) => s.accountId === studentId);

    if (group == null || student == null) {
      return undefined;
    }

    return {
      student,
      status: group.status,
      works: group.importantPublishedWorks
    };
  }

  @action
  invalidate(): void {
    this._workloadInformationByCourseSection.clear();
    this._allCourseSections.clear();
  }

  private getNumberOfStudentAtLevelInDailyWorkload(level: WorkloadStatus, dailyWorkload: DailyWorkload): number {
    return dailyWorkload.groups.filter((g) => g.status === level).reduce((prev, g) => prev + g.students.length, 0);
  }

  private convertDailyWorkloadToSummary(dailyWorkload: DailyWorkload | undefined): WorkloadSummaryInfo | undefined {
    if (dailyWorkload == null) {
      return undefined;
    }

    const aboveThresholdStudentCount = this.getNumberOfStudentAtLevelInDailyWorkload(
      WorkloadStatus.ABOVE_THRESHOLD,
      dailyWorkload
    );

    const atThresholdStudentCount = this.getNumberOfStudentAtLevelInDailyWorkload(
      WorkloadStatus.AT_THRESHOLD,
      dailyWorkload
    );

    const underThresholdStudentCount = this.getNumberOfStudentAtLevelInDailyWorkload(
      WorkloadStatus.AT_THRESHOLD,
      dailyWorkload
    );

    return {
      aboveThresholdStudentCount,
      atThresholdStudentCount,
      underThresholdStudentCount,
      itemCount: dailyWorkload.summary!.totalPublishedWorkCount,
      importantItemCount: dailyWorkload.summary!.importantPublishedWorkCount
    };
  }
}
