import { Day, DayOfWeek, ScheduleCycleInfo, SuggestedPeriod, TimeOfDay, UserDashboardInfo } from '@/models';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { Calendar } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { PartialActivitySchedule } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/partial_activity_schedule_pb';
import { PeriodScheduleCreationMode } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_creation_mode_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import { GrpcTransportService, ScheduleCycleTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppScheduleCycleTransportService extends AppBaseTransportService implements ScheduleCycleTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getScheduleCycles(ids: string[]): Promise<ScheduleCycleInfo[]> {
    return await Promise.all(
      ids.map((id) => this.getScheduleCycle(id, { case: 'allowDraftScheduleCycle', value: true }))
    );
  }

  async getScheduleCycle(
    scheduleCycleId: string,
    draft: { case: 'draftSessionId'; value: string } | { case: 'allowDraftScheduleCycle'; value: boolean } | undefined
  ): Promise<ScheduleCycleInfo> {
    const response = await this.performReadRequest('getScheduleCycle', (headers) =>
      this._grpcTransport.schedulesClient.getScheduleCycle(
        {
          scheduleCycleId,
          draft
        },
        { headers }
      )
    );

    return {
      scheduleCycle: response.scheduleCycle!,
      draftSessionId: response.draftSessionId,
      availableDraftSessionId: response.availableDraftSessionId
    };
  }

  async getSuggestedPeriods(
    periodScheduleId: string,
    time: TimeOfDay,
    scheduleCycle: ScheduleCycle
  ): Promise<SuggestedPeriod> {
    const response = await this.performReadRequest('getSuggestedPeriods', (headers) =>
      this._grpcTransport.volatileSchedulesClient.suggestPeriods(
        { where: { case: 'periodScheduleId', value: periodScheduleId }, time, scheduleCycle },
        { headers }
      )
    );

    return { period: response.suggestedPeriod!, otherPeriods: response.otherPeriods };
  }

  async getCalendar(
    scheduleCycleIds: string[],
    minimumDay: Day,
    maximumDay: Day,
    activities: Activity[],
    includeFreePeriods: boolean
  ): Promise<Calendar> {
    const response = await this.performReadRequest('getCalendar', (headers) =>
      this._grpcTransport.schedulesClient.getCalendar(
        { scheduleCycleIds, minimumDay, maximumDay, activities, includeFreePeriods },
        { headers }
      )
    );

    return response.calendar!;
  }
  async getVolatileCalendar(minimumDay: Day, maximumDay: Day, scheduleCycles: ScheduleCycle[]): Promise<Calendar> {
    const response = await this.performReadRequest('getVolatileCalendar', (headers) =>
      this._grpcTransport.volatileSchedulesClient.generateCalendar(
        { minimumDay, maximumDay, scheduleCycles, activities: [] },
        { headers }
      )
    );

    return response.calendar!;
  }

  async createOrUpdateCycleDayActivity(
    scheduleCycle: ScheduleCycle,
    cycleDay: number,
    label: string,
    startTime: TimeOfDay,
    endTime: TimeOfDay,
    originalPeriodId: string,
    activitySchedules: PartialActivitySchedule[]
  ): Promise<ScheduleCycle> {
    const response = await this.performReadRequest('createOrUpdateCycleDayActivities', (headers) =>
      this._grpcTransport.volatileSchedulesClient.createOrUpdateCycleDayActivities(
        { scheduleCycle, cycleDay, label, startTime, endTime, originalPeriodId, activitySchedules },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }

  async createScheduleCycle(
    name: string,
    startDay: Day,
    endDay: Day,
    cycleDayCount: number,
    isDayOfWeekAligned: boolean,
    firstCycleDay: number,
    terms: Term[],
    activityDaysOfWeek: DayOfWeek[],
    periodScheduleCreationMode: PeriodScheduleCreationMode
  ): Promise<ScheduleCycle> {
    const response = await this.performReadRequest('createScheduleCycle', (headers) =>
      this._grpcTransport.volatileSchedulesClient.createSchoolScheduleCycle(
        {
          name,
          isDayOfWeekAligned,
          cycleDayCount,
          firstCycleDay,
          terms,
          startDay,
          endDay,
          activityDaysOfWeek,
          periodScheduleCreationMode
        },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }

  async createScheduleCycleCopy(
    scheduleCycle: ScheduleCycle,
    name: string,
    startDay: Day,
    endDay: Day,
    shouldKeepTerms: boolean,
    shouldKeepPeriodSchedules: boolean,
    shouldKeepSpecialDays: boolean,
    shouldKeepSpecialDayOccurrences: boolean,
    shouldKeepActivitySchedules: boolean,
    shouldKeepActivityScheduleExceptions: boolean
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('createScheduleCycleCopy', (headers) =>
      this._grpcTransport.volatileSchedulesClient.createScheduleCycleCopy(
        {
          scheduleCycle,
          name,
          startDay,
          endDay,
          shouldKeepTerms,
          shouldKeepPeriodSchedules,
          shouldKeepSpecialDays,
          shouldKeepSpecialDayOccurrences,
          shouldKeepActivitySchedules,
          shouldKeepActivityScheduleExceptions
        },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }

  async attachScheduleCycleToUserDashboard(
    scheduleCycleId: string,
    userDashboard: UserDashboardInfo
  ): Promise<string[]> {
    if (userDashboard.kind === 'school') {
      const response = await this.performWriteRequest('attachScheduleCycleToSchool', (headers) =>
        this._grpcTransport.schoolsClient.attachScheduleCycleToSchool(
          { scheduleCycleId, schoolId: userDashboard.id },
          { headers }
        )
      );

      return response.scheduleCycleIds;
    } else {
      const response = await this.performWriteRequest('attachScheduleCycleToPlanner', (headers) =>
        this._grpcTransport.plannersClient.attachScheduleCycleToPlanner(
          { scheduleCycleId, plannerId: userDashboard.id },
          { headers }
        )
      );

      return response.scheduleCycleIds;
    }
  }

  async detachScheduleCycleFromUserDashboard(
    scheduleCycleId: string,
    userDashboard: UserDashboardInfo
  ): Promise<string[]> {
    if (userDashboard.kind === 'school') {
      const response = await this.performWriteRequest('detachScheduleCycleFromSchool', (headers) =>
        this._grpcTransport.schoolsClient.detachScheduleCycleFromSchool(
          { scheduleCycleId, schoolId: userDashboard.id },
          { headers }
        )
      );

      return response.scheduleCycleIds;
    } else {
      const response = await this.performWriteRequest('detachScheduleCycleFromPlanner', (headers) =>
        this._grpcTransport.plannersClient.detachScheduleCycleFromPlanner(
          { scheduleCycleId, plannerId: userDashboard.id },
          { headers }
        )
      );

      return response.scheduleCycleIds;
    }
  }

  async saveDraftScheduleCycleForPlanner(
    scheduleCycle: ScheduleCycle,
    draftSessionId: string,
    plannerId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle | undefined;
    hasMoreRecentRevision: boolean;
  }> {
    const response = await this.performWriteRequest('saveDraftScheduleCycleForPlanner', (headers) =>
      this._grpcTransport.plannerSchedulesClient.saveDraftScheduleCycle(
        { scheduleCycle, draftSessionId, plannerId },
        { headers }
      )
    );

    return { scheduleCycle: response.scheduleCycle, hasMoreRecentRevision: response.hasMoreRecentRevision };
  }

  async saveDraftScheduleCycleForSchool(
    scheduleCycle: ScheduleCycle,
    draftSessionId: string,
    schoolId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle | undefined;
    hasMoreRecentRevision: boolean;
  }> {
    const response = await this.performWriteRequest('saveDraftScheduleCycleForSchool', (headers) =>
      this._grpcTransport.schoolSchedulesClient.saveDraftScheduleCycle(
        { scheduleCycle, draftSessionId, schoolId },
        { headers }
      )
    );

    return { scheduleCycle: response.scheduleCycle, hasMoreRecentRevision: response.hasMoreRecentRevision };
  }

  async publishDraftScheduleCycleForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('publishDraftScheduleCycleForPlanner', (headers) =>
      this._grpcTransport.plannerSchedulesClient.publishDraftScheduleCycle(
        { scheduleCycleId, draftSessionId, plannerId },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }

  async publishDraftScheduleCycleForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<{ scheduleCycle: ScheduleCycle; hasDroppedChanges: boolean }> {
    const response = await this.performWriteRequest('publishDraftScheduleCycleForSchool', (headers) =>
      this._grpcTransport.schoolSchedulesClient.publishDraftScheduleCycle(
        { scheduleCycleId, draftSessionId, schoolId },
        { headers }
      )
    );

    return { scheduleCycle: response.scheduleCycle!, hasDroppedChanges: response.hasDroppedChanges };
  }

  async publishInitialScheduleCycleForPlanner(scheduleCycle: ScheduleCycle, plannerId: string): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('publishInitialScheduleCycleForPlanner', (headers) =>
      this._grpcTransport.plannerSchedulesClient.publishInitialScheduleCycle({ scheduleCycle, plannerId }, { headers })
    );

    return response.scheduleCycle!;
  }

  async publishInitialScheduleCycleForSchool(scheduleCycle: ScheduleCycle, schoolId: string): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('publishInitialScheduleCycleForSchool', (headers) =>
      this._grpcTransport.schoolSchedulesClient.publishInitialScheduleCycle({ scheduleCycle, schoolId }, { headers })
    );

    return response.scheduleCycle!;
  }

  async discardLastDraftScheduleCycleForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle;
    isDraft: boolean;
  }> {
    const response = await this.performWriteRequest('discardLastDraftScheduleCycleForPlanner', (headers) =>
      this._grpcTransport.plannerSchedulesClient.discardLastDraftScheduleCycle(
        { scheduleCycleId, draftSessionId, plannerId },
        { headers }
      )
    );

    return { scheduleCycle: response.scheduleCycle!, isDraft: response.isDraft };
  }

  async discardLastDraftScheduleCycleForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<{
    scheduleCycle: ScheduleCycle;
    isDraft: boolean;
  }> {
    const response = await this.performWriteRequest('discardLastDraftScheduleCycleForSchool', (headers) =>
      this._grpcTransport.schoolSchedulesClient.discardLastDraftScheduleCycle(
        { scheduleCycleId, draftSessionId, schoolId },
        { headers }
      )
    );

    return { scheduleCycle: response.scheduleCycle!, isDraft: response.isDraft };
  }

  async discardAllDraftScheduleCyclesForPlanner(
    scheduleCycleId: string,
    draftSessionId: string,
    plannerId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('discardAllDraftScheduleCyclesForPlanner', (headers) =>
      this._grpcTransport.plannerSchedulesClient.discardAllDraftScheduleCycles(
        { scheduleCycleId, draftSessionId, plannerId },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }

  async discardAllDraftScheduleCyclesForSchool(
    scheduleCycleId: string,
    draftSessionId: string,
    schoolId: string
  ): Promise<ScheduleCycle> {
    const response = await this.performWriteRequest('discardAllDraftScheduleCyclesForSchool', (headers) =>
      this._grpcTransport.schoolSchedulesClient.discardAllDraftScheduleCycles(
        { scheduleCycleId, draftSessionId, schoolId },
        { headers }
      )
    );

    return response.scheduleCycle!;
  }
}
