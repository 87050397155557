import { PersonRounded } from '@mui/icons-material';
import { Badge, BadgeProps, Stack, styled, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useServices } from '../../../hooks';
import { generateWorkIconUrl, WorkIconInfo, WorkloadSummaryInfo } from '../../../models';
import { WorkIcon } from '../../lists';
import { WorkloadInformationTooltipContent } from './WorkloadInformationTooltipContent';

export interface PlannerWorkloadTableCellProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  getWorkloadInformation: () => WorkloadSummaryInfo | undefined;
  showWorkloadBadge?: boolean;
  size?: 'small' | 'regular';
  disableTooltip?: boolean;
}

export const WorkloadInformationView = observer(
  ({
    sx = [],
    className,
    date,
    getWorkloadInformation,
    showWorkloadBadge = true,
    size = 'regular',
    disableTooltip = false
  }: PlannerWorkloadTableCellProps) => {
    const { workStore } = useServices();
    const theme = useTheme();
    const defaultIconId = workStore.workIcons.data.defaultIconId;
    const workloadInfo = getWorkloadInformation();

    const level: WorkloadInformationLevelInfo | undefined = useMemo(() => {
      if (workloadInfo == null) {
        return undefined;
      }

      if (workloadInfo.aboveThresholdStudentCount > 0) {
        return {
          kind: 'over-threshold',
          studentCount: workloadInfo.aboveThresholdStudentCount
        };
      } else if (workloadInfo.atThresholdStudentCount > 0) {
        return {
          kind: 'at-threshold',
          studentCount: workloadInfo.atThresholdStudentCount
        };
      } else if (workloadInfo.underThresholdStudentCount > 0) {
        return {
          kind: 'under-threshold',
          studentCount: workloadInfo.underThresholdStudentCount
        };
      } else {
        return undefined;
      }
    }, [workloadInfo]);

    const icon = workStore.workIcons.data.iconsById.get(defaultIconId)!;
    const iconInfo: WorkIconInfo = {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: generateWorkIconUrl(icon, 'light'),
      darkUrl: generateWorkIconUrl(icon, 'dark'),
      externalBadgeUrl: undefined
    };

    const majorIconInfo: WorkIconInfo = {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: generateWorkIconUrl(icon, 'light', undefined, undefined, 'major'),
      darkUrl: generateWorkIconUrl(icon, 'dark', undefined, undefined, 'major'),
      externalBadgeUrl: undefined
    };

    const iconColor = useMemo(() => {
      switch (level?.kind) {
        case 'under-threshold':
          return theme.palette.text.disabled;
        case 'at-threshold':
          return theme.palette.warning.main;
        case 'over-threshold':
          return theme.palette.error.main;
        default:
          return undefined;
      }
    }, [level]);

    const isTooltipDisabled = disableTooltip || workloadInfo == null;

    return (
      <Tooltip
        title={
          <WorkloadInformationTooltipContent
            date={date}
            workloadInfo={workloadInfo}
            iconInfo={iconInfo}
            importantIconInfo={majorIconInfo}
          />
        }
        disableFocusListener={isTooltipDisabled}
        disableHoverListener={isTooltipDisabled}
        disableTouchListener={isTooltipDisabled}
      >
        <Stack sx={sx} className={className} direction="row" spacing={0.25} alignItems="center">
          <StyledBadge
            badgeContent={showWorkloadBadge ? level?.studentCount : undefined}
            color={level?.kind === 'over-threshold' ? 'error' : level?.kind === 'at-threshold' ? 'warning' : 'primary'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <PersonRounded
              fontSize={size === 'regular' ? 'large' : 'medium'}
              sx={{ color: iconColor, opacity: level != null ? 1 : 0 }}
            />
          </StyledBadge>

          <Stack>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{ opacity: (workloadInfo?.itemCount ?? 0) > 0 ? 1 : 0 }}
            >
              <WorkIcon icon={iconInfo} size={12} />
              <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '700', fontSize: '0.65rem' }}>
                {workloadInfo?.itemCount}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{ opacity: (workloadInfo?.importantItemCount ?? 0) > 0 ? 1 : 0 }}
            >
              <WorkIcon icon={majorIconInfo} size={12} />
              <Typography variant="caption" color="textSecondary" sx={{ fontWeight: '700', fontSize: '0.65rem' }}>
                {workloadInfo?.importantItemCount}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
    );
  }
);

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 0,
    bottom: theme.spacing(1),
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '0.65rem',
    fontWeight: 700
  }
}));

type WorkloadInformationLevelKind = 'under-threshold' | 'at-threshold' | 'over-threshold';

interface WorkloadInformationLevelInfo {
  readonly kind: WorkloadInformationLevelKind;
  readonly studentCount: number;
}
