import { Picture } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, UnsplashTransportService } from '@/services';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration
} from './DialogActionButtonConfiguration';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from './DialogViewModel';
import { AppUnsplashPictureViewModel, UnsplashPictureViewModel } from './UnsplashPictureViewModel';

export interface UnsplashViewModel extends StaticDialogViewModel {
  readonly isLoading: boolean;
  readonly hasMore: boolean;
  readonly hasError: boolean;
  readonly canSave: boolean;
  readonly pictures: UnsplashPictureViewModel[];
  selectedPicture?: UnsplashPictureViewModel;

  search(query: string): Promise<void>;
  loadMore(): Promise<void>;
  save(): Promise<void>;
  cancel(): Promise<void>;
}

export class AppUnsplashViewModel extends AppBaseStaticDialogViewModel implements UnsplashViewModel {
  private _query = '';
  private _page = 1;

  @observable private _isLoading = false;
  @observable private _hasMore = true;
  @observable private _hasError = false;
  @observable private _selectedPicture: UnsplashPictureViewModel | undefined;
  @observable private _pictures: UnsplashPictureViewModel[] = [];

  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  private _openUnsplashButtonConfig = new BaseDialogActionButtonConfiguration(
    'secondary',
    'both',
    'right',
    'open-in',
    'start',
    () => this._localization.localizedStrings.settings.unsplashOpenUnsplash,
    'outlined',
    () => Promise.resolve(),
    undefined,
    undefined,
    'https://unsplash.com?utm_source=today&utm_medium=referral'
  );

  constructor(
    private readonly _onSuccess: (picture: Picture) => Promise<void>,
    private readonly _onCancel: () => Promise<void>,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _unsplash: UnsplashTransportService = ServiceContainer.services.unsplash
  ) {
    super(_onCancel);
    makeObservable(this);

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._saveButtonConfig.isEnabled = this.canSave;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @override
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    return [this._openUnsplashButtonConfig];
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get hasMore(): boolean {
    return this._hasMore;
  }

  @computed
  get hasError(): boolean {
    return this._hasError;
  }

  @computed
  get canSave(): boolean {
    return this.selectedPicture != null;
  }

  @computed
  get selectedPicture(): UnsplashPictureViewModel | undefined {
    return this._selectedPicture;
  }

  set selectedPicture(value: UnsplashPictureViewModel | undefined) {
    this._selectedPicture = value;
  }

  @computed
  get pictures(): UnsplashPictureViewModel[] {
    return this._pictures;
  }

  @action
  async search(query: string) {
    this._query = query;
    this._page = 1;
    this._pictures = [];
    await this.searchPictures();
    // The results for one page don't fill the page, so loading another page of results.
    await this.loadMore();
  }

  async loadMore() {
    this._page++;
    await this.searchPictures();
  }

  async save() {
    if (this.selectedPicture == null) {
      return;
    }

    await this.selectedPicture.download();
    const picture: Picture = {
      source: 'unsplash',
      urls: this.selectedPicture.urls,
      authorName: this.selectedPicture.authorName,
      authorUrl: this.selectedPicture.authorUrl,
      color: this.selectedPicture.color
    };
    return this._onSuccess(picture);
  }

  cancel() {
    return this._onCancel();
  }

  @action
  private async searchPictures() {
    this._isLoading = true;
    this._hasError = false;
    try {
      const results = await this._unsplash.search(this._query, this._page);
      runInAction(() => {
        this._pictures = this._pictures.concat(
          results.results.map((p) => new AppUnsplashPictureViewModel(p, this._unsplash))
        );
        this._hasMore = this._page < results.totalPages;
      });
    } catch (e) {
      captureException(e);
      runInAction(() => (this._hasError = true));
    } finally {
      runInAction(() => (this._isLoading = false));
    }
  }
}
