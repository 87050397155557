import { AttachmentInfo, AttachmentInfoKind } from '../AttachmentInfo';
import { EditableModel } from './core';

// A user cannot edit an attachment. However, we want to take advantage of the "shouldBeDeleted"
// property on EditableModel.
export class EditableAttachmentInfo extends EditableModel<AttachmentInfo> {
  get kind(): AttachmentInfoKind {
    return this.initialModel.kind;
  }

  get title(): string | undefined {
    return this.initialModel.title;
  }

  get thumbnailUrl(): string | undefined {
    return this.initialModel.thumbnailUrl;
  }

  get url(): string {
    return this.initialModel.url;
  }

  get externalId(): string | undefined {
    return this.initialModel.externalId;
  }

  get externalKind(): string | undefined {
    return this.initialModel.externalKind;
  }

  get updatedModel(): AttachmentInfo {
    return this.initialModel;
  }

  readonly hasChangesToSharedProperties = false;
}
