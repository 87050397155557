import { useServices } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { Splash } from '../Splash';
import { LoadingIndicator } from '../utils';

export const InitializingScreen = observer(() => {
  const { localization } = useServices();
  const strings = localization?.localizedStrings.utils;

  return (
    <Splash width="100%" height="100%" logoIsLink={false} showOptions={false}>
      <LoadingIndicator message={strings?.loadingDataLabel ?? 'Preparing…'} />
    </Splash>
  );
});
