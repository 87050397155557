import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { computed, makeObservable } from 'mobx';
import { EditableModel, EditableProperty } from './core';

export interface ScheduleCyclePeriodActivityInfo {
  readonly activity: Activity | undefined;
  readonly roomName: string;
  readonly activityScheduleId: string | undefined;
}

export class EditableScheduleCyclePeriodActivityInfo extends EditableModel<ScheduleCyclePeriodActivityInfo> {
  private readonly _activity: EditableProperty<Activity | undefined>;
  private readonly _roomName: EditableProperty<string>;

  constructor(
    public readonly activityScheduleId: string | undefined,
    activity: Activity | undefined,
    roomName: string
  ) {
    super(activityScheduleId == null, { activityScheduleId, activity, roomName });
    makeObservable(this);

    this.setFields([
      (this._activity = new EditableProperty<Activity | undefined>(
        activity,
        (a1, a2) => a1?.activityId === a2?.activityId && a2?.activityId === a2?.activityId
      )),
      (this._roomName = new EditableProperty<string>(roomName, (r1, r2) => r1 === r2))
    ]);
  }

  @computed
  get activity(): Activity | undefined {
    return this._activity.value;
  }

  set activity(value: Activity | undefined) {
    this._activity.value = value;
  }

  @computed
  get roomName(): string {
    return this._roomName.value!;
  }

  set roomName(value: string) {
    this._roomName.value = value;
  }

  @computed
  get updatedModel(): ScheduleCyclePeriodActivityInfo {
    return {
      activityScheduleId: this.activityScheduleId,
      activity: this._activity.value!,
      roomName: this._roomName.value ?? ''
    };
  }

  readonly hasChangesToSharedProperties = false;
}
