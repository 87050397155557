import {
  DayOfWeek,
  ItemRepeatPatternKind,
  ScheduleCycleInfo,
  createOccurrenceItemRepetition,
  createWeekItemRepetition,
  dateToPBDate,
  dateToString
} from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerCalendarStore } from '@/stores';
import { captureException } from '@sentry/react';
import { computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { UpdatableViewModelState } from '../shared';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { BaseItemDuplicationDialogViewModel } from './BaseItemDuplicationDialogViewModel';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerItemKind } from './PlannerItemKind';

type PlannerRepeatKind = ItemRepeatPatternKind | { case: 'day' };

export interface PlannerRepeatItemViewModel extends UpdatableDialogViewModel {
  readonly item: PlannerCopyItemInfo;
  readonly minimumDate: Date;
  untilDate: Date;
  untilCount: number;
  pattern: PlannerRepeatKind;
  readonly scheduleCycle: ScheduleCycleInfo;
  readonly canRepeatEveryOccurrence: boolean;
  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[];
}

export class AppPlannerRepeatItemViewModel
  extends BaseItemDuplicationDialogViewModel
  implements PlannerRepeatItemViewModel
{
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _isSaving = false;
  @observable private _item: PlannerCopyItemInfo | undefined;
  @observable private _scheduleCycle: ScheduleCycleInfo | undefined;
  @observable private _minimumDate: Date | undefined;
  @observable private _untilDate: Date | undefined;
  @observable private _untilCount = 0;
  @observable private _pattern: PlannerRepeatKind;
  @observable private _canRepeatEveryOccurrence = false;

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;
  private readonly _calendarStore: PlannerCalendarStore;

  constructor(
    plannerId: string,
    itemKind: PlannerItemKind,
    close: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    private readonly _dateService = ServiceContainer.services.dateService,
    plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    workStore = ServiceContainer.services.workStore
  ) {
    super(plannerId, itemKind, close, localization, plannerStore, workStore);
    makeObservable(this);
    this._calendarStore = this._plannerStore.getCalendarStore(this._plannerId);
    this._pattern = { case: 'day' };

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.repeatPlannerItem.submitButtonLabel(),
      'contained',
      () => this.save()
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = !this._isSaving && this.hasChanges;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get item(): PlannerCopyItemInfo {
    return this._item!;
  }

  @computed
  get canRepeatEveryOccurrence(): boolean {
    return this._canRepeatEveryOccurrence;
  }

  @computed
  get minimumDate(): Date {
    return this._minimumDate!;
  }

  @computed
  get untilDate(): Date {
    return this._untilDate!;
  }

  set untilDate(value: Date) {
    this._untilDate = value;
  }

  @computed
  get untilCount(): number {
    return this._untilCount;
  }

  set untilCount(value: number) {
    this._untilCount = value;
  }

  @computed
  get pattern(): PlannerRepeatKind {
    return this._pattern;
  }

  set pattern(value: PlannerRepeatKind) {
    this._pattern = value;
  }

  @computed
  get scheduleCycle(): ScheduleCycleInfo {
    return this._scheduleCycle!;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get hasChanges(): boolean {
    if (this._pattern.case === 'week') {
      return this._pattern.value.daysOfWeek.length > 0;
    }

    if (this._pattern.case === 'cycleDay') {
      return this._pattern.value.cycleDays.length > 0;
    }

    return true;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSaving;
  }

  @computed
  get hasData(): boolean {
    return this._scheduleCycle != null && this._item != null && this._minimumDate != null && this._untilDate != null;
  }

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[] {
    if (this.item.courseId == null) {
      return [];
    }

    return this._calendarStore.getOccurrencesForCourseSection(this.item.courseId, fromDate, toDate).map((o) => o.date);
  }

  async reloadData(): Promise<void> {
    runInAction(() => (this._state = 'pending'));

    try {
      const { item, date } = await this.loadItem();
      const minimumDate = date ?? this._dateService.now;
      await this._calendarStore.fetchDays(dateToPBDate(minimumDate), dateToPBDate(minimumDate), true);
      const calendarDay = this._calendarStore.days.get(dateToString(minimumDate))!;

      if (calendarDay == null) {
        throw new Error('Calendar day not found');
      }

      const scheduleCycleId = calendarDay.cycleDayScheduleCycleId;
      const scheduleCycleLoadable = this._userStore.getScheduleCycle(scheduleCycleId);
      await scheduleCycleLoadable.fetch(false);

      runInAction(() => {
        this._item = item;
        this._minimumDate = minimumDate;
        this._untilDate = minimumDate;
        this._scheduleCycle = scheduleCycleLoadable.data;
        this._state = 'fulfilled';
        this._canRepeatEveryOccurrence = (item.courseId?.length ?? 0) > 0;
        this._pattern = this._canRepeatEveryOccurrence
          ? { case: 'occurrence', value: createOccurrenceItemRepetition() }
          : { case: 'day' };
      });
    } catch (e) {
      captureException(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  private async save() {
    runInAction(() => (this._isSaving = true));

    const pattern: ItemRepeatPatternKind =
      this._pattern.case === 'day'
        ? {
            case: 'week',
            value: createWeekItemRepetition({
              daysOfWeek: [
                DayOfWeek.MONDAY,
                DayOfWeek.THURSDAY,
                DayOfWeek.WEDNESDAY,
                DayOfWeek.THURSDAY,
                DayOfWeek.FRIDAY,
                DayOfWeek.SATURDAY,
                DayOfWeek.SUNDAY
              ],
              recurrence: 1
            })
          }
        : this._pattern;

    const untilValue =
      this.untilCount > 0
        ? ({ case: 'untilCount', value: this.untilCount } as const)
        : ({ case: 'untilDate', value: dateToPBDate(this.untilDate) } as const);

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this._workStore.repeatWork(
            this._itemKind.id,
            true,
            untilValue,
            pattern,
            this.item.courseId != null,
            undefined,
            undefined
          );
          break;

        case 'note':
          await this._workStore.repeatNote(
            this._itemKind.id,
            true,
            untilValue,
            pattern,
            this.item.courseId != null,
            undefined,
            undefined
          );
          break;

        case 'publishedWork': {
          await this._workStore.repeatPublishedWork(
            this._itemKind.id,
            true,
            untilValue,
            pattern,
            this.item.courseId != null,
            undefined,
            undefined
          );
          break;
        }
      }

      runInAction(() => (this._isSaving = false));
      await this.dismiss();
    } catch (e) {
      captureException(e);
      runInAction(() => (this._error = LocalizedStrings.planner.distributeItem.saveErrorMessage()));
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }
}
