import { WorkloadGroupDetails } from '@/models';
import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { alpha, Collapse, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { PlannerWorkloadItemsGroup } from './PlannerWorkloadItemsGroup';

const collapseButtonVariants = {
  rotate: { rotate: '180deg', transition: { duration: 0.2 } },
  stop: { rotate: 0, transition: { duration: 0.2 } }
};

export interface PlannerWorkloadItemGroupsListSectionProps {
  sx?: SxProps;
  className?: string;
  status: WorkloadStatus;
  groups: WorkloadGroupDetails[];
}

export const PlannerWorkloadItemGroupsListSection = observer(
  ({ sx = [], className, status, groups }: PlannerWorkloadItemGroupsListSectionProps) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(true);
    const strings = LocalizedStrings.planner.workload;

    const values = useMemo(() => {
      switch (status) {
        case WorkloadStatus.ABOVE_THRESHOLD:
          return {
            backgroundColor: alpha(theme.palette.error.main, 0.8),
            color: theme.palette.error.contrastText,
            title: strings.studentAboveThreshold()
          };
        case WorkloadStatus.AT_THRESHOLD:
          return {
            backgroundColor: alpha(theme.palette.warning.main, 0.8),
            color: theme.palette.warning.contrastText,
            title: strings.studentAtThreshold()
          };
        case WorkloadStatus.UNDER_THRESHOLD:
        case WorkloadStatus.UNSPECIFIED:
        case WorkloadStatus.EMPTY:
          return {
            backgroundColor: alpha(theme.palette.text.disabled, 0.3),
            color: theme.palette.text.primary,
            title: strings.studentUnderThreshold()
          };
      }
    }, [status]);

    return (
      <Stack spacing={1} sx={sx} className={className}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            backgroundColor: values.backgroundColor,
            color: values.color,
            px: 1,
            py: 0.5,
            borderRadius: 1,
            alignItems: 'center'
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: '600', flex: 1 }}>
            {values.title}
          </Typography>

          <IconButton size="small" onClick={() => setIsExpanded((prev) => !prev)} color="inherit">
            <motion.div
              variants={collapseButtonVariants}
              animate={isExpanded ? 'rotate' : 'stop'}
              style={{ display: 'flex' }}
            >
              <KeyboardArrowDownRounded fontSize="small" color="inherit" />
            </motion.div>
          </IconButton>
        </Stack>

        <Collapse in={isExpanded}>
          <Stack spacing={1}>
            {groups.map((group, i) => (
              <PlannerWorkloadItemsGroup key={i} group={group} />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  }
);
