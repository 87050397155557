import { RouteTemplates } from '@/RouteTemplates';
import { useActivePlannerId, useIsMatchPath, useServices } from '@/hooks';
import {
  StudentsPlannerSummaryTableRowViewModel,
  getDailyActivityBehaviorTooltip,
  getInboxBehaviorLevel,
  getInboxBehaviorTooltip,
  getStudentEmotionalStatusLevel,
  getStudentEmotionalStatusTooltip
} from '@/viewmodels';
import ConnectedNoAccessIcon from '@mui/icons-material/VisibilityOffRounded';
import { Box, CardActionArea, Stack, Typography, alpha, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Link, useParams, useSearchParams } from 'react-router';
import { ContentCard } from '../../../shared';
import { StudentBehaviors } from '../../student';
import { StudentItemCountsIndicator } from './StudentItemCountsIndicator';

export interface SchoolCourseSectionDetailsStudentGridItemProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  student: StudentsPlannerSummaryTableRowViewModel;
  onClick?: () => void;
}

export const SchoolCourseSectionDetailsStudentGridItem = observer(
  ({ sx = [], className, style, student, onClick }: SchoolCourseSectionDetailsStudentGridItemProps) => {
    const { route, localization, dateService } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;
    const theme = useTheme();

    const params = useParams();
    const plannerId = useActivePlannerId();
    const schoolId = params.schoolId ?? '';
    const courseSectionId = params.courseId ?? '';
    const studentId = params.studentId ?? '';
    const [searchParams] = useSearchParams();
    const isCurrentPath = useIsMatchPath({ path: RouteTemplates.schoolStudentDetails, end: false });
    const isShowingAStudentPlanner = searchParams.get('view') !== 'insights';
    const linkLocation = isShowingAStudentPlanner
      ? route.resolveSchoolStudentPlannerLocation(plannerId, schoolId, courseSectionId, student.id)
      : route.resolveSchoolStudentInsightsLocation(plannerId, schoolId, courseSectionId, student.id);
    const isSelected = isCurrentPath && student.id === studentId;
    const inboxBehaviorLevel = getInboxBehaviorLevel(student.inboxItemsCount);
    const lastEmotionalStatusLevel = getStudentEmotionalStatusLevel(student.emotionalStateRating);

    return (
      <Box style={style} sx={{ ...sx }} className={className}>
        <ContentCard
          sx={{
            height: '100%',
            width: '100%',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: isSelected ? theme.palette.primary.main : undefined
          }}
          forcedBackgroundColor={isSelected ? alpha(theme.palette.primary.main, 0.08) : undefined}
          forcedBorderColor={isSelected ? theme.palette.primary.main : undefined}
        >
          <CardActionArea
            onClick={onClick}
            component={Link}
            to={linkLocation}
            sx={{ height: '100%', width: '100%', p: 1 }}
          >
            <Stack sx={{ height: '100%', width: '100%' }} spacing={1}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'flex-start',
                  flex: 1
                }}
              >
                <Typography
                  sx={{
                    color: isSelected ? theme.palette.primary.main : 'inherit',
                    flex: 1,
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2
                  }}
                  variant="subtitle2"
                >
                  {student.name}
                </Typography>

                {student.connectionState === 'connected' && (
                  <StudentBehaviors
                    isCompact={true}
                    behaviors={{
                      dailyActivity: {
                        kind: 'daily-activity',
                        level: student.lastConnectionIndicatorLevel,
                        tooltip: getDailyActivityBehaviorTooltip(student.lastConnection, localization, dateService)
                      },
                      inbox: {
                        kind: 'inbox',
                        level: inboxBehaviorLevel,
                        tooltip: getInboxBehaviorTooltip(inboxBehaviorLevel, localization)
                      },
                      lastEmotionalStatus: {
                        kind: 'last-emotional-status',
                        level: lastEmotionalStatusLevel,
                        tooltip: getStudentEmotionalStatusTooltip(student.emotionalStateRating, localization)
                      }
                    }}
                  />
                )}

                {student.connectionState === 'connected-no-access' && (
                  <ConnectedNoAccessIcon color="primary" fontSize="small" />
                )}
              </Stack>

              {student.connectionState === 'connected' && (
                <StudentItemCountsIndicator
                  hasLateItems={student.lateItemsCount > 0}
                  inboxCount={student.inboxItemsCount}
                  todayCount={student.todayItemsCount}
                  upcomingCount={student.upcomingItemsCount}
                  doneCount={student.doneItemsCount}
                />
              )}

              {student.connectionState !== 'connected' && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                    flex: 1,
                    fontWeight: 500
                  }}
                >
                  {student.connectionState === 'not-connected'
                    ? strings.detailsStudentsGridItemNotConnected
                    : strings.detailsStudentsGridItemNotConnectedNoAccess}
                </Typography>
              )}
            </Stack>
          </CardActionArea>
        </ContentCard>
      </Box>
    );
  }
);
