import { useActivePlannerId, useNavigateAsync, useRouteMatch, useServices, useViewModel } from '@/hooks';
import { Alert, AlertTitle, Box, Collapse, Divider, Snackbar, Stack, Tab, Tabs } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { UserDashboardInfo } from '../../../../models';
import { getUserDashboardForScheduleCycle } from '../../../../viewmodels';
import { Banner, UpdatablePresenter } from '../../../utils';
import { ScheduleCycleBottomActions } from './ScheduleCycleBottomActions';
import { ScheduleCycleHeader } from './ScheduleCycleHeader';
import { ScheduleCycleUserDashboardContext } from './ScheduleCycleUserDashboardContext';

const SubRoutes = {
  details: '',
  schedules: 'master-schedule',
  belltimes: 'belltimes',
  specialDays: 'specialdays',
  activitySchedules: 'activity-schedules'
};

export interface ScheduleCyclePageProps {
  sx?: SxProps;
  className?: string;
}

export const ScheduleCyclePage = observer(({ sx = [], className }: ScheduleCyclePageProps) => {
  const { route, userStore } = useServices();
  const navigate = useNavigateAsync();
  const plannerId = useActivePlannerId();
  const params = useParams();
  const scheduleCycleId = params.scheduleCycleId ?? '';

  const resolvedUserDashboard: UserDashboardInfo = useMemo(
    () => getUserDashboardForScheduleCycle(scheduleCycleId, plannerId, userStore),
    [plannerId, scheduleCycleId]
  );

  const [showHasDroppedChangesMessage, setShowHasDroppedChangesMessage] = useState(false);

  const viewModel = useViewModel(
    (viewModels) =>
      viewModels.createScheduleCycleDetailsViewModel(
        scheduleCycleId,
        resolvedUserDashboard.id,
        resolvedUserDashboard.kind
      ),
    [scheduleCycleId, resolvedUserDashboard]
  );

  function getSubRoute(subPath: string | undefined) {
    return route.resolvePlannerScheduleConfigLocation(scheduleCycleId, subPath, plannerId);
  }

  const routeMatch = useRouteMatch([
    getSubRoute(SubRoutes.specialDays),
    getSubRoute(SubRoutes.belltimes),
    getSubRoute(SubRoutes.schedules),
    getSubRoute(SubRoutes.activitySchedules),
    getSubRoute(undefined)
  ]);

  const currentTab = routeMatch?.pattern?.path;
  const strings = LocalizedStrings.scheduleCycle.edit;

  async function retrySaveDraft() {
    await viewModel.retrySaveDraft();
  }

  return (
    <ScheduleCycleUserDashboardContext value={{ plannerId, dashboard: resolvedUserDashboard, scheduleCycleId }}>
      <UpdatablePresenter
        isFlex
        sx={{ ...sx, height: '100%' }}
        className={className}
        viewModel={viewModel}
        renderData={() => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%'
            }}
          >
            <Collapse in={viewModel.hasSaveDraftError}>
              <Banner
                sx={{ my: 2 }}
                severity="error"
                actions={[
                  {
                    case: 'icon-text',
                    value: { text: strings.saveDraftError.retryButtonTitle() },
                    onClick: () => retrySaveDraft()
                  }
                ]}
              >
                <AlertTitle>{strings.saveDraftError.title()}</AlertTitle>
                {strings.saveDraftError.message()}
              </Banner>
            </Collapse>

            <ScheduleCycleHeader
              scheduleCycle={viewModel.scheduleCycle}
              onDelete={() => viewModel.removeScheduleCycle(route.resolvePlannerSchedulesLocation(plannerId), navigate)}
              isReadOnly={viewModel.isReadOnly}
              schoolId={resolvedUserDashboard.kind === 'school' ? resolvedUserDashboard.id : undefined}
            />

            <Stack>
              <Tabs value={currentTab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                <Tab
                  data-intercom-target="schedule-cycle-details-tab"
                  label={strings.tab.details()}
                  value={getSubRoute(undefined)}
                  component={Link}
                  to={getSubRoute(undefined)}
                />

                <Tab
                  data-intercom-target="schedule-cycle-master-schedule-tab"
                  label={strings.tab.schedules()}
                  value={getSubRoute(SubRoutes.schedules)}
                  component={Link}
                  to={getSubRoute(SubRoutes.schedules)}
                />
                <Tab
                  data-intercom-target="schedule-cycle-bell-times-tab"
                  label={strings.tab.bellTimes()}
                  value={getSubRoute(SubRoutes.belltimes)}
                  component={Link}
                  to={getSubRoute(SubRoutes.belltimes)}
                />

                <Tab
                  data-intercom-target="schedule-cycle-special-days-tab"
                  label={strings.tab.specialDays()}
                  value={getSubRoute(SubRoutes.specialDays)}
                  component={Link}
                  to={getSubRoute(SubRoutes.specialDays)}
                />

                <Tab
                  data-intercom-target="schedule-cycle-activity-schedules-tab"
                  label={strings.tab.activitySchedules()}
                  value={getSubRoute(SubRoutes.activitySchedules)}
                  component={Link}
                  to={getSubRoute(SubRoutes.activitySchedules)}
                />
              </Tabs>
              <Divider variant="fullWidth" />
            </Stack>

            <Box
              sx={{
                flex: 1,
                display: 'flex',
                overflowY: 'auto'
              }}
            >
              <Outlet />
            </Box>

            <Collapse in={viewModel.hasChanges && !viewModel.hasSaveDraftError}>
              <ScheduleCycleBottomActions
                sx={{ mt: 2 }}
                onUndo={() => viewModel.undo()}
                onCancel={() => viewModel.cancel()}
                onSave={() => viewModel.save()}
                showHasDroppedChangesMessage={() => setShowHasDroppedChangesMessage(true)}
              />
            </Collapse>

            <Snackbar
              open={showHasDroppedChangesMessage}
              autoHideDuration={6000}
              onClose={() => setShowHasDroppedChangesMessage(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setShowHasDroppedChangesMessage(false)}
                severity="warning"
                variant="filled"
                sx={{ width: '100%' }}
              >
                {strings.actions.publishDroppedChangesMessage()}
              </Alert>
            </Snackbar>
          </Box>
        )}
      />
    </ScheduleCycleUserDashboardContext>
  );
});
