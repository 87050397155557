import { useServices } from '@/hooks';
import EmptyListIcon from '@mui/icons-material/DirectionsBikeRounded';
import { EmptyListIndicator, MediaQuery } from '../../../utils';

export interface PlannerTodayListEmptyIndicatorProps {
  /**
   * Callback when pressing the button.
   */
  onButtonPress?: () => void;
  size?: 'small' | 'regular';
}

export const PlannerTodayListEmptyIndicator = ({ onButtonPress, size }: PlannerTodayListEmptyIndicatorProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <EmptyListIndicator
          title={strings.todayEmptyListTitle}
          subtitle={strings.todayEmptyListSubtitle}
          renderIcon={(className) => <EmptyListIcon className={className} />}
          size={size}
        />
      </MediaQuery>

      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <EmptyListIndicator
          title={strings.todayEmptyListTitle}
          subtitle={strings.todayEmptyListSubtitle}
          renderIcon={(className) => <EmptyListIcon className={className} />}
          size={size}
          button={
            onButtonPress != null
              ? {
                  title: strings.todayEmptyListButtonTitle,
                  action: () => {
                    onButtonPress();
                    return Promise.resolve();
                  }
                }
              : undefined
          }
        />
      </MediaQuery>
    </>
  );
};
