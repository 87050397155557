import { ActivityOccurrenceSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_occurrence_pb';
import { ActivitySchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { ActivityScheduleSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_schedule_pb';
import { CalendarDaySchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { CalendarSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_pb';
import { CalendarPeriodSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_period_pb';
import { DayAnnotationSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/day_annotation_pb';
import { DraftScheduleCycleSummarySchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/draft_schedule_cycle_summary_pb';
import { PartialActivityScheduleSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/partial_activity_schedule_pb';
import { PeriodSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { PeriodScheduleOccurrenceSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_occurrence_pb';
import { PeriodScheduleSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_schedule_pb';
import { ScheduleCycleSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { SpecialDayOccurrenceSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_occurrence_pb';
import { SpecialDaySchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/special_day_pb';
import { TermSchema } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createActivity(init?: MessageInitShape<typeof ActivitySchema>) {
  return create(ActivitySchema, init);
}

export function createActivityOccurrence(init?: MessageInitShape<typeof ActivityOccurrenceSchema>) {
  return create(ActivityOccurrenceSchema, init);
}

export function createActivitySchedule(init?: MessageInitShape<typeof ActivityScheduleSchema>) {
  return create(ActivityScheduleSchema, init);
}

export function createSchedulesCalendar(init?: MessageInitShape<typeof CalendarSchema>) {
  return create(CalendarSchema, init);
}

export function createCalendarDay(init?: MessageInitShape<typeof CalendarDaySchema>) {
  return create(CalendarDaySchema, init);
}

export function createCalendarPeriod(init?: MessageInitShape<typeof CalendarPeriodSchema>) {
  return create(CalendarPeriodSchema, init);
}

export function createDayAnnotation(init?: MessageInitShape<typeof DayAnnotationSchema>) {
  return create(DayAnnotationSchema, init);
}

export function createDraftScheduleCycleSummary(init?: MessageInitShape<typeof DraftScheduleCycleSummarySchema>) {
  return create(DraftScheduleCycleSummarySchema, init);
}

export function createPartialActivitySchedule(init?: MessageInitShape<typeof PartialActivityScheduleSchema>) {
  return create(PartialActivityScheduleSchema, init);
}

export function createPeriod(init?: MessageInitShape<typeof PeriodSchema>) {
  return create(PeriodSchema, init);
}

export function createPeriodSchedule(init?: MessageInitShape<typeof PeriodScheduleSchema>) {
  return create(PeriodScheduleSchema, init);
}

export function createPeriodScheduleOccurrence(init?: MessageInitShape<typeof PeriodScheduleOccurrenceSchema>) {
  return create(PeriodScheduleOccurrenceSchema, init);
}

export function createScheduleCycle(init?: MessageInitShape<typeof ScheduleCycleSchema>) {
  return create(ScheduleCycleSchema, init);
}

export function createSpecialDay(init?: MessageInitShape<typeof SpecialDaySchema>) {
  return create(SpecialDaySchema, init);
}

export function createSpecialDayOccurrence(init?: MessageInitShape<typeof SpecialDayOccurrenceSchema>) {
  return create(SpecialDayOccurrenceSchema, init);
}

export function createTerm(init?: MessageInitShape<typeof TermSchema>) {
  return create(TermSchema, init);
}
