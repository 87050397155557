import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import LessIcon from '@mui/icons-material/ArrowLeft';
import MoreIcon from '@mui/icons-material/ArrowRight';
import { Box, Button, IconButton, List, ListItem, ListItemText, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useBackButtonListener } from '../../../hooks';

export interface AdminDemoSchoolSnapshotListProps {
  sx?: SxProps;
  className?: string;
  snapshots: Snapshot[];
  onRestore: (snapshot: Snapshot, dayOffset: number) => Promise<void>;
}

export const AdminDemoSchoolSnapshotList = observer(
  ({ sx = [], className, snapshots, onRestore }: AdminDemoSchoolSnapshotListProps) => {
    const strings = LocalizedStrings.admin.general.manageActions.snapshots;

    const [dayOffset, setDayOffset] = useState(0);
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
    const [restoringSnapshot, setRestoringSnapshot] = useState<Snapshot | undefined>();
    const [isRestoring, setIsRestoring] = useState(false);

    function startRestore(element: HTMLButtonElement, snapshot: Snapshot) {
      const now = new Date();
      setDayOffset(now.getDay() - timestampDate(snapshot.time!).getDay());
      setRestoringSnapshot(snapshot);
      setAnchorElement(element);
    }

    function stopRestore() {
      if (!isRestoring) {
        setAnchorElement(null);
        setRestoringSnapshot(undefined);
      }
    }

    async function restore(snapshot: Snapshot, dayOffset: number) {
      setIsRestoring(true);

      try {
        await onRestore(snapshot, dayOffset);
      } finally {
        setAnchorElement(null);
        setRestoringSnapshot(undefined);
        setIsRestoring(false);
      }
    }

    useBackButtonListener((e) => {
      if (anchorElement != null) {
        e.stopPropagation();
        stopRestore();
      }
    });

    return (
      <Box sx={sx} className={className}>
        {snapshots.length === 0 ? (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.secondary
            }}
          >
            {strings.noSnapshots()}
          </Typography>
        ) : (
          <List dense disablePadding>
            {snapshots.map((s) => (
              <ListItem key={`snapshot-${s.name}-in-${s.schoolId}`} disableGutters>
                <ListItemText primary={s.name} secondary={format(timestampDate(s.time!), 'PPPpp')} />
                <Button
                  onClick={(e) => startRestore(e.currentTarget, s)}
                  sx={{ minWidth: 150, maxWidth: 250 }}
                  variant="contained-grey"
                  color="error"
                  disabled={isRestoring}
                >
                  {strings.restoreButton()}
                </Button>
              </ListItem>
            ))}
          </List>
        )}
        <Popover
          anchorEl={anchorElement}
          open={anchorElement != null}
          onClose={() => stopRestore()}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {restoringSnapshot && (
            <Stack
              spacing={1}
              sx={{
                p: 2,
                minWidth: 300
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                {strings.baseDateLabel()} {format(timestampDate(restoringSnapshot.time!), 'PPPP')}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    flex: 1
                  }}
                >
                  {strings.offsetDaysLabel(dayOffset)}
                </Typography>
                <IconButton size="small" disabled={dayOffset <= -6} onClick={() => setDayOffset(dayOffset - 1)}>
                  <LessIcon />
                </IconButton>
                <IconButton size="small" disabled={dayOffset >= 6} onClick={() => setDayOffset(dayOffset + 1)}>
                  <MoreIcon />
                </IconButton>
              </Stack>
              <Button
                variant="contained-grey"
                color="error"
                onClick={() => void restore(restoringSnapshot, dayOffset)}
                loading={isRestoring}
              >
                {strings.restoreButton()}
              </Button>
            </Stack>
          )}
        </Popover>
      </Box>
    );
  }
);
