import { CalendarEvent } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/calendar_event_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { differenceInMinutes } from 'date-fns';

export function calendarEventDuration(event: CalendarEvent): number | undefined {
  if (event.startTime == null || event.endTime == null) {
    return undefined;
  }

  return differenceInMinutes(timestampDate(event.endTime), timestampDate(event.startTime));
}
