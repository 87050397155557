import {
  ConnectedApps,
  PlannerAccessManagement,
  PlannerCourseSections,
  PlannerCurriculumCourses,
  PlannerEdit,
  PlannerSchoolsList,
  ScheduleCycleSettingsPage,
  SchoolSettingsPage,
  SettingsContent
} from '@/components';
import { Navigate, Outlet, RouteObject } from 'react-router';
import { PlannerSchoolSettingsRouteWrapper } from './PlannerSchoolSettingsRouteWrapper';
import { ScheduleCycleConfigRoutes } from './ScheduleCycleConfigRoutes';
import { SharedSettingsRoutes } from './SharedSettingsRoutes';

export const PlannerSettingsRoutes: RouteObject[] = [
  ...SharedSettingsRoutes,
  {
    path: 'schedules',
    element: <ScheduleCycleSettingsPage />,
    children: ScheduleCycleConfigRoutes
  },
  // Old name, redirecting in case of bookmarks.
  {
    path: 'schedule',
    children: [
      { path: '', element: <Navigate to="../schedules" /> },
      // We don't try to extract the id, it's enough to redirect to the list.
      { path: '*', element: <Navigate to="../../schedules" /> }
    ]
  },
  { path: 'courses', element: <PlannerCourseSections /> },
  { path: 'curriculum-courses', element: <PlannerCurriculumCourses /> },
  { path: 'access', element: <PlannerAccessManagement /> },
  { path: 'edit', element: <PlannerEdit /> },
  { path: 'connected-apps', element: <ConnectedApps /> },
  {
    path: 'schools',
    element: (
      <SettingsContent>
        <PlannerSchoolSettingsRouteWrapper>
          <Outlet />
        </PlannerSchoolSettingsRouteWrapper>
      </SettingsContent>
    ),
    children: [
      { path: ':schoolId?/settings', element: <SchoolSettingsPage /> },
      { path: '', element: <PlannerSchoolsList /> }
    ]
  },
  { path: '', element: <Navigate to="courses" replace /> }
];
