import EmptyListIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useServices } from '../../../../hooks';
import { EmptyListIndicator, MediaQuery } from '../../../utils';

export interface PlannerInboxListEmptyIndicatorProps {
  /**
   * Callback when pressing the button.
   */
  onButtonPress?: () => void;
  size?: 'small' | 'regular';
}

export const PlannerInboxListEmptyIndicator = ({ onButtonPress, size }: PlannerInboxListEmptyIndicatorProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <EmptyListIndicator
          title={strings.inboxEmptyListTitle}
          subtitle={strings.inboxEmptyListSubtitle}
          renderIcon={(className) => <EmptyListIcon className={className} />}
          size={size}
          button={
            onButtonPress != null
              ? {
                  title: strings.inboxEmptyListButtonTitle,
                  action: () => {
                    onButtonPress();
                    return Promise.resolve();
                  }
                }
              : undefined
          }
        />
      </MediaQuery>

      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <EmptyListIndicator
          title={strings.inboxEmptyListTitle}
          subtitle={strings.inboxEmptyListSubtitle}
          renderIcon={(className) => <EmptyListIcon className={className} />}
          size={size}
        />
      </MediaQuery>
    </>
  );
};
