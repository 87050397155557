import { RelativeDateSchema } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_pb';
import { TimeIntervalSchema } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_interval_pb';
import { TimeSlotSchema } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createRelativeDate(init?: MessageInitShape<typeof RelativeDateSchema>) {
  return create(RelativeDateSchema, init);
}

export function createTimeInterval(init?: MessageInitShape<typeof TimeIntervalSchema>) {
  return create(TimeIntervalSchema, init);
}

export function createTimeSlot(init?: MessageInitShape<typeof TimeSlotSchema>) {
  return create(TimeSlotSchema, init);
}
