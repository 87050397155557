import { useServices } from '@/hooks';
import { isSxArray } from '@/utils';
import { AdminWorkloadViewModel } from '@/viewmodels';
import { ArrowBackRounded, ArrowForwardRounded, CalendarMonthRounded, ErrorRounded } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { Button, CircularProgress, IconButton, Popover, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { addMonths, format, isSameMonth, subMonths } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDatePicker } from '../../shared';
import { useAdminWorkloadForceReloadContext } from './AdminWorkloadForceReloadContext';

export interface AdminWorkloadCalendarHeaderProps {
  sx?: SxProps;
  className?: string;
  currentDate: Date;
  onDateChange: (date: Date | undefined) => void;
  viewModel: AdminWorkloadViewModel;
}

export const AdminWorkloadCalendarHeader = observer(
  ({ sx = [], className, onDateChange, currentDate, viewModel }: AdminWorkloadCalendarHeaderProps) => {
    const { dateService } = useServices();
    const [datePickerButtonRef, setDatePickerButtonRef] = useState<HTMLButtonElement | undefined>();
    const forceReloadContext = useAdminWorkloadForceReloadContext();
    const strings = LocalizedStrings.admin.workload;

    function onReloadButtonClick() {
      forceReloadContext.onReload?.();
    }

    return (
      <Stack
        direction="row"
        sx={[{ px: 2, alignItems: 'center' }, ...(isSxArray(sx) ? sx : [sx])]}
        spacing={1}
        className={className}
      >
        <Typography variant="h6" sx={{ fontWeight: '600', flex: 1 }}>
          {format(currentDate, 'MMMM y')}
        </Typography>

        {!isSameMonth(currentDate, dateService.now) && (
          <Button variant="contained-grey" onClick={() => onDateChange(undefined)}>
            {strings.goToTodayButtonTitle()}
          </Button>
        )}

        <Stack direction="row" sx={{ alignItems: 'center' }}>
          {viewModel.isFetchingData ? (
            <Tooltip title={strings.reloadTooltip()}>
              <CircularProgress size={20} />
            </Tooltip>
          ) : (
            <IconButton onClick={() => onReloadButtonClick()}>
              <RefreshIcon />
            </IconButton>
          )}

          {viewModel.hasFetchError && !viewModel.isFetchingData && (
            <Tooltip title={strings.reloadErrorMessage()}>
              <ErrorRounded color="error" />
            </Tooltip>
          )}

          <IconButton onClick={() => onDateChange(subMonths(currentDate, 1))}>
            <ArrowBackRounded />
          </IconButton>
          <Box>
            <IconButton onClick={(e) => setDatePickerButtonRef(e.currentTarget)}>
              <CalendarMonthRounded />
            </IconButton>

            {datePickerButtonRef != null && (
              <Popover
                open={true}
                anchorEl={datePickerButtonRef}
                slotProps={{ paper: { sx: { width: 400 } } }}
                onClose={() => setDatePickerButtonRef(undefined)}
              >
                <UserDashboardCalendarDatePicker
                  selectedDates={() => []}
                  highlightedDates={() => []}
                  onSelectDate={(date) => {
                    onDateChange(date);
                    setDatePickerButtonRef(undefined);
                  }}
                />
              </Popover>
            )}
          </Box>
          <IconButton onClick={() => onDateChange(addMonths(currentDate, 1))}>
            <ArrowForwardRounded />
          </IconButton>
        </Stack>
      </Stack>
    );
  }
);
