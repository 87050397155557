import { createTimeOfDay, TimeOfDay } from '@/models';
import {
  ScheduleCycleActivitySchedulesDayColumnInfo,
  ScheduleCyclePeriodSchedulePeriodEditViewModel
} from '@/viewmodels';
import { Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useRef, useState } from 'react';
import { ScheduleCyclePeriodEditPopover } from './ScheduleCyclePeriodEditPopover';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';
import { ScheduleCyclePeriodScheduleGridHourLine } from './ScheduleCyclePeriodScheduleGridHourLine';
import { ScheduleCyclePeriodScheduleGridPeriodView } from './ScheduleCyclePeriodScheduleGridPeriodView';

export interface ScheduleCyclePeriodScheduleGridDayColumnProps {
  sx?: SxProps;
  column: ScheduleCycleActivitySchedulesDayColumnInfo;
  supportsActivitySchedules: boolean;
  canCreatePeriods: boolean;
  canEditPeriods: boolean;
  getEditViewModel: (
    columnIndex: number,
    periodId: string | undefined,
    time: TimeOfDay
  ) => Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel>;
}

export const ScheduleCyclePeriodScheduleGridDayColumn = observer(
  ({
    sx = [],
    column,
    getEditViewModel,
    supportsActivitySchedules,
    canCreatePeriods,
    canEditPeriods
  }: ScheduleCyclePeriodScheduleGridDayColumnProps) => {
    const [clickedTime, setClickedTime] = useState<TimeOfDay | undefined>();
    const containerRef = useRef<HTMLDivElement>(null);

    function onClick(e: MouseEvent<HTMLDivElement>) {
      e.stopPropagation();
      const offset = e.nativeEvent.offsetY;
      const totalMinutes = (60 * offset) / ScheduleCyclePeriodScheduleGridConstants.pointsPerHour;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const time = createTimeOfDay({ hours, minutes });
      setClickedTime(time);
    }

    return (
      <>
        <Stack
          sx={{
            position: 'relative',
            ...sx,
            width: ScheduleCyclePeriodScheduleGridConstants.dayColumnWidth
          }}
        >
          {Array(25)
            .fill(null)
            .map(
              (_, hourLineIndex) =>
                hourLineIndex > 0 &&
                hourLineIndex < 24 && (
                  <ScheduleCyclePeriodScheduleGridHourLine
                    key={`hour-line-${hourLineIndex}`}
                    sx={{
                      position: 'absolute',
                      top: hourLineIndex * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour,
                      left: 0,
                      right: 0
                    }}
                  />
                )
            )}

          {/*
         The parent stack cannot handle the click as we get an offset of 0 if one the hour lines is clicked.
         To prevent this, we add a transparent view above the lines, but below the periods that handles the click.
      */}
          <Box
            ref={containerRef}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent',
              zIndex: ScheduleCyclePeriodScheduleGridConstants.dayColumnClickHandlerZIndex
            }}
            onClick={column.canEdit && canCreatePeriods ? onClick : undefined}
          />

          {column.periods.map((period) => (
            <ScheduleCyclePeriodScheduleGridPeriodView
              key={`day-column-element-${period.id}`}
              period={period}
              getEditViewModel={(periodId, time) => getEditViewModel(column.index, periodId, time)}
              supportsActivitySchedules={supportsActivitySchedules}
              isReadOnly={!canEditPeriods}
            />
          ))}
        </Stack>
        {clickedTime != null && (
          <ScheduleCyclePeriodEditPopover
            isOpen={true}
            supportsActivities={supportsActivitySchedules}
            anchorEl={containerRef.current}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            anchorOrigin={{
              vertical: positionForTime(clickedTime),
              horizontal: 'center'
            }}
            sx={{ width: '100%', height: '100%' }}
            getViewModel={() => getEditViewModel(column.index, undefined, clickedTime)}
            dismiss={() => setClickedTime(undefined)}
          />
        )}
      </>
    );
  }
);

function positionForTime(time: TimeOfDay) {
  return (
    time.hours * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour +
    (time.minutes * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour) / 60
  );
}
