import { useActivePlannerId, useNavigateAsync, useViewModel } from '@/hooks';
import { PublishedWorkEditInfo } from '@/models';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import LocalizedStrings from 'strings';
import { Dialog, MultipleActionsAlertDialog } from '../../utils';
import { PublishedWorkEdit } from './PublishedWorkEdit';

export interface PublishedWorkEditProps {
  sx?: SxProps;
  className?: string;
  ids: { publishedWorkId: string; schoolId: string } | undefined;
  baseParams?: Partial<PublishedWorkEditInfo>;
  isOpen: boolean;
}

export const PublishedWorkEditDialog = observer(
  ({ sx = [], className, ids, baseParams, isOpen }: PublishedWorkEditProps) => {
    const plannerId = useActivePlannerId();
    const navigate = useNavigateAsync();
    const strings = LocalizedStrings.publishedWork.edit;

    function onSuccess() {
      return navigate(-1);
    }

    function close() {
      return navigate(-1);
    }

    const viewModelRef = useRef(
      useViewModel((viewModels) =>
        viewModels.createPublishedWorkEditViewModel(ids, plannerId, baseParams, onSuccess, close)
      )
    );
    const viewModel = viewModelRef.current;

    return (
      <>
        <Dialog
          sx={sx}
          className={className}
          isOpen={isOpen}
          canToggleFullscreen
          width="lg"
          viewModel={viewModel}
          title={ids == null ? strings.addTitle() : strings.editTitle()}
          fullScreenWidth="md"
          submit={() => void viewModel.save()}
          contentPadding={{ top: 2 }}
          renderData={() => <PublishedWorkEdit viewModel={viewModel} sx={{ width: '100%' }} />}
        />

        {viewModel.showApplyToCopiesConfirmation && (
          <MultipleActionsAlertDialog
            isOpen
            title={strings.applyToCopiesDialog.title()}
            message={strings.applyToCopiesDialog.message()}
            actions={[
              {
                label: strings.applyToCopiesDialog.applyToAllButtonTitle(),
                onClick: () => void viewModel.saveDuplicates(true),
                message: strings.applyToCopiesDialog.applyToAllTooltip()
              },
              {
                label: strings.applyToCopiesDialog.applyOnlyToThisCopyButtonTitle(),
                onClick: () => void viewModel.saveDuplicates(false),
                message: strings.applyToCopiesDialog.applyOnlyToThisCopyTooltip()
              }
            ]}
            onCancel={() => {
              viewModel.showApplyToCopiesConfirmation = false;
              return Promise.resolve();
            }}
          />
        )}
      </>
    );
  }
);
