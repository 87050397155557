import { timestampDateOptional, WorkIconInfo } from '@/models';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { Box, Card, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { WorkIcon } from '../../lists';
import { RichTextViewer } from '../../utils';
import { PublishedWorkDetailsDueDate } from './PublishedWorkDetailsDueDate';
import { PublishedWorkDetailsMaxGrade } from './PublishedWorkDetailsMaxGrade';

export interface PublishedWorkDetailsTitleCardProps {
  sx?: SxProps;
  className?: string;
  publishedWork: PublishedWork;
  courseColor: string | undefined;
  icon: WorkIconInfo;
}

export const PublishedWorkDetailsTitleCard = observer(
  ({ sx = [], className, publishedWork, icon, courseColor }: PublishedWorkDetailsTitleCardProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <Card sx={{ ...sx, p: 2 }} className={className} elevation={theme.palette.mode === 'light' ? 1 : 8}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              flex: 1
            }}
          >
            <WorkIcon icon={icon} color={courseColor} displayCourseSectionColor size={40} />

            <Stack
              direction="column"
              spacing={0.5}
              sx={{
                flex: 1
              }}
            >
              <Typography variant="h6">{publishedWork.title}</Typography>

              {(publishedWork.description?.text.length ?? 0) > 0 && (
                <RichTextViewer
                  id="published-work-details"
                  label={LocalizedStrings.publishedWork.edit.instructionsSectionTitle()}
                  content={publishedWork.description}
                  textSize="small"
                  sx={{ maxHeight: 300 }}
                />
              )}
            </Stack>
          </Stack>

          <Box>
            <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
          </Box>

          <Stack spacing={1} sx={{ minWidth: 180 }}>
            <PublishedWorkDetailsDueDate
              dueDate={timestampDateOptional(publishedWork.dueTime)}
              isDueAllDay={publishedWork.isDueAllDay}
            />

            <PublishedWorkDetailsMaxGrade maxGrade={publishedWork.maxGrade} />
          </Stack>
        </Stack>
      </Card>
    );
  }
);
