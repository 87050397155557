import { AttachmentSchema } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/attachment_pb';
import { CourseSchema } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/course_pb';
import { SyncSchoolSubjectSchema } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_school_subject_pb';
import { SyncStatusSchema } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/resources/sync_status_pb';
import { create, MessageInitShape } from '@bufbuild/protobuf';

export function createClassroomAttachment(init?: MessageInitShape<typeof AttachmentSchema>) {
  return create(AttachmentSchema, init);
}

export function createClassroomCourse(init?: MessageInitShape<typeof CourseSchema>) {
  return create(CourseSchema, init);
}

export function createClassroomSyncSchoolSubject(init?: MessageInitShape<typeof SyncSchoolSubjectSchema>) {
  return create(SyncSchoolSubjectSchema, init);
}

export function createClassroomSyncStatus(init?: MessageInitShape<typeof SyncStatusSchema>) {
  return create(SyncStatusSchema, init);
}
