import { WorkIconInfo, WorkloadSummaryInfo } from '@/models';
import { PersonOutlineRounded, PersonRounded } from '@mui/icons-material';
import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { WorkIcon } from '../../lists';

export interface WorkloadInformationTooltipContentProps {
  sx?: SxProps;
  className?: string;
  workloadInfo: WorkloadSummaryInfo | undefined;
  date: Date;
  iconInfo: WorkIconInfo;
  importantIconInfo: WorkIconInfo;
}

export const WorkloadInformationTooltipContent = observer(
  ({ sx = [], className, workloadInfo, date, iconInfo, importantIconInfo }: WorkloadInformationTooltipContentProps) => {
    const strings = LocalizedStrings.planner.workload;
    const theme = useTheme();

    if (workloadInfo == null) {
      return null;
    }

    return (
      <Stack sx={sx} className={className}>
        <Typography variant="caption" sx={{ fontWeight: '700', color: alpha(theme.palette.common.white, 0.8) }}>
          {format(date, 'PPP')}
        </Typography>

        <CountRow
          color={theme.palette.error.main}
          count={workloadInfo.aboveThresholdStudentCount}
          label={strings.studentOverThresholdMessage}
        />
        <CountRow
          color={theme.palette.warning.main}
          count={workloadInfo.atThresholdStudentCount}
          label={strings.studentAtThresholdMessage}
        />
        <CountRow
          color={theme.palette.text.disabled}
          count={workloadInfo.underThresholdStudentCount}
          label={strings.studentUnderThresholdMessage}
        />

        <WorkCountRow count={workloadInfo.itemCount} icon={iconInfo} label={strings.itemCount} />
        <WorkCountRow
          count={workloadInfo.importantItemCount}
          icon={importantIconInfo}
          label={strings.importantItemCount}
        />
      </Stack>
    );
  }
);

interface CountRowProps {
  color: string;
  count: number;
  label: (count: number) => string;
}

const CountRow = ({ color, count, label }: CountRowProps) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {count > 0 ? (
        <PersonRounded fontSize="small" sx={{ color }} />
      ) : (
        <PersonOutlineRounded fontSize="small" sx={{ color }} />
      )}
      <Typography variant="caption">{label(count)}</Typography>
    </Stack>
  );
};

interface WorkCountRowProps {
  icon: WorkIconInfo;
  count: number;
  label: (count: number) => string;
}

const WorkCountRow = ({ icon, count, label }: WorkCountRowProps) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Box sx={{ width: 20, display: 'flex', justifyContent: 'center' }}>
        <WorkIcon icon={icon} size={16} />
      </Box>
      <Typography variant="caption">{label(count)}</Typography>
    </Stack>
  );
};
