import {
  timestampDateOptional,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon,
  urlForWorkIconFromWork,
  WorkIconInfo
} from '@/models';
import { computed } from 'mobx';
import LocalizedStrings from 'strings';
import { ServiceContainer } from '../../providers';
import { AppBaseUpdatableDialogViewModel } from '../utils';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerItemKind } from './PlannerItemKind';

export interface ItemDuplicationItemAndDateInfo {
  item: PlannerCopyItemInfo;
  date: Date | undefined;
  isAllDay: boolean;
}

export abstract class BaseItemDuplicationDialogViewModel extends AppBaseUpdatableDialogViewModel {
  protected constructor(
    protected readonly _plannerId: string,
    protected readonly _itemKind: PlannerItemKind,
    close: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    protected readonly _plannerStore = ServiceContainer.services.plannerStore,
    protected readonly _workStore = ServiceContainer.services.workStore
  ) {
    super(localization, close);
  }

  @computed
  protected get courseSectionsLoadable() {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIconsLoadable() {
    return this._workStore.workIcons;
  }

  protected async loadItem(): Promise<ItemDuplicationItemAndDateInfo> {
    switch (this._itemKind.case) {
      case 'work':
        return await this.loadDataForWork(this._itemKind.id);

      case 'note':
        return await this.loadDataForNote(this._itemKind.id);

      case 'publishedWork':
        return await this.loadDataForPublishedWork(this._itemKind.id, this._itemKind.schoolId);
    }
  }

  protected async loadDataForWork(id: string): Promise<ItemDuplicationItemAndDateInfo> {
    const workLoadable = this._workStore.getWorkLoadable(id);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const course = this.courseSectionsLoadable.data.get(work.courseSectionId);
    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForWorkIconFromWork(icon, work, 'light'),
            darkUrl: urlForWorkIconFromWork(icon, work, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    return {
      item: {
        kind: 'work',
        id: work.id,
        title: work.title,
        subtitle: work.description,
        color: course?.courseSection?.color ?? '',
        courseId: course?.courseSection?.id,
        icon: iconInfo
      },
      date: timestampDateOptional(work.dueTime),
      isAllDay: work.isDueAllDay
    };
  }

  protected async loadDataForNote(id: string): Promise<ItemDuplicationItemAndDateInfo> {
    const noteLoadable = this._workStore.getNoteLoadable(id);
    await noteLoadable.fetch(false);

    const note = noteLoadable.data;
    const course = this.courseSectionsLoadable.data.get(note.courseSectionId);

    return {
      item: {
        kind: 'note',
        id: note.id,
        title: LocalizedStrings.planner.distributeItem.noteTitle(),
        subtitle: note.text,
        color: course?.courseSection?.color ?? '',
        courseId: course?.courseSection?.id,
        icon: undefined
      },
      date: timestampDateOptional(note.time),
      isAllDay: note.isAllDay
    };
  }

  protected async loadDataForPublishedWork(id: string, schoolId: string): Promise<ItemDuplicationItemAndDateInfo> {
    const workLoadable = this._workStore.getPublishedWorkLoadable(id, schoolId);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const allCourseSections = this.courseSectionsLoadable.values;
    const course = allCourseSections.find((cs) => cs.schoolsCourseSection?.id === work.courseSectionId);

    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'light'),
            darkUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    return {
      item: {
        kind: 'publishedWork',
        id: work.id,
        title: work.title,
        subtitle: work.description,
        color: course?.courseSection?.color ?? '',
        icon: iconInfo,
        schoolId: work.schoolId,
        courseId: course?.courseSection?.id
      },
      date: timestampDateOptional(work.dueTime),
      isAllDay: work.isDueAllDay
    };
  }
}
