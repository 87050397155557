import { useServices, useSync } from '@/hooks';
import { PlannerListKind, StudentConnectionState } from '@/models';
import { ExLocalizedStrings } from '@/resources/strings/ExLocalizedStrings';
import { PlannerListViewModel } from '@/viewmodels';
import { css } from '@emotion/css';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { List } from '../../../lists';
import {
  PlannerDoneListEmptyIndicator,
  PlannerInboxListEmptyIndicator,
  PlannerListItem,
  PlannerTodayListEmptyIndicator,
  PlannerUpcomingListEmptyIndicator
} from '../../../planner';
import { ContentCard } from '../../../shared';
import { UpdatablePresenter } from '../../../utils';
import { StudentDetailsNotConnected } from '../details';

export interface StudentPlannerListProps {
  sx?: SxProps;
  className?: string;
  kind: PlannerListKind;
  viewModel: PlannerListViewModel;
  connectionState: StudentConnectionState;
  showNotConnectedState: boolean;
}

export const StudentPlannerList = observer(
  ({ sx = [], className, kind, viewModel, connectionState, showNotConnectedState }: StudentPlannerListProps) => {
    const { localization } = useServices();
    const listClassName = css({ flex: 1 });
    const isConnected = connectionState === 'connected';

    useSync(
      `student-planner-list-${kind}`,
      async () => {
        if (isConnected) {
          await viewModel.reloadData();
        }
      },
      [viewModel, isConnected]
    );

    useEffect(() => {
      if (isConnected) {
        // Reloading data when a new student is selected.
        void viewModel.reloadData();
      }
    }, [viewModel, isConnected]);

    return (
      <ContentCard sx={{ ...sx, flex: 1 }} className={className}>
        <Stack sx={{ height: '100%' }}>
          <Typography variant="body1" sx={{ px: 2, pt: 1, textTransform: 'capitalize', fontWeight: '600' }}>
            {listTitle(kind, localization.localizedStrings)}
          </Typography>

          {isConnected ? (
            <UpdatablePresenter
              sx={{ width: '100%', height: '100%' }}
              viewModel={viewModel}
              renderData={() => (
                <List
                  className={listClassName}
                  sections={viewModel.groups}
                  renderEmptyIndicator={() => renderEmptyIndicator(kind)}
                  keyForItem={(section, row) => viewModel.groups[section].items[row].uniqueId}
                  renderItem={(section, index) => {
                    const item = viewModel.groups[section].items[index];
                    return <PlannerListItem item={item} displaySteps={false} />;
                  }}
                  rowHeightForItem={() => 60}
                />
              )}
            />
          ) : showNotConnectedState ? (
            <StudentDetailsNotConnected connectionState={connectionState} />
          ) : null}
        </Stack>
      </ContentCard>
    );
  }
);

function listTitle(kind: PlannerListKind, localizedStrings: ExLocalizedStrings) {
  const strings = localizedStrings.schoolCourseSection;

  switch (kind) {
    case 'inbox':
      return strings.studentDetailsPlannerInboxTitle;
    case 'today':
      return strings.studentDetailsPlannerTodayTitle;
    case 'upcoming':
      return strings.studentDetailsPlannerUpcomingTitle;
    case 'done':
      return strings.studentDetailsPlannerDoneTitle;
    default:
      return '';
  }
}

function renderEmptyIndicator(kind: PlannerListKind) {
  switch (kind) {
    case 'inbox':
      return <PlannerInboxListEmptyIndicator size="small" />;
    case 'today':
      return <PlannerTodayListEmptyIndicator size="small" />;
    case 'upcoming':
      return <PlannerUpcomingListEmptyIndicator size="small" />;
    case 'done':
      return <PlannerDoneListEmptyIndicator size="small" />;
    default:
      return <></>;
  }
}
