import { PlannerListKind } from '@/models';
import { DateService } from '@/services';
import { RelativeDateKind } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_kind_pb';
import { RelativeDate } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/relative_date_pb';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { format } from 'date-fns';
import { EnglishDateStrings, FrenchDateStrings } from './utils/DateStrings';

export interface WorkListStrings {
  readonly loading: string;
  readonly genericErrorMessage: string;
  readonly noDescription: string;
  readonly noDueDate: string;
  readonly completeWork: string;
  readonly createPlannedWork: string;
  readonly createWorkButtonAriaLabel: string;
  readonly editPlannedWork: string;
  readonly edit: string;
  readonly copy: string;
  readonly distribute: string;
  readonly duplicate: string;
  readonly paste: string;
  readonly markAsActive: string;
  readonly repeat: string;
  readonly showDetails: string;

  readonly inboxTitle: string;
  readonly inboxTitleSmall: string;
  readonly inboxSubtitle: string;
  readonly inboxEmptyListTitle: string;
  readonly inboxEmptyListSubtitle: string;
  readonly inboxEmptyListButtonTitle: string;

  readonly todayTitle: string;
  readonly todayTitleSmall: string;
  readonly todaySubtitle: string;
  readonly todayEmptyListTitle: string;
  readonly todayEmptyListSubtitle: string;
  readonly todayEmptyListButtonTitle: string;

  readonly upcomingTitle: string;
  readonly upcomingTitleSmall: string;
  readonly upcomingSubtitle: string;
  readonly upcomingEmptyListTitle: string;
  readonly upcomingEmptyListSubtitle: string;

  readonly doneTitle: string;
  readonly doneTitleSmall: string;
  readonly doneSubtitle: string;
  readonly doneEmptyListTitle: string;
  readonly doneEmptyListSubtitle: string;

  readonly completionDate: (date: Date | undefined, dateService: DateService) => string;
  readonly dueDate: (date: Date, dateService: DateService) => string;
  readonly lateSectionTitle: string;
  readonly listKind: (kind: PlannerListKind) => string;

  readonly studentWorksMobileWarning: string;
  readonly studentWorksOpenInExternalSource: (externalSourceName: string) => string;
  readonly studentWorksOpenInPlanner: string;
  readonly studentWorksTableCompletionDateColumnHeader: string;
  readonly studentWorksTableDueDateColumnHeader: string;
  readonly studentWorksTableEmptySubtitle: string;
  readonly studentWorksTableEmptyTitle: string;
  readonly studentWorksTableGoToInitialPageDefaultActionTitle: string;
  readonly studentWorksTableInboxDaysColumnHeader: string;
  readonly studentWorksTablePlannedSessionsColumnHeader: string;
  readonly studentWorksTablePlannedTimeColumnHeader: string;
  readonly studentWorksTablePlannedTimeValue: (duration?: number) => string;
  readonly studentWorksTableScrollToInitialPageLabel: string;
  readonly studentWorksTableSearchBarPlaceholder: string;
  readonly studentWorksTableSubtitle: (courseTitle: string) => string;
  readonly studentWorksTableTitle: string;

  readonly groupTitle: (relativeDate: RelativeDate) => string;
}

export const EnglishWorkListStrings: WorkListStrings = {
  loading: 'Loading…',
  genericErrorMessage: 'Oops',
  noDescription: 'No instructions',
  noDueDate: 'No due date',
  completeWork: "I'm done!",
  createPlannedWork: 'Plan a work session',
  createWorkButtonAriaLabel: 'Create item',
  editPlannedWork: 'Reschedule work session',
  edit: 'Edit',
  copy: 'Copy',
  distribute: 'Distribute',
  duplicate: 'Duplicate',
  paste: 'Paste',
  markAsActive: 'Restore',
  repeat: 'Repeat',
  showDetails: 'Show details',

  inboxTitle: 'Inbox',
  inboxTitleSmall: 'Inbox',
  inboxSubtitle: 'Plan a work session for each item',
  inboxEmptyListTitle: 'Inbox Zero!',
  inboxEmptyListSubtitle: "What is today's plan?",
  inboxEmptyListButtonTitle: "See today's plan",

  todayTitle: 'Today',
  todayTitleSmall: 'Today',
  todaySubtitle: 'Your plan for today',
  todayEmptyListTitle: 'All Clear!',
  todayEmptyListSubtitle: 'Inbox empty? Upcoming under control?\nIf so, enjoy your free time!',
  todayEmptyListButtonTitle: "See what's new",

  upcomingTitle: 'Upcoming',
  upcomingTitleSmall: 'Upcoming',
  upcomingSubtitle: 'Planned for later',
  upcomingEmptyListTitle: 'Sunny Forecast!',
  upcomingEmptyListSubtitle: 'Inbox empty? If so, enjoy an\nendless horizon of easy days!',

  doneTitle: 'Done',
  doneTitleSmall: 'Done',
  doneSubtitle: 'Bask in the joy of accomplishment!',
  doneEmptyListTitle: 'Get Busy!',
  doneEmptyListSubtitle: 'Complete your first\nassignment today!',

  completionDate: (date, dateService) => (date != null ? EnglishDateStrings.shortDateFormat(date, dateService) : ''),
  dueDate: EnglishDateStrings.shortDateFormat,
  lateSectionTitle: 'Late',
  listKind: (kind) => {
    switch (kind) {
      case 'inbox':
        return 'Inbox';
      case 'today':
        return 'Today';
      case 'upcoming':
        return 'Upcoming';
      case 'done':
        return 'Done';
      default:
        return kind;
    }
  },
  studentWorksMobileWarning: "To view more details about your student's work, visit Studyo Go on a larger device.",
  studentWorksOpenInExternalSource: (externalSourceName) => `View in ${externalSourceName}`,
  studentWorksOpenInPlanner: 'Open in planner',
  studentWorksTableCompletionDateColumnHeader: 'Completion date',
  studentWorksTableDueDateColumnHeader: 'Due date',
  studentWorksTableEmptySubtitle: "This student doesn't have items for your class yet.",
  studentWorksTableEmptyTitle: 'No item',
  studentWorksTableGoToInitialPageDefaultActionTitle: 'Go to initial page',
  studentWorksTableInboxDaysColumnHeader: 'Days in inbox',
  studentWorksTablePlannedSessionsColumnHeader: 'Planned sessions',
  studentWorksTablePlannedTimeColumnHeader: 'Planned time',
  studentWorksTablePlannedTimeValue: (duration) =>
    duration != null ? EnglishDateStrings.shortDurationFormat(duration) : '',
  studentWorksTableScrollToInitialPageLabel: 'Go to today',
  studentWorksTableSearchBarPlaceholder: 'Search items…',
  studentWorksTableSubtitle: (courseTitle) => `Only items for ${courseTitle} are displayed`,
  studentWorksTableTitle: 'Workload',

  groupTitle: (relativeDate) => {
    const unit = relativeDate.unitCount;
    switch (relativeDate.kind) {
      case RelativeDateKind.DAYS_AGO:
        return `${unit} days ago`;
      case RelativeDateKind.YESTERDAY:
        return 'Yesterday';
      case RelativeDateKind.TODAY:
        return 'Today';
      case RelativeDateKind.TOMORROW:
        return 'Tomorrow';
      case RelativeDateKind.IN_DAYS:
        return `In ${unit} days`;
      case RelativeDateKind.DAY_SHORT:
        return format(timestampDate(relativeDate.date!), 'eeee, MMMM D');
      case RelativeDateKind.DAY_LONG:
        return format(timestampDate(relativeDate.date!), 'PPP');
      case RelativeDateKind.WEEKS_AGO:
        return `${unit} weeks ago`;
      case RelativeDateKind.LAST_WEEK:
        return 'Last week';
      case RelativeDateKind.THIS_WEEK:
        return 'This week';
      case RelativeDateKind.NEXT_WEEK:
        return 'Next week';
      case RelativeDateKind.IN_WEEKS:
        return `In ${unit} weeks`;
      case RelativeDateKind.WEEK:
        return format(timestampDate(relativeDate.date!), "'Week of' MMMM D");
      case RelativeDateKind.MONTHS_AGO:
        return `${unit} months ago`;
      case RelativeDateKind.LAST_MONTH:
        return 'Last month';
      case RelativeDateKind.THIS_MONTH:
        return 'This month';
      case RelativeDateKind.NEXT_MONTH:
        return 'Next month';
      case RelativeDateKind.IN_MONTHS:
        return `In ${unit} months`;
      case RelativeDateKind.MONTH:
        return format(timestampDate(relativeDate.date!), 'MMMM');
      case RelativeDateKind.YEARS_AGO:
        return `${unit} years ago`;
      case RelativeDateKind.LAST_YEAR:
        return 'Last year';
      case RelativeDateKind.THIS_YEAR:
        return 'This year';
      case RelativeDateKind.NEXT_YEAR:
        return 'Next year';
      case RelativeDateKind.IN_YEARS:
        return `In ${unit} years`;
      case RelativeDateKind.YEAR:
        return format(timestampDate(relativeDate.date!), 'yyyy');
      case RelativeDateKind.UNSPECIFIED:
        return '';
    }
  }
};

export const FrenchWorkListStrings: WorkListStrings = {
  loading: 'Chargement…',
  genericErrorMessage: 'Oups',
  noDescription: 'Aucune instruction',
  noDueDate: 'Aucune date due',
  completeWork: 'J’ai terminé!',
  createPlannedWork: 'Planifier une session de travail',
  createWorkButtonAriaLabel: 'Créer un item',
  editPlannedWork: 'Modifier la session de travail',
  edit: 'Éditer',
  copy: 'Copier',
  distribute: 'Distribuer',
  duplicate: 'Dupliquer',
  paste: 'Coller',
  markAsActive: 'Restaurer',
  repeat: 'Répéter',
  showDetails: 'Voir les détails',

  inboxTitle: 'À traiter',
  inboxTitleSmall: 'À traiter',
  inboxSubtitle: 'Planifie une session de travail pour chaque item',
  inboxEmptyListTitle: 'Rien à traiter!',
  inboxEmptyListSubtitle: 'Que fait-on aujourd’hui?',
  inboxEmptyListButtonTitle: 'Voir le plan',

  todayTitle: 'Aujourd’hui',
  todayTitleSmall: 'Aujourd’hui',
  todaySubtitle: 'Ton plan pour la journée',
  todayEmptyListTitle: 'Libre!',
  todayEmptyListSubtitle: 'Rien à traiter? Tout est sous contrôle?\nAlors Studyo Go t’offre la journée!',
  todayEmptyListButtonTitle: 'Il y a du nouveau?',

  upcomingTitle: 'Prochainement',
  upcomingTitleSmall: 'Bientôt',
  upcomingSubtitle: 'Planifié pour plus tard',
  upcomingEmptyListTitle: 'Le soleil à l’horizon!',
  upcomingEmptyListSubtitle: 'Rien à traiter, ni à faire? Tu peux\nvraiment te la couler douce!',

  doneTitle: 'Complété',
  doneTitleSmall: 'Complété',
  doneSubtitle: 'Réjouis-toi de tes accomplissements!',
  doneEmptyListTitle: 'Active-toi!',
  doneEmptyListSubtitle: 'Complète ton premier devoir dès\naujourd’hui!',

  completionDate: (date, dateService) => (date != null ? FrenchDateStrings.shortDateFormat(date, dateService) : ''),
  dueDate: FrenchDateStrings.shortDateFormat,
  lateSectionTitle: 'En retard',
  listKind: (kind) => {
    switch (kind) {
      case 'inbox':
        return 'À traiter';
      case 'today':
        return 'Aujourd’hui';
      case 'upcoming':
        return 'Prochainement';
      case 'done':
        return 'Complété';
      default:
        return kind;
    }
  },

  studentWorksMobileWarning:
    'Pour obtenir plus de détails sur les travaux de cet étudiant, visitez Studyo Go sur un plus grand écran.',
  studentWorksOpenInExternalSource: (externalSourceName) => `Voir dans ${externalSourceName}`,
  studentWorksOpenInPlanner: 'Ouvrir dans le planificateur',
  studentWorksTableCompletionDateColumnHeader: 'Date de réalisation',
  studentWorksTableDueDateColumnHeader: 'Date de remise',
  studentWorksTableEmptySubtitle: 'Cet élève n’a pas d’item pour votre cours.',
  studentWorksTableEmptyTitle: 'Aucun item',
  studentWorksTableGoToInitialPageDefaultActionTitle: 'Aller à la page initiale',
  studentWorksTableInboxDaysColumnHeader: 'Jours avant d’être planifié',
  studentWorksTablePlannedSessionsColumnHeader: 'Sessions planifiées',
  studentWorksTablePlannedTimeColumnHeader: 'Temps planifié',
  studentWorksTablePlannedTimeValue: (duration) =>
    duration != null ? FrenchDateStrings.shortDurationFormat(duration) : '',
  studentWorksTableScrollToInitialPageLabel: 'Aller à aujourd’hui',
  studentWorksTableSearchBarPlaceholder: 'Rechercher un item…',
  studentWorksTableSubtitle: (courseTitle) => `Seules les items pour ${courseTitle} sont affichés`,
  studentWorksTableTitle: 'Charge de travail',

  groupTitle: (relativeDate) => {
    const unit = relativeDate.unitCount;
    switch (relativeDate.kind) {
      case RelativeDateKind.DAYS_AGO:
        return `Il y a ${unit} jours`;
      case RelativeDateKind.YESTERDAY:
        return 'Hier';
      case RelativeDateKind.TODAY:
        return 'Aujourd’hui';
      case RelativeDateKind.TOMORROW:
        return 'Demain';
      case RelativeDateKind.IN_DAYS:
        return `Dans ${unit} jours`;
      case RelativeDateKind.DAY_SHORT:
        return format(timestampDate(relativeDate.date!), 'eeee D MMMM');
      case RelativeDateKind.DAY_LONG:
        return format(timestampDate(relativeDate.date!), 'PPP');
      case RelativeDateKind.WEEKS_AGO:
        return `Il y a ${unit} semaines`;
      case RelativeDateKind.LAST_WEEK:
        return 'La semaine dernière';
      case RelativeDateKind.THIS_WEEK:
        return 'Cette semaine';
      case RelativeDateKind.NEXT_WEEK:
        return 'La semaine prochaine';
      case RelativeDateKind.IN_WEEKS:
        return `Dans ${unit} semaines`;
      case RelativeDateKind.WEEK:
        return format(timestampDate(relativeDate.date!), "'Semaine du' D MMMM");
      case RelativeDateKind.MONTHS_AGO:
        return `Il y a ${unit} mois`;
      case RelativeDateKind.LAST_MONTH:
        return 'Le mois dernier';
      case RelativeDateKind.THIS_MONTH:
        return 'Ce mois-ci';
      case RelativeDateKind.NEXT_MONTH:
        return 'Le mois prochain';
      case RelativeDateKind.IN_MONTHS:
        return `Dans ${unit} mois`;
      case RelativeDateKind.MONTH:
        return format(timestampDate(relativeDate.date!), 'MMMM');
      case RelativeDateKind.YEARS_AGO:
        return `Il y a ${unit} années`;
      case RelativeDateKind.LAST_YEAR:
        return 'L’année dernière';
      case RelativeDateKind.THIS_YEAR:
        return 'Cette année';
      case RelativeDateKind.NEXT_YEAR:
        return 'L’année prochaine';
      case RelativeDateKind.IN_YEARS:
        return `Dans ${unit} années`;
      case RelativeDateKind.YEAR:
        return format(timestampDate(relativeDate.date!), 'yyyy');
      case RelativeDateKind.UNSPECIFIED:
        return '';
    }
  }
};
