import {
  Day,
  DayOfWeek,
  ItemRepeatPatternKind,
  NoteCopyResponse,
  NoteDuplicationDetailsResponse,
  NoteRepeatResponse,
  PublishedWorkCopyResponse,
  PublishedWorkDuplicationDetailsResponse,
  PublishedWorkRepeatResponse,
  WorkCopyResponse,
  WorkDuplicationDetailsResponse,
  WorkIcons,
  WorkRepeatResponse
} from '@/models';
import { TimeSlot } from '@buf/studyo_studyo-today-common.bufbuild_es/studyo/today/type/time_slot_pb';
import { ItemDistributionPatternKind } from '@buf/studyo_studyo-today-item-duplication.bufbuild_es/studyo/today/item_duplication/v1/resources/item_distribution_pattern_kind_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { TimeSlotGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/time_slot_group_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { WorkStep } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { timestampFromDate } from '@bufbuild/protobuf/wkt';
import { ApplicationSettingsStorage, FirebaseContract, LocalizationService } from '../../services';
import {
  CreatePublishedWorkRequest,
  GrpcTransportService,
  UpdatePublishedWorkRequest,
  WorkTransportService
} from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppWorkTransportService extends AppBaseTransportService implements WorkTransportService {
  constructor(
    firebase: FirebaseContract,
    localization: LocalizationService,
    settingsStorage: ApplicationSettingsStorage,
    private readonly _grpcTransport: GrpcTransportService
  ) {
    super(firebase, localization, settingsStorage);
  }

  async getWork(workId: string): Promise<Work> {
    return await this.performReadRequest(
      'getWork',
      (headers) => this._grpcTransport.plannersClient.getWork({ workId }, { headers }),
      { isDemoObject: this.isDemoId(workId), supportsDemoMode: true }
    );
  }

  async getWorks(
    plannerId: string,
    courseSectionId: string | undefined,
    externalSourceName: string | undefined,
    includeCancelledWorks: boolean
  ): Promise<Work[]> {
    const response = await this.performReadRequest(
      'getWorks',
      (headers) =>
        this._grpcTransport.plannersClient.getWorks(
          {
            plannerId,
            courseSectionId,
            externalSourceName,
            includeCancelledWorks
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId), supportsDemoMode: true }
    );

    return response.works;
  }

  async createWork(work: Work): Promise<Work> {
    return await this.performWriteRequest(
      'createWork',
      (headers) =>
        this._grpcTransport.plannersClient.createWork(
          {
            title: work.title,
            iconId: work.iconId,
            dueTime: work.dueTime,
            description: work.description,
            isDueAllDay: work.isDueAllDay,
            plannerId: work.plannerId,
            importance: work.importance,
            courseSectionId: work.courseSectionId,
            attachments: work.attachments
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(work.plannerId) }
    );
  }

  async updateWork(work: Work, shouldClearDuplicationIds: boolean): Promise<Work> {
    return await this.performWriteRequest(
      'updateWork',
      (headers) =>
        this._grpcTransport.plannersClient.updateWork(
          {
            workId: work.id,
            title: work.title,
            iconId: work.iconId,
            dueTime: work.dueTime,
            description: work.description,
            isDueAllDay: work.isDueAllDay,
            importance: work.importance,
            courseSectionId: work.courseSectionId,
            attachments: work.attachments,
            syncToken: work.syncToken,
            shouldClearDuplicationIds
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(work.plannerId) }
    );
  }

  async updateWorkDuplicates(work: Work): Promise<Work[]> {
    const response = await this.performWriteRequest(
      'updateWorkDuplicates',
      (headers) =>
        this._grpcTransport.plannersClient.updateWorkDuplicates(
          {
            mainWorkId: work.id,
            title: work.title,
            iconId: work.iconId,
            description: work.description,
            importance: work.importance,
            attachments: work.attachments,
            syncToken: work.syncToken
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(work.plannerId) }
    );

    return response.updatedWorks;
  }

  async setWorkStatus(workId: string, status: WorkStatus, syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'setWorkStatus',
      (headers) =>
        this._grpcTransport.plannersClient.setWorkStatus({ workId, newStatus: status, syncToken }, { headers }),
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async getNotes(
    plannerId: string,
    courseSectionId: string | undefined,
    includeArchivedNotes: boolean
  ): Promise<Note[]> {
    const response = await this.performReadRequest(
      'getNotes',
      (headers) =>
        this._grpcTransport.plannersClient.getNotes({ plannerId, courseSectionId, includeArchivedNotes }, { headers }),
      { isDemoObject: this.isDemoId(plannerId) }
    );

    return response.notes;
  }

  async getNote(noteId: string): Promise<Note> {
    return await this.performReadRequest(
      'getNote',
      (headers) => this._grpcTransport.plannersClient.getNote({ noteId }, { headers }),
      { isDemoObject: this.isDemoId(noteId) }
    );
  }

  async createNote(note: Note): Promise<Note> {
    return await this.performWriteRequest('createNote', (headers) =>
      this._grpcTransport.plannersClient.createNote(
        {
          plannerId: note.plannerId,
          text: note.text,
          time: note.time,
          isAllDay: note.isAllDay,
          courseSectionId: note.courseSectionId
        },
        { headers }
      )
    );
  }

  async updateNote(note: Note, shouldClearDuplicationIds: boolean): Promise<Note> {
    return await this.performWriteRequest('updateNote', (headers) =>
      this._grpcTransport.plannersClient.updateNote(
        {
          noteId: note.id,
          text: note.text,
          time: note.time,
          isAllDay: note.isAllDay,
          courseSectionId: note.courseSectionId,
          syncToken: note.syncToken,
          shouldClearDuplicationIds
        },
        { headers }
      )
    );
  }

  async updateNoteDuplicates(note: Note): Promise<Note[]> {
    const response = await this.performWriteRequest(
      'updateNoteDuplicates',
      (headers) =>
        this._grpcTransport.plannersClient.updateNoteDuplicates(
          {
            mainNoteId: note.id,
            text: note.text,
            syncToken: note.syncToken
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(note.plannerId) }
    );

    return response.updatedNotes;
  }

  async setNoteStatus(noteId: string, isArchived: boolean, syncToken: bigint): Promise<Note> {
    return await this.performWriteRequest(
      'setNoteStatus',
      (headers) => this._grpcTransport.plannersClient.setNoteStatus({ noteId, isArchived, syncToken }, { headers }),
      { isDemoObject: this.isDemoId(noteId) }
    );
  }

  async getAvailableTimeSlots(
    plannerId: string,
    workId: string,
    timezone: string,
    timeSlotDuration: number,
    increment: number,
    extraDays: number,
    plannedWorkId?: string
  ): Promise<TimeSlotGroup[]> {
    const response = await this.performReadRequest(
      'getAvailableTimeSlots',
      (headers) =>
        this._grpcTransport.plannersClient.getAvailableTimeSlots(
          { plannerId, workId, timezone, timeSlotDuration, increment, extraDays, plannedWorkId },
          { headers }
        ),
      { isDemoObject: this.isDemoId(plannerId) }
    );

    return response.timeSlotGroups;
  }

  async createPlannedWork(workId: string, timeSlot: TimeSlot, stepIds: string[], syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'createPlannedWork',
      (headers) =>
        this._grpcTransport.plannersClient.createPlannedWork(
          { timeSlot, workId, syncToken, stepIds, useStepIds: true },
          { headers }
        ),
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async updatePlannedWork(
    plannedWorkId: string,
    workId: string,
    timeSlot: TimeSlot,
    stepIds: string[],
    syncToken: bigint
  ): Promise<Work> {
    return await this.performWriteRequest(
      'updatePlannedWork',
      (headers) =>
        this._grpcTransport.plannersClient.updatePlannedWork(
          { plannedWorkId, workId, timeSlot, syncToken, stepIds, useStepIds: true },
          { headers }
        ),
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async cancelPlannedWork(plannedWorkId: string, workId: string, syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'cancelPlannedWork',
      (headers) =>
        this._grpcTransport.plannersClient.cancelPlannedWork({ plannedWorkId, workId, syncToken }, { headers }),
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async setWorkSteps(workId: string, steps: WorkStep[], syncToken: bigint): Promise<Work> {
    return await this.performWriteRequest(
      'setWorkSteps',
      (headers) =>
        this._grpcTransport.plannersClient.setWorkSteps({ workId, steps, useSteps: true, syncToken }, { headers }),
      { isDemoObject: this.isDemoId(workId) }
    );
  }

  async getPublishedWork(publishedWorkId: string, schoolId: string): Promise<PublishedWork> {
    return await this.performReadRequest(
      'getPublishedWork',
      (headers) =>
        this._grpcTransport.schoolsClient.getPublishedWork(
          {
            publishedWorkId,
            schoolId
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(publishedWorkId) }
    );
  }

  async getPublishedWorks(schoolId: string, courseSectionId: string | undefined): Promise<PublishedWork[]> {
    const response = await this.performReadRequest(
      'getPublishedWorks',
      (headers) =>
        this._grpcTransport.schoolsClient.getPublishedWorks(
          {
            schoolId,
            courseSectionId
          },
          { headers }
        ),
      { isDemoObject: this.isDemoId(schoolId) || (courseSectionId != null && this.isDemoId(courseSectionId)) }
    );

    return response.publishedWorks;
  }

  async createPublishedWork(
    courseSectionId: string,
    schoolId: string,
    plannerId: string,
    request: CreatePublishedWorkRequest
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('createPublishedWork', (headers) =>
      this._grpcTransport.schoolsClient.createPublishedWork(
        {
          schoolId,
          plannerId,
          courseSectionId,
          title: request.title,
          iconId: request.iconId,
          description: request.description,
          importance: request.importanceLevel,
          dueTime: request.dueTime != null ? timestampFromDate(request.dueTime) : undefined,
          isDueAllDay: request.isDueAllDay,
          maxGrade: request.maxGrade,
          recipientIds: request.recipientIds,
          status: request.status,
          attachments: request.attachments,
          scheduledPublishTime:
            request.scheduledPublishedTime != null ? timestampFromDate(request.scheduledPublishedTime) : undefined,
          alsoPublishToExternalSourceName: request.alsoPublishToExternalSourceName
        },
        { headers }
      )
    );
  }

  async updatePublishedWork(
    publishedWorkId: string,
    schoolId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint,
    shouldClearDuplicationIds: boolean
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('updatePublishedWork', (headers) =>
      this._grpcTransport.schoolsClient.updatePublishedWork(
        {
          publishedWorkId,
          schoolId,
          title: request.title,
          iconId: request.iconId,
          description: request.description,
          importance: request.importanceLevel,
          dueTime: request.dueTime != null ? timestampFromDate(request.dueTime) : undefined,
          isDueAllDay: request.isDueAllDay,
          maxGrade: request.maxGrade,
          recipientIds: request.recipientIds,
          status: request.status,
          attachments: request.attachments,
          scheduledPublishTime:
            request.scheduledPublishedTime != null ? timestampFromDate(request.scheduledPublishedTime) : undefined,
          syncToken,
          shouldClearDuplicationIds
        },
        { headers }
      )
    );
  }

  async updatePublishedWorkDuplicates(
    publishedWorkId: string,
    request: UpdatePublishedWorkRequest,
    syncToken: bigint
  ): Promise<PublishedWork[]> {
    const response = await this.performWriteRequest('updatePublishedWorkDuplicates', (headers) =>
      this._grpcTransport.schoolsClient.updatePublishedWorkDuplicates(
        {
          mainPublishedWorkId: publishedWorkId,
          title: request.title,
          iconId: request.iconId,
          description: request.description,
          importance: request.importanceLevel,
          attachments: request.attachments,
          maxGrade: request.maxGrade,
          syncToken
        },
        { headers }
      )
    );

    return response.updatedPublishedWorks;
  }

  async setPublishedWorkStatus(
    publishedWorkId: string,
    schoolId: string,
    status: PublishedWorkStatus,
    scheduledPublishedTime: Date | undefined,
    syncToken: bigint
  ): Promise<PublishedWork> {
    return await this.performWriteRequest('setPublishedWorkStatus', (headers) =>
      this._grpcTransport.schoolsClient.setPublishedWorkStatus(
        {
          publishedWorkId,
          schoolId,
          status,
          scheduledPublishTime: scheduledPublishedTime != null ? timestampFromDate(scheduledPublishedTime) : undefined,
          syncToken
        },
        { headers }
      )
    );
  }

  async getWorkIcons(): Promise<WorkIcons> {
    const response = await this.performReadRequest('getWorkIcons', (headers) =>
      this._grpcTransport.plannersClient.getWorkIcons({}, { headers })
    );

    const iconsById = response.workIcons.reduce(
      (value, icon) => value.set(icon.iconId, icon),
      new Map<string, WorkIcon>()
    );

    return {
      defaultIconId: response.defaultIconId,
      iconsById,
      externalBadgeUrl: response.externalBadgeUrl
    };
  }

  async createCopyOfWork(
    plannerId: string,
    workId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<WorkCopyResponse> {
    const response = await this.performWriteRequest('createCopyOfWork', (headers) =>
      this._grpcTransport.plannersClient.createCopyOfWork(
        {
          workId,
          dueTime: dueTime != null ? timestampFromDate(dueTime) : undefined,
          isDueAllDay,
          courseSectionId,
          isCopyLinked: linkCopy,
          targetPlannerId: plannerId
        },
        { headers }
      )
    );

    return { original: response.original!, item: response.work! };
  }

  async distributeWork(
    workId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<Work[]> {
    const response = await this.performWriteRequest('distributeWork', (headers) =>
      this._grpcTransport.plannersClient.distributeWork(
        {
          workId,
          areCopiesLinked,
          courseSectionIds,
          patternKind,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    const works = response.works;
    if (response.original != null) {
      works.push(response.original);
    }
    return works;
  }

  async repeatWork(
    workId: string,
    areCopiesLinked: boolean,
    until:
      | { case: 'untilDate'; value: Day }
      | {
          case: 'untilCount';
          value: number;
        },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<WorkRepeatResponse> {
    const response = await this.performWriteRequest('repeatWork', (headers) =>
      this._grpcTransport.plannersClient.repeatWork(
        {
          workId,
          areCopiesLinked,
          until,
          pattern,
          shouldPlaceInCourseOccurrences,
          firstDayOfWeek,
          modifiedDuplicationId,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    return {
      original: response.originalWork!,
      created: response.createdWorks,
      updated: response.updatedWorks,
      removed: response.removedWorkIds
    };
  }

  async createCopyOfNote(
    plannerId: string,
    noteId: string,
    time: Date | undefined,
    isAllDay: boolean,
    courseSectionId: string,
    linkCopy: boolean
  ): Promise<NoteCopyResponse> {
    const response = await this.performWriteRequest('createCopyOfNote', (headers) =>
      this._grpcTransport.plannersClient.createCopyOfNote(
        {
          noteId,
          time: time != null ? timestampFromDate(time) : undefined,
          isAllDay,
          courseSectionId,
          isCopyLinked: linkCopy,
          targetPlannerId: plannerId
        },
        { headers }
      )
    );

    return { original: response.original!, item: response.note! };
  }

  async distributeNote(
    noteId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<Note[]> {
    const response = await this.performWriteRequest('distributeNote', (headers) =>
      this._grpcTransport.plannersClient.distributeNote(
        {
          noteId,
          areCopiesLinked,
          courseSectionIds,
          patternKind,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    const notes = response.notes;
    if (response.original != null) {
      notes.push(response.original);
    }
    return notes;
  }

  async repeatNote(
    noteId: string,
    areCopiesLinked: boolean,
    until:
      | { case: 'untilDate'; value: Day }
      | {
          case: 'untilCount';
          value: number;
        },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<NoteRepeatResponse> {
    const response = await this.performWriteRequest('repeatNote', (headers) =>
      this._grpcTransport.plannersClient.repeatNote(
        {
          noteId,
          areCopiesLinked,
          until,
          pattern,
          shouldPlaceInCourseOccurrences,
          firstDayOfWeek,
          modifiedDuplicationId,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    return {
      original: response.originalNote!,
      created: response.createdNotes,
      updated: response.updatedNotes,
      removed: response.removedNoteIds
    };
  }

  async createCopyOfPublishedWork(
    publishedWorkId: string,
    targetSchoolId: string,
    targetPlannerId: string,
    targetCourseSectionId: string,
    dueTime: Date | undefined,
    isDueAllDay: boolean,
    isCopyLinked: boolean
  ): Promise<PublishedWorkCopyResponse> {
    const response = await this.performWriteRequest('createCopyOfPublishedWork', (headers) =>
      this._grpcTransport.schoolsClient.createCopyOfPublishedWork(
        {
          publishedWorkId,
          targetSchoolId,
          targetPlannerId,
          targetCourseSectionId,
          dueTime: dueTime != null ? timestampFromDate(dueTime) : undefined,
          isDueAllDay,
          isCopyLinked
        },
        { headers }
      )
    );

    return { original: response.original!, item: response.publishedWork! };
  }

  async distributePublishedWork(
    publishedWorkId: string,
    courseSectionIds: string[],
    patternKind: ItemDistributionPatternKind,
    areCopiesLinked: boolean
  ): Promise<PublishedWork[]> {
    const response = await this.performWriteRequest('distributePublishedWork', (headers) =>
      this._grpcTransport.schoolsClient.distributePublishedWork(
        {
          publishedWorkId,
          courseSectionIds,
          patternKind,
          areCopiesLinked,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    const works = response.publishedWorks;
    if (response.original != null) {
      works.push(response.original);
    }
    return works;
  }

  async repeatPublishedWork(
    publishedWorkId: string,
    areCopiesLinked: boolean,
    until:
      | { case: 'untilDate'; value: Day }
      | {
          case: 'untilCount';
          value: number;
        },
    pattern: ItemRepeatPatternKind,
    shouldPlaceInCourseOccurrences: boolean,
    firstDayOfWeek: DayOfWeek | undefined,
    modifiedDuplicationId: string | undefined
  ): Promise<PublishedWorkRepeatResponse> {
    const response = await this.performWriteRequest('repeatPublishedWork', (headers) =>
      this._grpcTransport.schoolsClient.repeatPublishedWork(
        {
          publishedWorkId,
          areCopiesLinked,
          until,
          pattern,
          shouldPlaceInCourseOccurrences,
          firstDayOfWeek,
          modifiedDuplicationId,
          timezone: this._localization.currentTimezone
        },
        { headers }
      )
    );

    return {
      original: response.originalPublishedWork!,
      created: response.createdPublishedWorks,
      updated: response.updatedPublishedWorks,
      removed: response.removedPublishedWorkIds
    };
  }

  async getWorkDuplicationDetails(
    duplicationIds: string[],
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<WorkDuplicationDetailsResponse> {
    const response = await this.performReadRequest('getWorkDuplicationDetails', (headers) =>
      this._grpcTransport.plannersClient.getWorkDuplicationDetails(
        {
          duplicationIds,
          plannerId,
          shouldIncludeRelatedDuplications
        },
        { headers }
      )
    );

    return {
      duplications: response.duplications,
      relatedDuplications: response.relatedDuplications,
      duplicatedItems: response.duplicatedWorks,
      relatedDuplicatedItems: response.relatedDuplicatedWorks
    };
  }

  async getNoteDuplicationDetails(
    duplicationIds: string[],
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<NoteDuplicationDetailsResponse> {
    const response = await this.performReadRequest('getNoteDuplicationDetails', (headers) =>
      this._grpcTransport.plannersClient.getNoteDuplicationDetails(
        {
          duplicationIds,
          plannerId,
          shouldIncludeRelatedDuplications
        },
        { headers }
      )
    );

    return {
      duplications: response.duplications,
      relatedDuplications: response.relatedDuplications,
      duplicatedItems: response.duplicatedNotes,
      relatedDuplicatedItems: response.relatedDuplicatedNotes
    };
  }

  async getPublishedWorkDuplicationDetails(
    duplicationIds: string[],
    schoolId: string,
    plannerId: string,
    shouldIncludeRelatedDuplications: boolean
  ): Promise<PublishedWorkDuplicationDetailsResponse> {
    const response = await this.performReadRequest('getPublishedWorkDuplicationDetails', (headers) =>
      this._grpcTransport.schoolsClient.getPublishedWorkDuplicationDetails(
        {
          duplicationIds,
          schoolId,
          plannerId,
          shouldIncludeRelatedDuplications
        },
        { headers }
      )
    );

    return {
      duplications: response.duplications,
      relatedDuplications: response.relatedDuplications,
      duplicatedItems: response.duplicatedPublishedWorks,
      relatedDuplicatedItems: response.relatedDuplicatedPublishedWorks
    };
  }
}
