import {
  AttachmentInfo,
  createPlannerAttachment,
  createSchoolAttachment,
  plannerAttachmentInfoKindToAttachmentInfo,
  plannerAttachmentKindToAttachmentInfoKind,
  schoolAttachmentInfoKindToAttachmentInfo,
  schoolAttachmentKindToAttachmentInfoKind
} from '@/models';
import { Attachment as PlannerAttachment } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/attachment_pb';
import { Attachment as SchoolAttachment } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/attachment_pb';
import Audio from '@uppy/audio';
import Uppy from '@uppy/core';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import ScreenCapture from '@uppy/screen-capture';
import Transloadit, { AssemblyResult, COMPANION_ALLOWED_HOSTS, COMPANION_URL } from '@uppy/transloadit';
import Url from '@uppy/url';
import Webcam from '@uppy/webcam';
import { keyBy } from 'lodash';
import { AttachmentService, EnvironmentService } from '../contracts';

export class AppAttachmentService implements AttachmentService {
  private readonly _uppyInstance: Uppy;

  constructor(private readonly _environmentService: EnvironmentService) {
    const transloaditKey = _environmentService.transloadit.key;

    this._uppyInstance = new Uppy()
      .use(Transloadit, {
        waitForEncoding: true,
        assemblyOptions: {
          params: {
            auth: { key: transloaditKey },
            template_id: _environmentService.transloadit.templateId
          }
        }
      })
      .use(GoogleDrive, {
        companionUrl: COMPANION_URL,
        companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
        companionKeysParams: {
          key: transloaditKey,
          credentialsName: 'TodayGoogleDrive'
        }
      })
      .use(OneDrive, {
        companionUrl: COMPANION_URL,
        companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
        companionKeysParams: {
          key: transloaditKey,
          credentialsName: 'TodayOneDrive'
        }
      })
      .use(Dropbox, {
        companionUrl: COMPANION_URL,
        companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
        companionKeysParams: {
          key: transloaditKey,
          credentialsName: 'TodayDropBox'
        }
      })
      .use(Webcam)
      .use(ScreenCapture)
      .use(Audio)
      .use(Url, { companionUrl: COMPANION_URL });
  }

  get uppyInstance(): Uppy {
    return this._uppyInstance;
  }

  createLinkAttachmentForUrl(url: string): AttachmentInfo {
    return {
      kind: 'link',
      title: undefined,
      externalKind: undefined,
      thumbnailUrl: undefined,
      url,
      externalId: undefined
    };
  }

  createFileAttachmentsFromResults(results: Record<string, AssemblyResult[]>): AttachmentInfo[] {
    const originals = results[':original'];
    const thumbnailsById = keyBy(results.thumbnail, (t) => t.original_id);
    const values = originals.map((o) => ({ original: o, thumbnail: thumbnailsById[o.original_id] }));

    return values.map((value) => {
      const url = this.convertFileUrlToTodayFileUrl(value.original.url);
      const thumbnailUrl = this.convertOptionalFileUrlToTodayFileUrl(value.thumbnail.ssl_url);

      return {
        kind: 'file',
        url,
        thumbnailUrl,
        title: value.original.name,
        externalId: undefined,
        externalKind: undefined
      };
    });
  }

  attachmentInfoToPlannerAttachment(info: AttachmentInfo): PlannerAttachment {
    return createPlannerAttachment({ ...info, kind: plannerAttachmentInfoKindToAttachmentInfo(info.kind) });
  }

  attachmentInfoFromPlannerAttachment(attachment: PlannerAttachment): AttachmentInfo {
    return { ...attachment, kind: plannerAttachmentKindToAttachmentInfoKind(attachment.kind) };
  }

  attachmentInfoToSchoolAttachment(info: AttachmentInfo): SchoolAttachment {
    return createSchoolAttachment({ ...info, kind: schoolAttachmentInfoKindToAttachmentInfo(info.kind) });
  }

  attachmentInfoFromSchoolAttachment(attachment: SchoolAttachment): AttachmentInfo {
    return { ...attachment, kind: schoolAttachmentKindToAttachmentInfoKind(attachment.kind) };
  }

  private convertOptionalFileUrlToTodayFileUrl(url: string | undefined): string | undefined {
    return url != null ? this.convertFileUrlToTodayFileUrl(url) : undefined;
  }

  private convertFileUrlToTodayFileUrl(url: string): string {
    const urlValue = new URL(url);
    return `${this._environmentService.fileHostBasename}/files${urlValue.pathname}`;
  }
}
