import { ErrorBoundary, ScreenSizeListener } from '@/components';
import { DragContext, DraggedItem, useEventEmitter } from '@/contexts';
import { useServices } from '@/hooks';
import { DialogsRoutes, MainRoutes } from '@/routes';
import { Locale } from '@/utils';
import { App as CapacitorApp } from '@capacitor/app';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS, frFR } from '@mui/x-date-pickers/locales';
import { enUS as enAdapter, fr as frAdapter } from 'date-fns/locale';
import { Observer, observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { AppThemeProvider } from './AppThemeProvider';
import { BackgroundLocationState } from './BackgroundLocationState';

export const App = observer(() => {
  const services = useServices();
  const { localization, authentication, userStore, settingsStorage, monitoring } = services;
  const strings = localization.localizedStrings.application;
  const [draggedItem, setDraggedItem] = useState<DraggedItem | undefined>(undefined);
  const emitter = useEventEmitter();

  const hasServerError = authentication.error != null;
  const isReady =
    authentication.isInitialized &&
    userStore.plannersLoadable.hasData &&
    userStore.subscription.hasData &&
    userStore.getPreference('seen-curriculum-course-ids').hasData;

  const params = new URLSearchParams(document.location.search);

  const gclid = params.get('gclid');
  if (gclid) {
    settingsStorage.googleClickId = gclid;
  }

  const fbclid = params.get('fbclid');
  if (fbclid) {
    settingsStorage.facebookClickId = fbclid;
  }

  const hscid = params.get('hs_contact_id');
  if (hscid) {
    settingsStorage.hubspotContactId = hscid;
  }

  const location = useLocation();
  const state = (location.state ?? {}) as BackgroundLocationState;

  useEffect(() => {
    monitoring.onLocationChange();

    void CapacitorApp.addListener('backButton', (event) => {
      emitter.emit('back-button', event);
    });

    return () => void CapacitorApp.removeAllListeners();
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <AppThemeProvider>
        {(theme) => (
          <Observer>
            {() => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={getLocalizationProviderLocaleText(localization.currentLocale)}
                adapterLocale={getLocalizationProviderAdapterLocale(localization.currentLocale)}
              >
                <CssBaseline />

                {/* Sets the background color of the "root" div according to the current theme. */}
                {/*  Would otherwise show the body background color. */}
                <GlobalStyles styles={{ '#root': { backgroundColor: theme.palette.background.default } }} />

                <ScreenSizeListener>
                  <title>{strings.defaultTitle}</title>
                  <meta name="description" content={strings.defaultDescription} />
                  <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#121212" data-rh="true" />
                  <meta name="theme-color" media="(prefers-color-scheme: light)" content="#FFF" data-rh="true" />
                  <CssBaseline />
                  <ErrorBoundary services={services}>
                    <DragContext value={{ item: draggedItem, setItem: (item) => setDraggedItem(item) }}>
                      <MainRoutes />
                    </DragContext>

                    {/*
                        Even though we could use the current location to show these dialogs, using
                        "backgroundLocation" is a nice way for these dialogs that they can navigate back.
                        Otherwise, they will redirect to the appropriate dashboard.

                        These modals should only be presented if the authentication is initialized without error.
                      */}
                    {!hasServerError && isReady && state.backgroundLocation != null && <DialogsRoutes />}
                  </ErrorBoundary>
                </ScreenSizeListener>
              </LocalizationProvider>
            )}
          </Observer>
        )}
      </AppThemeProvider>
    </StyledEngineProvider>
  );
});

function getLocalizationProviderLocaleText(currentLocale: Locale) {
  const getBase = () => {
    switch (currentLocale) {
      case 'en':
        return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
      case 'fr':
        return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    }
  };

  const texts = { ...getBase() };
  texts.clearButtonLabel = LocalizedStrings.dateTime.pickerClearButtonLabel();
  return texts;
}

function getLocalizationProviderAdapterLocale(currentLocale: Locale) {
  switch (currentLocale) {
    case 'en': {
      const enA = enAdapter;
      if (enA.options != null) {
        enA.options.weekStartsOn = 0;
      }
      return enA;
    }

    case 'fr': {
      const frA = frAdapter;
      if (frA.options != null) {
        frA.options.weekStartsOn = 0;
      }
      return frA;
    }
  }
}
