import { List, ListItemButton, ListItemText, Stack, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';
import { isSxArray } from '../../../utils';
import { PlannerWorkloadPageViewModel } from '../../../viewmodels';
import { WorkloadInformationIcon } from '../../shared';

export interface PlannerWorkloadCourseSectionStudentsListProps {
  sx?: SxProps;
  className?: string;
  schoolCourseSectionId: string;
  date: Date;
  viewModel: PlannerWorkloadPageViewModel;
}

export const PlannerWorkloadCourseSectionStudentsList = observer(
  ({ sx = [], className, schoolCourseSectionId, date, viewModel }: PlannerWorkloadCourseSectionStudentsListProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    function showDetailsForStudent(studentId: string) {
      searchParams.set('studentId', studentId);
      setSearchParams(searchParams);
    }

    const data = viewModel.getWorkloadStatusForStudents(schoolCourseSectionId, date);

    return (
      <Stack sx={[{ overflow: 'hidden' }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
        <List dense sx={{ overflow: 'auto', flex: 1 }}>
          {data.map((student) => (
            <ListItemButton
              key={student.student.accountId}
              onClick={() => showDetailsForStudent(student.student.accountId)}
            >
              <ListItemText primary={student.student.fullName} />

              <WorkloadInformationIcon kind={'single-student'} status={student.status} size="small" />
            </ListItemButton>
          ))}
        </List>
      </Stack>
    );
  }
);
