import { useActivePlannerId, useNavigateAsync, usePlannerUserHasAccess, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { AddRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { CreateSchoolDialog } from '../../school';
import { SettingsCard } from '../../shared';
import { Banner, UpdatablePresenter } from '../../utils';
import { PlannerSchoolsListItem } from './PlannerSchoolsListItem';

interface LocationState {
  plannerSchoolsListShowCreateSchool?: boolean;
  hasAttachErrorSchoolId?: string;
}

export interface PlannerSchoolsListProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerSchoolsList = observer(({ sx = [], className }: PlannerSchoolsListProps) => {
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((viewModels) => viewModels.createPlannerSchoolsListViewModel(plannerId), [plannerId]);
  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);
  const strings = LocalizedStrings.settings.school.list;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigateAsync();
  const { hasAttachErrorSchoolId } = state;
  const [isAttachingSchool, setIsAttachingSchool] = useState(false);

  function onCreateSchoolButtonClick() {
    const newState: LocationState = { ...state, plannerSchoolsListShowCreateSchool: true };
    void navigate(location, { state: newState });
    return Promise.resolve();
  }

  async function retryAttachSchool() {
    if (hasAttachErrorSchoolId == null || hasAttachErrorSchoolId.length === 0) {
      return;
    }

    setIsAttachingSchool(true);

    try {
      await viewModel.attachSchoolToPlanner(hasAttachErrorSchoolId);
      // Removing schoolId from state.
      await navigate(location, { replace: true });
    } catch (e) {
      console.warn(`Attach school with id ${hasAttachErrorSchoolId} failed`);
      captureException(e);
    } finally {
      setIsAttachingSchool(false);
    }
  }

  return (
    <Box sx={sx} className={className}>
      <UpdatablePresenter
        sx={{ width: '100%' }}
        viewModel={viewModel}
        renderData={() => (
          <Stack
            spacing={2}
            sx={{
              width: '100%'
            }}
          >
            <SettingsCard
              title={strings.title()}
              actions={
                hasAccess && viewModel.canAddSchool
                  ? [{ icon: <AddRounded />, action: () => onCreateSchoolButtonClick() }]
                  : undefined
              }
            >
              <Stack
                sx={{
                  width: '100%',
                  pb: 2,
                  pt: 1
                }}
                spacing={1}
              >
                {!viewModel.isReadOnly && hasAttachErrorSchoolId != null && (
                  <Box sx={{ px: 2 }}>
                    <Banner
                      severity="error"
                      actions={[
                        {
                          case: 'icon-text',
                          value: {
                            text: strings.attachErrorAlertRetryButtonTitle()
                          },
                          tooltip: strings.attachErrorAlertRetryButtonTitle(),
                          onClick: () => void retryAttachSchool(),
                          disabled: isAttachingSchool
                        }
                      ]}
                    >
                      {strings.attachErrorAlertMessage()}
                    </Banner>
                  </Box>
                )}

                {viewModel.schools.map((school) => (
                  <PlannerSchoolsListItem key={school.school!.id} school={school} isReadOnly={viewModel.isReadOnly} />
                ))}

                {viewModel.schools.length === 0 && (
                  <Typography
                    sx={{
                      px: 3,
                      py: 1,
                      fontStyle: 'italic',
                      color: (theme) => theme.palette.text.secondary
                    }}
                  >
                    {strings.emptyMessage()}
                  </Typography>
                )}
              </Stack>
            </SettingsCard>
          </Stack>
        )}
      />
      {state.plannerSchoolsListShowCreateSchool === true && <CreateSchoolDialog isOpen={true} />}
    </Box>
  );
});
