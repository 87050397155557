import { ScheduleCycleInfo } from '@/models';
import { ScheduleCycleTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type ScheduleCycleLoadable = LoadableValue<ScheduleCycleInfo>;

export class AppScheduleCycleLoadable extends BaseLoadableValue<ScheduleCycleInfo> implements ScheduleCycleLoadable {
  constructor(
    private readonly _scheduleCycleId: string,
    private readonly _scheduleCycleTransport: ScheduleCycleTransportService
  ) {
    super();
  }

  protected async loadData(): Promise<ScheduleCycleInfo> {
    return this._scheduleCycleTransport.getScheduleCycle(this._scheduleCycleId, undefined);
  }
}
