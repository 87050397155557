import {
  SchoolInformation,
  SchoolInformationSchema
} from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import {
  WorkStep,
  WorkStepSchema
} from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_step_pb';
import {
  ScheduleCycle,
  ScheduleCycleSchema
} from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { clone } from '@bufbuild/protobuf';

export function clonePlannerSchoolInformation(message: SchoolInformation) {
  return clone(SchoolInformationSchema, message);
}

export function cloneScheduleCycle(message: ScheduleCycle) {
  return clone(ScheduleCycleSchema, message);
}

export function cloneWorkStep(message: WorkStep) {
  return clone(WorkStepSchema, message);
}
