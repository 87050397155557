import { useServices } from '@/hooks';
import { convertRichTextToPlainText, generateWorkIconUrl, WorkIconInfo, WorkloadPublishedWorkInfo } from '@/models';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { SchoolRounded } from '@mui/icons-material';
import { CardActionArea, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../../utils';
import { ColorIndicator, WorkIcon } from '../../lists';

export interface PlannerWorkloadItemsListItemProps {
  sx?: SxProps;
  className?: string;
  work: WorkloadPublishedWorkInfo;
}

export const PlannerWorkloadPublishedWorkListItem = observer(
  ({ sx = [], className, work }: PlannerWorkloadItemsListItemProps) => {
    const { workStore } = useServices();
    const [searchParams, setSearchParams] = useSearchParams();
    const strings = LocalizedStrings.planner.workload;

    const icon = workStore.workIcons.data.iconsById.get(work.publishedWork.iconId)!;
    const iconInfo: WorkIconInfo = {
      id: icon.iconId,
      title: icon.iconName,
      lightUrl: generateWorkIconUrl(icon, 'light', undefined, undefined, 'major'),
      darkUrl: generateWorkIconUrl(icon, 'dark', undefined, undefined, 'major'),
      externalBadgeUrl: undefined
    };

    const teachers = work.courseSection?.details?.teachers ?? [];
    const description = convertRichTextToPlainText(work.publishedWork.description);

    function showDetails() {
      searchParams.set('workloadWorkId', work.publishedWork.id);
      setSearchParams(searchParams);
    }

    return (
      <CardActionArea
        sx={[{ px: 1, py: 0.5 }, ...(isSxArray(sx) ? sx : [sx])]}
        className={className}
        onClick={showDetails}
      >
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Stack sx={{ flex: 1 }} direction="row" spacing={1}>
            <ColorIndicator color={work.plannerCourseSection?.courseSection?.color ?? work.courseSection?.color} />

            <Stack sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: '600' }}>
                {work.courseSection?.title ?? strings.publishedWorkListItemUnknownClass()}
              </Typography>

              <Typography variant="caption" color="textSecondary">
                {[
                  work.courseSection?.section ?? '',
                  teachers.length > 0
                    ? teachers[0].fullName + (teachers.length > 1 ? ` +${teachers.length - 1}` : '')
                    : ''
                ]
                  .filter((v) => v.trim().length > 0)
                  .join(' - ')}
              </Typography>
            </Stack>
          </Stack>

          <Stack sx={{ flex: 1 }}>
            <Typography variant="caption">
              {strings.publishedWorkListItemPublishDate()}
              &nbsp;
              <strong style={{ fontWeight: '600' }}>
                {format(timestampDate(work.publishedWork.createdTime!), 'PPP')}
              </strong>
              &nbsp;
              {strings.publishedWorkListItemPublishTime()}
              &nbsp;
              <strong style={{ fontWeight: '600' }}>
                {format(timestampDate(work.publishedWork.createdTime!), 'p')}
              </strong>
            </Typography>

            <Typography variant="caption" color="textSecondary">
              {work.periodTag != null ? (
                <span>
                  {strings.publishedWorkListItemDuePeriod()}
                  &nbsp;
                  <strong style={{ fontWeight: '600' }}>{work.periodTag}</strong>
                </span>
              ) : !work.publishedWork.isDueAllDay && work.publishedWork.dueTime != null ? (
                <span>
                  {strings.publishedWorkListItemDueTime()}
                  &nbsp;
                  <strong style={{ fontWeight: '600' }}>
                    {format(timestampDate(work.publishedWork.dueTime), 'p')}
                  </strong>
                </span>
              ) : (
                strings.publishedWorkListItemNoDuePeriod()
              )}
            </Typography>
          </Stack>

          <Stack sx={{ flex: 2, overflow: 'hidden' }} direction="row" spacing={1}>
            <WorkIcon icon={iconInfo} size={28} displayCourseSectionColor={false} />
            <Stack sx={{ flex: 1, overflow: 'hidden' }}>
              <Typography variant="body2" noWrap>
                {work.publishedWork.title}
              </Typography>
              <Typography variant="caption" color="textSecondary" noWrap sx={{ opacity: description.length ? 1 : 0 }}>
                {description}
              </Typography>
            </Stack>

            {work.isTaughtSection && (
              <Tooltip title={strings.publishedWorkListItemTaughtSectionTooltip()}>
                <SchoolRounded fontSize="small" color="primary" sx={{ alignSelf: 'center' }} />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </CardActionArea>
    );
  }
);
