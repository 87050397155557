import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import { PlannerItemsWorkInfo, contextMenuActionsForWork } from '@/viewmodels';
import { timestampDate } from '@bufbuild/protobuf/wkt';
import { Box, CardActionArea, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import LocalizedStrings from 'strings';
import { convertRichTextToPlainText } from '../../../models';
import { WorkIcon } from '../../lists';
import { ContextMenuHandler } from '../../shared';

export interface PlannerItemsWorkViewProps {
  sx?: SxProps;
  className?: string;
  work: PlannerItemsWorkInfo;
  displayCourseColor: boolean;
  isReadOnly: boolean;
}

export const PlannerItemsWorkView = observer(
  ({ sx = [], className, work, displayCourseColor, isReadOnly }: PlannerItemsWorkViewProps) => {
    const { featureFlag, pasteboard, plannerStore, route, workStore } = useServices();
    const { description, dueTime, isDueAllDay, title } = work.work;
    const resolvedDescription = useMemo(() => convertRichTextToPlainText(description), [description]);

    const plannerId = useActivePlannerId();
    const location = useLocation();
    const navigate = useNavigateAsync();

    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
    const strings = LocalizedStrings.planner.items;
    const displayDate = dueTime != null;

    function formatDueTime() {
      return dueTime != null ? strings.dueDate(format(timestampDate(dueTime), isDueAllDay ? 'PP' : 'PPp')) : '';
    }

    function onSelect() {
      const targetLocation = route.resolvePlannerWorkLocation(plannerId, work.id);
      void navigate(targetLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler
        actions={contextMenuActionsForWork(work.work, isReadOnly, pasteboard, workStore, plannerStore, featureFlag)}
      >
        {(contextMenuHandler) => (
          <CardActionArea
            component="div"
            sx={{
              ...sx,
              py: 1.5,
              px: { xs: 1, sm: 2 },
              display: 'flex',
              alignItems: 'stretch',
              width: '100%',
              overflow: 'hidden'
            }}
            className={className}
            onClick={onSelect}
            onContextMenu={contextMenuHandler}
          >
            <WorkIcon
              icon={work.icon}
              size={28}
              color={work.courseColor}
              displayCourseSectionColor={displayCourseColor}
            />

            <Box
              sx={{
                flex: 1,
                mx: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0
              }}
            >
              <Typography noWrap variant="body2" sx={{ fontWeight: 500 }}>
                {title}
              </Typography>
              {resolvedDescription.length > 0 && (
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    color: theme.palette.text.secondary
                  }}
                >
                  {resolvedDescription}
                </Typography>
              )}

              {displayDate && isExtraSmallScreen && (
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center',
                    mt: resolvedDescription.length > 0 ? 1 : 0
                  }}
                >
                  {formatDueTime()}
                </Typography>
              )}
            </Box>

            {displayDate && !isExtraSmallScreen && (
              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.text.secondary
                }}
              >
                {formatDueTime()}
              </Typography>
            )}
          </CardActionArea>
        )}
      </ContextMenuHandler>
    );
  }
);
