import { WorkloadStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/workload_status_pb';
import { PersonOutlineRounded, PersonRounded } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { isSxArray } from '../../../utils';

export interface WorkloadInformationIconProps {
  readonly sx?: SxProps;
  readonly className?: string;
  readonly kind: 'single-student' | 'class';
  readonly size?: 'small' | 'regular';
  readonly status: WorkloadStatus;
}

export const WorkloadInformationIcon = observer(
  ({ sx = [], className, kind, status, size = 'regular' }: WorkloadInformationIconProps) => {
    const theme = useTheme();

    const workloadLevelInfo = useMemo(() => {
      const fontSize = size === 'small' ? 'medium' : 'large';

      switch (status) {
        case WorkloadStatus.ABOVE_THRESHOLD:
          return {
            icon: (
              <PersonRounded
                sx={[{ color: theme.palette.error.main }, ...(isSxArray(sx) ? sx : [sx])]}
                className={className}
                fontSize={fontSize}
              />
            ),
            label: kind === 'single-student' ? 'Above threshold' : 'At least one student above threshold'
          };
        case WorkloadStatus.AT_THRESHOLD:
          return {
            icon: (
              <PersonRounded
                sx={[{ color: theme.palette.warning.main }, ...(isSxArray(sx) ? sx : [sx])]}
                className={className}
                fontSize={fontSize}
              />
            ),
            label: kind === 'single-student' ? 'At threshold' : 'At least one student at threshold'
          };
        case WorkloadStatus.UNDER_THRESHOLD:
          return {
            icon: (
              <PersonRounded
                sx={[{ color: theme.palette.text.disabled }, ...(isSxArray(sx) ? sx : [sx])]}
                className={className}
                fontSize={fontSize}
              />
            ),
            label: kind === 'single-student' ? 'Has some major items' : 'At least one student with some major item'
          };
        default:
          return {
            icon: (
              <PersonOutlineRounded
                sx={[{ color: theme.palette.text.secondary }, ...(isSxArray(sx) ? sx : [sx])]}
                className={className}
                fontSize={fontSize}
              />
            ),
            label: kind === 'single-student' ? 'Does not have any major items' : 'No student with major item'
          };
      }
    }, [status]);

    return <Tooltip title={workloadLevelInfo.label}>{workloadLevelInfo.icon}</Tooltip>;
  }
);
