import { Day } from '@/models';
import { DailyWorkloadResponse, SchoolTransportService } from '@/transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type CourseSectionDailyWorkloadLoadable = LoadableValue<DailyWorkloadResponse>;

export class AppCourseSectionDailyWorkloadLoadable extends BaseLoadableValue<DailyWorkloadResponse> {
  constructor(
    private readonly _courseSectionId: string,
    private readonly _schoolId: string,
    private readonly _date: Day,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super();
  }

  protected loadData(): Promise<DailyWorkloadResponse> {
    return this._schoolTransport.getDailyWorkload(this._schoolId, [this._courseSectionId], this._date, this._date);
  }
}
