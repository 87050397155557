import {
  Note,
  NoteSchema
} from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { MessageInitShape } from '@bufbuild/protobuf';
import { isSameDay, isSameMinute } from 'date-fns';
import { computed, makeObservable } from 'mobx';
import { createNote } from '../create-models';
import { timestampDateOptional, timestampFromDateOptional } from '../TimestampUtils';
import { EditableModel, EditableProperty } from './core';

export class EditableNote extends EditableModel<Note> {
  private readonly _text: EditableProperty<RichText>;
  private readonly _time: EditableProperty<Date>;
  private readonly _isAllDay: EditableProperty<boolean>;
  private readonly _courseSectionId: EditableProperty<string | undefined>;

  constructor(
    plannerId: string,
    defaultValues: MessageInitShape<typeof NoteSchema> | undefined,
    private readonly _note?: Note
  ) {
    super(
      _note == null,
      _note ??
        createNote({
          plannerId,
          courseSectionId: '',
          text: undefined,
          isAllDay: true,
          time: undefined,
          ...defaultValues
        })
    );

    makeObservable(this);

    this.setFields([
      (this._text = new EditableProperty<RichText>(this.initialModel.text, (v1, v2) => (v1 ?? '') === (v2 ?? ''))),
      (this._time = new EditableProperty<Date>(timestampDateOptional(this.initialModel.time), (value1, value2) => {
        if (value1 == null && value2 == null) {
          return true;
        }

        if (value1 != null && value2 != null) {
          return this.isAllDay ? isSameDay(value1, value2) : isSameMinute(value1, value2);
        }

        return false;
      })),
      (this._isAllDay = new EditableProperty<boolean>(this.initialModel.isAllDay)),
      (this._courseSectionId = new EditableProperty<string | undefined>(this.initialModel.courseSectionId))
    ]);
  }

  // Editable properties

  @computed
  get text(): RichText | undefined {
    return this._text.value;
  }

  set text(value: RichText | undefined) {
    this._text.value = value;
  }

  @computed
  get time(): Date | undefined {
    return this._time.value;
  }

  set time(value: Date | undefined) {
    this._time.value = value;
  }

  @computed
  get isAllDay(): boolean {
    return this._isAllDay.value ?? false;
  }

  set isAllDay(value: boolean) {
    this._isAllDay.value = value;
  }

  @computed
  get courseSectionId(): string | undefined {
    return this._courseSectionId.value;
  }

  set courseSectionId(value: string | undefined) {
    this._courseSectionId.value = value;
  }

  @computed
  get updatedModel(): Note {
    return createNote({
      ...this.initialModel,
      text: this._text.value,
      time: timestampFromDateOptional(this._time.value),
      isAllDay: this._isAllDay.value ?? true,
      courseSectionId: this._courseSectionId.value ?? ''
    });
  }

  @computed
  get hasChangesToSharedProperties(): boolean {
    return this.getHasChangesToSharedProperties([this._text]);
  }

  // Readonly properties

  get duplicationIds(): string[] {
    return this._note?.duplicationIds ?? [];
  }
}
