import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerListGroup } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_list_group_pb';
import { computed, makeObservable } from 'mobx';
import { timestampDateOptional } from '../../../models';
import { PlannerListItemInfo } from './PlannerListItemInfo';

export interface PlannerListGroupViewModel {
  readonly id: string;
  readonly title: string | undefined;
  readonly items: PlannerListItemInfo[];
  readonly numberOfRows: number;
}

export class AppPlannerListGroupViewModel implements PlannerListGroupViewModel {
  constructor(
    protected readonly _plannerListGroup: PlannerListGroup,
    public readonly items: PlannerListItemInfo[],
    private readonly _showTitle: boolean,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);
  }

  @computed
  get id(): string {
    if (this._plannerListGroup.relativeDate == null) {
      return 'no-date';
    }

    return `${this._plannerListGroup.relativeDate.kind}-${timestampDateOptional(this._plannerListGroup.relativeDate.date)?.toDateString() ?? ''}`;
  }

  @computed
  get title(): string | undefined {
    if (!this._showTitle) {
      return undefined;
    }

    const strings = this._localization.localizedStrings.workList;

    if (this._plannerListGroup.relativeDate == null) {
      return strings.noDueDate;
    }

    return strings.groupTitle(this._plannerListGroup.relativeDate);
  }

  @computed
  get numberOfRows(): number {
    return this.items.length;
  }
}
