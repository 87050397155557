import { isSxArray } from '@/utils';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { LoadingIndicator } from '../../../utils';

export interface UserDashboardCalendarDayLoadingIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayLoadingIndicator = observer(
  ({ sx = [], className }: UserDashboardCalendarDayLoadingIndicatorProps) => {
    const strings = LocalizedStrings.calendar.day;

    return (
      <Box sx={[{ display: 'flex' }, ...(isSxArray(sx) ? sx : [sx])]} className={className}>
        <LoadingIndicator
          sx={sx}
          className={className}
          message={strings.dataFetchLoadingMessage()}
          orientation="horizontal"
        />
      </Box>
    );
  }
);
