import { Classroom } from '@buf/studyo_studyo-today-classroom.bufbuild_es/studyo/today/classroom/v1/classroom_pb';
import { Curriculum } from '@buf/studyo_studyo-today-curriculum.bufbuild_es/studyo/today/curriculum/v1/curriculum_pb';
import { Demo } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/demo_pb';
import { GoogleCalendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/google_calendar_pb';
import { PlannerSchedules } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/planner_schedules_pb';
import { Planners } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/planners_pb';
import { Schedules } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/schedules_pb';
import { VolatileSchedules } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/volatile_schedules_pb';
import { SchoolSchedules } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/school_schedules_pb';
import { Schools } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/schools_pb';
import { Sel } from '@buf/studyo_studyo-today-sel.bufbuild_es/studyo/today/sel/v1/sel_pb';
import { Subscriptions } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/subscriptions_pb';
import { Users } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/users_pb';
import { Client, createClient, Interceptor } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { EnvironmentService } from '../../services';
import { GrpcTransportService } from '../contracts';

export class AppGrpcTransportService implements GrpcTransportService {
  readonly classroomClient: Client<typeof Classroom>;
  readonly curriculumClient: Client<typeof Curriculum>;
  readonly demoClient: Client<typeof Demo>;
  readonly googleCalendarClient: Client<typeof GoogleCalendar>;
  readonly plannersClient: Client<typeof Planners>;
  readonly plannerSchedulesClient: Client<typeof PlannerSchedules>;
  readonly schedulesClient: Client<typeof Schedules>;
  readonly schoolsClient: Client<typeof Schools>;
  readonly schoolSchedulesClient: Client<typeof SchoolSchedules>;
  readonly selClient: Client<typeof Sel>;
  readonly subscriptionsClient: Client<typeof Subscriptions>;
  readonly usersClient: Client<typeof Users>;
  readonly volatileSchedulesClient: Client<typeof VolatileSchedules>;

  constructor(environment: EnvironmentService) {
    // https://github.com/SafetyCulture/grpc-web-devtools#connect-web
    // __CONNECT_WEB_DEVTOOLS__ is loaded in as a script, so it is not guaranteed to be loaded before your code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const interceptors: Interceptor[] =
      window?.__CONNECT_WEB_DEVTOOLS__ != null ? [window.__CONNECT_WEB_DEVTOOLS__] : [];
    // To get around the fact that __CONNECT_WEB_DEVTOOLS__ might not be loaded, we can listen for a custom event,
    // and then push the interceptor to our array once loaded.
    window?.addEventListener('connect-web-dev-tools-ready', () => {
      if (window?.__CONNECT_WEB_DEVTOOLS__ != null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
      }
    });

    const transport = createGrpcWebTransport({ baseUrl: environment.todayApiUrl, interceptors });
    this.classroomClient = createClient(Classroom, transport);
    this.curriculumClient = createClient(Curriculum, transport);
    this.demoClient = createClient(Demo, transport);
    this.googleCalendarClient = createClient(GoogleCalendar, transport);
    this.plannersClient = createClient(Planners, transport);
    this.plannerSchedulesClient = createClient(PlannerSchedules, transport);
    this.schedulesClient = createClient(Schedules, transport);
    this.schoolsClient = createClient(Schools, transport);
    this.schoolSchedulesClient = createClient(SchoolSchedules, transport);
    this.selClient = createClient(Sel, transport);
    this.subscriptionsClient = createClient(Subscriptions, transport);
    this.usersClient = createClient(Users, transport);
    this.volatileSchedulesClient = createClient(VolatileSchedules, transport);
  }
}
