import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { createActivity } from './create-models';

export interface CourseSectionInfo {
  readonly id: string;
  readonly title: string;
  readonly section: string;
  readonly color: string;
  readonly isPersonal: boolean;
  readonly isStudent: boolean;
  readonly isTeacher: boolean;
  readonly schoolId?: string;
  readonly externalSource: { externalId: string; sourceName: string } | undefined;
}

export function plannerCourseSectionDetailsToInfo(courseSection: CourseSectionDetails): CourseSectionInfo {
  const externalSource = courseSection.courseSection?.externalSource;

  return {
    id: courseSection.courseSection!.id,
    color: courseSection.courseSection!.color,
    title: courseSection.courseSection!.title,
    section: courseSection.courseSection!.section,
    isPersonal: courseSection.schoolsCourseSection == null,
    isStudent: courseSection.role === CourseSectionRole.STUDENT,
    isTeacher: courseSection.role === CourseSectionRole.TEACHER,
    schoolId: courseSection.schoolsCourseSection?.id,
    externalSource:
      externalSource != null
        ? {
            sourceName: externalSource.sourceName,
            externalId: externalSource.externalId
          }
        : undefined
  };
}

export function schoolCourseSectionToInfo(courseSection: CourseSection): CourseSectionInfo {
  const { externalSource } = courseSection;

  return {
    id: courseSection.id,
    color: courseSection.color,
    title: courseSection.title,
    section: courseSection.section,
    isPersonal: false,
    isStudent: false,
    isTeacher: true,
    schoolId: courseSection.id,
    externalSource:
      externalSource != null
        ? {
            sourceName: externalSource.sourceName,
            externalId: externalSource.externalId
          }
        : undefined
  };
}

export function courseSectionInfoToActivity(courseSection: CourseSectionInfo): Activity {
  return createActivity({
    activityId: courseSection.externalSource?.externalId ?? courseSection.id,
    sourceName: courseSection.externalSource?.sourceName
  });
}
