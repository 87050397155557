import { createContext, useContext } from 'react';

export class AdminWorkloadForceReloadContextValue {
  onReload: (() => void) | undefined;
}

export const AdminWorkloadForceReloadContext = createContext(new AdminWorkloadForceReloadContextValue());

export const useAdminWorkloadForceReloadContext = () => useContext(AdminWorkloadForceReloadContext);
export function useRegisterAdminWorkloadForceReload(onReload: () => void) {
  const context = useAdminWorkloadForceReloadContext();
  context.onReload = onReload;
}
