import { useActivePlannerId, useNavigateAsync, useServices, useViewModel } from '@/hooks';
import { CheckRounded } from '@mui/icons-material';
import { Box, Divider, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { Link } from 'react-router';
import { Dialog, DrawerItem, SearchBar } from '../../utils';
import { UserProfileIcon } from '../UserProfileIcon';

export interface StudentPlannerPickerDialogProps {
  isOpen: boolean;
}

export const StudentPlannerPickerDialog = observer(({ isOpen }: StudentPlannerPickerDialogProps) => {
  const { route, localization } = useServices();
  const navigate = useNavigateAsync();
  const plannerId = useActivePlannerId();
  const strings = localization.localizedStrings.utils;

  function close() {
    return navigate(-1);
  }

  const viewModel = useViewModel((viewModels) => viewModels.createStudentPlannerPickerViewModel(close));
  const planners = viewModel.planners;

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.studentPlannerPickerTitle}
      width="sm"
      fullHeight
      contentPadding={{ left: 0, right: 0 }}
      renderData={() => (
        <Stack sx={{ height: '100%' }}>
          <Box sx={{ px: 2 }}>
            <SearchBar
              onSearch={(value) => (viewModel.searchText = value ?? '')}
              placeholder={strings.studentPlannerPickerSearchBarPlaceholder}
            />
          </Box>

          <Box sx={{ flex: 1, overflowY: 'auto' }}>
            <List>
              {planners.map((plannerInfo, index) => {
                const isCurrent = plannerInfo.plannerId === plannerId;
                const plannerLocation = route.resolvePlannerLocation(plannerInfo.plannerId);

                return (
                  <Fragment key={plannerInfo.plannerId}>
                    {index > 0 && <Divider variant="inset" />}

                    <ListItemButton sx={{ paddingLeft: 3 }} component={Link} to={plannerLocation}>
                      <ListItemAvatar>
                        <UserProfileIcon
                          url={plannerInfo.profileUrl ?? ''}
                          userFullName={plannerInfo.name}
                          emotionalStatusEmoji={plannerInfo.emotionalStatusEmoji}
                        />
                      </ListItemAvatar>

                      <ListItemText
                        primary={plannerInfo.name}
                        secondary={plannerInfo.email}
                        slotProps={{ primary: { color: isCurrent ? 'primary' : undefined } }}
                      />

                      {isCurrent && (
                        <ListItemIcon>
                          <CheckRounded color="primary" />
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  </Fragment>
                );
              })}

              {planners.length === 0 && (
                <DrawerItem title={strings.studentPlannerPickerNoResult} disabled sx={{ fontStyle: 'italic' }} />
              )}
            </List>
          </Box>
        </Stack>
      )}
    />
  );
});
