import type { TextFormat } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/text_format_pb';

/**
 * A common representation of rich text. We need this because both Planners and Schools define their own rich text
 * definition, which now includes a typeName which make them incompatible. So when we need to use rich text in a
 * shared component, we need to use this common representation.
 */
export interface CommonRichText {
  /**
   * The text value, which can contain formatting elements.
   */
  text: string;

  /**
   * The format of that text.
   */
  format: TextFormat;
}

export function convertRichTextToPlainText(richText: CommonRichText | undefined): string {
  const d = document.createElement('div');
  d.innerHTML = richText?.text ?? '';
  return d.innerText;
}
