import {
  AnalyticsService,
  AppAnalyticsService,
  AppApplicationSettingsService,
  AppApplicationSettingsStorage,
  AppAttachmentService,
  AppAuthenticationService,
  AppAutoSyncService,
  AppConnectedAppsService,
  AppCookiesService,
  AppDateService,
  AppEnvironmentService,
  AppGapiService,
  AppIntercomService,
  ApplicationSettingsService,
  ApplicationSettingsStorage,
  AppLocalizationService,
  AppMixpanelService,
  AppMonitoringService,
  AppNavigationService,
  AppNetworkService,
  AppPasteboardService,
  AppRouteService,
  AppSyncService,
  AppThemeService,
  AppUnsplashTransportService,
  AppUserService,
  AttachmentService,
  AuthenticationService,
  AutoSyncService,
  ConnectedAppsService,
  CookiesService,
  DateService,
  DevFirebaseService,
  EnvironmentService,
  FeatureFlagService,
  FirebaseContract,
  FirebaseService,
  GapiService,
  IntercomService,
  LocalizationService,
  MixpanelService,
  MonitoringService,
  NavigationService,
  NetworkService,
  PasteboardService,
  RouteService,
  SyncService,
  ThemeService,
  UnleashFeatureFlagService,
  UnsplashTransportService,
  UserService
} from '@/services';
import { GoogleTagManagerService } from '@/services/contracts/GoogleTagManagerService';
import { AppGoogleTagManagerService } from '@/services/implementations/AppGoogleTagManagerService';
import {
  AppCurriculumDataStore,
  AppPlannerDataStore,
  AppSchoolDataStore,
  AppStoreInvalidator,
  AppUserDataStore,
  AppWorkDataStore,
  AppWorkloadDataStore,
  CurriculumDataStore,
  PlannerDataStore,
  SchoolDataStore,
  StoreInvalidator,
  UserDataStore,
  WorkDataStore,
  WorkloadDataStore
} from '@/stores';
import {
  AppClassroomTransportService,
  AppCurriculumTransportService,
  AppDemoTransportService,
  AppGoogleCalendarTransportService,
  AppGrpcTransportService,
  AppPlannerTransportService,
  AppScheduleCycleTransportService,
  AppSchoolTransportService,
  AppSubscriptionsTransportService,
  AppUserTransportService,
  AppWorkTransportService,
  ClassroomTransportService,
  CurriculumTransportService,
  DemoTransportService,
  GoogleCalendarTransportService,
  GrpcTransportService,
  PlannerTransportService,
  ScheduleCycleTransportService,
  SchoolTransportService,
  SubscriptionsTransportService,
  UserTransportService,
  WorkTransportService
} from '@/transports';
import { computed, makeObservable, observable, when } from 'mobx';

export interface ServiceProviderLoader {
  readonly isLoaded: boolean;
  readonly serviceProvider?: ServiceProvider;
}

export class AppServiceProviderLoader implements ServiceProviderLoader {
  @observable serviceProvider: ServiceProvider | undefined;
  private readonly _environment: EnvironmentService = new AppEnvironmentService();

  constructor() {
    makeObservable(this);
    when(
      () => this._environment.isLoaded,
      () => {
        this.serviceProvider = new AppServiceProvider(this._environment);
      }
    );
  }

  @computed
  get isLoaded(): boolean {
    return this.serviceProvider != null;
  }
}

export interface ServiceProvider {
  readonly analytics: AnalyticsService;
  readonly attachmentService: AttachmentService;
  readonly authentication: AuthenticationService;
  readonly autoSync: AutoSyncService;
  readonly classroomTransport: ClassroomTransportService;
  readonly connectedApps: ConnectedAppsService;
  readonly cookiesService: CookiesService;
  readonly curriculumStore: CurriculumDataStore;
  readonly curriculumTransport: CurriculumTransportService;
  readonly dateService: DateService;
  readonly demoTransport: DemoTransportService;
  readonly environment: EnvironmentService;
  readonly featureFlag: FeatureFlagService;
  readonly firebase: FirebaseContract;
  readonly gapi: GapiService;
  readonly googleCalendarTransport: GoogleCalendarTransportService;
  readonly grpcTransport: GrpcTransportService;
  readonly intercom: IntercomService;
  readonly localization: LocalizationService;
  readonly mixpanel: MixpanelService;
  readonly monitoring: MonitoringService;
  readonly navigation: NavigationService;
  readonly network: NetworkService;
  readonly pasteboard: PasteboardService;
  readonly plannerStore: PlannerDataStore;
  readonly plannerTransport: PlannerTransportService;
  readonly route: RouteService;
  readonly scheduleCycleTransport: ScheduleCycleTransportService;
  readonly schoolStore: SchoolDataStore;
  readonly schoolTransport: SchoolTransportService;
  readonly settings: ApplicationSettingsService;
  readonly settingsStorage: ApplicationSettingsStorage;
  readonly subscriptionsTransport: SubscriptionsTransportService;
  readonly syncService: SyncService;
  readonly theme: ThemeService;
  readonly unsplash: UnsplashTransportService;
  readonly user: UserService;
  readonly storeInvalidator: StoreInvalidator;
  readonly userStore: UserDataStore;
  readonly userTransport: UserTransportService;
  readonly workloadStore: WorkloadDataStore;
  readonly workStore: WorkDataStore;
  readonly workTransport: WorkTransportService;
}

class AppServiceProvider implements ServiceProvider {
  readonly analytics: AnalyticsService;
  readonly attachmentService: AttachmentService;
  readonly authentication: AuthenticationService;
  readonly autoSync: AutoSyncService;
  readonly classroomTransport: ClassroomTransportService;
  readonly connectedApps: ConnectedAppsService;
  readonly cookiesService: CookiesService;
  readonly curriculumStore: CurriculumDataStore;
  readonly curriculumTransport: CurriculumTransportService;
  readonly dateService: DateService;
  readonly demoTransport: DemoTransportService;
  readonly featureFlag: FeatureFlagService;
  readonly firebase: FirebaseContract;
  readonly gapi: GapiService;
  readonly googleCalendarTransport: GoogleCalendarTransportService;
  readonly grpcTransport: GrpcTransportService;
  readonly intercom: IntercomService;
  readonly googleTagManager: GoogleTagManagerService;
  readonly localization: LocalizationService;
  readonly mixpanel: MixpanelService;
  readonly monitoring: MonitoringService;
  readonly navigation: NavigationService;
  readonly network: NetworkService;
  readonly pasteboard: PasteboardService;
  readonly plannerStore: PlannerDataStore;
  readonly plannerTransport: PlannerTransportService;
  readonly route: RouteService;
  readonly scheduleCycleTransport: ScheduleCycleTransportService;
  readonly schoolStore: SchoolDataStore;
  readonly schoolTransport: SchoolTransportService;
  readonly settings: ApplicationSettingsService;
  readonly settingsStorage: ApplicationSettingsStorage;
  readonly storeInvalidator: StoreInvalidator;
  readonly subscriptionsTransport: SubscriptionsTransportService;
  readonly syncService: SyncService;
  readonly theme: ThemeService;
  readonly unsplash: UnsplashTransportService;
  readonly user: UserService;
  readonly userStore: UserDataStore;
  readonly userTransport: UserTransportService;
  readonly workloadStore: WorkloadDataStore;
  readonly workStore: WorkDataStore;
  readonly workTransport: WorkTransportService;

  constructor(readonly environment: EnvironmentService) {
    if (!environment.isLoaded) {
      throw new Error('The environment service must be loaded before instantiating the service provider.');
    }

    this.attachmentService = new AppAttachmentService(environment);
    this.pasteboard = new AppPasteboardService();
    this.dateService = new AppDateService();
    this.navigation = new AppNavigationService();

    this.user = new AppUserService();

    this.settingsStorage = new AppApplicationSettingsStorage(this.user, this.dateService);

    this.storeInvalidator = new AppStoreInvalidator(this.settingsStorage);

    this.localization = new AppLocalizationService(this.attachmentService, this.settingsStorage, this.user);

    this.featureFlag = new UnleashFeatureFlagService(this.environment, this.user);

    this.firebase =
      this.environment.environmentKind === 'dev'
        ? new DevFirebaseService(this.environment, this.localization)
        : new FirebaseService(this.environment, this.localization);

    this.grpcTransport = new AppGrpcTransportService(this.environment);

    this.classroomTransport = new AppClassroomTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.curriculumTransport = new AppCurriculumTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.googleCalendarTransport = new AppGoogleCalendarTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.plannerTransport = new AppPlannerTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.scheduleCycleTransport = new AppScheduleCycleTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.schoolTransport = new AppSchoolTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.subscriptionsTransport = new AppSubscriptionsTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.userTransport = new AppUserTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.workTransport = new AppWorkTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.demoTransport = new AppDemoTransportService(
      this.firebase,
      this.localization,
      this.settingsStorage,
      this.grpcTransport
    );

    this.theme = new AppThemeService(this.settingsStorage);

    this.route = new AppRouteService();

    this.network = new AppNetworkService();

    this.autoSync = new AppAutoSyncService(this.network, this.dateService);

    this.intercom = new AppIntercomService(this.environment, this.userTransport, this.user);

    this.googleTagManager = new AppGoogleTagManagerService(this.environment);

    this.mixpanel = new AppMixpanelService(this.environment, this.user);

    this.analytics = new AppAnalyticsService(this.intercom, this.firebase, this.mixpanel, this.userTransport);

    this.unsplash = new AppUnsplashTransportService(this.localization, this.environment);

    this.settings = new AppApplicationSettingsService(this.settingsStorage);

    this.gapi = new AppGapiService(this.environment);

    this.authentication = new AppAuthenticationService(this.analytics, this.firebase, this.user, this.userTransport);

    this.plannerStore = new AppPlannerDataStore(
      this.plannerTransport,
      this.scheduleCycleTransport,
      this.localization,
      this.storeInvalidator,
      this.settings,
      this.settingsStorage,
      this.user
    );

    this.schoolStore = new AppSchoolDataStore(
      this.schoolTransport,
      this.user,
      this.userTransport,
      this.workTransport,
      this.demoTransport,
      this.storeInvalidator,
      this.localization,
      this.dateService,
      this.settingsStorage
    );

    this.userStore = new AppUserDataStore(
      this.plannerTransport,
      this.scheduleCycleTransport,
      this.schoolTransport,
      this.subscriptionsTransport,
      this.user,
      this.userTransport,
      this.authentication,
      this.featureFlag,
      this.localization,
      this.storeInvalidator,
      this.settingsStorage
    );

    this.workloadStore = new AppWorkloadDataStore(this.schoolTransport);

    this.workStore = new AppWorkDataStore(
      this.workTransport,
      this.user,
      this.storeInvalidator,
      this.settingsStorage,
      this.localization
    );

    this.curriculumStore = new AppCurriculumDataStore(this.plannerStore, this.curriculumTransport);

    this.connectedApps = new AppConnectedAppsService(
      this.userStore,
      this.user,
      this.autoSync,
      this.classroomTransport,
      this.googleCalendarTransport,
      this.gapi
    );

    this.monitoring = new AppMonitoringService(
      this.intercom,
      this.firebase,
      this.user,
      this.connectedApps,
      this.analytics,
      this.authentication,
      this.userStore,
      this.localization
    );

    this.syncService = new AppSyncService(
      this.autoSync,
      this.curriculumStore,
      this.userStore,
      this.user,
      this.plannerStore,
      this.connectedApps
    );

    this.cookiesService = new AppCookiesService(this.environment, this.firebase, this.user);
  }
}
