import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { captureException } from '@sentry/react';
import { compareAsc } from 'date-fns';
import { chain, groupBy } from 'lodash';
import { action, computed, makeObservable, observable, ObservableSet, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { comparePlannerCourseSectionDetails, timestampDateOptional } from '../../models';
import { ServiceContainer } from '../../providers';
import { toggleItemInSet } from '../../utils';
import { UpdatableViewModelState } from '../shared';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { BaseItemDuplicationDialogViewModel } from './BaseItemDuplicationDialogViewModel';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerItemKind } from './PlannerItemKind';

export interface PlannerDeleteDuplicatedItemSectionItemInfo {
  readonly id: string;
  readonly dueDate: Date | undefined;
  readonly isAllDay: boolean;
  readonly courseSectionId: string;
  readonly kind: PlannerItemKind;
  readonly syncToken: bigint;
}

export interface PlannerDeleteDuplicatedItemSectionInfo {
  readonly courseSection: CourseSectionDetails | undefined;
  readonly items: PlannerDeleteDuplicatedItemSectionItemInfo[];
}

export interface PlannerDeleteDuplicatedItemViewModel extends UpdatableDialogViewModel {
  readonly item: PlannerCopyItemInfo;
  readonly sections: PlannerDeleteDuplicatedItemSectionInfo[];
  readonly selectedItemIds: string[];
  toggleSelection(itemId: string): void;
}

export abstract class AppPlannerDeleteDuplicatedItemViewModel
  extends BaseItemDuplicationDialogViewModel
  implements PlannerDeleteDuplicatedItemViewModel
{
  @observable private _isSaving = false;
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _originalItem: PlannerCopyItemInfo | undefined;
  @observable private _allItems: PlannerDeleteDuplicatedItemSectionItemInfo[] = [];
  @observable private _sections: PlannerDeleteDuplicatedItemSectionInfo[] = [];
  private readonly _selectedItemIds: ObservableSet<string>;

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    plannerId: string,
    itemKind: PlannerItemKind,
    protected readonly _duplicationIds: string[],
    onDismiss: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    plannerStore = ServiceContainer.services.plannerStore,
    workStore = ServiceContainer.services.workStore
  ) {
    super(plannerId, itemKind, onDismiss, localization, plannerStore, workStore);
    makeObservable(this);
    this._selectedItemIds = observable.set([itemKind.id]);

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.archiveDuplicatedItem.submitButtonTitle(),
      'contained',
      () => this.save(),
      undefined,
      'submit',
      undefined,
      'error'
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = !this._isSaving && this.hasChanges;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get item(): PlannerCopyItemInfo {
    return this._originalItem!;
  }

  @computed
  get selectedItemIds(): string[] {
    return Array.from(this._selectedItemIds);
  }

  @computed
  get sections(): PlannerDeleteDuplicatedItemSectionInfo[] {
    return this._sections;
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }

  @computed
  get hasChanges(): boolean {
    return this._selectedItemIds.size > 0;
  }

  get isSubmitting(): boolean {
    return this._isSaving;
  }

  @computed
  get hasData(): boolean {
    return this._originalItem != null;
  }

  async reloadData(): Promise<void> {
    runInAction(() => (this._state = 'pending'));

    try {
      const originalItem = await this.loadItem();
      const allItems = await this.getAllItems();
      const itemsByCourseSection = groupBy(allItems, 'courseSectionId');
      const sections = Object.entries(itemsByCourseSection)
        .map(([courseSectionId, items]) => ({
          courseSection: this.courseSectionsLoadable.data.get(courseSectionId),
          items
        }))
        .sort((section1, section2) => {
          if (section1.courseSection == null || section2.courseSection == null) {
            // Showing with no course section items at the end
            return section1.courseSection == null ? 1 : -1;
          }

          return comparePlannerCourseSectionDetails(
            section1.courseSection,
            section2.courseSection,
            this._localization.currentLocale
          );
        });

      sections.forEach((section) => {
        section.items.sort((a, b) => {
          if (a.dueDate == null || b.dueDate == null) {
            // Showing items with no due date at the end
            return a.dueDate == null ? 1 : -1;
          }
          return compareAsc(a.dueDate, b.dueDate);
        });
      });

      runInAction(() => {
        this._originalItem = originalItem.item;
        this._allItems = allItems;
        this._sections = sections;
        this._state = 'fulfilled';
      });
    } catch (e) {
      captureException(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  @action
  toggleSelection(itemId: string) {
    toggleItemInSet(itemId, this._selectedItemIds);
  }

  private async save() {
    try {
      runInAction(() => {
        this._isSaving = true;
        this._error = undefined;
      });

      const promises = chain(this.selectedItemIds)
        .map((id) => this._allItems.find((item) => item.id === id))
        .compact()
        .map((item) => {
          switch (item.kind.case) {
            case 'work':
              return this.cancelWork(item.id, item.syncToken);
            case 'note':
              return this.cancelNote(item.id, item.syncToken);
            case 'publishedWork':
              return this.cancelPublishedWork(item.id, item.kind.schoolId, item.syncToken);
          }
        })
        .value();

      await Promise.all(promises);
      runInAction(() => (this._isSaving = false));
      await this.dismiss();
    } catch (e) {
      captureException(e);
      runInAction(() => {
        this._error = (e as Error).message;
        this._isSaving = false;
      });
    }
  }

  protected abstract getAllItems(): Promise<PlannerDeleteDuplicatedItemSectionItemInfo[]>;

  private cancelWork(id: string, syncToken: bigint) {
    return this._workStore.setWorkStatus(id, WorkStatus.CANCELLED, syncToken);
  }

  private cancelNote(id: string, syncToken: bigint) {
    return this._workStore.setNoteStatus(id, true, syncToken);
  }

  private cancelPublishedWork(id: string, schoolId: string, syncToken: bigint) {
    return this._workStore.setPublishedWorkStatus(id, schoolId, PublishedWorkStatus.CANCELLED, undefined, syncToken);
  }
}

export class AppWorkPlannerDeleteDuplicatedItemViewModel extends AppPlannerDeleteDuplicatedItemViewModel {
  protected async getAllItems(): Promise<PlannerDeleteDuplicatedItemSectionItemInfo[]> {
    const duplicationDetails = await this._workStore.getWorkDuplicationDetails(
      this._duplicationIds,
      this._plannerId,
      true
    );

    return chain([...duplicationDetails.duplicatedItems, ...duplicationDetails.relatedDuplicatedItems])
      .map<PlannerDeleteDuplicatedItemSectionItemInfo>((work) => ({
        id: work.id,
        courseSectionId: work.courseSectionId,
        dueDate: timestampDateOptional(work.dueTime),
        isAllDay: work.isDueAllDay,
        kind: { case: 'work', id: work.id },
        syncToken: work.syncToken
      }))
      .uniqBy('id')
      .value();
  }
}

export class AppNotePlannerDeleteDuplicatedItemViewModel extends AppPlannerDeleteDuplicatedItemViewModel {
  protected async getAllItems(): Promise<PlannerDeleteDuplicatedItemSectionItemInfo[]> {
    const duplicationDetails = await this._workStore.getNoteDuplicationDetails(
      this._duplicationIds,
      this._plannerId,
      true
    );

    return chain([...duplicationDetails.duplicatedItems, ...duplicationDetails.relatedDuplicatedItems])
      .map<PlannerDeleteDuplicatedItemSectionItemInfo>((note) => ({
        id: note.id,
        courseSectionId: note.courseSectionId,
        dueDate: timestampDateOptional(note.time),
        isAllDay: note.isAllDay,
        kind: { case: 'note', id: note.id },
        syncToken: note.syncToken
      }))
      .uniqBy('id')
      .value();
  }
}

export class AppPublishedWorkPlannerDeleteDuplicatedItemViewModel extends AppPlannerDeleteDuplicatedItemViewModel {
  protected async getAllItems(): Promise<PlannerDeleteDuplicatedItemSectionItemInfo[]> {
    if (this._itemKind.case !== 'publishedWork') {
      return [];
    }

    const duplicationDetails = await this._workStore.getPublishedWorkDuplicationDetails(
      this._duplicationIds,
      this._itemKind.schoolId,
      this._plannerId,
      true
    );

    return chain([...duplicationDetails.duplicatedItems, ...duplicationDetails.relatedDuplicatedItems])
      .map<PlannerDeleteDuplicatedItemSectionItemInfo>((work) => ({
        id: work.id,
        courseSectionId: work.courseSectionId,
        dueDate: timestampDateOptional(work.dueTime),
        isAllDay: work.isDueAllDay,
        kind: { case: 'publishedWork', id: work.id, schoolId: work.schoolId },
        syncToken: work.syncToken
      }))
      .uniqBy('id')
      .value();
  }
}
