import {
  AllPlannerItemsPasteboardContentKind,
  FeatureFlagService,
  PasteboardService,
  UserDashboardPlannerItemsLocationState
} from '@/services';
import { PlannerDataStore, WorkDataStore } from '@/stores';
import { CourseSectionOccurrence } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_occurrence_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { timestampFromDate } from '@bufbuild/protobuf/wkt';
import { PersonRounded, SendRounded, StickyNote2Rounded } from '@mui/icons-material';
import { set } from 'date-fns';
import LocalizedStrings from 'strings';
import { ContextMenuAction, ContextMenuActionsGroup } from './ContextMenuAction';

export function contextMenuActionsForWork(
  work: Work,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  workStore: WorkDataStore,
  plannerStore: PlannerDataStore,
  featureFlag: FeatureFlagService
): ContextMenuAction[] | ContextMenuActionsGroup[] {
  function copy() {
    pasteboard.setContent({ case: 'work', value: work });
    return Promise.resolve();
  }

  async function markAsDone() {
    await workStore.setWorkStatus(work.id, WorkStatus.COMPLETED, work.syncToken);
    await plannerStore.fetchPlannerContents(work.plannerId);
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  return [
    {
      icon: { case: 'predefined', value: 'edit' },
      hidden: isReadOnly,
      onClick: { state: { workEdit: { workId: work.id } } as UserDashboardPlannerItemsLocationState }
    },
    {
      icon: { case: 'predefined', value: 'plan-work-session' },
      hidden: isReadOnly,
      onClick: { state: { plannedWorkEdit: { workId: work.id } } as UserDashboardPlannerItemsLocationState }
    },
    {
      icon: { case: 'predefined', value: 'copy' },
      hidden: !isItemDuplicationEnabled,
      onClick: copy
    },
    {
      icon: { case: 'predefined', value: 'duplicate' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          workEdit: { workId: undefined, baseParams: { ...work, dueTime: undefined, isDueAllDay: true } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'repeat' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          repeatItem: { kind: { case: 'work', id: work.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'distribute' },
      hidden:
        !isItemDuplicationEnabled ||
        work.dueTime == null ||
        work.courseSectionId.length === 0 ||
        work.creatorUserId.length === 0,
      onClick: {
        state: {
          distributeItem: { kind: { case: 'work', id: work.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'complete' },
      onClick: work.isStatusChangedExternally
        ? { externalUrl: work.externalSource?.url, target: '_blank' }
        : markAsDone,
      hidden: work.status !== WorkStatus.ACTIVE || (work.isStatusChangedExternally && work.externalSource?.url == null)
    }
  ];
}

export function contextMenuActionsForPlannedWork(
  work: Work,
  plannedWork: PlannedWork,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  workStore: WorkDataStore,
  featureFlag: FeatureFlagService
): ContextMenuAction[] | ContextMenuActionsGroup[] {
  function copy() {
    pasteboard.setContent({ case: 'work', value: work });
    return Promise.resolve();
  }

  function markAsDone() {
    return workStore.setWorkStatus(work.id, WorkStatus.COMPLETED, work.syncToken);
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  return [
    {
      icon: { case: 'predefined', value: 'edit' },
      hidden: isReadOnly,
      onClick: { state: { workEdit: { workId: work.id } } as UserDashboardPlannerItemsLocationState }
    },
    {
      icon: { case: 'predefined', value: 'edit-work-session' },
      hidden: isReadOnly,
      onClick: {
        state: { plannedWorkEdit: { workId: work.id, id: plannedWork.id } } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'copy' },
      hidden: !isItemDuplicationEnabled,
      onClick: copy
    },
    {
      icon: { case: 'predefined', value: 'duplicate' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          workEdit: { workId: undefined, baseParams: { ...work, dueTime: undefined, isDueAllDay: true } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'repeat' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          repeatItem: { kind: { case: 'work', id: work.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'distribute' },
      hidden: !isItemDuplicationEnabled || work.dueTime == null || work.courseSectionId.length === 0,
      onClick: {
        state: {
          distributeItem: { kind: { case: 'work', id: work.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'complete' },
      onClick: markAsDone,
      hidden: work.status !== WorkStatus.ACTIVE
    }
  ];
}

export function contextMenuActionsForPublishedWork(
  work: PublishedWork,
  isReadOnly: boolean,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): ContextMenuAction[] | ContextMenuActionsGroup[] {
  function copy() {
    pasteboard.setContent({ case: 'published-work', value: work });
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  return [
    {
      icon: { case: 'predefined', value: 'edit' },
      hidden: isReadOnly || !work.canExternalSourceBeUpdated,
      onClick: { state: { workEdit: { workId: work.id } } as UserDashboardPlannerItemsLocationState }
    },
    {
      icon: { case: 'predefined', value: 'copy' },

      hidden: !isItemDuplicationEnabled,
      onClick: copy
    },
    {
      icon: { case: 'predefined', value: 'duplicate' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          publishedWorkEdit: {
            ids: undefined,
            newWorkDefaultValues: { ...work, dueTime: undefined, isDueAllDay: true }
          }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'repeat' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          repeatItem: { kind: { case: 'publishedWork', id: work.id, schoolId: work.schoolId } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'distribute' },
      hidden: !isItemDuplicationEnabled || work.dueTime == null || work.courseSectionId.length === 0,
      onClick: {
        state: {
          distributeItem: { kind: { case: 'publishedWork', id: work.id, schoolId: work.schoolId } }
        } as UserDashboardPlannerItemsLocationState
      }
    }
  ];
}

export function contextMenuActionsForNote(
  note: Note,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): ContextMenuAction[] | ContextMenuActionsGroup[] {
  function copy() {
    pasteboard.setContent({ case: 'note', value: note });
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  return [
    {
      icon: { case: 'predefined', value: 'copy' },
      hidden: !isItemDuplicationEnabled,
      onClick: copy
    },
    {
      icon: { case: 'predefined', value: 'duplicate' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          noteEdit: { id: undefined, baseParams: { ...note, time: undefined, isAllDay: true } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'repeat' },
      hidden: !isItemDuplicationEnabled,
      onClick: {
        state: {
          repeatItem: { kind: { case: 'note', id: note.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    },
    {
      icon: { case: 'predefined', value: 'distribute' },
      hidden: !isItemDuplicationEnabled || note.time == null || note.courseSectionId.length === 0,
      onClick: {
        state: {
          distributeItem: { kind: { case: 'note', id: note.id } }
        } as UserDashboardPlannerItemsLocationState
      }
    }
  ];
}

export function contextMenuActionsForCourseOccurrence(
  occurrence: CourseSectionOccurrence,
  date: Date,
  isReadOnly: boolean,
  canCreatePublishedWork: boolean,
  pasteboard: PasteboardService,
  featureFlag: FeatureFlagService
): ContextMenuAction[] | ContextMenuActionsGroup[] {
  if (isReadOnly) {
    return [];
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');
  const resolvedDueTime = set(date, { hours: occurrence.startTime!.hours, minutes: occurrence.startTime!.minutes });

  return [
    {
      title: () => LocalizedStrings.planner.contextMenuOptions.createItemInPeriodSectionTitle(),
      actions: [
        {
          icon: { case: 'custom', value: <PersonRounded /> },
          title: () => LocalizedStrings.planner.contextMenuOptions.createWork(),
          onClick: {
            state: {
              workEdit: {
                workId: undefined,
                baseParams: {
                  courseSectionId: occurrence.courseSectionId,
                  dueTime: timestampFromDate(resolvedDueTime),
                  isDueAllDay: false
                }
              }
            } as UserDashboardPlannerItemsLocationState
          }
        },
        {
          icon: { case: 'custom', value: <StickyNote2Rounded /> },
          title: () => LocalizedStrings.planner.contextMenuOptions.createNote(),
          onClick: {
            state: {
              noteEdit: {
                id: undefined,
                baseParams: {
                  courseSectionId: occurrence.courseSectionId,
                  time: timestampFromDate(resolvedDueTime),
                  isAllDay: false
                }
              }
            } as UserDashboardPlannerItemsLocationState
          }
        },
        {
          icon: { case: 'custom', value: <SendRounded /> },
          title: () => LocalizedStrings.planner.contextMenuOptions.createPublishedWork(),
          hidden: !canCreatePublishedWork,
          onClick: {
            state: {
              publishedWorkEdit: {
                ids: undefined,
                baseParams: {
                  courseSectionId: occurrence.courseSectionId,
                  dueTime: timestampFromDate(resolvedDueTime),
                  isDueAllDay: false
                }
              }
            } as UserDashboardPlannerItemsLocationState
          }
        }
      ]
    },
    {
      actions: [
        {
          icon: { case: 'predefined', value: 'paste' },
          hidden:
            !isItemDuplicationEnabled ||
            pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind) == null,
          onClick: () => {
            pasteboard.setPasteContext({ date: date, period: occurrence });
            return Promise.resolve();
          }
        }
      ]
    }
  ];
}
