import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { createPlannerSchoolInformation } from '../../models';
import { SchoolTransportService } from '../../transports';
import { BaseLoadableValue, LoadableValue } from './Loadable';

export type SchoolLoadable = LoadableValue<SchoolInformation>;

export class AppSchoolLoadable extends BaseLoadableValue<SchoolInformation> implements SchoolLoadable {
  constructor(
    private readonly _schoolId: string,
    initialValue: SchoolInformation | undefined,
    private readonly _schoolTransport: SchoolTransportService
  ) {
    super(initialValue);
  }

  protected async loadData(): Promise<SchoolInformation> {
    const school = await this._schoolTransport.getSchool(this._schoolId);
    return createPlannerSchoolInformation({ school });
  }
}
