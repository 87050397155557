import { useActivePlannerId, useServices } from '@/hooks';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PopoverProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import LocalizedStrings from 'strings';
import { comparePlannerCourseSectionDetails } from '../../../models';
import { UserDashboardCalendarFiltersCourseItem } from '../../shared';
import { OptionsPopover, OptionsPopoverItem, OptionsPopoverSection } from '../../utils';

export interface PlannerWorkloadOptionsPopoverProps extends Omit<PopoverProps, 'children'> {
  close: () => void;
  courseSections: CourseSectionDetails[];
}

export const PlannerWorkloadOptionsPopover = observer(
  ({ courseSections, ...props }: PlannerWorkloadOptionsPopoverProps) => {
    const plannerId = useActivePlannerId();
    const { localization, settings, settingsStorage } = useServices();
    const strings = LocalizedStrings.planner.workload;

    const [courseIds, setCourseIds] = useState<string[] | undefined>();

    useEffect(() => {
      const getCourseIds = async () => {
        const ids = await settingsStorage.plannerWorkloadDisplayedCourseSectionsForUserDashboard(plannerId);
        setCourseIds(ids);
      };

      void getCourseIds();
    }, [plannerId]);

    function resetOptions() {
      settings.plannerWorkloadShowWeekends = true;
      onCourseSelectionChanged(undefined);
    }

    function toggleVisibilityOfCourseSection(courseId: string) {
      if (courseIds == null) {
        const allIds = courseSections.map((c) => c.courseSection!.id);
        const otherIds = allIds.filter((id) => id !== courseId);
        onCourseSelectionChanged(otherIds);
      } else {
        const newValues = Array.from(courseIds);
        const idIndex = courseIds.indexOf(courseId);

        if (idIndex < 0) {
          newValues.push(courseId);
        } else {
          newValues.splice(idIndex, 1);
        }

        onCourseSelectionChanged(newValues);
      }
    }

    function onCourseSelectionChanged(ids: string[] | undefined) {
      // Cannot unselect all courses
      if (ids == null || ids.length > 0) {
        setCourseIds(ids);
        settingsStorage.setPlannerWorkloadDisplayedCourseSectionsForUserDashboard(ids, plannerId);
      }
    }

    return (
      <OptionsPopover {...props} title={strings.optionsPopoverTitle()} onResetOptions={resetOptions}>
        <OptionsPopoverSection>
          <OptionsPopoverItem
            title={strings.showWeekendsOption()}
            isSelected={settings.plannerWorkloadShowWeekends}
            onClick={() => (settings.plannerWorkloadShowWeekends = !settings.plannerWorkloadShowWeekends)}
          />
        </OptionsPopoverSection>

        {courseSections.length > 0 && (
          <OptionsPopoverSection title={strings.displayedClassesFilterLabel()}>
            {courseSections
              .sort((cs1, cs2) => comparePlannerCourseSectionDetails(cs1, cs2, localization.currentLocale))
              .map((courseSection) => {
                const { id, title, section, color } = courseSection.courseSection!;

                return (
                  <UserDashboardCalendarFiltersCourseItem
                    key={id}
                    title={title}
                    subtitle={section}
                    isSelected={courseIds == null || courseIds.includes(id)}
                    onClick={() => toggleVisibilityOfCourseSection(id)}
                    color={color}
                  />
                );
              })}
          </OptionsPopoverSection>
        )}
      </OptionsPopover>
    );
  }
);
