import { css } from '@emotion/css';
import { Link as MUILink, Typography } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router';

export interface AdminTableCellLinkProps {
  style?: CSSProperties;
  className?: string;
  value: string;
  link: string;
  isExternal?: boolean;
}

export const AdminTableCellLink = observer(
  ({ style, link, isExternal = false, className, value }: AdminTableCellLinkProps) => {
    const renderText = () => (
      <Typography
        sx={{
          color: 'inherit'
        }}
      >
        {value}
      </Typography>
    );

    const baseClassName = css({
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    });

    if (isExternal) {
      return (
        <MUILink
          style={style}
          className={clsx(className, baseClassName)}
          href={link}
          target="_blank"
          rel="noreferrer"
          underline="hover"
          sx={{
            color: 'inherit'
          }}
        >
          {renderText()}
        </MUILink>
      );
    } else {
      const linkClassName = css({
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      });

      return (
        <RouterLink style={style} className={clsx(className, linkClassName, baseClassName)} to={link}>
          {renderText()}
        </RouterLink>
      );
    }
  }
);
